@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]
Project                        :    Ynex - Nextjs Typescript TailwindCss Admin & Dashboard Template

Created Date                   :   29/05/2024

Author & Copyright Ownership   :   Spruko Technologies Private Limited 

Author URL                     :   https://themeforest.net/user/spruko

Support	                       :   https://support.spruko.com/

License Details                :   https://spruko.com/licenses-details

-------------------------------------------------------------------*/
/*--- Start Table of Content ---
Font-family
Icons
Variables
Authentication
Dashboard_styles
Error
Header
Landing
Job_landing
Horizontal
Icon_click
Icon_hover
Menu_click
Menu_hover
Vertical
Chat
Pages_styles
Task
Calendar
Datatable
Plugins
Bg-img-styles
Boxed
Classic-page-styles
Closed_menu
Detached_menu
Double_menu
Header-scrollable
Header-styles
Icon-overlay
Icontext
Loader
Menu-scrollable
Menu-styles
Accordion
Alerts
Avatars
Breadcrumb
Buttons
Cards
Carousels
Components
Custom
Dropdown
Forms
Modal
Offcanvas
Pagination
Progress
Tables
Tailwind
Tooltip
Toast
--- End Table of Content ---*/
/* Start FontFamily Styles */
/* inter */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
/* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap");
/* End Tailwind Styles Styles */
/* Start Icons */
@import url("../iconfonts/RemixIcons/fonts/remixicon.css");
@import url("../iconfonts/tabler-icons/iconfont/tabler-icons.css");
@import url("../iconfonts/feather/feather.css");
@import url("../iconfonts/bootstrap-icons/icons/font/bootstrap-icons.css");
@import url("../iconfonts/line-awesome/1.3.0/css/line-awesome.css");
@import url("../iconfonts/boxicons/css/boxicons.css");
/* Start Icons */
:root {
  --body-bg: 240 241 247;
  --primary: 132 90 223;
  --primary-rgb: 132, 90, 223;
  --secondary: 35 183 229;
  --warning: 245 184 73;
  --info: 73 182 245;
  --success: 38 191 148;
  --danger: 230 83 60;
  --light: 243 246 248;
  --dark: 35 35 35;
  --orange: 255 165 5;
  --pink: 231 145 188;
  --teal: 18 194 194;
  --purple: 137 32 173;
  --default-text-color: 51 51 53;
  --default-border: 243 243 243;
  --default-background: 247 248 249;
  --menu-prime-color: 83 100 133;
  --menu-border-color: 243 243 243;
  --header-prime-color: 83 100 133;
  --header-border-color: 243 243 243;
  --bootstrap-card-border: 243 243 243;
  --list-hover-focus-bg: 245 246 247;
  --text-muted: 140 144 151;
  --input-border: 233 237 246;
  --red: 208 61 70;
  --blue: 43 62 101;
  --green: 29 216 113;
  --cyan: 0 209 209;
  --indigo: 77 93 219;
  --gray: 134 153 163;
  --yellow: 255 193 2;
  --facebook: 59 89 152;
  --twitter: 0 172 238;
  --github: 51 51 51;
  --google: 207 78 67;
  --youtube: 255 0 0;
  --bs-width: 1px;
}

/* dark mode */
.dark {
  --body-bg: 26 28 30;
  --dark-bg: 37 41 48;
  --light: 43 46 49;
  --dark: 240 245 248;
  --input-border: 49 51 53;
  --form-control-bg: 35 38 40;
  --default-text-color: 255 255 255;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #131314;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #e3e3e3;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  border-color: #8e918f;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #13131461;
  border-color: #8e918f1f;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #e3e3e31f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: white;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: white;
  opacity: 8%;
}

/* Start Tailwind Styles */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* End Tailwind Styles */
/* CUSTOM STYLES */
.authentication {
  @apply min-h-screen;
}
.authentication .authentication-brand.desktop-logo {
  @apply block;
}
.authentication .authentication-brand.desktop-dark {
  @apply hidden;
}
.authentication .swiper-button-next,
.authentication .swiper-button-prev {
  @apply w-[1.563rem] h-[1.563rem] text-white bg-black/30 rounded-md !important;
}
.authentication .swiper-button-next:after,
.authentication .swiper-button-next:before,
.authentication .swiper-button-prev:after,
.authentication .swiper-button-prev:before {
  @apply text-white !important;
}
.authentication .swiper-pagination-bullet {
  @apply w-[1.25rem] h-[0.25rem] rounded-md bg-white dark:bg-bodybg !important;
}
.authentication .swiper-pagination-bullet-active {
  @apply bg-white dark:bg-bodybg !important;
}
.authentication .swiper-pagination-bullet {
  @apply opacity-[0.1];
}
.authentication .swiper-pagination-bullet-active {
  @apply opacity-[0.5];
}
.authentication .google-svg {
  @apply w-3 h-3 me-2;
}
.authentication .authentication-barrier {
  @apply relative before:absolute before:w-[45%] before:h-[0.125rem] before:bg-gradient-to-l before:from-transparent before:to-light before:rounded-full before:end-0 before:top-[0.563rem] before:z-[1] after:absolute after:w-[45%] after:h-[0.125rem] after:bg-gradient-to-l after:from-light after:to-transparent after:rounded-full after:start-0 after:top-[0.563rem] after:z-[1];
}
.authentication .authentication-barrier span {
  @apply relative z-[2];
}
.authentication.coming-soon .authentication-cover,
.authentication.under-maintenance .authentication-cover {
  @apply bg-[url("../public/assets/images/media/media-19.jpg")];
}
.authentication.coming-soon
  .authentication-cover
  .aunthentication-cover-content,
.authentication.under-maintenance
  .authentication-cover
  .aunthentication-cover-content {
  @apply w-full h-full p-[3rem] backdrop:filter-none bg-white dark:bg-bodybg;
}
.authentication.coming-soon
  .authentication-cover
  .aunthentication-cover-content:before,
.authentication.coming-soon
  .authentication-cover
  .aunthentication-cover-content:after,
.authentication.under-maintenance
  .authentication-cover
  .aunthentication-cover-content:before,
.authentication.under-maintenance
  .authentication-cover
  .aunthentication-cover-content:after {
  @apply hidden;
}
.authentication.coming-soon
  .authentication-cover
  .aunthentication-cover-content
  .coming-soon-time,
.authentication.coming-soon
  .authentication-cover
  .aunthentication-cover-content
  .under-maintenance-time,
.authentication.under-maintenance
  .authentication-cover
  .aunthentication-cover-content
  .coming-soon-time,
.authentication.under-maintenance
  .authentication-cover
  .aunthentication-cover-content
  .under-maintenance-time {
  @apply border-2 border-dashed border-black/10;
}
.authentication.coming-soon
  .authentication-cover
  .aunthentication-cover-content
  .authentication-brand,
.authentication.under-maintenance
  .authentication-cover
  .aunthentication-cover-content
  .authentication-brand {
  @apply w-auto h-auto border-0;
}
.authentication .coming-soom-image-container img,
.authentication .under-maintenance-image-container img {
  @apply w-full h-auto;
}
.authentication .authentication-cover {
  @apply bg-[url("../public/assets/images/authentication/1.jpg")] bg-cover bg-center bg-no-repeat w-full h-full flex items-center justify-center relative before:absolute before:w-full before:h-full before:inset-0 before:bg-primary/10;
}
.authentication .authentication-cover .aunthentication-cover-content {
  @apply w-[70%] h-[37.5rem] bg-black/20 p-8 backdrop-blur-[1.875rem] relative !important;
}
.authentication .authentication-cover .aunthentication-cover-content img {
  @apply my-0 mx-auto w-[13.75rem] h-[13.75rem];
}
.authentication.authentication-basic .desktop-dark {
  @apply hidden;
}
.authentication.authentication-basic .desktop-logo {
  @apply block;
}

.dark .authentication.authentication-basic .desktop-dark {
  @apply block;
}
.dark .authentication.authentication-basic .desktop-logo {
  @apply hidden;
}
.dark .authentication .authentication-brand.desktop-logo {
  @apply hidden;
}
.dark .authentication .authentication-brand.desktop-dark {
  @apply block;
}

.main-index footer {
  @apply ps-0 !important;
}

.box.crm-highlight-card {
  @apply relative overflow-hidden shadow-none after:absolute after:h-full after:w-full after:bg-[url("../public/assets/images/media/media-65.png")] after:bg-cover after:bg-center after:bg-no-repeat after:z-[0] after:opacity-20;
  @apply bg-primary !important;
}
.box.crm-highlight-card .box-body {
  @apply z-10;
}

.crm-lead-legend {
  @apply relative before:absolute before:w-[0.375rem] before:h-[0.375rem] before:rounded-md before:top-[0.375rem] before:-start-[0.625rem];
}
.crm-lead-legend.mobile:before {
  @apply bg-primary;
}
.crm-lead-legend.desktop:before {
  @apply bg-secondary;
}
.crm-lead-legend.laptop:before {
  @apply bg-warning;
}
.crm-lead-legend.tablet:before {
  @apply bg-success;
}

.crm-leadsinprogress {
  @apply mb-0;
}
.crm-leadsinprogress li {
  @apply mb-[1.3rem];
}
.crm-leadsinprogress li:last-child {
  @apply mb-0;
}

.leads-source-chart canvas {
  @apply relative;
}
.leads-source-chart .lead-source-value {
  @apply absolute text-center;
}

#leads-source {
  @apply h-[15.3rem] w-auto !important;
}

.crm-deals-status li {
  @apply relative mb-4 ms-4 before:absolute before:w-2 before:h-2 before:top-[0.375rem] before:-start-4 before:rounded-[50%];
}
.crm-deals-status li:last-child {
  @apply mb-0;
}
.crm-deals-status li.primary:before {
  @apply bg-primary;
}
.crm-deals-status li.info:before {
  @apply bg-info;
}
.crm-deals-status li.warning:before {
  @apply bg-warning;
}
.crm-deals-status li.success:before {
  @apply bg-success;
}

.crm-timeline-content {
  @apply w-[15.5rem];
}

.crm-recent-activity li {
  @apply pb-[1.3rem];
}
.crm-recent-activity li:last-child {
  @apply pb-0;
}
.crm-recent-activity .crm-timeline-content {
  @apply w-[15.5rem];
}

.crm-recent-activity-content {
  @apply relative before:absolute before:w-[1px] before:bg-transparent before:top-[1.4375rem] before:start-[0.5625rem];
}
.crm-recent-activity-content:nth-child(1) {
  @apply before:border-e before:border-dashed before:border-primary/20 before:h-[60%];
}
.crm-recent-activity-content:nth-child(2) {
  @apply before:border-e before:border-dashed before:border-secondary/20 before:h-[68%];
}
.crm-recent-activity-content:nth-child(3) {
  @apply before:border-e before:border-dashed before:border-success/20 before:h-[42%];
}
.crm-recent-activity-content:nth-child(4) {
  @apply before:border-e before:border-dashed before:border-pinkmain/20 before:h-[60%];
}
.crm-recent-activity-content:nth-child(5) {
  @apply before:border-e before:border-dashed before:border-warning/20 before:h-[47%];
}
.crm-recent-activity-content:nth-child(6) {
  @apply before:border-e before:border-dashed before:border-info/20 before:h-[60%];
}
.crm-recent-activity-content:nth-child(7) {
  @apply before:border-e before:border-dashed before:border-[#232323]/20 dark:before:border-white/20 before:h-[50%];
}
.crm-recent-activity-content:last-child {
  @apply before:hidden;
}

#crm-main circle {
  @apply fill-transparent;
}

#crm-main .apexcharts-datalabels-group text {
  @apply fill-white/90;
}

#crm-main #apexcharts-radialbarTrack-0 {
  @apply stroke-black/20;
}

#crm-revenue-analytics .apexcharts-title-text {
  @apply translate-y-[0.625rem];
}

.apexcharts-bar-series.apexcharts-plot-series
  .apexcharts-series
  .apexcharts-bar-area {
  @apply stroke-defaultborder !important;
}

.apexcharts-legend-text {
  @apply text-defaulttextcolor ps-[0.9375rem] -ms-[0.625rem] !important;
}

.apexcharts-text.apexcharts-yaxis-label tspan,
.apexcharts-text.apexcharts-xaxis-label tspan {
  @apply fill-[#8c9097];
}

.ecommerce-icon svg {
  @apply w-[1.75rem] h-[1.75rem] p-[0.35rem] rounded-md;
}
.ecommerce-icon svg.primary {
  @apply bg-primary;
}
.ecommerce-icon svg.secondary {
  @apply bg-secondary;
}
.ecommerce-icon svg.success {
  @apply bg-success;
}
.ecommerce-icon svg.warning {
  @apply bg-warning;
}

.svg-white {
  @apply fill-white;
}

.ecommerce-sale-image {
  @apply relative before:absolute before:w-full before:h-full before:bg-black/60;
}
.ecommerce-sale-image img {
  @apply h-[17.3rem];
}

.card-img-overlay {
  @apply absolute inset-0 flex flex-col;
}

.card-img,
.card-img-bottom,
.card-img-top {
  @apply w-full;
}

.earning {
  @apply relative;
}
.earning:before {
  @apply absolute top-[0.438rem] -start-[0.818rem] bottom-[0.313rem] w-[0.5rem] h-[0.5rem] rounded-[3.125rem];
}
.earning.first-half {
  @apply before:bg-primary/30;
}
.earning.top-gross {
  @apply before:bg-primary;
}
.earning.second-half {
  @apply before:bg-[#e4e7ed];
}

.nav.tab-style-1 {
  @apply border-b-0 p-[0.65rem] bg-light rounded-md mb-4;
}
.nav.tab-style-1 .nav-item {
  @apply me-2;
}
.nav.tab-style-1 .nav-item:last-child {
  @apply me-0;
}
.nav.tab-style-1 .nav-link {
  @apply rounded-md py-[0.35rem] px-4;
}
.nav.tab-style-1 .nav-link.active {
  @apply bg-primary text-white shadow-md transition-colors ease-in duration-[0.15sec];
}

.tab-content .tab-pane {
  @apply p-4 border border-solid border-defaultborder rounded-md text-defaulttextcolor;
}

.list-group-item {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 border-b-0 border-t border-e border-s border-inherit border-solid py-[0.65rem] px-[1.25rem] dark:border-defaultborder/10;
}
.list-group-item:last-child {
  @apply border-b;
}

@media (min-width: 576px) {
  .crypto-buy-sell-input {
    @apply w-[60%] !important;
  }
}
/* Start:: Crypto Wallet */
.dark .qr-image {
  @apply invert;
}

/* End:: Crypto Wallet */
/* Start:: Crypto Currency Exchange */
#btc-currency-chart,
#eth-currency-chart,
#ltc-currency-chart,
#dash-currency-chart,
#xrs-currency-chart,
#glm-currency-chart,
#monero-currency-chart,
#eos-currency-chart {
  @apply absolute bottom-0;
}

.currency-exchange-box {
  @apply relative bg-primary shadow-none z-[10]
  before:absolute before:h-full before:w-full before:bg-[url("../public/assets/images/media/media-1.jpg")] before:bg-cover before:bg-center before:bg-no-repeat before:z-[0] before:opacity-10 !important;
}
.currency-exchange-box .currency-exchange-area {
  @apply bg-black/10 backdrop-blur-[30px] !important;
}

/* End:: Crypto Currency Exchange */
#buy-crypto2 .choices__inner,
#sell-crypto2 .choices__inner,
#buy-crypto .choices__inner,
#sell-crypto .choices__inner {
  @apply min-w-[5rem] !important;
}
#buy-crypto2 .choices__list--dropdown .choices__item--selectable::after,
#buy-crypto2 .choices__list[aria-expanded] .choices__item--selectable::after,
#sell-crypto2 .choices__list--dropdown .choices__item--selectable::after,
#sell-crypto2 .choices__list[aria-expanded] .choices__item--selectable::after,
#buy-crypto .choices__list--dropdown .choices__item--selectable::after,
#buy-crypto .choices__list[aria-expanded] .choices__item--selectable::after,
#sell-crypto .choices__list--dropdown .choices__item--selectable::after,
#sell-crypto .choices__list[aria-expanded] .choices__item--selectable::after {
  @apply hidden !important;
}
#buy-crypto2 .choices__list--dropdown .choices__item--selectable,
#buy-crypto2 .choices__list[aria-expanded] .choices__item--selectable,
#sell-crypto2 .choices__list--dropdown .choices__item--selectable,
#sell-crypto2 .choices__list[aria-expanded] .choices__item--selectable,
#buy-crypto .choices__list--dropdown .choices__item--selectable,
#buy-crypto .choices__list[aria-expanded] .choices__item--selectable,
#sell-crypto .choices__list--dropdown .choices__item--selectable,
#sell-crypto .choices__list[aria-expanded] .choices__item--selectable {
  @apply pe-0 !important;
}

#btc-chart .apexcharts-grid,
#eth-chart .apexcharts-grid,
#dash-chart .apexcharts-grid {
  @apply hidden;
}

.basic-subscription,
.pro-subscription {
  @apply relative before:absolute before:w-2 before:h-2 before:-start-[0.938rem] before:rounded-[0.3rem] before:top-[0.6rem] !important;
}

.basic-subscription:before {
  @apply bg-primary;
}

.pro-subscription:before {
  @apply bg-secondary;
}

.featured-nft {
  @apply w-full h-[13rem] rounded-md;
}
.featured-nft img {
  @apply bg-cover bg-center bg-no-repeat w-full h-full rounded-md;
}

.svg-primary {
  @apply fill-primary;
}

.nft-auction-time {
  @apply absolute top-[3.5rem] end-4 py-1 px-2 bg-[#ffffff26] rounded-[0.3rem] backdrop-blur-[1.875rem];
}

.nft-like-section {
  @apply absolute top-4;
}

.nft-like-badge {
  @apply bg-[#ffffff26] backdrop-blur-[1.875rem];
}

.nft-collector-progress {
  @apply w-[3.125rem];
}

.nft-details-auction-time {
  @apply absolute w-[400px] h-[1.875rem] bg-success flex items-center justify-center text-white top-[3.125rem] font-medium -end-[8.4375rem] rotate-45 z-[10];
}

.create-nft-item .single-fileupload {
  @apply mx-0 !important;
}
.create-nft-item .filepond--root[data-style-panel-layout~="circle"] {
  @apply rounded-md w-full;
}
.create-nft-item .filepond--drop-label.filepond--drop-label label {
  @apply p-8;
}
.create-nft-item .filepond--drop-label {
  @apply text-textmuted !important;
}
.create-nft-item .filepond--panel-root {
  @apply border-[0.125rem] border-dashed border-inputborder !important;
}
.create-nft-item
  .filepond--root[data-style-panel-layout~="circle"]
  .filepond--image-preview-wrapper {
  @apply rounded-[0.3rem];
}

.create-nft-item .filepond--root[data-style-panel-layout~="circle"] {
  @apply sm:h-[15.75rem] h-[14.5rem] w-[14.5rem] sm:w-[15.75rem] !important;
}
.create-nft-item .filepond--drop-label label {
  @apply text-[0.75rem];
}
.create-nft-item
  .filepond--root[data-style-panel-layout~="circle"]
  .filepond--image-preview-wrapper,
.create-nft-item .filepond--file {
  @apply h-[15.75rem] w-[15.75rem] !important;
}

/* Start:: NFT Wallet */
.nft-wallet {
  @apply relative text-center shadow-none border border-defaultborder rounded-md hover:bg-light;
}
.nft-wallet.active {
  @apply border-primary;
}

.nft-list li:hover {
  @apply bg-light text-primary;
}

/* End:: NFT Wallet */
/* Start:: NFT Live Auction */
.nft-tag.nft-tag-primary:hover,
.nft-tag.nft-tag-primary.active {
  @apply text-primary;
}
.nft-tag.nft-tag-primary:hover .nft-tag-icon,
.nft-tag.nft-tag-primary.active .nft-tag-icon {
  @apply bg-primary/10;
}
.nft-tag.nft-tag-primary .nft-tag-icon {
  @apply text-primary;
}

.nft-tag.nft-tag-secondary:hover,
.nft-tag.nft-tag-secondary.active {
  @apply text-secondary;
}
.nft-tag.nft-tag-secondary:hover .nft-tag-icon,
.nft-tag.nft-tag-secondary.active .nft-tag-icon {
  @apply bg-secondary/10;
}
.nft-tag.nft-tag-secondary .nft-tag-icon {
  @apply text-secondary;
}

.nft-tag.nft-tag-warning:hover,
.nft-tag.nft-tag-warning.active {
  @apply text-warning;
}
.nft-tag.nft-tag-warning:hover .nft-tag-icon,
.nft-tag.nft-tag-warning.active .nft-tag-icon {
  @apply bg-warning/10;
}
.nft-tag.nft-tag-warning .nft-tag-icon {
  @apply text-warning;
}

.nft-tag.nft-tag-info:hover,
.nft-tag.nft-tag-info.active {
  @apply text-info;
}
.nft-tag.nft-tag-info:hover .nft-tag-icon,
.nft-tag.nft-tag-info.active .nft-tag-icon {
  @apply bg-info/10;
}
.nft-tag.nft-tag-info .nft-tag-icon {
  @apply text-info;
}

.nft-tag.nft-tag-success:hover,
.nft-tag.nft-tag-success.active {
  @apply text-success;
}
.nft-tag.nft-tag-success:hover .nft-tag-icon,
.nft-tag.nft-tag-success.active .nft-tag-icon {
  @apply text-success/10;
}
.nft-tag.nft-tag-success .nft-tag-icon {
  @apply text-success !important;
}

.nft-tag.nft-tag-danger:hover,
.nft-tag.nft-tag-danger.active {
  @apply text-danger;
}
.nft-tag.nft-tag-danger:hover .nft-tag-icon,
.nft-tag.nft-tag-danger.active .nft-tag-icon {
  @apply bg-danger/10;
}
.nft-tag.nft-tag-danger .nft-tag-icon {
  @apply text-danger;
}

.nft-tag.nft-tag-dark:hover,
.nft-tag.nft-tag-dark.active {
  @apply text-black;
}
.nft-tag.nft-tag-dark:hover .nft-tag-icon,
.nft-tag.nft-tag-dark.active .nft-tag-icon {
  @apply bg-black/10;
}
.nft-tag.nft-tag-dark .nft-tag-icon {
  @apply text-black dark:text-white;
}

.nft-tag .nft-tag-text {
  @apply font-semibold inline-block py-0 pe-[1.25rem] ps-2;
}

.nft-tag .nft-tag-icon {
  @apply inline-block py-3 px-4 rounded-full bg-light;
}

.nft-tag {
  @apply relative inline-flex items-center border border-solid border-white dark:border-defaultborder/10 rounded-[50rem] bg-white dark:bg-bodybg text-defaulttextcolor;
}

.nft-timer-container {
  @apply absolute  top-[7%] inline-flex items-center py-[0.2rem] px-[0.7rem] rounded-t-none rounded-r-[50rem] rounded-b-[50rem] rounded-l-none text-white font-medium bg-black/10;
}

.nft-btn {
  @apply absolute end-[5%] bottom-[5%] h-8 w-8 p-[5px] rounded-full bg-black/10 text-white text-[1rem] inline-flex items-center justify-center transition-all ease-linear duration-[0.3sec]
  z-[1];
}
.nft-btn:hover {
  @apply bg-white text-primary;
}

.nft-img {
  @apply rounded-md max-h-[10rem] w-full;
}

/* End:: NFT Live Auction */
/* Start:: CRM Deals */
.lead-discovered,
.lead-qualified,
.contact-initiated,
.need-identified,
.negotiation,
.deal-finalized {
  @apply relative ms-[1rem] before:absolute before:w-[0.5rem] before:h-[0.5rem] before:rounded-full before:-start-[0.875rem] before:top-[0.4375rem];
}

.lead-discovered:before {
  @apply bg-primary;
}

.lead-qualified:before {
  @apply bg-warning;
}

.contact-initiated:before {
  @apply bg-success;
}

.need-identified:before {
  @apply bg-info;
}

.negotiation:before {
  @apply bg-danger;
}

.deal-finalized:before {
  @apply bg-secondary;
}

#leads-discovered .box.custom-box {
  @apply border-s-[0.4rem] border-solid border-primary/40 !important;
}
#leads-discovered .box.custom-box .company-name {
  @apply text-primary;
}
#leads-discovered .box.custom-box .avatar {
  @apply bg-primary;
}

#leads-qualified .box.custom-box {
  @apply border-s-[0.4rem] border-solid border-warning/40 !important;
}
#leads-qualified .box.custom-box .company-name {
  @apply text-warning;
}
#leads-qualified .box.custom-box .avatar {
  @apply bg-warning;
}

#contact-initiated .box.custom-box {
  @apply border-s-[0.4rem] border-solid border-success/40 !important;
}
#contact-initiated .box.custom-box .company-name {
  @apply text-success;
}
#contact-initiated .box.custom-box .avatar {
  @apply bg-success;
}

#needs-identified .box.custom-box {
  @apply border-s-[0.4rem] border-solid border-info/40 !important;
}
#needs-identified .box.custom-box .company-name {
  @apply text-info;
}
#needs-identified .box.custom-box .avatar {
  @apply bg-info;
}

#negotiation .box.custom-box {
  @apply border-s-[0.4rem] border-solid border-danger/40 !important;
}
#negotiation .box.custom-box .company-name {
  @apply text-danger;
}
#negotiation .box.custom-box .avatar {
  @apply bg-danger;
}

#deal-finalized .box.custom-box {
  @apply border-s-[0.4rem] border-solid border-secondary/40 !important;
}
#deal-finalized .box.custom-box .company-name {
  @apply text-secondary;
}
#deal-finalized .box.custom-box .avatar {
  @apply bg-secondary;
}

#leads-discovered .box.custom-box,
#leads-qualified .box.custom-box,
#contact-initiated .box.custom-box,
#needs-identified .box.custom-box,
#negotiation .box.custom-box,
#deal-finalized .box.custom-box {
  @apply mb-2;
}
#leads-discovered .box.custom-box:last-child,
#leads-qualified .box.custom-box:last-child,
#contact-initiated .box.custom-box:last-child,
#needs-identified .box.custom-box:last-child,
#negotiation .box.custom-box:last-child,
#deal-finalized .box.custom-box:last-child {
  @apply mb-[1.5rem];
}
#leads-discovered .box.custom-box .box-body,
#leads-qualified .box.custom-box .box-body,
#contact-initiated .box.custom-box .box-body,
#needs-identified .box.custom-box .box-body,
#negotiation .box.custom-box .box-body,
#deal-finalized .box.custom-box .box-body {
  @apply p-4;
}
#leads-discovered .box.custom-box .box-body .deal-description,
#leads-qualified .box.custom-box .box-body .deal-description,
#contact-initiated .box.custom-box .box-body .deal-description,
#needs-identified .box.custom-box .box-body .deal-description,
#negotiation .box.custom-box .box-body .deal-description,
#deal-finalized .box.custom-box .box-body .deal-description {
  @apply ms-[2.25rem];
}

/* End:: CRM Deals */
.card-img-top {
  @apply rounded-ss-[0.375] rounded-se-[0.375] !important;
}

.bg-primary-gradient {
  @apply text-white bg-gradient-to-r from-primary to-bluemain;
}

.swiper {
  @apply mx-auto relative list-none p-0 z-[1];
}

.swiper-vertical > .swiper-wrapper {
  @apply flex-col;
}

.swiper-wrapper {
  @apply relative w-full h-full z-[1] flex transition-transform box-content;
}

.swiper-pointer-events {
  @apply touch-pan-y;
}

.swiper-pointer-events.swiper-vertical {
  @apply touch-pan-x;
}

.swiper-slide {
  @apply flex-shrink-0 w-full h-full relative transition-transform;
}

.swiper-slide img {
  @apply block w-full h-full object-cover;
}

.nft-featuredcollect-image {
  @apply h-[8.813rem] rounded-md relative before:absolute before:w-full before:h-full before:bg-black/10 !important;
}

.main-card-icon {
  @apply bottom-[0.75rem] end-4 absolute p-3 rounded-md;
}

.tansaction-icon {
  @apply p-[0.375rem] rounded-sm end-3;
}
.tansaction-icon svg {
  @apply w-[1.25rem] h-[1.25rem];
}

.recent-transactions-card .list-group-item {
  @apply py-3 px-[1.25rem];
}

.tansaction-icon {
  @apply me-3 rounded-sm p-[0.375rem];
}

.tansaction-icon svg {
  @apply h-[1.25rem] w-[1.25rem];
}

.timeline-main {
  @apply ps-[1.77rem];
}

.latest-timeline ul.timeline-main {
  @apply relative before:border-s-[0.125rem] before:border-dotted before:border-defaultborder before:dark:border-defaultborder/10 before:absolute before:start-1 before:w-[1px] before:h-[84%] before:z-0 before:top-3;
}

.latest-timeline .timeline-main li.activity {
  @apply p-0 relative mb-2;
}

.featured_icon1 {
  @apply w-[0.438rem] h-[0.438rem] leading-[3.75rem] -ms-[1.625rem] text-black text-[0.625rem] bg-primary relative rounded-[1.125rem] top-3;
}

.featured_icon1.featured-danger {
  @apply bg-danger;
}

.featured_icon1.featured-success {
  @apply bg-success;
}

.nav.panel-tabs-task a.active {
  @apply rounded-md text-info bg-info/10 !important;
}

.nav.panel-tabs-task a {
  @apply py-[0.4rem] px-2 text-[0.711rem] transition-all ease-linear duration-[0.3sec] !important;
}

.avatar-list-stacked {
  @apply p-0;
}
.avatar-list-stacked .avatar {
  @apply -me-[0.45rem] border rounded-full border-solid border-black/[0.05] align-middle transition-all ease-linear duration-[200ms] !important;
}
.avatar-list-stacked .avatar:last-child {
  @apply me-0 !important;
}
.avatar-list-stacked .avatar:hover {
  @apply z-[1] -translate-y-[0.188rem];
}

[dir="rtl"] .avatar-list-stacked .ri-arrow-right-s-line {
  @apply rotate-180;
}

#visitors-countries {
  @apply h-[23.4rem];
}
#visitors-countries #jvm-markers-labels-group {
  @apply hidden overflow-auto;
}

#visitors-countries circle:nth-child(1) {
  @apply fill-primary stroke-primary/30 stroke-[30];
}

#visitors-countries circle:nth-child(2) {
  @apply fill-secondary stroke-secondary/20 stroke-[30];
}

#visitors-countries circle:nth-child(3) {
  @apply fill-danger stroke-danger/20 stroke-[30];
}

#visitors-countries circle:nth-child(4) {
  @apply fill-info stroke-info/20 stroke-[30];
}

#visitors-countries circle:nth-child(5) {
  @apply fill-primary stroke-primary/30 stroke-[30];
}

#visitors-countries circle:nth-child(6) {
  @apply fill-warning stroke-warning/20 stroke-[30];
}

#visitors-countries circle:nth-child(7) {
  @apply fill-success stroke-success/20 stroke-[30];
}

#visitors-countries circle:nth-child(8) {
  @apply fill-pinkmain stroke-pinkmain/20 stroke-[30];
}

#visitors-countries circle:nth-child(9) {
  @apply fill-[#232323] stroke-[#232323]/20 stroke-[30];
}

#visitors-countries circle:nth-child(10) {
  @apply fill-indigomain stroke-indigomain/20 stroke-[30];
}

#vector-map #jvm-regions-group path,
#marker-map #jvm-regions-group path,
#marker-image-map #jvm-regions-group path,
#lines-map #jvm-regions-group path,
#visitors-countries #jvm-regions-group path,
#users-map #jvm-regions-group path {
  @apply fill-light !important;
}

@media (max-width: 1399.99px) {
  .sales-visitors-countries {
    @apply border-e border-dashed border-defaultborder dark:border-defaultborder/10;
  }
}
@media (min-width: 1400px) {
  .sales-visitors-countries {
    @apply border-e border-dashed border-defaultborder dark:border-defaultborder/10;
  }
}
.panel-tabs-billing li a.active {
  @apply bg-success/10 font-medium text-success;
}

.panel-tabs-billing li a {
  @apply py-1 px-2 rounded-md text-[0.75rem];
}

.billing-invoice-details {
  @apply w-[11rem];
}

#analytics-bouncerate {
  @apply absolute bottom-0;
}

.custom-card.upgrade-card {
  @apply h-[11.625rem] relative overflow-hidden bg-primary bg-no-repeat
  before:absolute before:bg-[url("../public/assets/images/media/media-85.png")] before:bg-cover before:bg-no-repeat before:start-0 before:top-0 before:end-0 before:bottom-0 before:opacity-[0.15] !important;
}
.custom-card.upgrade-card .avatar {
  @apply absolute -top-[0.6875rem] end-[1.5rem];
}
.custom-card.upgrade-card .upgrade-card-content {
  @apply absolute bottom-4;
}

#analytics-users .apexcharts-grid {
  @apply hidden;
}

.analytics-visitors-countries li {
  @apply mb-[1.385rem];
}
.analytics-visitors-countries li:last-child {
  @apply mb-0;
}
.analytics-visitors-countries li .progress {
  @apply w-[6.25rem];
}

#country-sessions .apexcharts-graphical clipPath rect {
  @apply scale-[0.98];
}

.team-members-card li {
  @apply mb-[1.5rem];
}
.team-members-card li:last-child {
  @apply mb-0;
}
.team-members-card #user1 .apexcharts-grid,
.team-members-card #user2 .apexcharts-grid,
.team-members-card #user3 .apexcharts-grid,
.team-members-card #user4 .apexcharts-grid,
.team-members-card #user5 .apexcharts-grid {
  @apply hidden;
}

.daily-task-card,
.projects-maintask-card {
  @apply mb-0;
}
.daily-task-card li,
.projects-maintask-card li {
  @apply mb-[1.25rem];
}
.daily-task-card li:last-child,
.daily-task-card li .card,
.projects-maintask-card li:last-child,
.projects-maintask-card li .card {
  @apply mb-0;
}

.project-transactions-card {
  @apply mb-0;
}
.project-transactions-card li {
  @apply mb-[1.45rem];
}
.project-transactions-card li:last-child {
  @apply mb-0;
}

.projects-tracking-card {
  @apply bg-white relative;
}
.projects-tracking-card .card-body {
  @apply z-[1];
}
.projects-tracking-card img {
  @apply w-[15.5rem] h-[9.5rem];
}

.shape-1,
.shape-2,
.shape-3,
.shape-4,
.shape-5,
.shape-6,
.shape-7,
.shape-8,
.shape-9,
.shape-10,
.shape-11,
.shape-12,
.shape-13,
.shape-14,
.shape-15,
.shape-16 {
  @apply absolute animate-projects !important;
}
.shape-1 i,
.shape-2 i,
.shape-3 i,
.shape-4 i,
.shape-5 i,
.shape-6 i,
.shape-7 i,
.shape-8 i,
.shape-9 i,
.shape-10 i,
.shape-11 i,
.shape-12 i,
.shape-13 i,
.shape-14 i,
.shape-15 i,
.shape-16 i {
  @apply opacity-[0.2];
}

.shape-1 {
  @apply start-[20%]  top-[20%];
}

.shape-2 {
  @apply start-[5%] top-[42%];
}

.shape-3 {
  @apply end-[15%] bottom-[55%];
}

.shape-4 {
  @apply start-[55%]  top-[25%];
}

.shape-5 {
  @apply start-[50%]  bottom-[25%];
}

.shape-6 {
  @apply end-[10%] bottom-[10%];
}

.shape-7 {
  @apply start-[20%]  bottom-[17%];
}

.shape-8 {
  @apply end-[20%] bottom-[17%];
}

.shape-9 {
  @apply end-[25%] top-[10%];
}

.shape-10 {
  @apply end-[5%] top-[15%];
}

.shape-11 {
  @apply start-[15%]  bottom-[35%];
}

.shape-12 {
  @apply start-[25%]  top-[40%];
}

.shape-13 {
  @apply start-[10%]  bottom-[10%];
}

.shape-14 {
  @apply -end-[10%]  -bottom-[10%];
}

.shape-15 {
  @apply -start-[20%] -bottom-[17%];
}

.shape-16 {
  @apply -end-[20%]  -bottom-[17%];
}

.hrm-main-card {
  @apply border-t-[3px] border-solid !important;
}
.hrm-main-card.primary {
  @apply border-primary/30 !important;
}
.hrm-main-card.secondary {
  @apply border-secondary/30 !important;
}
.hrm-main-card.warning {
  @apply border-warning/30 !important;
}
.hrm-main-card.danger {
  @apply border-danger/30 !important;
}

.hrm-jobs-legend {
  @apply relative before:absolute before:w-[0.375rem] before:h-[0.375rem] before:rounded-md before:top-[0.375rem] before:-start-[0.625rem];
}
.hrm-jobs-legend.published:before {
  @apply bg-primary;
}
.hrm-jobs-legend.private:before {
  @apply bg-primary/70;
}
.hrm-jobs-legend.closed:before {
  @apply bg-primary/50;
}
.hrm-jobs-legend.onhold:before {
  @apply bg-primary/30;
}

.timeline-widget {
  @apply relative before:absolute before:w-[0.125rem] before:h-full before:bg-defaultborder before:dark:bg-defaultborder/10 before:top-0 before:start-[3rem];
}
.timeline-widget .timeline-widget-list {
  @apply mb-6 relative before:absolute before:w-3 before:h-3 before:start-[2.688rem] before:top-1 before:rounded-[3.125rem] before:border-[0.125rem] before:border-solid before:border-defaultborder before:dark:border-defaultborder/10 before:bg-white before:dark:bg-bodybg;
}
.timeline-widget .timeline-widget-list:last-child {
  @apply mb-0;
}
.timeline-widget .timeline-widget-list .timeline-widget-content {
  @apply max-w-[20rem];
}

.my-stocks-ul li {
  @apply py-[0.87rem] px-4 border-b border-dashed border-defaultborder dark:border-defaultborder/10;
}
.my-stocks-ul li:last-child {
  @apply border-b-0;
}

.category-link {
  @apply p-[1.25rem] rounded-md border border-solid border-defaultborder dark:border-defaultborder/10 flex flex-col items-center justify-center text-textmuted transition-all ease-linear duration-[0.3s];
}
.category-link.primary .category-svg {
  @apply fill-primary;
}
.category-link.secondary .category-svg {
  @apply fill-secondary;
}
.category-link.warning .category-svg {
  @apply fill-warning;
}
.category-link.success .category-svg {
  @apply fill-success;
}

.category-link .category-svg {
  @apply h-[1.875rem] w-[1.875rem] mb-4 transition-all ease-linear duration-[0.3sec];
}

.category-link.primary:not(.active):hover,
.category-link.primary.active {
  @apply text-primary bg-primary/10 border-primary/20;
}

.category-link.secondary:not(.active):hover,
.category-link.secondary.active {
  @apply text-secondary bg-secondary/10 border-secondary/20;
}

.category-link.warning:not(.active):hover,
.category-link.warning.active {
  @apply text-warning bg-warning/10 border-warning/20;
}

.category-link.success:not(.active):hover,
.category-link.success.active {
  @apply text-success bg-success/10 border-success/20;
}

.bg-outline-primary {
  @apply border border-solid border-primary text-primary;
}

.bg-outline-secondary {
  @apply border border-solid border-secondary text-secondary;
}

.bg-outline-warning {
  @apply border border-solid border-warning text-warning;
}

.bg-outline-info {
  @apply border border-solid border-info text-info;
}

.bg-outline-success {
  @apply border border-solid border-success text-success;
}

.bg-outline-danger {
  @apply border border-solid border-danger text-danger;
}

.bg-outline-dark {
  @apply border border-solid border-black text-black;
}

.bg-outline-light {
  @apply border border-solid border-light text-light;
}

.course-status-progress {
  @apply w-[3.125rem] !important;
}

.courses-instructors li {
  @apply mb-[0.85rem];
}
.courses-instructors li:last-child {
  @apply mb-0;
}

#waterTrack .apexcharts-grid,
#sleepTrack .apexcharts-grid {
  @apply hidden;
}

.personal-upcoming-events li {
  @apply mb-4;
}
.personal-upcoming-events li:last-child {
  @apply mb-0;
}

.personal-messages-list li {
  @apply mb-[1.2rem];
}
.personal-messages-list li:last-child {
  @apply mb-0;
}
.personal-messages-list li .message {
  @apply max-w-[15rem];
}

.text-truncate {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}

.circle-progress {
  @apply relative z-[1];
}

.circle-progress::after {
  @apply absolute h-full w-full rounded-full top-0 end-0 -z-[1] bg-gradient-to-t from-primary/50 to-white/50;
}

.personal-goals-list li {
  @apply mb-4;
}
.personal-goals-list li:last-child {
  @apply mb-0;
}

.personal-favourite-contacts li {
  @apply mb-5;
}
.personal-favourite-contacts li:last-child {
  @apply mb-0;
}

.dark button.gridjs-sort,
.dark button.gridjs-sort-desc,
.dark button.gridjs-sort-asc {
  @apply invert;
}

.circle-progress {
  @apply relative z-[1] after:absolute after:h-full after:w-full after:rounded-full after:top-0 after:end-0 after:bg-[gradient-1,gradient-2] after:z-[-1];
}

#particles-js {
  @apply absolute w-full h-full bg-white;
}

.error-page {
  @apply absolute w-full min-h-screen flex bg-center bg-cover bg-no-repeat items-center justify-center;
}

.error-text {
  @apply text-[8.25rem] font-medium leading-none;
}

@media (max-width: 575.98px) {
  .error-text {
    @apply text-[3rem];
  }
}
/* Start::app-header */
.app-header {
  @apply w-full max-w-full h-[3.75rem] z-[49] fixed top-0 start-0 border-b border-b-headerbordercolor dark:border-white/10 transition-all ease-linear duration-[0.1s] bg-white dark:bg-bodybg;
}
.app-header #mainHeaderProfile {
  @apply text-headerprimecolor;
}

.app-header {
  @apply lg:ps-60;
}

.header-icon-badge {
  @apply absolute py-[0.15rem] px-1 top-3 bottom-[0.45rem] text-[0.625rem];
}

.related-app {
  @apply text-center rounded-md border border-defaultborder dark:border-white/10;
}
.related-app:hover {
  @apply bg-light;
}

.header-profile-dropdown {
  @apply min-w-[11rem];
}

.dark .dropdown-profile p {
  @apply text-white/70;
}
.dark .dropdown-profile span {
  @apply text-white/70;
}

/* End::app-header */
/* Start::main-header-container */
.main-header-container {
  @apply flex items-stretch justify-between h-full;
}
.main-header-container .header-content-left,
.main-header-container .header-content-right {
  @apply flex items-stretch;
}
.main-header-container .header-element {
  @apply flex items-stretch;
}
.main-header-container .header-link {
  @apply flex items-center py-4 px-[0.65rem];
}
.main-header-container .header-link:hover .header-link-icon,
.main-header-container .header-link.show .header-link-icon {
  @apply fill-headerprimecolor text-headerprimecolor;
}
.main-header-container .header-link-icon {
  @apply w-7 h-7 text-xl text-headerprimecolor fill-headerprimecolor transition-all ease-linear duration-[0.05s] relative rounded-[50%] p-1;
}
.main-header-container .header-link-icon:hover {
  @apply bg-light;
}
.main-header-container .dropdown-toggle {
  @apply no-underline;
}
.main-header-container .dropdown-toggle::after {
  @apply content-none;
}
.main-header-container .main-profile-user .dropdown-menu {
  @apply w-52;
}
.main-header-container .main-profile-user .dropdown-item {
  @apply font-normal text-[0.8125rem] text-defaulttextcolor py-5 px-4 h-[2.375rem] flex items-center;
}
.main-header-container .main-profile-user .dropdown-item:hover {
  @apply text-primary;
}
.main-header-container .main-profile-user .dropdown-item:hover i {
  @apply text-primary opacity-[1];
}
.main-header-container .main-header-dropdown {
  @apply top-[-3px] shadow-[0rem_0.25rem_0.625rem_rgba(20,20,20,0.1)];
}
.main-header-container .main-header-dropdown.header-shortcuts-dropdown {
  @apply w-[22rem];
}
.main-header-container .main-header-dropdown .dropdown-item {
  @apply p-[0.65rem] border-b border-b-defaultborder dark:border-b-white/10;
}
.main-header-container .main-header-dropdown .dropdown-item:last-child {
  @apply border-b-0;
}
.main-header-container .cart-dropdown .main-header-dropdown {
  @apply w-[22rem] !important;
}
.main-header-container .notifications-dropdown .main-header-dropdown {
  @apply w-[22rem];
}

[dir="rtl"] .sidemenu-toggle .open-toggle {
  @apply rotate-180;
}

/* End::main-header-container */
/* Start::Header-dropdown */
.header-product-item {
  @apply ps-0 flex;
}
.header-product-item li {
  @apply relative list-none text-[0.75rem] font-normal text-textmuted pe-[0.938rem];
}
.header-product-item li:nth-child(2),
.header-product-item li:nth-child(3),
.header-product-item li:nth-child(4) {
  @apply before:absolute before:start-[-0.438rem] before:w-px before:top-1 before:h-2.5 before:bg-textmuted before:opacity-[0.1];
}

.header-cart-remove .ti {
  @apply p-1 rounded-sm text-[0.85rem] ms-3 text-danger;
}
.header-cart-remove:hover .ti {
  @apply bg-danger/10;
}

/* End::Header-dropdown */
/* Start::header-search */
#searchModal .form-control {
  @apply relative;
}
#searchModal .input-group {
  @apply border-[2px] border-primary rounded-sm;
}
#searchModal .input-group i {
  @apply text-textmuted;
}

.search-tags {
  @apply text-[0.75rem] text-defaulttextcolor border border-defaultborder rounded-sm bg-light py-0.5 px-[0.55rem] leading-[1.2] inline-flex items-center cursor-default font-normal my-1 mx-0;
}
.search-tags .tag-addon:last-child {
  @apply rounded-md;
}
.search-tags a.tag-addon {
  @apply no-underline cursor-pointer inline-block py-0 px-2  bg-light me-[-0.4rem] ms-2 text-center min-w-[1.5rem];
}
.search-tags a.tag-addon i {
  @apply align-middle my-0 -mx-[0.25rem] leading-6 text-defaultsize;
}
.search-tags i {
  @apply text-defaultsize;
}

/* End::header-search */
/* Start::header-country-selector */
.country-selector .header-link img {
  @apply w-5 h-5;
}
.country-selector .dropdown-menu img {
  @apply w-4 h-4;
}

/* End::header-country-selector */
/* Start:header dropdowns scroll */
#header-shortcut-scroll,
#header-notification-scroll,
#header-cart-items-scroll {
  @apply max-h-80;
}

/* End:header dropdowns scroll */
/* Start::header badge pulse */
.pulse {
  @apply block cursor-pointer animate-pulse;
}
.pulse.pulse-secondary {
  @apply shadow-[0rem_0rem_0rem_rgba(35,183,229,0.4)];
}

/* End::header badge pulse */
/* Start::Header theme-mode icon style */
[class="light"] .layout-setting .dark-layout {
  @apply hidden;
}
[class="light"] .layout-setting .light-layout {
  @apply block;
  display: block;
}

.layout-setting .dark-layout {
  @apply hidden;
}

.layout-setting .light-layout {
  display: block;
}

.dark .layout-setting .light-layout {
  @apply hidden;
}
.dark .layout-setting .dark-layout {
  @apply block;
}

/* End::Header theme-mode icon style */
/* Start::Header fullscreen responsive */
@media (max-width: 767.98px) {
  .header-element.header-fullscreen {
    @apply hidden;
  }
}
/* End::Header fullscreen responsive */
/* Start::Responsive header dropdowns */
@media (max-width: 575.98px) {
  .app-header .dropdown-menu {
    @apply w-full;
  }
}
/* End::Responsive header dropdowns */
/* Start::toggle */
.animated-arrow.hor-toggle {
  @apply text-center w-8 text-[1.2rem] relative m-[0.3125rem] me-[0.625rem];
}

.animated-arrow {
  @apply absolute start-0 top-0 z-[50] cursor-pointer p-[0.3125rem] mt-[0.375rem] mb-0 ms-2 me-0 transition-all ease-in-out duration-[0.05s];
}

.animated-arrow.hor-toggle span {
  @apply align-middle;
}

.animated-arrow span {
  @apply cursor-pointer h-0.5 w-3 bg-headerprimecolor dark:bg-white/60 absolute block content-[""] transition-all ease-in-out duration-[0.05s];
}

.animated-arrow span:before,
.animated-arrow span:after {
  @apply transition-all ease-in-out duration-[0.05s];
}

.animated-arrow span {
  @apply before:top-[-0.375rem] before:w-[1.4375rem];
}

.animated-arrow span {
  @apply after:bottom-[-0.375rem] after:w-60;
}

.animated-arrow span:before,
.animated-arrow span:after {
  @apply cursor-pointer h-0.5 w-[1.0625rem] bg-headerprimecolor dark:bg-white/60 absolute block content-[""];
}

[data-toggled="icon-overlay-close"] .animated-arrow span,
[data-toggled="close-menu-close"] .animated-arrow span,
[data-toggled="icon-text-close"] .animated-arrow span,
[data-toggled="detached-close"] .animated-arrow span,
[data-toggled="menu-click-closed"] .animated-arrow span,
[data-toggled="menu-hover-closed"] .animated-arrow span,
[data-toggled="icon-click-closed"] .animated-arrow span,
[data-toggled="icon-hover-closed"] .animated-arrow span,
[data-toggled="double-menu-close"] .animated-arrow span {
  @apply bg-transparent bg-none !important;
}
[data-toggled="icon-overlay-close"] .animated-arrow span,
[data-toggled="close-menu-close"] .animated-arrow span,
[data-toggled="icon-text-close"] .animated-arrow span,
[data-toggled="detached-close"] .animated-arrow span,
[data-toggled="menu-click-closed"] .animated-arrow span,
[data-toggled="menu-hover-closed"] .animated-arrow span,
[data-toggled="icon-click-closed"] .animated-arrow span,
[data-toggled="icon-hover-closed"] .animated-arrow span,
[data-toggled="double-menu-close"] .animated-arrow span {
  @apply before:rotate-45 before:bottom-0 !important;
}
[data-toggled="icon-overlay-close"] .animated-arrow span,
[data-toggled="close-menu-close"] .animated-arrow span,
[data-toggled="icon-text-close"] .animated-arrow span,
[data-toggled="detached-close"] .animated-arrow span,
[data-toggled="menu-click-closed"] .animated-arrow span,
[data-toggled="menu-hover-closed"] .animated-arrow span,
[data-toggled="icon-click-closed"] .animated-arrow span,
[data-toggled="icon-hover-closed"] .animated-arrow span,
[data-toggled="double-menu-close"] .animated-arrow span {
  @apply before:top-0 !important;
}
[data-toggled="icon-overlay-close"] .animated-arrow span,
[data-toggled="close-menu-close"] .animated-arrow span,
[data-toggled="icon-text-close"] .animated-arrow span,
[data-toggled="detached-close"] .animated-arrow span,
[data-toggled="menu-click-closed"] .animated-arrow span,
[data-toggled="menu-hover-closed"] .animated-arrow span,
[data-toggled="icon-click-closed"] .animated-arrow span,
[data-toggled="icon-hover-closed"] .animated-arrow span,
[data-toggled="double-menu-close"] .animated-arrow span {
  @apply after:-rotate-45 !important;
}
[data-toggled="icon-overlay-close"] .animated-arrow span,
[data-toggled="close-menu-close"] .animated-arrow span,
[data-toggled="icon-text-close"] .animated-arrow span,
[data-toggled="detached-close"] .animated-arrow span,
[data-toggled="menu-click-closed"] .animated-arrow span,
[data-toggled="menu-hover-closed"] .animated-arrow span,
[data-toggled="icon-click-closed"] .animated-arrow span,
[data-toggled="icon-hover-closed"] .animated-arrow span,
[data-toggled="double-menu-close"] .animated-arrow span {
  @apply after:w-[1.0625rem] after:top-0 !important;
}

/* End::toggle */
/* Start::header notification dropdown */
.header-notification-text {
  @apply max-w-[14.5rem];
}

/* Start::header notification dropdown */
.header-icon {
  @apply text-[1.125rem] leading-[1.75rem] text-headerprimecolor;
}

.badge {
  @apply py-[0.25rem] px-[0.45rem] font-[600] rounded-sm leading-none text-center;
}

.avatar.avatar-sm {
  @apply w-[1.75rem] h-[1.75rem] leading-[1.75rem] text-[0.65rem];
}

.dropdown-divider {
  @apply m-0 border-defaultborder border-t;
}

.dark .app-header {
  @apply bg-bodybg border-b border-b-white/10 !important;
}

@media (max-width: 575.98px) {
  .main-header-container .header-element > button,
  .main-header-container .header-element > a {
    @apply py-4 !important;
  }
  .main-header-container .header-element.header-theme-mode button {
    @apply py-0 !important;
  }
}
@media (max-width: 991.98px) {
  .app-header .horizontal-logo .header-logo img {
    @apply h-8 leading-8;
  }
}
/* Start Landing Styles */
.landing-body .landing-main-image img {
  @apply z-[11] relative w-[26.063rem] h-[26.063rem] bg-black/[0.05] rounded-full before:absolute before:w-[24.5rem] before:h-[24.5rem] before:bg-black/10 before:opacity-[0.3] before:start-[5rem] before:top-[0rem] before:rounded-full
  after:absolute after:w-[12.5rem] after:h-[12.5rem] after:bg-transparent after:border-primary/50 after:opacity-[0.3] after:start-[5.1rem] after:bottom-[4.4rem];
}

@media (min-width: 992px) {
  [data-nav-layout="horizontal"][class="dark"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1::before {
    @apply bg-bodybg !important;
  }
  [data-nav-layout="horizontal"][class="dark"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .landing-logo-container
    .responsive-logo-dark
    img {
    @apply block;
  }
  [data-nav-layout="horizontal"][class="dark"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .landing-logo-container
    .responsive-logo-light
    img {
    @apply hidden;
  }
  [data-nav-layout="horizontal"][class="dark"]
    .landing-body
    .app-sidebar.sticky {
    @apply bg-primary/10 !important;
  }
  [data-nav-layout="horizontal"][class="dark"]
    .landing-body
    .app-sidebar.sticky.sticky-pin {
    @apply bg-bodybg shadow-[0_0.25rem_1rem_rgba(255,255,255,0.1)] !important;
  }
  [data-nav-layout="horizontal"][class="light"]
    .landing-body
    .app-sidebar.sticky.sticky-pin {
    @apply shadow-[0_0.25rem_1rem_rgba(0,0,0,0.1)] !important;
  }
  [data-nav-layout="horizontal"][class="light"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .responsive-logo-dark
    img {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"][class="light"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .responsive-logo-light
    img {
    @apply block !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar {
    @apply bg-primary/10 dark:bg-primary/10 py-4;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item
    .side-menu__angle {
    @apply text-white dark:text-defaulttextcolor/10 !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3 {
    @apply bg-white dark:bg-bodybg;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item
    .side-menu__angle {
    @apply text-[#536485] dark:text-defaulttextcolor/10 !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item:hover,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item.active,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item:hover,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item.active,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item:hover,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item.active {
    @apply text-primary !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item:hover
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item.active
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item:hover
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item.active
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item:hover
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item.active
    .side-menu__angle {
    @apply text-primary !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item:hover::before,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1
    .slide
    .side-menu__item.active::before,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item:hover::before,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child2
    .slide
    .side-menu__item.active::before,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item:hover::before,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child3
    .slide
    .side-menu__item.active::before {
    @apply border-primary !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .side-menu__item {
    @apply rounded-sm p-[0.3rem];
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .slide-menu.child1 {
    @apply rounded-sm py-[0.55rem] px-[0.3rem];
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide-menu.child1
    .side-menu__item {
    @apply px-[1.6rem] py-2 !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub.open
    .slide-menu.child1 {
    @apply overflow-visible text-defaulttextcolor dark:text-defaulttextcolor/70 start-auto hover:text-primary active:text-primary before:absolute before:-top-[7px] before:start-[10%] before:w-[13px] before:h-[13px] before:z-[99999] before:border
          before:border-solid before:border-transparent before:rotate-45 before:bg-white !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply start-auto !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .side-menu__item {
    @apply pe-[1.3rem] ps-[1.3rem] text-[#536485];
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item.active,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item:hover {
    @apply bg-transparent text-primary !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item.active
    .side-menu__label,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item.active
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item:hover
    .side-menu__label,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item:hover
    .side-menu__angle {
    @apply text-white opacity-100 !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before {
    @apply top-[1.2rem] start-[0.65rem] !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .landing-logo-container
    .responsive-logo-dark
    img {
    @apply block;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .landing-logo-container
    .responsive-logo-light
    img {
    @apply hidden;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__label {
    @apply text-defaulttextcolor dark:text-white !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__angle {
    @apply text-defaulttextcolor dark:text-white !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__item.active,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__item:hover {
    @apply text-primary !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__item.active
    .side-menu__label,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__item.active
    .side-menu__angle,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__item:hover
    .side-menu__label,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .side-menu__item:hover
    .side-menu__angle {
    @apply text-primary !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .landing-logo-container
    .responsive-logo-dark
    img {
    @apply hidden;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.sticky.sticky-pin
    .landing-logo-container
    .responsive-logo-light
    img {
    @apply block;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .sticky {
    @apply top-0  px-0 h-auto bg-primary/10 dark:bg-primary/10 shadow-none border-e-0 !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .main-sidebar {
    @apply h-auto !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar.sticky.sticky-pin {
    @apply bg-white dark:bg-bodybg;
  }
}
[data-nav-layout="horizontal"]
  .landing-body
  .app-sidebar
  .slide
  .side-menu__item {
  @apply py-[0.8rem] px-4 !important;
}
[data-nav-layout="horizontal"]
  .landing-body
  .app-sidebar
  .slide
  .side-menu__item.active,
[data-nav-layout="horizontal"]
  .landing-body
  .app-sidebar
  .slide
  .side-menu__item:hover {
  @apply text-white dark:text-defaulttextcolor/10;
}
[data-nav-layout="horizontal"]
  .landing-body
  .app-sidebar
  .sticky.sticky-pin
  .side-menu__item.active {
  @apply font-normal;
}
[data-nav-layout="horizontal"]
  .landing-body
  .app-sidebar
  .sticky.sticky-pin
  .side-menu__item.active
  .side-menu__label {
  @apply text-defaulttextcolor !important;
}
[data-nav-layout="horizontal"]
  .landing-body
  .accordion.accordion-primary
  .accordion-button.collapsed:after {
  @apply bg-white dark:bg-bodybg text-primary !important;
}
[data-nav-layout="horizontal"] .landing-body .landing-banner {
  @apply relative w-full h-[37rem] top-0 bg-[url("../public/assets/images/media/landing/banner.jpg")] bg-cover bg-center bg-no-repeat
        before:absolute before:w-full before:h-full before:bg-primary before:opacity-70;
}
[data-nav-layout="horizontal"]
  .landing-body
  .landing-banner
  .main-banner-container {
  @apply py-[4rem] px-[3rem];
}
[data-nav-layout="horizontal"]
  .landing-body
  .landing-banner
  .landing-banner-heading {
  @apply leading-[1.25]  text-[3rem] font-[600] text-white;
}
[data-nav-layout="horizontal"] .landing-body .main-menu-container .main-menu {
  @apply ps-0;
}
[data-nav-layout="horizontal"] .landing-body .content {
  @apply mt-[4rem];
}
[data-nav-layout="horizontal"] .landing-body .section {
  @apply py-[3.375rem] px-0 bg-cover relative;
}
[data-nav-layout="horizontal"] .landing-body .featur-icon {
  @apply w-[75px] h-[75px] p-[14px] rounded-full bg-white/[0.05] my-0 mx-auto;
}
[data-nav-layout="horizontal"] .landing-body .feature-logos {
  @apply flex justify-center;
}
[data-nav-layout="horizontal"] .landing-body .landing-Features {
  @apply relative w-full h-full top-0 bg-[url("../public/assets/images/media/landing/2.jpg")] bg-cover bg-center bg-no-repeat z-[9]
        before:absolute before:w-full before:h-full before:top-0 before:-z-[1] before:bg-primary/95;
}
[data-nav-layout="horizontal"]
  .landing-body
  .landing-testimonials
  .swiper-pagination-bullet {
  @apply bg-primary !important;
}
[data-nav-layout="horizontal"]
  .landing-body
  .landing-testimonials
  .swiper-pagination-bullet:active {
  @apply bg-primary dark:bg-primary !important;
}
[data-nav-layout="horizontal"] .landing-body .landing-section-heading {
  @apply relative text-[0.813rem] font-[600] before:absolute before:w-[60%] before:h-[0.25rem] before:bg-gradient-to-r before:from-success before:to-success/10 before:opacity-[0.5] before:-top-[0.625rem] before:rounded-[3.125rem] before:end-0;
}
[data-nav-layout="horizontal"] .landing-body .customize-image img {
  @apply w-[30.5rem] h-[20rem] mt-[20px] p-[15px] relative;
}
[data-nav-layout="horizontal"] .landing-body h3 {
  @apply text-[1.75rem];
}
[data-nav-layout="horizontal"] .landing-body .team-card .team-avatar {
  @apply shadow-[0_0_0_0.5rem_rgba(0,0,0,0.05)] !important;
}
[data-nav-layout="horizontal"] .landing-body .landing-footer {
  @apply bg-black border-b border-solid border-white/10 !important;
}
[data-nav-layout="horizontal"] .landing-body .landing-main-footer {
  @apply bg-black;
}
[data-nav-layout="horizontal"] .landing-body .landing-footer-list li {
  @apply mb-2;
}
[data-nav-layout="horizontal"] .landing-body .brand-img-white {
  @apply block;
}
[data-nav-layout="horizontal"] .landing-body .swiper-pagination-bullet {
  @apply w-[1.25rem] h-[0.25rem] rounded-md bg-white dark:bg-primary !important;
}
[data-nav-layout="horizontal"] .landing-body .brand-img-light {
  @apply block;
}
@media (min-width: 992px) {
  [data-nav-layout="horizontal"] .landing-body .app-sidebar {
    @apply top-0 px-0 h-auto shadow-none border-0 !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .side-menu__label {
    @apply text-white dark:text-defaulttextcolor/10 opacity-[0.8];
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .main-sidebar {
    @apply h-auto !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item
    .side-menu__angle {
    @apply text-[#536485];
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item:hover
    .side-menu__angle {
    @apply text-white dark:text-defaulttextcolor/10;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.app-sidebar
    .side-menu__label {
    @apply text-white dark:text-defaulttextcolor;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .landing-logo-container
    .horizontal-logo
    .desktop-white {
    @apply hidden;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .landing-logo-container
    .horizontal-logo
    .desktop-logo {
    @apply block;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar.app-sidebar
    .side-menu__item:hover
    .side-menu__label {
    @apply text-white dark:text-defaulttextcolor;
  }
  [data-nav-layout="horizontal"] .landing-body .app-header {
    @apply hidden;
  }
  [data-nav-layout="horizontal"] .landing-body .main-sidebar-header {
    @apply block !important;
  }
  [data-nav-layout="horizontal"] .landing-body .main-menu-container {
    @apply flex items-center justify-between;
  }
  [data-nav-layout="horizontal"] .landing-body .main-menu-container .slide-left,
  [data-nav-layout="horizontal"]
    .landing-body
    .main-menu-container
    .slide-right {
    @apply hidden;
  }
  [data-nav-layout="horizontal"] .landing-body .main-content {
    @apply p-0;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-logo-container
    .horizontal-logo
    .header-logo
    .desktop-logo {
    @apply hidden;
  }
}
[data-nav-layout="horizontal"] .landing-body iframe {
  @apply w-full;
}
@media (max-width: 991.98px) {
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item.active,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .side-menu__item:hover {
    @apply bg-transparent;
  }
  [data-nav-layout="horizontal"] .landing-body .main-menu-container .main-menu {
    @apply ps-[20px] !important;
  }
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .slide {
    @apply p-0;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-logo-container
    .horizontal-logo
    .header-logo
    .desktop-logo {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-logo-container
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden !important;
  }
}
@media (max-width: 1115.98px) {
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-body
    .landing-main-image
    img {
    @apply bg-transparent before:hidden after:hidden !important;
  }
}
@media (max-width: 767.98px) {
  [data-nav-layout="horizontal"] .landing-body .landing-banner {
    @apply h-[34rem];
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-banner
    .main-banner-container {
    @apply p-4;
  }
}
@media (max-width: 400px) {
  [data-nav-layout="horizontal"] .landing-body .landing-body .landing-banner {
    @apply h-[45rem];
  }
}
@media (max-width: 480px) {
  [data-nav-layout="horizontal"] .landing-body .landing-banner .section {
    @apply py-[2.375rem] px-0;
  }
}
@media (max-width: 420px) {
  [data-nav-layout="horizontal"] .landing-body .landing-banner {
    @apply h-[37.5rem];
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-banner
    .main-banner-container {
    @apply p-4;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-banner
    .landing-banner-heading {
    @apply text-[2rem];
  }
}
@media (max-width: 992px) {
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .side-menu__item {
    @apply py-[0.8rem] pr-[5rem] ps-4;
  }
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide-menu.child1
    li,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide-menu.child2
    li,
  [data-nav-layout="horizontal"]
    .landing-body
    .app-sidebar
    .slide-menu.child3
    li {
    @apply p-0 ps-0 relative;
  }
}
@media (min-width: 992px) {
  [data-nav-layout="horizontal"].dark
    .landing-body
    .main-menu-container
    .landing-logo-container
    .horizontal-logo
    .header-logo
    .desktop-logo {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"].dark
    .landing-body
    .app-sidebar
    .side-menu__item {
    @apply text-defaulttextcolor/70;
  }
}
@media (max-width: 991.98px) {
  [data-nav-layout="horizontal"].dark
    .landing-body
    .app-header
    .main-header-container
    .horizontal-logo
    .header-logo
    .toggle-logo {
    @apply hidden;
  }
  [data-nav-layout="horizontal"].dark
    .landing-body
    .app-header
    .main-header-container
    .horizontal-logo
    .header-logo
    .toggle-dark {
    @apply block;
  }
}
[data-nav-layout="horizontal"].dark
  .landing-body
  .landing-trusted-companies
  img {
  @apply invert-[1];
}
[data-nav-layout="horizontal"].dark
  .landing-body
  .app-sidebar.sticky-pin
  .landing-logo-container
  .horizontal-logo
  .desktop-white {
  @apply block;
}
[data-nav-layout="horizontal"].dark .brand-img-light {
  @apply block;
}
[data-nav-layout="horizontal"].dark .brand-img-white {
  @apply hidden;
}
[data-nav-layout="horizontal"].dark .section-bg {
  @apply bg-black/10;
}
@media (min-width: 992px) {
  [data-nav-layout="horizontal"] .landing-body .app-sidebar .main-menu {
    @apply pe-6;
  }
}
@media (max-width: 991.98px) {
  [data-nav-layout="horizontal"] .landing-body .main-content {
    @apply pt-[3.75rem] !important;
  }
  [data-nav-layout="horizontal"] .landing-main-image {
    @apply hidden;
  }
}

.landing-body .pickr-container-primary :last-child button {
  @apply block;
}
.landing-body .pickr-container-background :last-child button {
  @apply block;
}

@media (max-width: 991.98px) {
  .landing-body .app-sidebar .slide-menu {
    @apply ps-4;
  }
  .landing-body .app-sidebar .slide-menu.child1 .side-menu__item,
  .landing-body .app-sidebar .slide-menu.child2 .side-menu__item {
    @apply py-[0.45rem] px-[1.6rem] !important;
  }
}
/* End Landing Styles */
.bg-banner-2 {
  @apply relative top-0 bg-[url("../public/assets/images/media/backgrounds/4.png")] bg-cover bg-center bg-no-repeat z-[0] before:absolute before:w-full before:h-full before:bg-primary/80 before:inset-0 before:-z-[1];
}

.featured-jobs {
  @apply hidden border border-solid border-defaultborder dark:border-defaultborder/10;
}

.feature-style .feature-style-icon {
  @apply relative w-[4rem] h-[4rem] leading-[4rem] text-[1.25rem] items-center justify-center inline-flex rounded-full font-medium !important;
}

.feature-style .feature-style-icon img,
.feature-style .feature-style-icon svg {
  @apply w-[2.5rem] h-[2.5rem];
}

.feature-style {
  @apply relative hidden overflow-hidden before:absolute before:w-[4rem] before:h-[4rem] before:-end-[1.5rem] before:-bottom-[1.5rem] before:bg-primary/10 before:rounded-full;
}

.bg-banner {
  @apply bg-primary dark:bg-primary text-white relative overflow-hidden z-[1] before:absolute before:h-full before:w-full before:top-0 before:bg-[url("../public/assets/images/media/backgrounds/7.jpg")] before:bg-no-repeat
  before:bg-bottom before:bg-cover before:opacity-[0.1] before:transition-all before:ease-linear before:duration-[0.3sec] before:-z-[1];
}

.featured-card-4 svg {
  @apply w-[1.5rem] h-[1.5rem];
}

.landing-body .landing-main-footer .landing-footer-list li:not(:first-child) {
  @apply relative before:absolute before:w-[0.3rem] before:h-[0.3rem] before:border before:border-solid before:border-white before:rounded-full before:bg-transparent 
  before:-start-[0.2rem] before:top-2;
}

.custom-form-group {
  @apply relative flex items-center;
}

.custom-form-group .custom-form-btn {
  @apply absolute end-2 rounded-[0.3rem] flex items-center justify-center bg-white dark:bg-bodybg;
}

.custom-form-group .custom-form-btn .gps-location {
  @apply text-textmuted opacity-[0.6] text-[1.0625rem] leading-[0] me-[10px];
}

.landing-body.jobs-landing .landing-main-footer .landing-footer-list li {
  @apply inline-block px-3 py-0;
}

/* CUSTOM STYLES */
/* PAGES STYLES */
.main-chart-wrapper {
  @apply relative overflow-hidden my-0 -mx-[1.25rem];
}
.main-chart-wrapper .chat-info,
.main-chart-wrapper .main-chat-area,
.main-chart-wrapper .chat-user-details {
  @apply bg-white dark:bg-bodybg h-[calc(100vh-8rem)] rounded-md;
}
.main-chart-wrapper .chat-users-tab,
.main-chart-wrapper .chat-groups-tab,
.main-chart-wrapper .chat-calls-tab {
  @apply max-h-[calc(100vh-21rem)];
}
.main-chart-wrapper .chat-content {
  @apply max-h-[calc(100vh-21rem)];
}
.main-chart-wrapper
  .chat-content
  .simplebar-content-wrapper
  .simplebar-content {
  @apply mt-auto;
}
.main-chart-wrapper .chat-content ul li {
  @apply mb-4;
}
.main-chart-wrapper .chat-content ul li:last-child {
  @apply mb-0;
}
.main-chart-wrapper .responsive-chat-close,
.main-chart-wrapper button.responsive-userinfo-open {
  @apply hidden;
}
.main-chart-wrapper .chat-info {
  @apply relative;
}
.main-chart-wrapper .chat-info .chat-add-icon {
  @apply absolute bottom-3 end-[0.8rem] z-[1];
}
.main-chart-wrapper .chat-info .nav-link {
  @apply rounded-none text-[#8c9097];
}
.main-chart-wrapper .chat-info .nav-link.active {
  @apply bg-primary/10;
}
.main-chart-wrapper .chat-info .tab-pane {
  @apply p-0;
}
.main-chart-wrapper .chat-info .chat-groups-tab li {
  @apply py-[0.625rem] px-[1.25rem];
}
.main-chart-wrapper .chat-info .chat-groups-tab .group-indivudial {
  @apply text-primary font-semibold;
}
.main-chart-wrapper .chat-info .chat-calls-tab li {
  @apply py-[0.625rem] px-[1.25rem];
}
.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-success i,
.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-success i {
  @apply text-success text-[0.875rem];
}
.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-failed i,
.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-failed i {
  @apply text-danger text-[0.875rem];
}
.main-chart-wrapper .chat-info .chat-users-tab li,
.main-chart-wrapper .chat-info .chat-groups-tab li {
  @apply py-[0.625rem] px-[1.25rem];
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg {
  @apply text-[#8c9097] dark:text-defaulttextcolor/70 max-w-[11.25rem] inline-block;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-msg {
  @apply text-success;
}
.main-chart-wrapper
  .chat-info
  .chat-users-tab
  li
  .chat-msg-typing
  .chat-read-icon,
.main-chart-wrapper
  .chat-info
  .chat-groups-tab
  li
  .chat-msg-typing
  .chat-read-icon {
  @apply hidden;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon {
  @apply leading-none;
}
.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon i {
  @apply text-[1rem] text-success;
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread {
  @apply bg-defaultbackground text-defaulttextcolor dark:text-defaulttextcolor/70 dark:bg-light;
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-msg {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70;
}
.main-chart-wrapper
  .chat-info
  .chat-users-tab
  li.chat-msg-unread
  .chat-read-icon
  i,
.main-chart-wrapper
  .chat-info
  .chat-groups-tab
  li.chat-msg-unread
  .chat-read-icon
  i {
  @apply text-[#8c9097] dark:text-defaulttextcolor/70;
}
.main-chart-wrapper .chat-info .chat-users-tab li.chat-inactive .chat-read-icon,
.main-chart-wrapper
  .chat-info
  .chat-groups-tab
  li.chat-inactive
  .chat-read-icon {
  @apply hidden;
}
.main-chart-wrapper .chat-user-details {
  @apply p-[1.5rem];
}
.main-chart-wrapper .chat-user-details .avatar {
  @apply outline-1 outline-primary/20 border-solid !important;
}
.main-chart-wrapper .chat-user-details .shared-files li {
  @apply mb-1;
}
.main-chart-wrapper .chat-user-details .shared-files li:last-child {
  @apply mb-0;
}
.main-chart-wrapper .chat-user-details .shared-files .shared-file-icon i {
  @apply w-4 h-4 leading-none rounded-[0.3rem] border border-solid border-defaultborder dark:border-defaultborder/10 dark:text-defaulttextcolor/70 flex items-center p-[1.125rem] justify-center text-[1.125rem] text-[#8c9097];
}
.main-chart-wrapper .chat-user-details .chat-media img {
  @apply w-[5.5rem] h-[5.5rem] rounded-md mb-[1.125rem];
}
.main-chart-wrapper .main-chat-area {
  @apply relative;
}
.main-chart-wrapper .main-chat-area .chat-content {
  @apply bg-cover bg-center bg-no-repeat bg-fixed p-[2.5rem];
}
.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 font-semibold text-defaultsize;
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chatting-user-info
  .msg-sent-time {
  @apply text-[#8c9097] text-[0.75rem] font-medium;
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chatting-user-info
  .msg-sent-time
  .chat-read-mark
  i {
  @apply text-success me-[0.3rem];
}
.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div {
  @apply mt-[0.4rem] p-3 w-fit;
}
.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div p {
  @apply text-[0.813rem];
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .main-chat-msg
  div
  .chat-media-image {
  @apply w-[6.25rem] h-[6.25rem] rounded-md;
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chat-item-start
  .main-chat-msg
  div {
  @apply bg-light text-defaulttextcolor dark:text-defaulttextcolor/70 rounded-[0.3rem];
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chat-item-start
  .msg-sent-time {
  @apply ms-1;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
  @apply justify-end text-end;
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chat-item-end
  .main-chat-msg
  div {
  @apply bg-primary text-white rounded-[0.3rem];
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chat-item-end
  .msg-sent-time {
  @apply me-1;
}
.main-chart-wrapper .main-chat-area .chat-content .chat-item-start,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
  @apply flex;
}
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chat-item-start
  .chat-list-inner,
.main-chart-wrapper
  .main-chat-area
  .chat-content
  .chat-item-end
  .chat-list-inner {
  @apply flex items-start max-w-[75%];
}
.main-chart-wrapper .main-chat-area .chat-footer {
  @apply w-full;
}
.main-chart-wrapper .main-chat-area .chat-footer {
  @apply flex-shrink-0 flex items-center h-[4.75rem] py-0 px-[1.5rem] border-t border-solid dark:text-defaulttextcolor/70 border-defaultborder dark:border-defaultborder/10 bg-white dark:bg-bodybg absolute start-auto end-auto bottom-0;
}
.main-chart-wrapper .main-chat-area .chat-day-label {
  @apply text-center mb-8 opacity-[0.6] relative text-[#8c9097];
}
.main-chart-wrapper .main-chat-area .chat-day-label span {
  @apply py-[0.188rem] px-2 text-[0.7rem] bg-primary/20 rounded-[0.3rem] text-primary;
}
@media (min-width: 992px) {
  .main-chart-wrapper .chat-info {
    @apply min-w-[21.875rem] max-w-[21.875rem];
  }
}
.main-chart-wrapper .main-chat-area {
  @apply w-full max-w-full overflow-hidden;
}
.main-chart-wrapper .chat-user-details {
  @apply sm:min-w-[21.875rem] sm:max-w-[21.875rem] min-w-[20rem] max-w-[20rem];
}
@media (max-width: 1275.98px) and (min-width: 992px) {
  .main-chart-wrapper .chat-info {
    @apply min-w-[21.875rem] max-w-[21.875rem];
  }
  .main-chart-wrapper .main-chat-area {
    @apply w-full max-w-full overflow-hidden;
  }
  .main-chart-wrapper .chat-user-details {
    @apply hidden;
  }
}
@media (max-width: 991.98px) {
  .main-chart-wrapper .chat-info {
    @apply w-full;
  }
  .main-chart-wrapper .main-chat-area {
    @apply hidden min-h-full max-w-full;
  }
  .main-chart-wrapper .responsive-chat-close {
    @apply block;
  }
}
@media (max-width: 1400px) {
  .main-chart-wrapper .chat-user-details {
    @apply hidden absolute;
  }
  .main-chart-wrapper .chat-user-details.open {
    @apply block end-0 top-2 shadow-sm border border-solid border-defaultborder dark:border-defaultborder/10;
  }
  .main-chart-wrapper button.responsive-userinfo-open {
    @apply block;
  }
}

@media (max-width: 991.98px) {
  .main-chart-wrapper.responsive-chat-open .chat-info {
    @apply hidden;
  }
  .main-chart-wrapper.responsive-chat-open .main-chat-area {
    @apply block;
  }
}
@media (max-width: 767.98px) {
  .main-chart-wrapper
    .main-chat-area
    .chat-content
    .main-chat-msg
    div
    .chat-media-image {
    @apply w-[2.5rem] h-[2.5rem];
  }
}
@media (max-width: 354px) {
  .main-chart-wrapper .chat-calls-tab,
  .main-chart-wrapper .chat-groups-tab,
  .main-chart-wrapper .chat-users-tab {
    @apply max-h-[calc(100vh-22rem)];
  }
}
[dir="rtl"] .chat-footer .btn-send {
  @apply rotate-180;
}

.tab-style-2 {
  @apply border-b-0;
}
.tab-style-2 .nav-item {
  @apply me-2;
}
.tab-style-2 .nav-item:last-child {
  @apply me-0;
}
.tab-style-2 .nav-item .nav-link {
  @apply border-0;
}
.tab-style-2 .nav-item .nav-link i {
  @apply w-[1.875rem] h-[1.875rem] p-[0.4rem] rounded-[50px] bg-light text-defaulttextcolor dark:text-defaulttextcolor/70 inline-flex;
}
.tab-style-2 .nav-item .nav-link:hover {
  @apply border-0;
}
.tab-style-2 .nav-item .nav-link.active {
  @apply bg-primary/10 relative border-0 text-primary;
}
.tab-style-2 .nav-item .nav-link.active i {
  @apply bg-primary/10 text-primary before:absolute before:start-[45%] before:end-0 before:bottom-0 before:w-[20%] before:h-[0.175rem] before:bg-primary before:rounded-[50px];
}

/* start:: Aboutus */
.about-container {
  @apply bg-white dark:bg-bodybg rounded-md overflow-hidden p-[1.25rem];
}

.aboutus-banner {
  @apply w-full bg-[url("../public/assets/images/media/media-79.jpg")] dark:bg-bodybg bg-cover bg-center bg-no-repeat relative flex items-center justify-start rounded-md z-[9] mb-[2rem] border-white dark:border-bodybg before:absolute before:bg-black/80 before:w-full before:h-full before:inset-0 before:rounded-[0.3rem];
}
.aboutus-banner .aboutus-banner-content {
  @apply z-[10] text-center text-white;
}

@media (max-width: 575.98px) {
  .about-company-stats .about-company-stats-border {
    @apply border-b border-dashed border-black/10;
  }
}
@media (min-width: 576px) {
  .about-company-stats {
    @apply absolute -bottom-[5rem] z-[10] start-0 end-0;
  }
  .about-company-stats .about-company-stats-border {
    @apply border-e border-dashed border-black/10 dark:border-defaultborder/10;
  }
  .aboutus-banner {
    @apply p-[4rem] mb-[8rem];
  }
}
.about-heading-white {
  @apply relative before:absolute before:w-full before:h-[0.25rem] before:rounded-[3.125rem] before:bg-gradient-to-r before:from-white before:to-transparent/20 before:-bottom-[0.625rem] before:start-[0.375rem];
}

@media (max-width: 1199.98px) {
  .aboutus-banner-img {
    @apply hidden;
  }
}
@media (min-width: 1200px) {
  .aboutus-img {
    @apply flex items-center justify-center relative before:absolute before:w-[12.5rem] before:h-[12.5rem] before:top-[6rem] before:start-[11.875rem] before:bg-white/[0.06] after:absolute after:w-[12.5rem] after:-top-[2.625rem] after:end-[11.5rem] after:h-[12.5rem] after:bg-white/[0.06];
  }
}
.aboutus-banner-img img {
  @apply w-full h-[17.5rem] z-[1] !important;
}

.about-main,
.about-motto {
  @apply flex items-center justify-center;
}

.motto-icon {
  @apply w-[1.875rem] h-[1.875rem];
}

.about-heading {
  @apply relative mb-4 before:absolute before:w-[60%] before:h-[0.25rem] before:rounded-[3.125rem] before:bg-gradient-to-r before:from-success before:to-success/20 before:-bottom-[0.625rem] before:start-[0.375rem];
}

/* End:: Aboutus */
/* start:: blog */
.overlay-card {
  @apply relative overflow-hidden text-black/90 before:inset-0 before:bg-black/20 before:absolute;
}
.overlay-card .box-header {
  @apply border-0 border-solid border-black/10;
}
.overlay-card .box-footer {
  @apply border-0 border-solid border-black/10;
}
.overlay-card .over-content-bottom {
  @apply top-auto;
}

.page-link {
  @apply text-defaulttextcolor bg-white dark:bg-bodybg dark:border-defaultborder/10 border border-solid border-defaultborder;
}
.page-link:focus {
  @apply shadow-none bg-light;
}
.page-link:hover {
  @apply text-primary bg-light;
}

.page-item.active .page-link {
  @apply text-white bg-primary border-primary;
}

.disabled > .page-link,
.page-link.disabled {
  @apply text-defaulttextcolor bg-white dark:bg-bodybg dark:border-defaultborder/10 border-defaultborder opacity-[0.7];
}

.popular-blog-content {
  @apply max-w-[9rem];
}

.blog-caption {
  @apply absolute start-[1.5rem] end-[15%] bottom-[1.5rem] p-0 text-white;
}

.carousel-caption {
  @apply text-white;
}

.carousel-caption {
  @apply absolute inset-x-[15%] bottom-[1.25rem] pt-[1.25rem] mb-[1.25rem] text-white;
}

/* End:: blog */
/* start:: blog-details */
.blog-details-img {
  @apply h-[450px];
}

.blog-popular-tags .badge {
  @apply m-[0.313rem] text-[0.65rem] !important;
}

/* End:: blog-details */
/* start:: blog-create */
.form-label {
  @apply text-[0.8rem] font-semibold text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.form-text {
  @apply text-[#8c9097];
}

.form-label {
  @apply mb-2;
}

.choices__inner {
  @apply inline-block align-top w-full bg-[#f9f9f9] overflow-hidden;
}

.choices__list--multiple {
  @apply inline;
}

.choices__list--multiple .choices__item {
  @apply inline-block align-middle rounded-[20px] py-[4px] px-[10px] text-[12px] font-medium mr-[3.75rem] mb-[3.75rem] bg-[#00bcd4] border border-solid border-[#00bcd4] text-white break-all box-border;
}

label {
  @apply inline-flex;
}

.blog-images-container .filepond--root {
  @apply w-full;
}
.blog-images-container .filepond--panel-root {
  @apply border-inputborder rounded-md !important;
}
.blog-images-container .filepond--root .filepond--drop-label label {
  @apply text-[#8c9097];
}

.filepond--root .filepond--drop-label {
  @apply min-h-[4.75rem];
}

.filepond--root .filepond--list-scroller {
  @apply mt-4 mb-4;
}

.filepond--drop-label {
  @apply end-0 m-0 text-[#4f4f4f] flex justify-center items-center h-0 select-none;
}

.filepond--drop-label.filepond--drop-label label {
  @apply block m-0 p-2;
}

.filepond--drop-label label {
  @apply cursor-default text-[0.875rem] font-normal leading-[1.5];
}

#blog-content {
  @apply h-auto;
}

/* End:: blog-create */
/* start:: contacts*/
.contact-action {
  @apply relative p-[1.25rem];
}
.contact-action .contact-overlay {
  @apply absolute w-0 h-full bg-primary/70 inset-0 z-[1] rounded-md;
}
.contact-action:hover .contact-overlay {
  @apply w-full h-full top-0 start-0;
}
.contact-action:hover .contact-hover-btn,
.contact-action:hover .contact-hover-dropdown,
.contact-action:hover .contact-hover-dropdown1 {
  @apply block opacity-[1];
}
.contact-action .contact-hover-btn {
  @apply opacity-0 z-[2];
}
.contact-action .contact-hover-dropdown {
  @apply opacity-0 z-[2];
}
.contact-action .contact-hover-dropdown1 {
  @apply opacity-0 z-[2];
}
.contact-action .contact-hover-buttons {
  @apply absolute w-full h-full inset-0;
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .contact-mail {
    @apply max-w-[8.125rem];
  }
}
@media (max-width: 400px) {
  .contact-mail {
    @apply max-w-[8.125rem];
  }
}
/* end:: contacts*/
/* start:: contact-us */
.contact-page-banner {
  @apply relative w-full h-[25rem] bg-[url("../public/assets/images/media/media-68.jpg")] bg-cover bg-no-repeat bg-center pt-[6.25rem] before:absolute before:w-full before:h-full before:bg-primary/80
  before:inset-0;
}
.contact-page-banner div {
  @apply z-[1] relative;
}

@media (min-width: 576px) {
  .contactus-form {
    @apply relative -top-[6.25rem];
  }
}
.contactus-form .tab-style-2 .nav-item .nav-link {
  @apply py-[0.85rem] px-8;
}

.contactus-form iframe {
  @apply w-full;
}

/* end:: contact-us */
/* start:: cart */
.product-quantity-container {
  @apply w-[8.75rem];
}
.product-quantity-container .input-group input.form-control:focus {
  @apply shadow-none border-0;
}
.product-quantity-container
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  @apply ms-0;
}

.cart-empty svg {
  @apply w-[6.25rem] h-[6.25rem] mb-[1.25rem] fill-defaulttextcolor text-center inline-flex  !important;
}

.input-group-text {
  @apply border border-defaultborder text-[0.875rem] rounded-[0.3125rem] bg-light text-defaulttextcolor  tracking-normal;
}

.swal2-container .swal2-popup {
  @apply bg-white dark:bg-bodybg pt-0 pb-8 px-0;
}

.swal2-container .swal2-icon {
  @apply w-[5rem] h-[5rem];
}

.swal2-container .swal2-icon.swal2-warning {
  @apply border-warning text-warning;
}

.swal2-container .swal2-title {
  @apply text-[1.15rem] pt-8 pb-2 px-8;
}

.swal2-container .swal2-html-container {
  @apply text-[0.8rem] text-textmuted mt-0 mb-[0.3rem] mx-[1.6rem];
}

.swal2-container .swal2-styled.swal2-confirm {
  @apply bg-primary text-white py-[0.375rem] px-3 text-[0.8125rem] !important;
}

.swal2-container .swal2-styled.swal2-cancel {
  @apply bg-light text-defaulttextcolor py-[0.375rem] px-3 text-[0.8125rem] !important;
}

.swal2-container .swal2-icon {
  @apply mt-8 mb-0 mx-auto !important;
}

/* end:: cart */
/* Start:: Checkout */
.product-checkout .tab-style-2 .nav-item .nav-link {
  @apply py-[0.85rem] px-[2rem];
}
.product-checkout .form-floating input,
.product-checkout .form-floating textarea {
  @apply text-[0.813rem] font-semibold;
}
.product-checkout .shipping-method-container,
.product-checkout .payment-card-container {
  @apply relative p-[0.625rem] border border-solid border-defaultborder rounded-md;
}
.product-checkout .shipping-method-container .form-check-input,
.product-checkout .payment-card-container .form-check-input {
  @apply absolute end-3 top-[1.2rem];
}
@media (min-width: 576px) {
  .product-checkout .shipping-method-container .shipping-partner-details,
  .product-checkout .shipping-method-container .saved-card-details,
  .product-checkout .payment-card-container .shipping-partner-details,
  .product-checkout .payment-card-container .saved-card-details {
    @apply w-[12.5rem];
  }
}

.checkout-payment-success {
  @apply text-center;
}
.checkout-payment-success img {
  @apply w-[200px] h-[200px];
}

.form-floating {
  @apply relative !important;
}

.form-floating > label {
  @apply absolute top-0 start-0 z-[2] h-full pt-1 pb-4 px-3 overflow-hidden text-[#8c9097] text-start text-ellipsis whitespace-nowrap pointer-events-none border border-solid border-transparent text-[.813rem];
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    @apply transition-none;
  }
}
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  @apply pt-[1.625rem] pb-[0.625rem];
}

.form-control.is-valid,
.was-validated .form-control:valid {
  @apply border-success !important;
}

.product-checkout .payment-card-container,
.product-checkout .shipping-method-container {
  @apply relative p-[0.625rem] border border-solid border-defaultborder dark:border-white/10 rounded-md;
}

.product-checkout .payment-card-container .form-check-input,
.product-checkout .shipping-method-container .form-check-input {
  @apply absolute end-3 top-[1.2rem];
}

.product-checkout .shipping-method-container .shipping-partner-details {
  @apply w-[12.5rem];
}

.tab-style-checkout {
  @apply border-b-0;
}
.tab-style-checkout .nav-item {
  @apply me-2;
}
.tab-style-checkout .nav-item:last-child {
  @apply me-0;
}
.tab-style-checkout .nav-item .nav-link {
  @apply border-0;
}
.tab-style-checkout .nav-item .nav-link i {
  @apply w-[1.875rem] h-[1.875rem] p-[0.4rem] rounded-[50px] bg-light text-defaulttextcolor inline-flex;
}
.tab-style-checkout .nav-item .nav-link:hover {
  @apply border-0;
}
.tab-style-checkout .nav-item .nav-link.active {
  @apply bg-transparent relative border-0 text-primary;
}
.tab-style-checkout .nav-item .nav-link.active i {
  @apply bg-primary/10 text-primary before:absolute before:start-[45%] before:end-0 before:bottom-0 before:w-[20%] before:h-[0.175rem] before:bg-primary before:rounded-[50px] !important;
}

/* End:: Checkout */
/* Start:: Order Details */
.order-track {
  @apply relative before:start-[20px] before:top-0 before:bottom-0 before:end-0 before:absolute before:w-[1px] before:border-s before:border-dashed before:border-primary before:h-full before:opacity-[0.2];
}

/* End:: Order Details */
/* Start:: Orders */
.orders-delivery-address {
  @apply w-[75%];
}

.delivery-date {
  @apply w-[60px] h-[60px] p-[10px] bg-primary/5 items-center justify-center flex flex-col rounded-[0.3rem];
}

/* End:: Orders */
/* start:: products*/
.navbar-expand-xxl .navbar-collapse {
  @apply flex flex-grow;
}

.navbar-expand-xxl .navbar-nav {
  @apply flex-row;
}

.navbar {
  @apply rounded-md;
}
.navbar .navbar-nav .nav-link {
  @apply leading-none p-[0.4rem] px-4 font-medium;
}
.navbar .navbar-toggler {
  @apply p-[0.4rem] text-[1rem] leading-none text-black border border-solid border-defaultborder rounded-md;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  @apply w-4 h-4 relative bg-none before:absolute before:text-[1rem] before:text-defaulttextcolor before:start-0;
}
.navbar .navbar-toggler:focus {
  @apply shadow-none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  @apply text-black;
}

.navbar-brand {
  @apply me-6;
}
.navbar-brand img {
  @apply h-8 leading-8;
}

.nav-link {
  @apply text-defaulttextcolor rounded-md font-semibold hover:text-primary;
}
.nav-link.active {
  @apply bg-primary/10 text-primary;
}

.nav-item {
  @apply me-2;
}
.nav-item:last-child {
  @apply me-0;
}

.navbar-brand {
  @apply text-defaulttextcolor;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  @apply text-primary;
}

.nav-link.disabled {
  @apply text-defaulttextcolor opacity-[0.3];
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  @apply text-gray-500;
}

.navbar-text {
  @apply text-defaulttextcolor;
}

/* Start:: Products */
.product-icons {
  @apply hidden;
}

.product-image img {
  @apply bg-light;
}

.product-card {
  @apply relative;
}
.product-card:hover .product-icons {
  @apply block;
}
.product-card:hover .product-icons .wishlist,
.product-card:hover .product-icons .cart,
.product-card:hover .product-icons .view {
  @apply absolute w-[1.75rem] h-[1.75rem] rounded-md flex items-center justify-center;
}
.product-card:hover .product-icons .wishlist i,
.product-card:hover .product-icons .cart i,
.product-card:hover .product-icons .view i {
  @apply text-[0.9rem];
}
.product-card:hover .product-icons .wishlist {
  @apply bg-danger/20 text-danger top-[1.75rem] end-[1.75rem];
}
.product-card:hover .product-icons .cart {
  @apply bg-primary/20 text-primary top-16 end-[1.75rem];
}
.product-card:hover .product-icons .view {
  @apply bg-success/20 text-success top-[6.25rem] end-[1.75rem];
}

.ecommerce-more-link {
  @apply relative py-[0.3rem] pe-[0.5rem] ps-3 bg-primary/10 text-primary rounded-md font-medium text-[0.625rem] hover:text-primary
  before:absolute before:content-[\f64d] before:end-2 before:top-[0.313rem] before:font-bold after:absolute after:content-[\F2EA] after:end-2 after:top-[0.313rem] after:font-bold after:hidden;
}

.ecommerce-more-link[aria-expanded="true"] {
  @apply after:block before:hidden;
}

.products-navigation-card .form-check-label {
  @apply text-defaultsize font-medium;
}

/* End:: Products */
/* End:: products*/
/* start:: Product Details */
.swiper-view-details .swiper-slide {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}
.swiper-view-details .swiper-slide.swiper-slide-thumb-active {
  @apply bg-light !important;
}

.switcher-nft-details .swiper-slide {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}
.switcher-nft-details .swiper-slide.swiper-slide-thumb-active {
  @apply bg-light p-[2px] border border-primary !important;
}

.product-colors {
  @apply w-8 h-8 flex items-center justify-center rounded-[3.125rem] border dark:border-white/10 border-solid me-2 bg-light;
}
.product-colors i {
  @apply text-[1.5rem];
}
.product-colors.color-2.selected {
  @apply border-[0.125rem] border-solid border-white;
}
.product-colors.color-2 i {
  @apply text-white;
}
.product-colors.color-4.selected {
  @apply border-[0.125rem] border-solid border-[#514f4d];
}
.product-colors.color-4 i {
  @apply text-[#514f4d];
}
.product-colors.color-1.selected {
  @apply border-[0.125rem] border-solid border-[#e9d2d1];
}
.product-colors.color-1 i {
  @apply text-[#e9d2d1];
}
.product-colors.color-3.selected {
  @apply border-[0.125rem] border-solid border-[#c2c3c7];
}
.product-colors.color-3 i {
  @apply text-[#c2c3c7];
}
.product-colors.color-5.selected {
  @apply border-[0.125rem] border-solid border-[#4a6181];
}
.product-colors.color-5 i {
  @apply text-[#4a6181];
}

.product-sizes {
  @apply w-8 h-8 flex items-center justify-center rounded-[3.125rem] border border-solid border-defaultborder dark:border-white/10 me-2 text-[0.75rem] bg-light text-[#8c9097];
}
.product-sizes.selected {
  @apply bg-success/10 text-success;
}

.offer-promotion {
  @apply relative p-2 bg-warning/10 border border-solid border-defaultborder rounded-md;
}
.offer-promotion .promotion-code {
  @apply text-defaulttextcolor;
}
.offer-promotion .offer-details {
  @apply border border-dashed border-warning py-1 px-2 rounded-md;
}

.ecommerce-assurance {
  @apply border border-dashed border-success rounded-md text-center py-4 px-6;
}
.ecommerce-assurance svg {
  @apply w-[3rem] h-[3rem];
}

.product-images {
  @apply ps-[1.875rem];
}
.product-images .products-review-images img {
  @apply w-[3.125rem] h-[3.125rem] me-1 rounded-md bg-light;
}

.similar-products-image {
  @apply w-[3rem] h-[3rem];
}
.similar-products-image img {
  @apply w-[3rem] h-[3rem] rounded-md bg-light;
}

.similar-product-name {
  @apply max-w-[80%];
}

.swiper-button-next:after,
.swiper-button-prev:after {
  @apply text-[0.75rem] font-[800] !important;
}

/* End:: Product Details */
.product-list {
  @apply border border-defaultborder dark:border-defaultborder/10;
}

/* Start:: mail-app */
.main-mail-container {
  @apply relative overflow-hidden  my-0 -mx-[1.25rem];
}

.mail-navigation,
.total-mails,
.mails-information,
.mail-recepients {
  @apply bg-white dark:bg-bodybg h-[calc(100vh-8rem)] overflow-hidden rounded-md;
}

.mail-info-body {
  @apply max-h-[calc(100vh-16.8rem)];
}

@media (min-width: 1400px) {
  .total-mails {
    @apply max-w-[24rem] min-w-[24rem];
  }
  .total-mails .mail-msg span {
    @apply max-w-[17rem] inline-block;
  }
}
@media (max-width: 1399.98px) {
  .mails-information {
    @apply hidden;
  }
}
@media (min-width: 1400px) and (max-width: 1480px) {
  .responsive-mail-action-icons .dropdown {
    @apply block;
  }
  .responsive-mail-action-icons .close-button {
    @apply hidden;
  }
  .mail-action-icons {
    @apply hidden;
  }
}
@media (max-width: 1399.98px) {
  .responsive-mail-action-icons {
    @apply block;
  }
  .mail-action-icons {
    @apply hidden;
  }
}
@media (max-width: 575.98px) {
  .mail-recepients {
    @apply hidden;
  }
}
@media (min-width: 1489px) {
  .responsive-mail-action-icons {
    @apply hidden;
  }
}
@media (max-width: 991.98px) {
  .mail-navigation {
    @apply w-full;
  }
}
@media (min-width: 992px) {
  .mail-navigation {
    @apply min-w-[16rem] max-w-[16rem];
  }
}
.total-mails {
  @apply w-full;
}
.total-mails .mail-messages {
  @apply max-w-full max-h-[calc(100vh-16.65rem)];
}
.total-mails .mail-messages li {
  @apply pt-[1.1rem] px-[1.25rem] pb-[0.625rem] border border-solid border-defaultborder dark:border-defaultborder/10;
}
.total-mails .mail-messages li.active {
  @apply bg-light;
}
.total-mails .mail-messages li:last-child {
  @apply border-b-0;
}
.total-mails .mail-messages li .avatar.mail-msg-avatar {
  @apply w-[1.8rem] h-[1.8rem];
}
.total-mails .mail-messages li .avatar.mail-msg-avatar.online:before,
.total-mails .mail-messages li .avatar.mail-msg-avatar.offline:before {
  @apply w-[0.55rem] h-[0.55rem];
}
.total-mails .mail-messages li .mail-msg .mail-starred i {
  @apply opacity-[0.5] text-[#8c9097];
}
.total-mails .mail-messages li .mail-msg .mail-starred.true i {
  @apply text-warning opacity-[1];
}

.mail-recepients {
  @apply min-w-[4.4rem] max-w-[4.4rem];
}
.mail-recepients .total-mail-recepients {
  @apply max-h-[calc(100vh-12.4rem)];
}
.mail-recepients .mail-recepeint-person .avatar {
  @apply w-8 h-8 mb-4;
}
.mail-recepients .mail-recepeint-person .avatar.online:before,
.mail-recepients .mail-recepeint-person .avatar.offline:before {
  @apply w-[0.55rem] h-[0.55rem];
}
.mail-recepients .mail-recepeint-person:last-child {
  @apply mb-0;
}

.mail-navigation ul.mail-main-nav {
  @apply max-h-[calc(100vh-19rem)] p-4 mb-0;
}
.mail-navigation ul.mail-main-nav li {
  @apply p-2 rounded-md border-defaultborder font-medium;
}
.mail-navigation ul.mail-main-nav li div {
  @apply text-textmuted;
}
.mail-navigation ul.mail-main-nav li.active div {
  @apply text-primary;
}
.mail-navigation ul.mail-main-nav li:hover div {
  @apply text-primary;
}

.mails-information {
  @apply w-full;
}
.mails-information .mail-info-header {
  @apply p-3 border-b border-defaultborder dark:border-defaultborder/10;
}
.mails-information .mail-info-footer {
  @apply p-3 border-t border-defaultborder;
}
.mails-information .mail-attachment {
  @apply p-1 w-[12rem] h-[2.75rem] border border-defaultborder rounded-md flex items-center;
}
.mails-information .mail-attachment .attachment-icon svg,
.mails-information .mail-attachment .attachment-icon i {
  @apply w-6 h-6 text-[2rem] me-2;
}
.mails-information .mail-attachment .attachment-name {
  @apply max-w-[7rem] inline-block text-xs font-medium;
}

.mail-reply .ql-toolbar.ql-snow .ql-formats {
  @apply mt-[5px] mb-[5px];
}

#mail-compose-editor .ql-editor {
  @apply min-h-[12.62rem] !important;
}

.mail-compose .ql-toolbar.ql-snow .ql-formats {
  @apply my-[5px];
}

@media (max-width: 420px) {
  .mail-msg span {
    @apply max-w-[180px];
  }
}
@media (max-width: 357px) {
  .mails-information {
    @apply h-[calc(100vh-2rem)];
  }
}
/* End:: mail-app */
/* Start:: mail-settings */
.mail-notification-settings,
.mail-security-settings {
  @apply w-[60%];
}

@media (max-width: 575.98px) {
  #account-settings .btn-group label {
    @apply text-[0.625rem];
  }
}
.avatar .avatar-badge {
  @apply absolute -top-[4%] -end-[0.375rem] w-[1.4rem] h-[1.4rem] border-[2px] border-solid border-white rounded-full flex items-start justify-center !important;
}

/* End:: mail-settings */
/* start:: file-manager */
.file-manager-container {
  @apply relative block my-0 -mx-[1.25rem];
}
.file-manager-container .file-manager-navigation,
.file-manager-container .file-manager-folders,
.file-manager-container .selected-file-details {
  @apply bg-white dark:bg-bodybg h-[calc(100vh-8rem)] overflow-hidden;
}
.file-manager-container .files-main-nav {
  @apply max-h-[calc(100vh-17.5rem)];
}
.file-manager-container .file-folders-container,
.file-manager-container .filemanager-file-details {
  @apply max-h-[calc(100vh-11.9rem)];
}
@media (min-width: 576px) {
  .file-manager-container .file-manager-navigation {
    @apply w-[30rem];
  }
}
@media (max-width: 575.98px) {
  .file-manager-container .file-manager-navigation {
    @apply w-full;
  }
  .file-manager-container .file-manager-folders {
    @apply hidden;
  }
}
.file-manager-container .selected-file-details {
  @apply w-[40rem];
}
.file-manager-container .file-manager-folders {
  @apply w-full;
}
.file-manager-container ul.files-main-nav {
  @apply p-4 mb-0;
}
.file-manager-container ul.files-main-nav li {
  @apply py-2 px-3 rounded-md mb-[0.15rem];
}
.file-manager-container ul.files-main-nav li:last-child {
  @apply mb-0;
}
.file-manager-container ul.files-main-nav li div {
  @apply text-[#8c9097];
}
.file-manager-container ul.files-main-nav li:hover div {
  @apply text-primary;
}
.file-manager-container ul.files-main-nav li.active {
  @apply bg-primary/10;
}
.file-manager-container ul.files-main-nav li.active div {
  @apply text-primary;
}
.file-manager-container ul.files-main-nav li div.filemanager-upgrade-storage {
  @apply w-[235px] h-auto bg-light border-2 border-dashed border-defaultborder dark:border-defaultborder/10 rounded-md text-center text-defaulttextcolor p-4;
}
.file-manager-container
  ul.files-main-nav
  li
  div.filemanager-upgrade-storage
  img {
  @apply w-[150px] h-[150px];
}
.file-manager-container .file-format-icon svg {
  @apply w-[2.2rem] h-[2.2rem];
}
.file-manager-container .folder-svg-container svg {
  @apply w-[3rem] h-[3rem];
}
.file-manager-container .file-details img {
  @apply w-[150px] h-[150px] bg-light border-defaultborder rounded-md;
}
@media (max-width: 1200px) {
  .file-manager-container .selected-file-details {
    @apply hidden;
  }
  .file-manager-container .selected-file-details.open {
    @apply w-[19.5rem] absolute block end-0 top-2 shadow-sm border-s border-solid border-defaultborder dark:border-defaulttextcolor/10;
  }
}
.file-manager-container .file-manager-navigation.close {
  @apply hidden;
}
.file-manager-container .file-manager-folders.open {
  @apply block;
}

@media (max-width: 365px) {
  .file-manager-container .file-folders-container {
    @apply max-h-[calc(100vh-13.9rem)];
  }
}
/* end:: file-manager */
/* Start::object-fit */
.object-fit-container {
  @apply flex items-center justify-center;
}
.object-fit-container img,
.object-fit-container video {
  @apply w-[15.625rem] h-[15.625rem];
}

/* End::object-fit */
/* Start::invoice */
.invoice-amount-input {
  @apply w-[9.375rem];
}

.choices-control .choices__inner {
  @apply bg-light dark:bg-light border-0 !important;
}

.svg-icon-background {
  @apply w-[2.5rem] h-[2.5rem] p-[0.625rem] rounded-md flex items-center justify-center;
}
.svg-icon-background svg {
  @apply w-[1.25rem] h-[1.25rem];
}

.invoice-quantity-container {
  @apply w-[8.75rem];
}

/* End::invoice */
/* Start::pricing */
.pricing-basic,
.pricing-pro,
.pricing-premium {
  @apply relative before:absolute before:bg-cover before:bg-no-repeat before:bg-center;
}

.pricing-basic:before {
  @apply bg-[url("../public/assets/images/media/backgrounds/1.png")] w-[2.5rem] h-[2.5rem] top-[0.375rem] end-[0.375rem] opacity-[0.2];
}

.pricing-pro:before {
  @apply bg-[url("../public/assets/images/media/backgrounds/2.png")] w-[2.5rem] h-[2.5rem] top-[0.375rem] end-[0.375rem] opacity-[0.2];
}

.pricing-premium:before {
  @apply bg-[url("../public/assets/images/media/backgrounds/3.png")] w-[2.5rem] h-[2.5rem] top-[0.375rem] end-[0.375rem] opacity-[0.2];
}

.pricing-svg1 {
  @apply relative z-[2] before:absolute before:bg-[url("../public/assets/images/media/backgrounds/9.svg")] before:bg-cover before:bg-center before:bg-no-repeat before:w-[6.5rem] before:h-[6.5rem]
  before:-top-4 before:-start-[1.25rem] before:end-0 before:bottom-0 before:-z-[1];
}
.pricing-svg1 svg {
  @apply h-16 w-16;
}

.pricing-offer {
  @apply relative;
}
.pricing-offer .pricing-offer-details {
  @apply absolute w-[5.438rem] h-[3.75rem] bg-primary text-white text-[0.75rem] rotate-[45deg] flex items-end justify-center -end-[2.063rem] -top-[1.25rem] p-[0.313rem];
}

[dir="rtl"] .pricing-offer-details {
  @apply rotate-[315deg];
}

/* End::pricing */
/* Start:: Profile */
.main-profile-cover {
  @apply bg-[url("../public/assets/images/media/team-covers/4.jpg")] bg-cover bg-center bg-no-repeat relative z-[9]
  before:absolute before:w-full before:h-full before:bg-primary/80 before:inset-0;
}

.main-profile-info {
  @apply z-[10] relative;
}

.profile-works img {
  @apply w-[5.5rem] h-[5.5rem] rounded-[0.3rem] mb-[1.25rem];
}

.profile-timeline {
  @apply mb-0;
}
.profile-timeline li {
  @apply ps-[5.313rem] relative mb-[1.75rem] before:absolute before:bg-transparent before:border before:border-dashed before:border-black/10 before:dark:border-defaultborder/10 before:h-full before:start-[2.813rem] before:top-[1.813rem];
}
.profile-timeline li .profile-timeline-avatar {
  @apply absolute start-[2.125rem] top-[0.188rem];
}
.profile-timeline li:last-child {
  @apply mb-0 before:hidden;
}
.profile-timeline .profile-activity-media img {
  @apply w-[4rem] h-[3rem] rounded-md m-[0.35rem] max-w-[inherit];
}

@media (max-width: 575.98px) {
  .timeline .timeline-time {
    @apply z-[99] w-full end-[0.313] absolute top-[1.5rem] !important;
  }
  .timeline::before {
    @apply border-0 !important;
  }
}
.profile-recent-posts {
  @apply max-w-[10rem];
}

.profile-post-content {
  @apply w-[42rem];
  width: 42rem;
}

#profile-posts-scroll {
  @apply max-h-[35rem];
}

@media (max-width: 420px) {
  .profile-post-link {
    @apply max-w-[9.375rem];
  }
}

/* End:: Profile */
/* Start:: Reviews */
.reviews-container .box {
  @apply relative;
}
.reviews-container .box:before {
  @apply content-[""] font-[remixicon] absolute w-[2.188rem] h-[2.188rem] text-lg flex items-center rounded-full justify-center text-white bg-primary shadow-[0_0_0.5rem] shadow-primary/50 end-5 -top-5;
}

/* End:: Reviews */
/* Start::Team */
.teams-nav {
  @apply max-h-[calc(100vh-9rem)];
}

.teams-nav ul li {
  @apply py-[0.625rem] px-[1.25rem];
}

.team-member-card {
  @apply overflow-hidden;
}
.team-member-card .avatar {
  @apply absolute top-[65%] start-[38%] border-[0.25rem] border-solid border-black/[0.075];
}
@media (min-width: 480px) {
  .team-member-card .team-member-details {
    @apply ps-[4.75rem];
  }
  .team-member-card .avatar {
    @apply start-4;
  }
  .team-member-card .team-member-stats div {
    @apply border-x border-dashed border-defaultborder dark:border-defaultborder/10;
  }
  .team-member-card .team-member-stats div:last-child,
  .team-member-card .team-member-stats div:first-child {
    @apply border-0 !important;
  }
}
@media (max-width: 479.95px) {
  .team-member-card .team-member-stats div {
    @apply border-b border-dashed border-defaultborder dark:border-defaultborder/10;
  }
  .team-member-card .team-member-stats div:last-child {
    @apply border-b-0;
  }
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .team-member-card .team-member-details {
    @apply max-w-[12rem];
  }
}
.team-member-card .teammember-cover-image {
  @apply relative before:w-full before:h-full before:bg-primary/30 before:inset-0 !important;
}
.team-member-card .teammember-cover-image .card-img-top {
  @apply w-full h-[6.25rem];
}
.team-member-card .teammember-cover-image .team-member-star {
  @apply absolute top-3 p-[0.375rem] bg-black/10  end-3 rounded-md w-8 h-8 flex items-center justify-center;
}

/* End::Team */
/* Start:: Notifications */
.notification-container li .un-read {
  @apply border-s-[0.25rem] border-solid border-primary/30;
}

/* End:: Notifications */
/* Start:: Terms & Conditions */
.terms-conditions {
  @apply max-h-[44.75rem];
}

.terms-heading {
  @apply relative before:absolute before:w-[50%] before:h-[0.25rem] before:bg-gradient-to-r before:from-primary before:to-primary/10 before:-bottom-[0.65rem] before:rounded-md;
}

.card.card-fullscreen {
  @apply rounded-none;
}

/* End:: Terms & Conditions */
/* Start:: Timeline */
.timeline {
  @apply relative before:absolute before:top-[4.625rem] before:bottom-[3rem] before:w-[1px] before:border before:border-dashed before:border-primary/20 before:start-[20%] before:-ms-[1.5px];
}

.timeline > li {
  @apply relative min-h-[3.125rem] py-[0.938rem] px-0;
}

.timeline .timeline-time {
  @apply absolute start-0 w-[18%] top-[3.35rem];
}

.timeline .timeline-time .date,
.timeline .timeline-time .time {
  @apply block font-medium;
}

.timeline .timeline-time .date {
  @apply leading-none text-[0.6rem] mb-0 text-[#8c9097];
}

.timeline .timeline-time .time {
  @apply leading-[1.5rem] text-[1.125rem] text-[#8c9097];
}

.timeline .timeline-icon {
  @apply start-[15%] absolute w-[10%] text-center top-[3.188rem];
}

.timeline .timeline-icon a {
  @apply w-[0.625rem] h-[0.625rem] inline-block rounded-full bg-light text-primary leading-[0.625rem] text-[0.875rem] border-[0.188rem] border-solid border-primary/50;
}

.timeline .timeline-body {
  @apply shadow-sm ms-[22%] me-[18%] md:top-[2.5rem] bg-white dark:bg-bodybg relative py-[0.875rem] px-[1.25rem] rounded-md;
}

.timeline .timeline-body > div + div {
  @apply mt-[0.938rem];
}

.timeline-loadmore-container {
  @apply mt-[3.5rem] mb-[1.5rem];
}

.timeline li:last-child {
  @apply before:hidden;
}

@media (max-width: 575.98px) {
  .timeline .timeline-icon a {
    @apply hidden;
  }
  .timeline li:before {
    @apply hidden;
  }
  .timeline-body .media {
    @apply flex-col;
  }
  .timeline-body .media .main-img-user {
    @apply mb-[0.625rem] !important;
  }
  .timeline .timeline-time {
    @apply z-[9] w-full end-[0.313rem] absolute !important;
  }
  .timeline-main-content {
    @apply flex-col;
  }
  .timeline .timeline-body {
    @apply ms-0 me-0 relative;
  }
  .timeline-badge {
    @apply absolute start-[1.15rem] top-[0.45rem];
  }
  .timeline .timeline-time .date,
  .timeline .timeline-time .time {
    @apply inline-flex;
  }
  .timeline .timeline-time .time {
    @apply leading-none text-[0.688rem] ms-[0.313rem] me-[0.625rem] text-[#8c9097];
  }
}
/* End:: Timeline */
/* Start:: To Do Task */
.task-navigation ul.task-main-nav li {
  @apply p-2 border-defaultborder rounded-[0.3rem] font-medium;
}
.task-navigation ul.task-main-nav li:hover a {
  @apply text-primary;
}
.task-navigation ul.task-main-nav li.active div {
  @apply text-primary;
}

.task-pending-card {
  @apply border-s-[0.45rem] border-solid border-secondary/40 !important;
}

.task-inprogress-card {
  @apply border-s-[0.45rem] border-solid border-primary/40 !important;
}

.task-completed-card {
  @apply border-s-[0.45rem] border-solid border-success/40 !important;
}

.task-pending-card .box-body,
.task-inprogress-card .box-body,
.task-completed-card .box-body {
  @apply relative;
}
.task-pending-card .box-body .badge,
.task-inprogress-card .box-body .badge,
.task-completed-card .box-body .badge {
  @apply absolute bottom-[1.25rem] end-[1.25rem];
}
.task-pending-card:hover,
.task-inprogress-card:hover,
.task-completed-card:hover {
  @apply shadow-sm shadow-black/10;
}

.task-tabs-container .tab-pane {
  @apply min-h-[calc(100vh-22rem)] border-0;
}

/* End:: To Do Task */
.ql-snow {
  @apply rounded-none !important;
}

@media (max-width: 575.98px) {
  #account-settings label.ti-btn {
    @apply text-[0.625rem] py-1 px-1 gap-0;
  }
}
/* Start:: Companies Search */
.companies-search-input .choices__inner {
  @apply rounded-none min-h-full !important;
}

.companies-search-input .choices {
  @apply w-full mb-0 !important;
}
.companies-search-input .choices__list--single .choices__item {
  @apply whitespace-nowrap !important;
}

@media screen and (max-width: 622px) {
  .companies-search-input {
    @apply block;
  }
  .companies-search-input .form-control,
  .companies-search-input .search-company {
    @apply w-full rounded-md mb-2 !important;
  }
  .companies-search-input .choices {
    @apply rounded-md mb-2 !important;
  }
  .companies-search-input .ti-btn {
    @apply w-full rounded-md  !important;
  }
}
/* End:: Companies Search */
/* Start:: Ratings */
.star-rating {
  @apply touch-none;
}

/* End:: Ratings */
/* Start::task-kanboard-board */
.ynex-kanban-board {
  @apply flex overflow-x-auto items-stretch mb-3 pb-4;
}
.ynex-kanban-board .kanban-tasks-type {
  @apply min-w-[20rem] me-6 w-full;
}
.ynex-kanban-board .kanban-tasks-type:last-child {
  @apply me-0;
}
.ynex-kanban-board .kanban-tasks-type .kanban-tasks {
  @apply touch-none;
}
.ynex-kanban-board .task-image .kanban-image {
  @apply h-[150px] w-full rounded-md;
}
.ynex-kanban-board .kanban-content {
  @apply mt-3;
}
.ynex-kanban-board .kanban-task-description {
  @apply text-textmuted text-[0.82rem];
}
.ynex-kanban-board .kanban-tasks-type.new .kanban-tasks.box {
  @apply border-t-[3px] border-solid border-primary/30;
}
.ynex-kanban-board .kanban-tasks-type.todo .kanban-tasks.box {
  @apply border-t-[3px] border-solid border-warning/30;
}
.ynex-kanban-board .kanban-tasks-type.in-progress .kanban-tasks.box {
  @apply border-t-[3px] border-solid border-info/30;
}
.ynex-kanban-board .kanban-tasks-type.inreview .kanban-tasks.box {
  @apply border-t-[3px] border-solid border-danger/30;
}
.ynex-kanban-board .kanban-tasks-type.completed .kanban-tasks.box {
  @apply border-t-[3px] border-solid border-success/30;
}
.ynex-kanban-board #new-tasks .box:last-child,
.ynex-kanban-board #todo-tasks .box:last-child,
.ynex-kanban-board #inprogress-tasks .box:last-child,
.ynex-kanban-board #inreview-tasks .box:last-child,
.ynex-kanban-board #completed-tasks .box:last-child {
  @apply mb-0;
}
.ynex-kanban-board #new-tasks,
.ynex-kanban-board #todo-tasks,
.ynex-kanban-board #inprogress-tasks,
.ynex-kanban-board #inreview-tasks,
.ynex-kanban-board #completed-tasks {
  @apply relative max-h-[35rem];
}
.ynex-kanban-board #new-tasks .simplebar-content,
.ynex-kanban-board #todo-tasks .simplebar-content,
.ynex-kanban-board #inprogress-tasks .simplebar-content,
.ynex-kanban-board #inreview-tasks .simplebar-content,
.ynex-kanban-board #completed-tasks .simplebar-content {
  @apply pt-0 pb-4 px-0;
}
.ynex-kanban-board .task-Null {
  @apply relative min-h-[12.5rem] before:absolute before:inset-0 before:bg-white dark:before:bg-bodybg before:rounded-md before:bg-[url("../public/assets/images/media/media-83.svg")] before:bg-cover before:bg-center before:h-[12.5rem]
        before:w-full before:my-0 before:mx-auto;
}

/* end::task-kanboard-board */
/* Start::task-details */
.task-title {
  @apply relative start-4 before:absolute before:w-2 before:h-2 before:-start-4 before:top-2 before:rounded-full before:bg-secondary;
}

.task-details-key-tasks {
  @apply list-decimal;
}
.task-details-key-tasks li {
  @apply mb-2 text-[0.875rem] text-[#8c9097];
}
.task-details-key-tasks li:last-child {
  @apply mb-0;
}

.task-description {
  @apply text-[0.875rem];
}

.task-details-progress {
  @apply w-[6.25rem];
}

/* End::task-details */
/* PAGES STYLES */
/* PLUGINS STYLES */
/* Start Calendar  Styles */
#external-events .fc-event {
  @apply rounded-sm my-1 py-2 px-3;
}

.fc-theme-standard td,
.fc-theme-standard th {
  @apply border-gray-200 dark:border-white/10 !important;
}

.fc .fc-button:focus {
  @apply shadow-md !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  @apply shadow-md !important;
}

.fc-h-event {
  @apply text-white bg-primary border-primary;
}

.fc .fc-button-primary {
  @apply text-white bg-primary border-primary !important;
}

.fc .fc-button-primary:hover {
  @apply text-white bg-primary/80 border-primary/80 !important;
}

.fc .fc-button-primary:disabled {
  @apply text-white bg-primary/80 border-primary/80 !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  @apply text-white bg-primary/80 border-primary/80 !important;
}

.fc-theme-standard .fc-scrollgrid {
  @apply border-gray-200 dark:border-white/10 !important;
}

.fc .fc-scrollgrid-section-sticky > * {
  @apply bg-gray-200 dark:bg-black/20 !important;
}

.fc-theme-standard .fc-list {
  @apply border-gray-200 dark:border-white/10 !important;
}

.fc .fc-non-business {
  @apply bg-gray-50 dark:bg-black/20 !important;
}

.fc .fc-scrollgrid {
  @apply rounded-sm overflow-hidden;
}

.fc .fc-bg-event,
.fc .fc-highlight,
.fc .fc-non-business,
.fc .fc-view-harness-active > .fc-view {
  @apply rounded-sm overflow-hidden;
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  @apply bg-gray-50 dark:bg-black/20 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  @apply bg-primary/20 !important;
}

.fc .fc-timegrid-col.fc-day-today {
  @apply bg-primary/20 !important;
}

.fc-theme-standard .fc-list-day-cushion {
  @apply bg-gray-50 dark:bg-black/20 !important;
}

.fc .fc-list-event:hover td {
  @apply bg-primary/20 !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  @apply shadow-md shadow-gray-200 dark:shadow-gray-800 !important;
}

.fullcalendar-events-activity li {
  @apply mb-3 text-sm py-1 ps-8 relative before:absolute before:w-3 before:h-3 before:border before:border-primary before:rounded-sm before:bg-transparent before:start-1 before:top-[0.563rem]
        after:absolute after:h-full after:bg-transparent after:border-s-2 after:border-dashed after:border-primary/10 after:start-[0.563rem] after:top-5;
}

.fullcalendar-events-activity li:last-child {
  @apply mb-0;
}

.fullcalendar-events-activity li:last-child::after {
  @apply border-0;
}

#full-calendar-activity {
  @apply max-h-44;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
  @apply p-2 flex justify-center;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  @apply px-2;
}

.fc .fc-day-other .fc-daygrid-day-top {
  @apply opacity-100 text-gray-500 dark:text-white/70 !important;
}

.fc-theme-standard .fc-popover {
  @apply border-gray-200 dark:border-white/10 bg-white dark:bg-bodybg;
}

.fc-header-toolbar {
  @apply md:flex block md:space-y-0 space-y-2 !important;
}

.fc .fc-list-sticky .fc-list-day > * {
  @apply bg-gray-100 dark:bg-black/20;
}

.fc-event-dragging {
  @apply px-2 py-2;
}

.fc .fc-daygrid-day-bottom {
  @apply text-[0.44em] pt-0 !important;
}

/* End Calendar  Styles */
/* Start Datatable  Styles */
.tabulator .tabulator-header .tabulator-col {
  @apply bg-white border-gray-200 dark:bg-bodybg dark:border-white/10 !important;
}

.tabulator
  .tabulator-header
  .tabulator-headers
  .tabulator-col
  .tabulator-col-content {
  @apply px-6 py-3 !important;
}

.tabulator .tabulator-row .tabulator-cell {
  @apply px-6 py-3 !important;
}

.tabulator .tabulator-row.tabulator-row-even {
  @apply bg-gray-100 dark:bg-black/20 !important;
}

.tabulator-row.tabulator-selectable:hover {
  @apply bg-gray-100 dark:bg-black/20 !important;
}

.tabulator .tabulator-row {
  @apply border-t border-gray-200 dark:border-white/10 !important;
}

.tabulator .tabulator-header {
  @apply border-0  bg-white dark:text-white dark:bg-bodybg !important;
}

.tabulator {
  @apply border-0 bg-transparent !important;
}

.tabulator-row .tabulator-cell {
  @apply border-gray-200 dark:border-white/10 !important;
}

.tabulator .tabulator-row .tabulator-cell:nth-child(9) {
  @apply border-e-0 !important;
}

.tabulator .tabulator-header .tabulator-col:nth-child(9) {
  @apply border-e-0 !important;
}

.tabulator .tabulator-footer {
  @apply border-gray-200 dark:text-defaulttextcolor/70 dark:border-white/10 !important;
}

.tabulator .tabulator-footer {
  @apply bg-transparent font-medium !important;
}

.tabulator .tabulator-tableholder .tabulator-table {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70  bg-transparent !important;
}

.tabulator-row {
  @apply bg-transparent !important;
}

.tabulator .tabulator-footer .tabulator-paginator {
  @apply dark:text-white !important;
}

select.tabulator-page-size {
  @apply bg-[length:1rem_1rem] !important;
}

.tabulator .tabulator-footer .tabulator-page-size {
  @apply py-1 ps-3 pe-8 leading-[1.6] text-xs border-gray-200 dark:border-white/10 bg-transparent rounded-md !important;
}

.tabulator .tabulator-footer .tabulator-page {
  @apply py-1 px-3 rounded-md border-gray-200 dark:border-white/10 text-defaulttextcolor  dark:text-defaulttextcolor/70 bg-transparent !important;
}

.tabulator .tabulator-footer .tabulator-page.active {
  @apply text-primary !important;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  @apply text-primary bg-primary/30 !important;
}

select.tabulator-page-size {
  @apply filter !important;
}
select.tabulator-page-size option {
  @apply dark:bg-bodybg border-gray-200 dark:border-white/10 !important;
}

.tabulator .tabulator-row .tabulator-cell.tabulator-row-handle {
  @apply px-0 !important;
}

.sortable-data select {
  @apply border-inherit rounded-md bg-[length:1rem_1rem] !important;
}
.sortable-data .choices {
  @apply mb-0 !important;
}

.tabulator .tabulator-col-resize-handle:last-child {
  @apply hidden  !important;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  @apply border-0 border-gray-200 dark:border-white/10 !important;
}

.tabulator .tabulator-footer .tabulator-footer-contents {
  @apply sm:flex-row flex-col space-y-2 sm:space-y-0 p-4 !important;
}

.tabulator .tabulator-footer .tabulator-page-size {
  @apply sm:ps-3 ps-1 sm:pe-8 pe-4 !important;
}

.tabulator .tabulator-footer .tabulator-page {
  @apply sm:px-3 px-1 !important;
}

.tabulator .tabulator-footer .tabulator-paginator label {
  @apply hidden !important;
}

@media screen and (max-width: 1024px) {
  .tabulator-col,
  .tabulator-cell {
    @apply w-60 !important;
  }
}
.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="ascending"]
  .tabulator-col-content
  .tabulator-col-sorter {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="ascending"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  @apply border-b-gray-200 dark:border-b-white/10;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="descending"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  @apply border-t-gray-200 dark:border-t-white/10;
}

.tabulator .tabulator-footer .tabulator-page-size {
  @apply block mb-2 w-full sm:inline-block sm:mb-0 sm:w-auto !important;
}

.sortable-data .choices__list--dropdown .choices__item--selectable,
.sortable-data .choices__list[aria-expanded] .choices__item--selectable {
  @apply px-3 !important;
}

/* End Datatable  Styles */
.choices__inner {
  @apply py-2 px-[0.85rem] bg-white dark:bg-bodybg rounded-md text-defaultsize border border-solid border-inputborder dark:border-defaultborder/10 min-w-[13px] leading-[1.65] focus:dark:border-defaultborder/10 !important;
}

.input-group .choices__inner {
  @apply rounded-s-none !important;
}

.choices__input {
  @apply p-0 !important;
}

.choices__list--single {
  @apply pb-0 ps-0 pe-4 !important;
}

.choices[data-type*="select-one"] .choices__input {
  @apply p-[0.625rem] !important;
}

.choices[data-type*="select-one"]::after {
  @apply end-[0.7188rem] border-t-textmuted border-b-transparent border-x-transparent dark:border-b-transparent dark:border-x-transparent dark:border-t-textmuted !important;
}

.choices[data-type*="select-one"]::after {
  @apply text-textmuted !important;
}

.choices[data-type*="select-one"] .choices__input {
  @apply p-[0.625rem] !important;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 z-[1] !important;
}

.choices[data-type*="select-one"] .choices__input {
  @apply border-b border-solid border-defaultborder bg-white dark:bg-bodybg dark:border-defaultborder/10 text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  @apply bg-primary text-white dark:bg-primary dark:text-white !important;
}

.choices__list--dropdown .choices__list {
  @apply max-h-[250px] !important;
}

.choices[data-type*="select-one"]::after {
  @apply border-textmuted;
}

.choices[data-type*="select-one"]:after {
  @apply h-0 w-0 border-solid border-[#333] absolute end-[11.5px] top-[50%] -mt-[2.5px] pointer-events-none;
}

.choices__input {
  @apply bg-transparent text-black dark:text-white !important;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  @apply border-defaultborder dark:border-defaultborder/10;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  @apply border-s border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

.choices__list--multiple .choices__item {
  @apply bg-primary border border-solid border-primary mb-1 !important;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  @apply border-s border-white dark:border-defaultborder/10 !important;
}

.choices__list--multiple .choices__item {
  @apply mb-1 !important;
}

.choices__list--single .choices__item {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.choices__input {
  @apply mb-0 !important;
}

.choices__list--multiple .choices__item {
  @apply rounded-sm py-[0.025rem] px-[0.625rem] !important;
}

.form-control-select-sm .choices__inner {
  @apply p-[0.275rem] !important;
}

.choices[data-type*="select-one"].is-open::after {
  @apply -mt-[0.156rem] !important;
}

.choices__heading {
  @apply border-b border-solid border-defaultborder dark:border-defaultborder/10 text-textmuted !important;
}

.choices[data-type*="select-one"]::after {
  @apply end-[0.788rem] start-[inherit] !important;
}

.choices[data-type*="select-one"] .choices__button {
  @apply end-0 start-[inherit] me-[1.5625rem] ms-[inherit] !important;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  @apply mt-0 ms-[8px] mb-0 -me-[4px] ps-4 rtl:ps-0 border-x-0 !important;
}

select {
  @apply rtl:bg-left;
  @apply rtl:bg-[0.5rem];
}

.ts-wrapper.single .ts-control:after {
  @apply end-[15px] start-auto !important;
}

.choices[data-type*="select-one"] .choices__button {
  @apply dark:invert-[1];
}

.ts-control {
  @apply bg-white dark:bg-bodybg dark:border-defaultborder/10 border-defaultborder shadow-none px-3 !important;
}
.ts-control input {
  @apply dark:text-white !important;
}

.ts-dropdown .active.create {
  @apply dark:text-white !important;
}

.multi .ts-control {
  @apply px-3 !important;
}

.ts-dropdown .active {
  @apply dark:bg-light dark:text-defaulttextcolor/70 text-defaulttextcolor;
}

.ts-dropdown.single {
  @apply dark:border-defaultborder/10 border-defaultborder !important;
}

.ts-dropdown {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 dark:text-defaulttextcolor/70 text-defaulttextcolor;
}

.ts-dropdown [data-selectable].option {
  @apply dark:hover:bg-light hover:bg-light;
}

.ts-wrapper.single .ts-control {
  @apply bg-none shadow-none dark:text-defaulttextcolor/70 text-defaulttextcolor px-3 !important;
}

.ts-wrapper.multi .ts-control [data-value] {
  @apply bg-none border-primary bg-primary !important;
}

[class="dark"] .choices[data-type*="select-one"] .choices__button {
  filter: invert(1);
}

/* Start:Apex Charts */
.apexcharts-canvas .apexcharts-element-hidden,
.apexcharts-datalabel.apexcharts-element-hidden,
.apexcharts-hide .apexcharts-series-points {
  @apply hidden;
}

#marketCap .apexcharts-canvas line {
  @apply stroke-defaultborder dark:stroke-defaultborder/10;
}

.apexcharts-tooltip {
  @apply shadow-none !important;
}

.apexcharts-tooltip-marker {
  @apply me-[0.625rem];
}

.apexcharts-tooltip.apexcharts-theme-light {
  @apply border border-defaultborder border-solid dark:border-defaultborder/10 bg-white dark:bg-bodybg !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

.apexcharts-grid line,
.apexcharts-xaxis line,
.apexcharts-grid-borders line {
  @apply stroke-defaultborder dark:stroke-defaultborder/10;
}

button.gridjs-sort {
  @apply relative end-[2rem] ltr:float-right rtl:float-left h-[1.25rem] w-[0.625rem] !important;
}

.tabulator-col-title {
  @apply dark:text-defaulttextcolor/70 !important;
}

.apexcharts-radialbar-track.apexcharts-track path {
  @apply stroke-light;
}

.apexcharts-menu {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  @apply bg-light !important;
}

.apexcharts-inner.apexcharts-graphical line.apexcharts-xaxis-tick {
  @apply stroke-transparent !important;
}

#column-rotated-labels .apexcharts-xaxis-texts-g {
  @apply translate-y-[2.5rem];
}

#chart-year,
#chart-quarter {
  @apply bg-white dark:bg-bodybg border border-defaultborder border-solid dark:border-defaultborder/10 !important;
}

.apexcharts-bar-series.apexcharts-plot-series
  .apexcharts-series
  .apexcharts-bar-area {
  @apply stroke-defaultborder dark:stroke-defaultborder/10 !important;
}

.apexcharts-treemap .apexcharts-series.apexcharts-treemap-series rect {
  @apply stroke-white dark:stroke-bodybg !important;
}

.apexcharts-series.apexcharts-pie-series .apexcharts-pie-area {
  @apply stroke-white dark:stroke-bodybg !important;
}

.apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  @apply fill-defaulttextcolor dark:fill-defaulttextcolor/70;
}

#analytics-followers
  .apexcharts-datalabels-group
  .apexcharts-text.apexcharts-datalabel-value {
  @apply fill-[#4b9bfa] dark:fill-[#4b9bfa];
}

#analytics-views
  .apexcharts-datalabels-group
  .apexcharts-text.apexcharts-datalabel-value {
  @apply fill-warning dark:fill-warning;
}

.apexcharts-radialbar-hollow {
  @apply fill-white dark:fill-bodybg;
}

#sale-value .apexcharts-radialbar-hollow {
  @apply fill-white dark:fill-bodybg;
}

.apexcharts-radar-series.apexcharts-plot-series polygon,
.apexcharts-radar-series.apexcharts-plot-series line {
  @apply stroke-defaultborder dark:stroke-defaultborder/10 !important;
}

.apexcharts-pie line,
.apexcharts-pie circle {
  @apply stroke-defaultborder dark:stroke-defaultborder/10 !important;
}
.apexcharts-pie text {
  @apply fill-defaulttextcolor dark:fill-defaulttextcolor/70 !important;
}

#crm-total-customers .apexcharts-grid line,
#crm-total-customers .apexcharts-xaxis line,
#crm-total-customers .apexcharts-grid-borders line,
#crm-total-revenue .apexcharts-grid line,
#crm-total-revenue .apexcharts-xaxis line,
#crm-total-revenue .apexcharts-grid-borders line,
#crm-conversion-ratio .apexcharts-grid line,
#crm-conversion-ratio .apexcharts-xaxis line,
#crm-conversion-ratio .apexcharts-grid-borders line,
#crm-total-deals .apexcharts-grid line,
#crm-total-deals .apexcharts-xaxis line,
#crm-total-deals .apexcharts-grid-borders line,
#nft-balance-chart .apexcharts-grid line,
#nft-balance-chart .apexcharts-xaxis line,
#nft-balance-chart .apexcharts-grid-borders line,
#total-invested .apexcharts-grid line,
#total-invested .apexcharts-xaxis line,
#total-invested .apexcharts-grid-borders line,
#total-investments .apexcharts-grid line,
#total-investments .apexcharts-xaxis line,
#total-investments .apexcharts-grid-borders line,
#portfolio-value .apexcharts-grid line,
#portfolio-value .apexcharts-xaxis line,
#portfolio-value .apexcharts-grid-borders line,
#returns-rate .apexcharts-grid line,
#returns-rate .apexcharts-xaxis line,
#returns-rate .apexcharts-grid-borders line,
#btcCoin .apexcharts-grid line,
#btcCoin .apexcharts-xaxis line,
#btcCoin .apexcharts-grid-borders line,
#ethCoin .apexcharts-grid line,
#ethCoin .apexcharts-xaxis line,
#ethCoin .apexcharts-grid-borders line,
#dshCoin .apexcharts-grid line,
#dshCoin .apexcharts-xaxis line,
#dshCoin .apexcharts-grid-borders line,
#glmCoin .apexcharts-grid line,
#glmCoin .apexcharts-xaxis line,
#glmCoin .apexcharts-grid-borders line {
  @apply stroke-white dark:stroke-bodybg !important;
}

.apexcharts-canvas .apexcharts-toolbar {
  @apply z-[1];
}

.apexcharts-subtitle-text {
  @apply fill-textmuted !important;
}

.apexcharts-pie .apexcharts-datalabels rect {
  @apply fill-transparent;
}
.apexcharts-pie text {
  @apply fill-white dark:fill-defaulttextcolor/70;
}

[dir="rtl"] .apexcharts-canvas {
  direction: ltr;
}
[dir="rtl"] .apexcharts-canvas .apexcharts-yaxis .apexcharts-yaxis-texts-g {
  @apply -translate-x-[1.75] translate-y-0;
}

.apexcharts-boxPlot-area {
  @apply stroke-defaulttextcolor dark:stroke-defaulttextcolor/70 !important;
}

/* End:Apex Charts */
/* Start:Full Calendar */
.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
  @apply border-0 border-t border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  @apply py-0 px-1 !important;
}

.fc .fc-button-primary {
  @apply bg-primary border-primary !important;
}

.fc .fc-non-business {
  @apply bg-white dark:bg-bodybg !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  @apply shadow-none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 border-t-0 !important;
}

.fc-list-table td,
.fc-list-table th {
  @apply border-l-0 border-r-0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  @apply bg-primary/10 !important;
}

.fc-theme-standard .fc-list {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

.fc .fc-list-event:hover td {
  @apply bg-light !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  @apply shadow-none !important;
}

.fc-theme-standard .fc-list-day-cushion {
  @apply bg-light !important;
}

.fc-theme-standard .fc-scrollgrid {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

@media (max-width: 420px) {
  .fc-scroller.fc-scroller-liquid {
    @apply overflow-scroll !important;
  }
}
@media (max-width: 380px) {
  .fc .fc-daygrid-day-bottom {
    @apply text-[0.75rem] pt-0 pe-[3px] pb-[3px] ps-0 !important;
  }
  .fc .fc-daygrid-more-link {
    @apply z-[99] !important;
  }
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    @apply block !important;
  }
  .fc-toolbar-chunk {
    @apply mt-2;
  }
}
/* End:Full Calendar */
/* Start:Pickers */
.flatpickr-calendar {
  @apply bg-white dark:bg-bodybg shadow-md border border-solid border-defaultborder dark:border-defaultborder/10 text-defaultsize !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply text-black/90 fill-black/90 !important;
}

.flatpickr-monthDropdown-months,
.numInput {
  @apply text-black !important;
}

.flatpickr-day.today.inRange {
  @apply text-primary !important;
}

.dayContainer {
  @apply p-1 !important;
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  @apply bg-white dark:bg-bodybg text-defaultsize !important;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  @apply w-[1.25rem] h-[1.25rem] p-1 fill-primary !important;
}

.flatpickr-day.inRange {
  @apply shadow-none !important;
}

.flatpickr-calendar.open {
  @apply z-[106] !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply bg-primary/10 rounded-sm !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after,
.flatpickr-calendar.arrowBottom:before {
  @apply border-t-textmuted !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  @apply border-b-textmuted !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  @apply shadow-none !important;
}

.flatpickr-day {
  @apply text-defaulttextcolor font-medium !important;
}
.flatpickr-day.nextMonthDay,
.flatpickr-day.prevMonthDay {
  @apply opacity-[0.5] !important;
}

.flatpickr-day.today {
  @apply bg-primary border-primary text-white dark:text-defaulttextcolor/70 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply bg-primary border-primary text-white dark:text-defaulttextcolor/70 !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  @apply bg-defaultbackground dark:bg-light border-defaultborder dark:border-defaultborder/10 !important;
}

.flatpickr-day.today:hover {
  @apply bg-primary border-primary text-white dark:text-defaulttextcolor/70 !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  @apply border-t border-solid border-t-defaultborder dark:border-t-defaultborder/10 !important;
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  @apply border-b border-solid border-b-defaultborder dark:border-b-defaultborder/10 !important;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  @apply border-primary/50 !important;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  @apply bg-defaultbackground dark:bg-light !important;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  @apply text-textmuted !important;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  @apply start-0 !important;
}

.flatpickr-weekdays {
  @apply bg-primary/10 !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply top-0 pt-[0.313rem] px-[0.313rem] pb-0 text-primary fill-primary !important;
}

@media (min-width: 420px) {
  .flatpickr-time .flatpickr-am-pm {
    @apply pt-0 pe-[1.875rem] pb-0 ps-2;
  }
}
.flatpickr-weekdays {
  @apply border-b border-solid border-b-defaultborder dark:border-b-defaultborder/10 !important;
}

.numInputWrapper span.arrowUp {
  @apply -top-[0.125rem] !important;
}

.flatpickr-current-month .numInputWrapper {
  @apply w-[3.5rem] !important;
}

.flatpickr-calendar.hasTime {
  @apply w-auto;
}

.flatpickr-months .numInputWrapper span {
  @apply end-0 !important;
}

@media (max-width: 575.98) {
  .flatpickr-calendar {
    @apply w-[250px] !important;
  }
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply -mt-4 mr-[2.25rem] mb-0 ml-0 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  @apply pb-0 ps-0 pe-2 text-[0.875rem] font-medium !important;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  @apply stroke-primary;
}

.flatpickr-day {
  @apply rounded-md !important;
}

.numInputWrapper:hover {
  @apply bg-transparent !important;
}

.numInputWrapper span {
  @apply border-0 !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  @apply border-primary !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply fill-white dark:fill-black !important;
}

.numInputWrapper span:hover {
  @apply bg-transparent !important;
}

.numInputWrapper span.arrowUp:after {
  @apply border-s-[0.25rem] border-e-[0.25rem] border-b-[0.25rem] border-solid border-b-primary/50 top-[75%] !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  @apply border-t-primary !important;
}

.numInputWrapper span.arrowDown:after {
  @apply border-s-[0.25rem] border-e-[0.25rem] border-b-[0.25rem] border-solid border-b-primary/50 top-[15%] !important;
}

span.flatpickr-weekday {
  @apply text-primary/80 font-[700] !important;
}

.flatpickr-months .flatpickr-month {
  @apply text-primary fill-primary !important;
}

.flatpickr-monthDropdown-months,
.numInput {
  @apply text-primary !important;
}

.pcr-app {
  @apply bg-white dark:bg-bodybg !important;
}

.pcr-app .pcr-interaction .pcr-result {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 bg-defaultbackground dark:bg-bodybg2 !important;
}

.theme-container button,
.theme-container1 button,
.theme-container2 button {
  @apply hidden;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview {
  @apply me-3 !important;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-opacity {
  @apply ms-3 !important;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  @apply shadow-sm !important;
}

/* End:Pickers */
/* Start::Gallery */
.glightbox {
  @apply overflow-hidden;
}

@media (min-width: 769px) {
  .gslide-image img {
    @apply rounded-md;
  }
  .glightbox-clean .gclose,
  .glightbox-clean .gnext,
  .glightbox-clean .gprev {
    @apply bg-[#ffffff0d] w-[2.5rem] h-[2.5rem] p-[0.875rem] !important;
  }
}
/* End::Gallery */
/* Start::Calendar */
#external-events .fc-event {
  @apply cursor-move pb-[0.4rem] py-[0.375rem] px-3 text-[0.75rem] rounded-[0.35rem];
}

#calendar-container {
  @apply relative z-[1];
}

#calendar {
  @apply max-w-[68.75rem] my-[1.25rem] mx-auto;
}

/* End::Calendar */
/* Start::Leaflet Maps */
#map,
#map1,
#map-popup,
#map-custom-icon,
#interactive-map {
  @apply h-[18.75rem] z-[10] relative;
}

/* End::Leaflet Maps */
/* Start::Vector Maps */
#vector-map,
#marker-map,
#marker-image-map,
#lines-map,
#us-map,
#canada-map,
#spain-map,
#russia-map {
  @apply h-[21.875rem];
}

.jvm-tooltip {
  @apply bg-[#1a1c1e] !important;
}

#vector-map #jvm-regions-group path,
#marker-map #jvm-regions-group path,
#marker-image-map #jvm-regions-group path,
#lines-map #jvm-regions-group path,
#visitors-countries #jvm-regions-group path,
#users-map #jvm-regions-group path {
  @apply fill-light !important;
}

.jvm-zoom-btn {
  @apply flex items-center justify-center;
}

#jvm-markers-labels-group text {
  @apply fill-textmuted;
}

/* End::Vector Maps */
/* Start::Google Maps */
#google-map,
#google-map-overlay,
#map-layers,
#map-markers,
#streetview-map,
#map-geofencing {
  @apply h-[18.75rem];
}

.google-map-overlay {
  @apply block text-center text-white dark:text-defaulttextcolor/70 text-[1.25rem] opacity-[0.8] leading-[0.875rem] bg-primary border-solid border-[0.188rem] border-primary rounded-sm shadow-sm py-0 px-1;
}

.google-overlay_arrow {
  @apply start-[50%] ms-[-1rem] w-0 h-0 absolute;
}

.google-overlay_arrow.above {
  @apply -bottom-[0.938rem] border-s-[0.938rem] border-transparent border-e-[1rem] border-solid border-e-transparent border-t-[1rem] border-t-[#336699];
}

.google-overlay_arrow.below {
  @apply -bottom-[0.938rem] border-s-[1rem] border-transparent border-e-[1rem] border-solid border-e-transparent border-t-[1rem] border-t-[#336699];
}

/* End::Google Maps */
/* Start::Apex Charts */
#pie-basic .apexcharts-canvas,
#donut-update .apexcharts-canvas,
#pie-monochrome .apexcharts-canvas,
#donut-gradient .apexcharts-canvas,
#donut-pattern .apexcharts-canvas,
#pie-image .apexcharts-canvas,
#polararea-basic .apexcharts-canvas,
#polararea-monochrome .apexcharts-canvas {
  @apply my-0 mx-auto;
}

.apexcharts-legend-text {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 ps-[0.9375rem] -ms-[0.625rem] !important;
}

.apexcharts-text.apexcharts-yaxis-label tspan,
.apexcharts-text.apexcharts-xaxis-label tspan {
  @apply fill-textmuted;
}

.apexcharts-canvas .apexcharts-series.apexcharts-heatmap-series rect {
  @apply stroke-white dark:stroke-bodybg;
}

.apexcharts-canvas .apexcharts-series-markers.apexcharts-series-bubble circle {
  @apply stroke-white dark:stroke-bodybg;
}

.apexcharts-yaxis .apexcharts-text {
  @apply fill-textmuted;
}

/* End::Apex Charts */
/* Start::Chartjs Charts */
.chartjs-chart {
  @apply max-h-[18.75rem];
}

/* Start::Chartjs Charts */
/* Start::Apex Column Charts */
#chart-year,
#chart-quarter {
  @apply w-[96%] max-w-[48%] shadow-none ps-0 pt-[1.25rem] bg-white dark:bg-bodybg border border-solid border-[#ddd];
}

#chart-year {
  @apply float-left relative transition-all ease-linear duration-[1s] z-[3];
}

#chart-year.chart-quarter-activated {
  @apply translate-x-0 transition-all ease-linear duration-[1s];
}

#chart-quarter {
  @apply float-left relative -z-[2] transition-all ease-linear duration-[1s];
}

#chart-quarter.active {
  @apply transition-all ease-linear duration-[1.1s] translate-x-0 z-[1];
}

@media screen and (min-width: 480px) {
  #chart-year {
    @apply translate-x-[50%];
  }
  #chart-quarter {
    @apply -translate-x-[50%];
  }
}
/* End::Apex Column Charts */
/* Start::ECharts */
.echart-charts {
  @apply h-[20rem];
}

#echart-funnel div:nth-child(3) {
  @apply bg-light !important;
}
#echart-funnel div:nth-child(3) textarea {
  @apply bg-white dark:bg-bodybg2 border-defaultborder text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}
#echart-funnel div:nth-child(3) h4 {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

#echart-stacked-line
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  > span:nth-child(1) {
  @apply me-1 ms-0 !important;
}

/* End::ECharts */
/* Start::dropzone */
.dropzone {
  @apply border-[0.125rem] border-dashed border-defaultborder dark:border-defaultborder/10 bg-transparent !important;
}
.dropzone .dz-message .dz-button {
  @apply text-[1.25rem] text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.dropzone .dz-preview {
  @apply rounded-[1.25rem];
}

/* End::dropzone */
/* Start Filepond Styles */
.filepond--credits {
  @apply hidden;
}

.filepond--drop-label.filepond--drop-label label {
  @apply dark:text-defaulttextcolor/70;
}

.filepond--panel-root {
  @apply bg-transparent border-dashed border-gray-200 dark:border-defaulttextcolor/10 rounded-sm !important;
}

.filepond.circular-filepond .filepond--panel-root {
  @apply rounded-full !important;
}

.circular-filepond.filepond--root[data-style-panel-layout~="circle"]
  .filepond--file
  [data-align*="left"] {
  @apply start-[40%];
}

.circular-filepond.filepond--root[data-style-panel-layout~="circle"] {
  @apply w-32 h-32 my-0 mx-auto !important;
}

.filepond--root {
  @apply mb-0 !important;
}

/* End Filepond Styles */
/* Start::filepond */
.filepond--drop-label {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 rounded-[0.3rem] !important;
}

.filepond--credits {
  @apply hidden;
}

.filepond--panel-root {
  @apply bg-white dark:bg-bodybg border-[0.125rem] border-dashed border-defaultborder dark:border-defaultborder/10 !important;
}

.filepond--drop-label.filepond--drop-label label {
  @apply p-[1.5rem] text-defaultsize !important;
}

.filepond--root {
  @apply mb-0 !important;
}

.filepond--file {
  @apply bg-primary !important;
}

.single-fileupload {
  @apply w-[8rem] h-[8rem] my-0 mx-auto !important;
}

/* End::filepond */
/* Start:: quill editor */
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  @apply border border-solid border-defaultborder dark:border-defaulttextcolor/10 !important;
}

.ql-snow .ql-picker {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  @apply stroke-defaulttextcolor dark:stroke-defaulttextcolor/70 !important;
}

.ql-snow .ql-fill {
  @apply fill-defaulttextcolor dark:fill-defaulttextcolor/70 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  @apply border-t-0 !important;
}

.ql-snow .ql-picker-options .ql-picker-item {
  @apply pt-0 pb-0 !important;
}

#product-features {
  @apply max-h-[12.5rem] overflow-y-scroll border-b border-defaultborder dark:border-defaultborder/10;
}
#product-features .ql-container {
  @apply border-b-0 !important;
}

.ql-editor {
  @apply min-h-[15.62rem] overflow-visible !important;
}

.ql-snow .ql-formats {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  @apply border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}

.ql-snow .ql-picker-options {
  @apply bg-white dark:bg-bodybg !important;
}

.ql-snow .ql-tooltip {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaulttextcolor/10 border border-solid shadow-md text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.ql-snow .ql-tooltip input[type="text"] {
  @apply border-defaultborder dark:border-defaulttextcolor/10 border border-solid shadow-md text-defaulttextcolor dark:text-defaulttextcolor/70 outline-0 !important;
}

.ql-snow .ql-tooltip {
  @apply translate-x-[12.5rem] z-[100] !important;
}

.ql-snow .ql-picker-label {
  @apply ps-2 pe-[0.125rem] !important;
}

.ql-snow .ql-formats .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  @apply end-0 start-auto !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.ql-editor {
  @apply text-start !important;
}

.ql-snow .ql-editor {
  @apply p-[1.25rem];
}

.ql-bubble {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  @apply -ms-[1.5em] me-[0.3em] text-start !important;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  @apply ps-[1.5rem] !important;
}

.ql-toolbar.ql-snow .ql-formats {
  @apply m-1;
}

.ql-tooltip.ql-editing {
  @apply top-0 !important;
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
  @apply ms-[12px] me-0;
}

.ql-bubble .ql-toolbar .ql-formats {
  @apply pt-[8px] ms-0 pb-[8px] me-[12px];
}

/* end:: quill editor */
/* Start:: select2 */
.select2.select2-container {
  @apply w-full !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 leading-[2.25] border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}

.select2-container--default .select2-selection--single {
  @apply bg-white dark:bg-bodybg border border-defaultborder dark:border-defaulttextcolor/10 rounded-md !important;
}

.select2-container .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  @apply h-[2.25rem] end-3 start-auto !important;
}

.select2-dropdown {
  @apply bg-white dark:bg-bodybg border border-defaultborder dark:border-defaulttextcolor/10 rounded-md !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  @apply border border-defaultborder dark:border-defaulttextcolor/10 rounded-md !important;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.select2-results__option--selectable {
  @apply text-defaultsize;
}

.select2-container--default .select2-results__option--selected {
  @apply bg-primary text-white !important;
}
.select2-container--default
  .select2-results__option--selected.select2-results__option--highlighted {
  @apply bg-primary text-white !important;
}

.select2-search__field {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}
.select2-search__field:focus-visible {
  @apply outline-none !important;
}

.select2-container--default .select2-selection--multiple {
  @apply border dark:bg-bodybg border-defaultborder dark:border-defaulttextcolor/10 rounded-md !important;
}

.select2-container .select2-selection--multiple {
  @apply min-h-[2.25rem] !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  @apply bg-primary text-white border border-solid border-primary rounded-[0.15rem] mt-[0.375rem] !important;
}

.select2-selection--multiple .select2-search__field {
  @apply bg-transparent !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  @apply border-e border-white/10 text-white -top-[0.3rem] font-medium text-[1.125rem] !important;
}

.select2-selection--multiple .select2-selection__choice__display {
  @apply text-[0.75rem] px-3 !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:focus {
  @apply bg-primary !important;
}

.select2-results__option span img,
.select2-selection__rendered span img {
  @apply w-[1.45rem] h-[1.45rem] rounded-[1.25rem] me-1;
}

.select2-container .select2-search--inline .select2-search__field {
  @apply mt-2 !important;
}

.select2-container--disabled.select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.select2-container--disabled .select2-selection.select2-selection--multiple {
  @apply bg-white dark:bg-bodybg !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  @apply font-normal h-[1.25rem] text-[1.5625rem] w-[1.25rem] absolute end-[0.625rem] !important;
}

.select2-selection__clear {
  @apply text-textmuted;
}

.select2-dropdown {
  @apply z-[10] !important;
}

.select2-container--default
  .select2-results__option--selected.select2-results__option--highlighted {
  @apply bg-primary text-white !important;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  @apply bg-[#f7f8f9] text-defaulttextcolor !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  @apply bg-primary text-white border border-primary border-solid rounded-[0.15rem] mt-[0.375rem] !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  @apply border border-solid rounded-md !important;
}

.select2-selection__choice__remov {
  @apply text-white !important;
}

.select2-results__option span img,
.select2-selection__rendered span img {
  @apply w-[1.45rem] h-[1.45rem] rounded-[1.25rem] me-1 inline-flex !important;
}

.select2-container--default .select2-results__option--selected {
  @apply bg-primary text-white !important;
}

.select2-container--default .select2-selection--multiple {
  @apply border-defaultborder !important;
}

.select2-dropdown {
  @apply border border-solid border-defaultborder !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  @apply border border-solid border-defaultborder !important;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  @apply border-e !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  @apply me-[20px] ms-0 ps-0 !important;
}

/* End:: select2 */
.swiper-button-next,
.swiper-button-prev {
  @apply w-[1.563rem] h-[1.563rem] text-white bg-black/30 rounded-md !important;
}

.swiper-pagination-bullet {
  @apply w-[1.25rem] h-[0.25rem] rounded-md bg-white !important;
}

.custom-pagination .swiper-pagination .swiper-pagination-bullet {
  @apply w-[1.25rem] h-[1.25rem] text-black !important;
}

.custom-pagination
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-white text-black !important;
}

.swiper-pagination-bullet-active {
  @apply bg-white !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  @apply fill-success bg-success !important;
}

.swiper-pagination {
  @apply text-white;
}

[dir="rtl"] pre[class*="language-"] > code {
  @apply border-l-0 text-right !important;
}

/* End:: prism js */
/* Start:: Draggable Cards */
#draggable-left .card,
#draggable-right .card {
  @apply touch-none;
}

/* End:: Draggable Cards */
/* Start:: Full Calendar */
.fullcalendar-events-activity li {
  @apply mb-3 text-[0.8125rem] py-1 pe-4 ps-[2rem] relative before:absolute before:w-3 before:h-3 before:border-[0.125rem] before:border-solid before:border-primary/30 before:rounded-[3.125rem] before:bg-none before:start-1 before:top-[0.563rem] after:absolute after:h-full after:bg-transparent after:border-e-[2px] after:border-dashed after:border-primary/10 after:start-[0.563rem] after:top-[1.25rem];
}
.fullcalendar-events-activity li:last-child {
  @apply mb-0 after:border-e-0 after:border-dashed after:border-defaultborder;
}

#full-calendar-activity {
  @apply max-h-[21rem];
}

/* End:: Full Calendar */
/* Start::Icons Page */
.icons-list {
  @apply list-none mt-0 -me-4 ms-0 p-0 flex flex-wrap !important;
}
.icons-list .icons-list-item {
  @apply text-center h-12 w-12 flex items-center justify-center border border-solid border-defaultborder dark:border-defaultborder/10 m-1 rounded-sm !important;
}
.icons-list .icons-list-item i {
  @apply text-[1.05rem] text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.fe {
  @apply text-inherit;
}

/* End::Icons Page */
/* Start::Scrollspy */
.scrollspy-example {
  @apply h-[12.5rem] mt-2 overflow-auto;
}

.scrollspy-example-2 {
  @apply h-[21.875rem] overflow-auto;
}

.scrollspy-example-3 {
  @apply h-[13.75rem] overflow-auto;
}

.simple-list-example-scrollspy .active {
  @apply bg-primary text-white !important;
}

.scrollspy-example-4 {
  @apply h-[12.5rem] mt-2 overflow-auto;
}

#navbar-example3 .nav-pills .nav-link {
  @apply py-2 px-4;
}
#navbar-example3 .active,
#navbar-example3 .nav-pills .show > .nav-link {
  @apply text-white bg-primary !important;
}

/* End::Scrollspy */
#google-map,
#google-map-overlay,
#map-geofencing,
#map-layers,
#map-markers,
#streetview-map {
  @apply h-[18.75rem];
}

#interactive-map,
#map,
#map-custom-icon,
#map-popup,
#map1 {
  @apply h-[18.75rem] z-[10];
}

#project-descriptioin-editor {
  @apply h-[200px] overflow-auto !important;
}

.chartjs-chart {
  @apply max-h-[18.75rem] !important;
}

.echart-charts {
  @apply h-[20rem] !important;
}

pre[class*="language-"] > code {
  @apply shadow-none bg-light border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md bg-none !important;
}

pre[class*="language-"] {
  @apply bg-none bg-transparent border-0 !important;
}

pre[class*="language-"]:before {
  @apply content-none !important;
}

pre[class*="language-"]:after {
  @apply content-none !important;
}

pre[class*="language-"] > code {
  @apply max-h-[300px];
}

.noUi-handle {
  @apply border border-solid border-white rounded-[3px] bg-white dark:bg-bodybg dark:border-defaultborder/10 cursor-default shadow-none !important;
}

.noUi-target {
  @apply bg-defaultbackground border border-solid border-defaultborder shadow-sm !important;
}

.noUi-horizontal {
  @apply h-[0.35rem] dark:bg-bodybg dark:border-defaultborder/10 !important;
}

.noUi-vertical {
  @apply w-[0.35rem] dark:bg-bodybg dark:border-defaultborder/10 !important;
}

.noUi-connect {
  @apply bg-primary !important;
}

.noUi-horizontal .noUi-handle {
  @apply w-[1rem] h-[1rem] -end-[-0.063rem] !important;
}

.noUi-handle:after,
.noUi-handle:before {
  @apply h-[0.375rem] w-[1px] start-[0.3rem] top-1 !important;
}

.noUi-handle:after {
  @apply start-[0.45rem] !important;
}

#slider-round {
  @apply h-[0.625rem] !important;
}
#slider-round .noUi-handle {
  @apply h-[1.125rem] w-[1.125rem] -top-[0.313rem] -end-[0.563rem] rounded-full bg-primary !important;
}
#slider-round .noUi-handle:before,
#slider-round .noUi-handle:after {
  @apply hidden !important;
}

#slider-square {
  @apply h-[0.625rem] !important;
}
#slider-square .noUi-handle {
  @apply h-[1.125rem] w-[1.125rem] -top-[0.45rem] -end-[0.563rem] rounded-none bg-primary !important;
}
#slider-square .noUi-handle:before,
#slider-square .noUi-handle:after {
  @apply hidden !important;
}

#color1,
#color2,
#color3 {
  @apply m-[0.625rem] inline-block h-[12.5rem] !important;
}

#colorpicker {
  @apply h-[15rem] w-[19.375rem] p-[0.625rem] border border-solid border-defaultborder !important;
}

#result {
  @apply my-[4.25rem] me-0 ms-[4rem] h-[6.25rem] w-[6.25rem] inline-block align-top text-gray-50 bg-gray-50 border border-solid border-white shadow-sm;
}

#color1 .noUi-connect {
  @apply bg-danger !important;
}

#color2 .noUi-connect {
  @apply bg-secondary !important;
}

#color3 .noUi-connect {
  @apply bg-primary !important;
}

#slider-hide .noUi-tooltip {
  @apply hidden;
}

#slider-hide .noUi-active .noUi-tooltip {
  @apply block;
}

#slider-toggle {
  @apply h-[3.125rem];
}

.noUi-vertical .noUi-handle {
  @apply w-4 h-4 !important;
}

.c-1-color {
  @apply bg-secondary !important;
}

.c-2-color {
  @apply bg-warning !important;
}

.c-3-color {
  @apply bg-info !important;
}

.c-4-color {
  @apply bg-danger !important;
}

.c-5-color {
  @apply bg-indigomain !important;
}

#secondary-colored-slider .noUi-connect {
  @apply bg-secondary !important;
}

#warning-colored-slider .noUi-connect {
  @apply bg-warning !important;
}

#info-colored-slider .noUi-connect {
  @apply bg-info !important;
}

#success-colored-slider .noUi-connect {
  @apply bg-success !important;
}

#danger-colored-slider .noUi-connect {
  @apply bg-danger !important;
}

.noUi-marker {
  @apply bg-defaultborder dark:bg-white/10 !important;
}

.rangeslider__handle {
  @apply rtl:dir-rtl rtl:left-0 rtl:text-right !important;
}

.rangeslider__fill {
  @apply rtl:w-full !important;
}

/*Color Picker*/
.pickr .pcr-button {
  @apply overflow-hidden rounded-sm !important;
}

.pcr-app {
  @apply rounded-sm z-[9999999999] !important;
}

/*Color Picker*/
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply fill-primary !important;
}

.flatpickr-day.today {
  @apply border-primary bg-primary text-warning !important;
}

.flatpickr-calendar {
  @apply border-s border-solid border-defaultborder dark:border-defaultborder/10 dark:bg-bodybg !important;
}

.flatpickr-day.selected {
  @apply bg-primary text-white border-primary hover:bg-primary hover:text-white hover:border-primary !important;
}

span.flatpickr-weekday {
  @apply text-primary !important;
}

.flatpickr-day.today {
  @apply text-white !important;
}

.flatpickr-day {
  @apply rounded-md !important;
}

.flatpickr-day.today:hover {
  @apply bg-primary border-primary text-white !important;
}
.flatpickr-day.today:hover.active {
  @apply bg-primary border-primary text-white !important;
}

.flatpickr-weekdays {
  @apply bg-primary/10 text-primary !important;
}

.flatpickr-month {
  @apply bg-primary/10 text-primary text-[0.813rem] !important;
}

.ql-toolbar.ql-snow {
  @apply rounded-t-[0.3rem] !important;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 !important;
}

.ql-bubble {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 rounded-md !important;
}

/* Start:: grid js tables */
.gridjs-table {
  @apply w-full;
}

table.gridjs-table {
  @apply text-start text-defaultsize font-medium !important;
}

.gridjs-wrapper {
  @apply shadow-none border-b-0 border-t-0 rounded-none !important;
}
.gridjs-wrapper:nth-last-of-type(2) {
  @apply rounded-none !important;
}

.gridjs-container {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

th.gridjs-th {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 text-defaulttextcolor dark:text-defaulttextcolor/70 p-3 text-start !important;
}

td.gridjs-td {
  @apply border border-solid border-defaultborder dark:border-defaultborder/10 p-3 !important;
}

.gridjs-tbody,
td.gridjs-td {
  @apply bg-white dark:bg-bodybg !important;
}

.gridjs-footer {
  @apply bg-white dark:bg-bodybg border-b-0 border-transparent rounded-none border-t-0 shadow-none pt-3 !important;
}

.gridjs-pagination {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.gridjs-pagination .gridjs-pages button:first-child {
  @apply rounded-ss-md rounded-es-md rounded-ee-none rounded-se-none !important;
}

.gridjs-pagination .gridjs-pages button:last-child {
  @apply rounded-es-none rounded-ss-none rounded-ee-md rounded-se-md !important;
}

@media (max-width: 575.98px) {
  .gridjs-search-input {
    @apply w-[12.5rem] !important;
  }
}
.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.gridjs-pagination .gridjs-pages button {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 py-[0.375rem] px-3 !important;
}
.gridjs-pagination .gridjs-pages button:focus {
  @apply shadow-none me-0 !important;
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  @apply font-semibold bg-primary text-white !important;
}

.gridjs-pagination .gridjs-pages {
  @apply float-right !important;
}

input.gridjs-input {
  @apply bg-white border border-defaulttextcolor dark:border-defaultborder/10 text-defaultsize py-[0.375rem] px-3;
}
input.gridjs-input:focus {
  @apply font-semibold border border-solid border-primary !important;
}

th.gridjs-th-fixed {
  @apply bg-light !important;
}

#grid-header-fixed .gridjs-wrapper {
  @apply border-t border-b border-solid border-defaultborder dark:border-defaultborder/10 !important;
}
#grid-header-fixed
  .gridjs-container
  .gridjs-wrapper
  .gridjs-thead
  .gridjs-tr
  th {
  @apply -top-[0.1px] !important;
}

.gridjs-tr {
  @apply border text-start border-defaultborder dark:border-defaultborder/10 !important;
}

input.gridjs-input {
  @apply bg-white dark:bg-bodybg;
}

[dir="rtl"] .tabulator-footer-contents {
  @apply dir-ltr !important;
}
[dir="rtl"] .ql-editor .ql-align-right {
  @apply text-left !important;
}

:is(.dark [type="radio"]):checked {
  @apply bg-primary;
}

@media (max-width: 575.98px) {
  #crm-revenue-analytics .apexcharts-canvas .apexcharts-toolbar {
    inset-block-start: 0.75rem !important;
    inset-inline-end: 0 !important;
  }
}
#product-features {
  @apply rounded-b-sm !important;
}

.ti-btn-icon {
  @apply w-[2.313rem] h-[2.313rem] text-[0.95rem];
}

.swal2-title {
  @apply text-defaulttextcolor !important;
}

[type="checkbox"]:focus {
  @apply outline-none ring-0 border-defaultborder !important;
}

#security .choices__list--dropdown .choices__item--selectable::after,
#security .choices__list[aria-expanded] .choices__item--selectable::after,
#buycrypto .choices__list--dropdown .choices__item--selectable::after,
#buycrypto .choices__list[aria-expanded] .choices__item--selectable::after,
.companies-search-input
  .choices__list--dropdown
  .choices__item--selectable::after,
.companies-search-input
  .choices__list[aria-expanded]
  .choices__item--selectable::after {
  @apply hidden !important;
}
#security .choices__list--dropdown .choices__item--selectable,
#security .choices__list[aria-expanded] .choices__item--selectable,
#buycrypto .choices__list--dropdown .choices__item--selectable,
#buycrypto .choices__list[aria-expanded] .choices__item--selectable,
.companies-search-input .choices__list--dropdown .choices__item--selectable,
.companies-search-input
  .choices__list[aria-expanded]
  .choices__item--selectable {
  @apply p-3 !important;
}

.newproject .choices__inner {
  @apply w-[120px] !important;
}
.newproject .choices__list--dropdown .choices__item--selectable::after,
.newproject .choices__list[aria-expanded] .choices__item--selectable::after {
  @apply hidden !important;
}
.newproject .choices__list--dropdown .choices__item--selectable,
.newproject .choices__list[aria-expanded] .choices__item--selectable {
  @apply p-3 !important;
}

.crypto-buy-sell .choices__inner {
  @apply min-w-[5rem] border-inputborder dark:border-white/10 !important;
}
.crypto-buy-sell .choices__list--dropdown .choices__item--selectable::after,
.crypto-buy-sell
  .choices__list[aria-expanded]
  .choices__item--selectable::after {
  @apply hidden !important;
}
.crypto-buy-sell .choices__list--dropdown .choices__item--selectable,
.crypto-buy-sell .choices__list[aria-expanded] .choices__item--selectable {
  @apply pe-0 !important;
}

[dir="rtl"] .ts-control input {
  @apply ms-[10px] !important;
}

/* PLUGINS STYLES */
/* SWITCHER STYLES */
/* Start Background Image Styles */
[bg-img="bgimg1"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img1.jpg")] bg-cover bg-center !important;
}
[bg-img="bgimg1"][data-menu-styles="gradient"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img1.jpg")] bg-cover bg-center !important;
}

[bg-img="bgimg2"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img2.jpg")] bg-cover bg-center !important;
}
[bg-img="bgimg2"][data-menu-styles="gradient"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img2.jpg")] bg-cover bg-center !important;
}

[bg-img="bgimg3"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img3.jpg")] bg-cover bg-center !important;
}
[bg-img="bgimg3"][data-menu-styles="gradient"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img3.jpg")] bg-cover bg-center !important;
}

[bg-img="bgimg4"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img4.jpg")] bg-cover bg-center !important;
}
[bg-img="bgimg4"][data-menu-styles="gradient"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img4.jpg")] bg-cover bg-center !important;
}

[bg-img="bgimg5"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img5.jpg")] bg-cover bg-center !important;
}
[bg-img="bgimg5"][data-menu-styles="gradient"] .app-sidebar {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img5.jpg")] bg-cover bg-center !important;
}

[bg-img="bgimg5"] .app-sidebar,
[bg-img="bgimg4"] .app-sidebar,
[bg-img="bgimg3"] .app-sidebar,
[bg-img="bgimg2"] .app-sidebar,
[bg-img="bgimg1"] .app-sidebar {
  @apply bg-cover bg-center bg-no-repeat before:absolute before:inset-x-0 before:top-0 before:w-full before:h-full before:-z-[1];
}
[bg-img="bgimg5"][data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]
  .app-sidebar,
[bg-img="bgimg5"][data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
  .app-sidebar,
[bg-img="bgimg5"][data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar,
[bg-img="bgimg5"][data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar,
[bg-img="bgimg4"][data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]
  .app-sidebar,
[bg-img="bgimg4"][data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
  .app-sidebar,
[bg-img="bgimg4"][data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar,
[bg-img="bgimg4"][data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar,
[bg-img="bgimg3"][data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]
  .app-sidebar,
[bg-img="bgimg3"][data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
  .app-sidebar,
[bg-img="bgimg3"][data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar,
[bg-img="bgimg3"][data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar,
[bg-img="bgimg2"][data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]
  .app-sidebar,
[bg-img="bgimg2"][data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
  .app-sidebar,
[bg-img="bgimg2"][data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar,
[bg-img="bgimg2"][data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar,
[bg-img="bgimg1"][data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]
  .app-sidebar,
[bg-img="bgimg1"][data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
  .app-sidebar,
[bg-img="bgimg1"][data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar,
[bg-img="bgimg1"][data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar {
  position: fixed;
}
[bg-img="bgimg5"][data-menu-styles="light"] .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="light"] .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="light"] .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="light"] .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="light"] .app-sidebar {
  @apply before:bg-white/80;
}
[bg-img="bgimg5"][data-menu-styles="light"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="light"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="light"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="light"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="light"] .app-sidebar .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"].light[data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg4"].light[data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg3"].light[data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg2"].light[data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg1"].light[data-menu-styles="dark"] .app-sidebar {
  @apply before:bg-[#252930]/80;
}
[bg-img="bgimg5"].light[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg4"].light[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg3"].light[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg2"].light[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg1"].light[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"][data-menu-styles="dark"].dark .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="dark"].dark .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="dark"].dark .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="dark"].dark .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="dark"].dark .app-sidebar {
  @apply before:bg-bodybg/80;
}
[bg-img="bgimg5"][data-menu-styles="dark"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="dark"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="dark"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="dark"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="dark"].dark
  .app-sidebar
  .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"][data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="dark"] .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="dark"] .app-sidebar {
  @apply before:bg-[#252930]/80;
}
[bg-img="bgimg5"][data-menu-styles="dark"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="dark"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="dark"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="dark"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="dark"] .app-sidebar .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"][data-menu-styles="color"] .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="color"] .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="color"] .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="color"] .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="color"] .app-sidebar {
  @apply before:bg-primary/80 before:bg-gradient-to-t before:from-black/30 before:to-black/30;
}
[bg-img="bgimg5"][data-menu-styles="color"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="color"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="color"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="color"] .app-sidebar .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="color"] .app-sidebar .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"][data-menu-styles="gradient"] .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="gradient"] .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="gradient"] .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="gradient"] .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="gradient"] .app-sidebar {
  @apply before:bg-gradient-to-b before:from-primary/80 before:to-secondary/80 after:absolute after:h-full after:w-full after:inset-0 after:bg-gradient-to-t after:from-black/30 after:to-black/30 after:-z-[1];
}
[bg-img="bgimg5"][data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"][data-menu-styles="transparent"] .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="transparent"] .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="transparent"] .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="transparent"] .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="transparent"] .app-sidebar {
  @apply before:bg-bodybg/80;
}
[bg-img="bgimg5"][data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header {
  @apply bg-transparent !important;
}
[bg-img="bgimg5"][data-menu-styles="transparent"].dark .app-sidebar,
[bg-img="bgimg4"][data-menu-styles="transparent"].dark .app-sidebar,
[bg-img="bgimg3"][data-menu-styles="transparent"].dark .app-sidebar,
[bg-img="bgimg2"][data-menu-styles="transparent"].dark .app-sidebar,
[bg-img="bgimg1"][data-menu-styles="transparent"].dark .app-sidebar {
  @apply before:bg-bodybg2/80;
}
[bg-img="bgimg5"][data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg4"][data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg3"][data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg2"][data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header,
[bg-img="bgimg1"][data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header {
  @apply bg-transparent !important;
}
@media (min-width: 992px) {
  [bg-img="bgimg5"][data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header,
  [bg-img="bgimg4"][data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header,
  [bg-img="bgimg3"][data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header,
  [bg-img="bgimg2"][data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header,
  [bg-img="bgimg1"][data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header {
    backdrop-filter: blur(30px);
  }
}

/* End Background Image Styles */
/* Start Boxed Styles */
@media (min-width: 1400px) {
  [data-width="boxed"] body {
    @apply bg-bodybg dark:bg-bodybg/60 !important;
  }
  [data-width="boxed"] .page {
    @apply w-[1400px] my-0 mx-auto relative shadow-[0rem_0rem_1rem_rgba(0,0,0,0.1)] dark:shadow-[0rem_0rem_1rem_rgba(255,255,255,0.1)] !important;
  }
  [data-width="boxed"] .page .app-header {
    @apply w-[1400px] my-0 mx-auto;
  }
  [data-width="boxed"][data-nav-layout="horizontal"] .app-sidebar {
    @apply w-[1400px] mx-auto my-0 !important;
  }
  [data-width="boxed"] .page .app-sidebar {
    @apply start-auto;
  }
  [data-width="boxed"] .page .app-header {
    @apply start-auto;
  }
  [data-width="boxed"] .main-chart-wrapper .chat-user-details.open {
    @apply end-0;
  }
  [data-width="boxed"].dark .page {
    @apply shadow-[-5px_0px_13px_10px_rgba(255,255,255,0.03)] !important;
  }
  [data-width="boxed"][data-vertical-style="detached"] .page {
    @apply w-[1400px] mx-auto !important;
  }
  [data-width="boxed"][data-vertical-style="detached"] .page .app-sidebar {
    @apply mx-4;
  }
  [data-width="boxed"] #sales-donut {
    @apply w-full !important;
  }
  [data-width="boxed"] .chat-info {
    @apply min-w-[21.875rem] max-w-[21.875rem];
  }
  [data-width="boxed"] .main-chat-area {
    @apply w-full max-w-full overflow-hidden;
  }
  [data-width="boxed"] .chat-user-details {
    @apply hidden;
  }
  [data-width="boxed"]
    .main-chart-wrapper
    .main-chat-right-icons
    .responsive-userinfo-open {
    @apply flex;
  }
  [data-width="boxed"] .main-chart-wrapper .chat-user-details.open {
    @apply block bottom-2;
  }
  [data-width="boxed"] .main-chart-wrapper .chat-user-details {
    @apply absolute;
  }
  [data-width="boxed"] .mail-info-body {
    @apply max-h-[calc(100vh-19.3rem)];
  }
  [data-width="boxed"] #product-quantity {
    @apply w-8;
  }
  [data-width="boxed"] .xxxl\:col-span-2 {
    @apply col-span-4;
  }
  [data-width="boxed"] .xxxl\:col-span-3,
  [data-width="boxed"] .xxxl\:col-span-4,
  [data-width="boxed"] .xxxl\:col-span-6,
  [data-width="boxed"] .xxxl\:col-span-8 {
    @apply col-span-12;
  }
  [data-width="boxed"] .xxxl\:col-span-9 {
    @apply col-span-12;
  }
  [data-width="boxed"] .xxxl\:flex {
    @apply block;
  }
  [data-width="boxed"] .xxxl\:space-y-0 {
    @apply space-y-2;
  }
  [data-width="boxed"] .xxl\:max-w-\[250px\] {
    @apply max-w-[160px];
  }
  [data-width="boxed"] .xxxl\:\!col-span-3 {
    @apply col-span-6 !important;
  }
  [data-width="boxed"] .swiper-text {
    @apply hidden;
  }
  [data-width="boxed"] .xxxl\:grid-cols-4 {
    @apply grid-cols-1 !important;
  }
}

/* End Boxed Styles */
/* Start Classic-Page Styles */
[data-page-style="classic"] body {
  @apply bg-white;
}
[data-page-style="classic"] .box {
  @apply shadow-none shadow-transparent border;
}
[data-page-style="classic"].dark body {
  @apply bg-bodybg;
}
[data-page-style="classic"].dark .box {
  @apply shadow-none shadow-transparent border border-defaultborder/10;
}
[data-page-style="classic"].dark[data-width="boxed"] .page {
  @apply bg-bodybg !important;
}
[data-page-style="classic"][data-width="boxed"] .page {
  @apply bg-white !important;
}

/* End Classic-Page Styles */
[data-page-style="modern"] {
  --light: 255 255 255;
  --custom-white: 243 246 248;
  --default-border: 230 235 241;
}
[data-page-style="modern"] .dropdown-menu {
  @apply bg-white;
}
[data-page-style="modern"] body {
  @apply bg-white;
}
[data-page-style="modern"] .box {
  @apply shadow-none bg-[#f3f6f8];
}
[data-page-style="modern"][class="dark"] {
  --light: 20, 20, 20;
  --custom-white: rgb(var(--body-bg));
}
[data-page-style="modern"][class="dark"] .box {
  @apply shadow-none bg-bodybg;
}
[data-page-style="modern"][class="dark"] .dropdown-menu {
  @apply bg-light;
}
[data-page-style="modern"][class="dark"] body {
  @apply bg-bodybg;
}
[data-page-style="modern"][class="dark"] .ti-btn-light {
  @apply bg-black/10 border-black/10 !important;
}
[data-page-style="modern"][class="dark"] .ti-btn-light:hover,
[data-page-style="modern"][class="dark"] .ti-btn-light:focus,
[data-page-style="modern"][class="dark"] .ti-btn-light:active {
  @apply bg-black/10 border-black/10 !important;
}
[data-page-style="modern"] .footer {
  @apply bg-[#f3f6f8];
}
[data-page-style="modern"] .app-sidebar,
[data-page-style="modern"] .footer,
[data-page-style="modern"] .app-header,
[data-page-style="modern"] .app-sidebar .main-sidebar {
  @apply shadow-none;
}
@media (min-width: 992px) {
  [data-page-style="modern"][data-menu-styles][data-nav-layout="horizontal"]
    .app-sidebar {
    @apply border-b border-headerbordercolor !important;
  }
  [data-page-style="modern"].dark[data-menu-styles][data-nav-layout="horizontal"]
    .app-sidebar {
    @apply border-white/10 !important;
  }
}

/* Start Closed-Menu Styles */
@media screen and (min-width: 992px) {
  [data-vertical-style="closed"][data-toggled="close-menu-close"] .app-sidebar {
    @apply hidden;
  }
  [data-vertical-style="closed"][data-toggled="close-menu-close"] .content {
    @apply ms-0 !important;
  }
  [data-vertical-style="closed"][data-toggled="close-menu-close"] .app-header {
    @apply ps-0 !important;
  }
  [data-vertical-style="closed"][data-toggled="close-menu-open"] .app-sidebar {
    @apply block;
  }
  [data-vertical-style="closed"][data-toggled="close-menu-open"] .content {
    @apply ms-64;
  }
  [data-vertical-style="closed"][data-toggled="close-menu-open"] .app-header {
    @apply ps-64;
  }
}

/* End Closed-Menu Styles */
/* Start Detached-Menu Styles */
@media screen and (min-width: 992px) {
  [data-vertical-style="detached"] .app-header {
    @apply z-[50] px-0 shadow-none !important;
  }
  [data-vertical-style="detached"] .main-header {
    @apply my-0 basis-auto p-0 !important;
  }
  [data-vertical-style="detached"] .main-header-container {
    @apply mx-auto w-[94%];
  }
  [data-vertical-style="detached"] .page {
    @apply w-[94%] mx-auto my-0 !important;
  }
  [data-vertical-style="detached"] .horizontal-logo {
    @apply block -order-1;
  }
  [data-vertical-style="detached"] .horizontal-logo img {
    @apply h-8 leading-8;
  }
  [data-vertical-style="detached"] footer {
    @apply bg-transparent shadow-none dark:bg-transparent !important;
  }
  [data-vertical-style="detached"] .app-sidebar {
    @apply top-[calc(64px-(-1.5rem))] rounded-md bottom-6 h-auto overflow-hidden border border-white/10;
  }
  [data-vertical-style="detached"] .app-sidebar .main-sidebar-header {
    @apply hidden;
  }
  [data-vertical-style="detached"] .app-sidebar .main-sidebar {
    @apply mt-0 h-full !important;
  }
  [data-vertical-style="detached"] .content {
    @apply ms-64;
  }
  [data-vertical-style="detached"] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style="detached"] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style="detached"] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style="detached"] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style="detached"] .horizontal-logo .header-logo .toggle-white {
    @apply hidden;
  }
  [data-vertical-style="detached"] .horizontal-logo .header-logo .desktop-logo {
    @apply block;
  }
  [data-vertical-style="detached"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-vertical-style="detached"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-vertical-style="detached"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-dark,
  [data-vertical-style="detached"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-white,
  [data-vertical-style="detached"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="detached"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply block !important;
  }
  [data-vertical-style="detached"].dark
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-vertical-style="detached"].dark
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-vertical-style="detached"].dark
    .horizontal-logo
    .header-logo
    .toggle-dark,
  [data-vertical-style="detached"].dark
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="detached"].dark
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply block !important;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar {
    @apply w-[5rem];
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__label {
    @apply hidden;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__item {
    @apply block text-center text-xs;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__item
    .side-menu__icon {
    @apply mx-auto w-full block;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .slide__category {
    @apply py-[1.2rem] px-[1.65rem] relative before:absolute before:start-[2.25rem] before:end-0 before:top-[1.25rem] before:w-[0.35rem] before:rounded-[3.125rem] before:h-[0.35rem] before:border before:border-solid before:opacity-[1] !important;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .slide__category
    .category-name {
    @apply hidden;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply hidden !important;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .main-menu {
    @apply px-0;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-dark,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-white,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    )
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply block !important;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-header
    .header-logo
    .desktop-dark {
    @apply block;
  }
  [data-vertical-style="detached"][data-toggled="detached-close"] .content {
    @apply ms-24;
  }
  [data-vertical-style="detached"].dark .app-sidebar {
    @apply border-white/10;
  }
  [data-vertical-style="detached"] .app-sidebar {
    @apply start-[inherit];
  }
}

/* End Detached-Menu Styles */
/* Start Double-Menu Styles */
@media screen and (min-width: 992px) {
  [data-vertical-style="doublemenu"] .app-sidebar .slide.has-sub {
    @apply grid;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-sidebar {
    @apply overflow-visible h-[90%];
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-sidebar-header {
    @apply w-[4.95rem] backdrop-blur-3xl;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-sidebar {
    @apply w-[4.95rem];
  }
  [data-vertical-style="doublemenu"] .app-sidebar .side-menu__icon {
    @apply mx-auto;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide {
    @apply p-0;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child1 {
    @apply w-[12rem];
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child2,
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child3 {
    @apply w-full;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child1,
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child2,
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child3 {
    @apply mt-0 !important;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item {
    @apply text-start flex;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item:before {
    @apply top-[0.938rem];
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__angle,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__angle,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child3
    .side-menu__angle {
    @apply block top-[0.65rem];
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide__category,
  [data-vertical-style="doublemenu"] .app-sidebar .side-menu__angle {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .side-menu__label {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply absolute transition-none h-full top-0 overflow-y-auto overflow-x-hidden !important;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1
    .slide {
    @apply px-0;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  [data-vertical-style="doublemenu"] .main-menu {
    @apply px-0;
  }
  [data-vertical-style="doublemenu"] #sidebar-scroll {
    @apply overflow-y-visible mt-0;
    @apply h-full !important;
  }
  [data-vertical-style="doublemenu"] #sidebar-scroll .main-menu-container {
    @apply mt-[4.45rem];
  }
  [data-vertical-style="doublemenu"] .app-sidebar {
    @apply w-20 !important;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-sidebar {
    @apply w-20 !important;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide.has-sub .slide-menu {
    @apply py-0;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply block;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"] .side-menu__label1 {
    @apply block text-base font-semibold text-white border-b border-b-white/10 py-6 px-4 !important;
  }
  [data-vertical-style="doublemenu"].dark .slide-menu.child1 {
    @apply border-white/10 !important;
  }
  [data-vertical-style="doublemenu"].dark .side-menu__label1 {
    @apply border-white/10 text-white !important;
  }
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-bodybg rounded-none !important;
  }
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply block;
  }
  [data-vertical-style="doublemenu"] .double-menu-active {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child1 {
    @apply border-s border-s-white/10 !important;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide-menu.child1 .slide {
    @apply start-0;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item {
    @apply ps-8 pe-4;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before {
    @apply start-3;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__angle {
    @apply end-4;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide.has-sub .slide-menu {
    @apply start-[5rem] rounded-none !important;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply px-0 !important;
  }
  [data-vertical-style="doublemenu"] .content {
    @apply ms-20;
  }
  [data-vertical-style="doublemenu"] .app-header {
    @apply ps-20;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-open"] .content {
    @apply ms-[17rem];
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-open"]
    .app-header {
    @apply ps-[17rem];
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .content {
    @apply ms-20;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"] .header {
    @apply ps-20;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .slide.has-sub
    .slide-menu.child1 {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .double-menu-active {
    @apply block !important;
  }
}

/* End Double-Menu Styles */
@media (min-width: 992px) {
  [data-vertical-style="doublemenu"] .content {
    @apply ms-[17rem];
  }
  [data-vertical-style="doublemenu"] .app-header {
    @apply ps-[17rem];
  }
  [data-vertical-style="doublemenu"]:not([data-toggled="double-menu-open"])
    .content {
    @apply ms-20;
  }
  [data-vertical-style="doublemenu"]:not([data-toggled="double-menu-open"])
    .app-header {
    @apply ps-20;
  }
}
@media (min-width: 992px) {
  [data-vertical-style="doublemenu"] .app-sidebar {
    @apply w-20 border-e-0;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-sidebar {
    @apply overflow-visible h-full shadow-none mt-0;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-sidebar-header {
    @apply w-20 border-e-0;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-menu-container {
    @apply mt-[3.75rem];
  }
  [data-vertical-style="doublemenu"] .app-sidebar .main-menu > .slide {
    @apply py-0 px-[1.2rem];
  }
  [data-vertical-style="doublemenu"] .app-sidebar .category-name,
  [data-vertical-style="doublemenu"] .app-sidebar .side-menu__label,
  [data-vertical-style="doublemenu"] .app-sidebar .side-menu__angle {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .side-menu__icon {
    @apply me-0;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .slide__category {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .simplebar-mask {
    position: inherit;
  }
  [data-vertical-style="doublemenu"] .app-sidebar .simplebar-placeholder {
    @apply h-auto !important;
  }
  [data-vertical-style="doublemenu"] .app-header {
    @apply ps-[17rem];
  }
  [data-vertical-style="doublemenu"] .content {
    @apply ms-[17rem];
  }
  [data-vertical-style="doublemenu"] .slide.has-sub .slide-menu {
    @apply bg-white dark:bg-bodybg absolute start-2 shadow-none transition-none h-full end-0 rounded-md border-e border-e-white/10 !important;
  }
  [data-vertical-style="doublemenu"] .slide.has-sub .slide-menu.child2,
  [data-vertical-style="doublemenu"] .slide.has-sub .slide-menu.child3 {
    @apply start-0 relative h-auto top-0 !important;
  }
  [data-vertical-style="doublemenu"]
    .slide.has-sub
    .slide-menu.child2
    .slide:nth-child(2),
  [data-vertical-style="doublemenu"]
    .slide.has-sub
    .slide-menu.child3
    .slide:nth-child(2) {
    @apply pt-[0rem];
  }
  [data-vertical-style="doublemenu"] .slide-menu.child1 .slide,
  [data-vertical-style="doublemenu"] .slide-menu.child2 .slide,
  [data-vertical-style="doublemenu"] .slide-menu.child3 .slide {
    @apply ps-0;
  }
  [data-vertical-style="doublemenu"] .slide-menu.child1 .slide .side-menu__item,
  [data-vertical-style="doublemenu"] .slide-menu.child2 .slide .side-menu__item,
  [data-vertical-style="doublemenu"]
    .slide-menu.child3
    .slide
    .side-menu__item {
    @apply text-start;
  }
  [data-vertical-style="doublemenu"]
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  [data-vertical-style="doublemenu"]
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  [data-vertical-style="doublemenu"]
    .slide-menu.child3
    .slide
    .side-menu__item:before {
    @apply top-[0.938rem] start-[0.75rem];
  }
  [data-vertical-style="doublemenu"] .slide-menu.child1 .side-menu__angle,
  [data-vertical-style="doublemenu"] .slide-menu.child2 .side-menu__angle,
  [data-vertical-style="doublemenu"] .slide-menu.child3 .side-menu__angle {
    @apply block end-4 top-[0.65rem];
  }
  [data-vertical-style="doublemenu"] .slide-menu.child2,
  [data-vertical-style="doublemenu"] .slide-menu.child3 {
    @apply min-w-[10rem];
  }
  [data-vertical-style="doublemenu"] .slide-menu.child1 {
    @apply min-w-[12rem];
  }
  [data-vertical-style="doublemenu"] .slide-menu.child1 .slide:nth-child(2) {
    @apply pt-3;
  }
  [data-vertical-style="doublemenu"] .side-menu__label1 {
    @apply block text-[0.938rem] font-medium text-white py-5 px-3 w-48 h-[3.625rem] border-b border-b-white/10 !important;
  }
  [data-vertical-style="doublemenu"] .slide-menu {
    @apply hidden;
  }
  [data-vertical-style="doublemenu"] .slide-menu.double-menu-active {
    @apply visible block h-full absolute top-0 rounded-none border-t-0 overflow-y-hidden !important;
  }
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"][class="light"][data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"].dark[data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"].dark[data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="color"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="gradient"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="doublemenu"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply block !important;
  }
  [data-vertical-style="doublemenu"].dark[data-page-style="classic"]
    .slide.has-sub
    .slide-menu {
    @apply border-e border-e-white/10;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .app-header {
    @apply ps-20;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .content {
    @apply ms-20;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply hidden !important;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-close"]
    .app-sidebar
    .main-sidebar {
    @apply shadow-defaultshadow;
  }
  [data-vertical-style="doublemenu"][data-toggled="double-menu-open"]
    .app-sidebar
    .main-sidebar
    .slide.side-menu__label1 {
    @apply border-e border-e-white/10;
  }
}

/* Start Header-Scrollable Styles */
[data-header-position="scrollable"] .app-header {
  @apply absolute;
}
@media screen and (min-width: 992px) {
  [data-header-position="scrollable"]:not([data-menu-position="scrollable"])
    .app-sidebar.sticky-pin {
    @apply fixed top-0 !important;
  }
}
[data-header-position="scrollable"]:not(
    [data-menu-position="scrollable"]
  )[data-toggled="open"]
  .app-sidebar.sticky-pin {
  @apply mt-0;
}

/* End Header-Scrollable Styles */
/* Start Header Styles */
[data-header-styles="dark"] .app-header {
  --header-prime-color: 255 255 255;
  @apply bg-[#111c43] border-white/10;
}
[data-header-styles="dark"]
  .app-header
  .main-header-container
  .header-link-icon {
  @apply hover:bg-white/10 text-white/60 fill-black/60 !important;
}
[data-header-styles="dark"] .app-header .animated-arrow span {
  @apply bg-white/60;
}
[data-header-styles="dark"] .app-header .animated-arrow span:before,
[data-header-styles="dark"] .app-header .animated-arrow span:after {
  @apply bg-white/60;
}
[data-header-styles="dark"] .app-header .sidebar-toggle {
  @apply bg-transparent hover:bg-black/10 text-black/60 hover:text-black/60 focus:ring-white/10 focus:ring-offset-white/10 !important;
}
[data-header-styles="dark"] .app-header .sidebar-toggle svg {
  @apply text-black/60;
}
[data-header-styles="dark"] .app-header .ti-dropdown-toggle {
  @apply bg-transparent hover:bg-black/10 border-white/10 text-black/60 hover:text-black/60 focus:ring-offset-white/10 !important;
}
[data-header-styles="dark"] .app-header .ti-dropdown-toggle svg {
  @apply text-black/60 !important;
}
[data-header-styles="dark"] .app-header .dropdown-profile {
  @apply focus:ring-gray-400;
}
[data-header-styles="dark"] .app-header .dropdown-profile img {
  @apply ring-white;
}
[data-header-styles="dark"] .app-header .dropdown-profile p {
  @apply text-white/60 !important;
}
[data-header-styles="dark"] .app-header .dropdown-profile span {
  @apply text-white/60 !important;
}
[data-header-styles="dark"] .app-header #dropdown-flag {
  @apply focus:ring-white/10;
}
[data-header-styles="dark"] .app-header .header-search button,
[data-header-styles="dark"] .app-header .switcher-icon button {
  @apply bg-transparent hover:bg-black/20 text-black/60 hover:text-white focus:ring-white/10 focus:ring-offset-white/10;
}
[data-header-styles="dark"] .app-header .header-search button svg,
[data-header-styles="dark"] .app-header .switcher-icon button svg {
  @apply text-black/60;
}
[data-header-styles="dark"] .app-header .header-theme-mode a,
[data-header-styles="dark"] .app-header .header-fullscreen a {
  @apply bg-transparent hover:bg-black/20 text-black/60 hover:text-white focus:ring-white/10 focus:ring-offset-white/10;
}
[data-header-styles="dark"] .app-header .header-theme-mode a svg,
[data-header-styles="dark"] .app-header .header-fullscreen a svg {
  @apply text-black/60;
}
@media (min-width: 992px) {
  [data-header-styles="dark"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-header-styles="dark"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply hidden !important;
  }
  [data-header-styles="dark"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply block !important;
  }
}

[data-header-styles="color"] .app-header {
  --header-prime-color: 255 255 255 0.1;
  @apply bg-primary border-white/20 !important;
}
[data-header-styles="color"]
  .app-header
  .main-header-container
  .header-link-icon {
  @apply hover:bg-white/20 text-white/60 fill-black/60 !important;
}
[data-header-styles="color"] .app-header .animated-arrow span {
  @apply bg-white/60;
}
[data-header-styles="color"] .app-header .animated-arrow span:before,
[data-header-styles="color"] .app-header .animated-arrow span:after {
  @apply bg-white/60;
}
[data-header-styles="color"] .app-header .sidebar-toggle {
  @apply bg-transparent hover:bg-white/20 text-white/60 hover:text-white/10 focus:ring-transparent focus:ring-offset-transparent !important;
}
[data-header-styles="color"] .app-header .sidebar-toggle svg {
  @apply fill-white/60;
}
[data-header-styles="color"] .app-header .ti-dropdown-toggle {
  @apply bg-transparent hover:bg-white/20 border-white/20 text-white/60 hover:text-white/20 focus:ring-transparent focus:ring-offset-transparent;
}
[data-header-styles="color"] .app-header .ti-dropdown-toggle svg {
  @apply fill-white/60 !important;
}
[data-header-styles="color"] .app-header .dropdown-profile {
  @apply focus:ring-gray-400;
}
[data-header-styles="color"] .app-header .dropdown-profile img {
  @apply ring-white;
}
[data-header-styles="color"] .app-header .dropdown-profile p {
  @apply text-white/60 !important;
}
[data-header-styles="color"] .app-header .dropdown-profile span {
  @apply text-white/60 !important;
}
[data-header-styles="color"] .app-header #dropdown-flag {
  @apply focus:ring-white/20;
}
[data-header-styles="color"] .app-header .header-search button,
[data-header-styles="color"] .app-header .switcher-icon button {
  @apply bg-transparent hover:bg-white/20 text-white/60 hover:text-white/10 focus:ring-white/20 focus:ring-offset-white/20 !important;
}
[data-header-styles="color"] .app-header .header-search svg,
[data-header-styles="color"] .app-header .switcher-icon svg {
  @apply fill-white/60 !important;
}
[data-header-styles="color"] .app-header .header-theme-mode a,
[data-header-styles="color"] .app-header .header-fullscreen a {
  @apply bg-transparent hover:bg-white/20 text-white/60 hover:text-white/10 focus:ring-white/20 focus:ring-offset-white/20;
}
[data-header-styles="color"] .app-header .header-theme-mode svg,
[data-header-styles="color"] .app-header .header-fullscreen svg {
  @apply fill-white/60 !important;
}
@media (min-width: 992px) {
  [data-header-styles="color"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-header-styles="color"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply hidden !important;
  }
  [data-header-styles="color"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply block !important;
  }
}
@media (max-width: 991.98px) {
  [data-header-styles="color"]
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-header-styles="color"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-header-styles="color"]
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply block;
  }
}

[data-header-styles="gradient"] .app-header {
  --header-prime-color: 255 255 255;
  @apply bg-gradient-to-r from-primary to-secondary border-white/20 before:absolute before:h-full before:w-full before:inset-0 before:-z-[1] !important;
}
[data-header-styles="gradient"]
  .app-header
  .main-header-container
  .header-link-icon {
  @apply hover:bg-white/20 text-white/60 fill-black/60 !important;
}
[data-header-styles="gradient"] .app-header .animated-arrow span {
  @apply bg-white/60;
}
[data-header-styles="gradient"] .app-header .animated-arrow span:before,
[data-header-styles="gradient"] .app-header .animated-arrow span:after {
  @apply bg-white/60;
}
[data-header-styles="gradient"] .app-header .sidebar-toggle {
  @apply bg-transparent hover:bg-black/20 text-white/60 hover:text-white/20 focus:ring-transparent focus:ring-offset-transparent;
}
[data-header-styles="gradient"] .app-header .sidebar-toggle svg {
  @apply fill-white/60;
}
[data-header-styles="gradient"] .app-header .ti-dropdown-toggle {
  @apply bg-transparent hover:bg-black/20 border-white/20 text-white/60 hover:text-white/20 focus:ring-transparent focus:ring-offset-transparent;
}
[data-header-styles="gradient"] .app-header .ti-dropdown-toggle svg {
  @apply fill-white/60;
}
[data-header-styles="gradient"] .app-header .dropdown-profile {
  @apply focus:ring-gray-400;
}
[data-header-styles="gradient"] .app-header .dropdown-profile img {
  @apply ring-white;
}
[data-header-styles="gradient"] .app-header .dropdown-profile p {
  @apply text-white/60 !important;
}
[data-header-styles="gradient"] .app-header .dropdown-profile span {
  @apply text-white/60 !important;
}
[data-header-styles="gradient"] .app-header #dropdown-flag {
  @apply focus:ring-white/20;
}
[data-header-styles="gradient"] .app-header .header-search button,
[data-header-styles="gradient"] .app-header .switcher-icon button {
  @apply bg-transparent hover:bg-white/20 text-white/60 hover:text-white/60 focus:ring-black/20 focus:ring-offset-black/20;
}
[data-header-styles="gradient"] .app-header .header-search svg,
[data-header-styles="gradient"] .app-header .switcher-icon svg {
  @apply fill-white/60;
}
[data-header-styles="gradient"] .app-header .header-theme-mode a,
[data-header-styles="gradient"] .app-header .header-fullscreen a {
  @apply bg-transparent hover:bg-white/20 text-white/60 hover:text-white/60 focus:ring-black/20 focus:ring-offset-black/20;
}
[data-header-styles="gradient"] .app-header .header-theme-mode svg,
[data-header-styles="gradient"] .app-header .header-fullscreen svg {
  @apply fill-white/60;
}
@media (min-width: 992px) {
  [data-header-styles="gradient"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-header-styles="gradient"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply hidden !important;
  }
  [data-header-styles="gradient"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply block !important;
  }
}
@media (max-width: 991.98px) {
  [data-header-styles="gradient"]
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-header-styles="gradient"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-header-styles="gradient"]
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply block;
  }
}

[data-header-styles="light"] .app-header {
  @apply bg-white border-[#f3f3f3] !important;
}
[data-header-styles="light"]
  .app-header
  .main-header-container
  .header-link-icon {
  @apply hover:bg-white/20 text-[#536485] fill-black/60 !important;
}
[data-header-styles="light"] .app-header .animated-arrow span {
  @apply bg-[#536485];
}
[data-header-styles="light"] .app-header .animated-arrow span:before,
[data-header-styles="light"] .app-header .animated-arrow span:after {
  @apply bg-[#536485] !important;
}
[data-header-styles="light"] .app-header .sidebar-toggle {
  @apply bg-transparent text-[#536485] hover:bg-gray-50 focus:ring-0 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white !important;
}
[data-header-styles="light"] .app-header .ti-dropdown-toggle {
  @apply bg-transparent text-[#536485] hover:bg-gray-50 border-gray-200 focus:ring-offset-white focus:ring-primary !important;
}
[data-header-styles="light"] .app-header .dropdown-profile {
  @apply focus:ring-gray-400;
}
[data-header-styles="light"] .app-header .dropdown-profile img {
  @apply ring-white;
}
[data-header-styles="light"] .app-header .dropdown-profile p {
  @apply text-[#536485] !important;
}
[data-header-styles="light"] .app-header .dropdown-profile span {
  @apply text-[#536485] !important;
}
[data-header-styles="light"] .app-header #dropdown-flag {
  @apply focus:ring-gray-400;
}
[data-header-styles="light"] .app-header .header-search button,
[data-header-styles="light"] .app-header .switcher-icon button {
  @apply bg-transparent text-[#536485] hover:bg-gray-50 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white !important;
}
[data-header-styles="light"] .app-header .header-theme-mode a,
[data-header-styles="light"] .app-header .header-fullscreen a {
  @apply bg-transparent text-[#536485] hover:bg-gray-50 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white !important;
}
@media screen and (min-width: 992px) {
  [data-header-styles="light"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white,
  [data-header-styles="light"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply hidden !important;
  }
  [data-header-styles="light"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-logo {
    @apply block !important;
  }
}

[data-header-styles="transparent"] .app-header {
  @apply bg-bodybg border-b border-black/[0.07] !important;
}
[data-header-styles="transparent"] .app-header .sidebar-toggle {
  @apply bg-transparent text-[#536485] focus:ring-0 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white;
}
[data-header-styles="transparent"] .app-header .ti-dropdown-toggle {
  @apply bg-transparent text-[#536485] border-gray-200 focus:ring-offset-white focus:ring-primary;
}
[data-header-styles="transparent"] .app-header .dropdown-profile {
  @apply focus:ring-gray-400;
}
[data-header-styles="transparent"] .app-header .dropdown-profile img {
  @apply ring-white;
}
[data-header-styles="transparent"] .app-header #dropdown-flag {
  @apply focus:ring-gray-400;
}
[data-header-styles="transparent"] .app-header .header-search button,
[data-header-styles="transparent"] .app-header .switcher-icon button {
  @apply bg-transparent text-[#536485] focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white;
}
[data-header-styles="transparent"] .app-header .header-theme-mode a,
[data-header-styles="transparent"] .app-header .header-fullscreen a {
  @apply bg-transparent text-[#536485] focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white;
}
@media (min-width: 992px) {
  [data-header-styles="transparent"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-dark,
  [data-header-styles="transparent"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-header-styles="transparent"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-logo {
    @apply block;
  }
}
@media (max-width: 991.98px) {
  [data-header-styles="transparent"]
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-dark,
  [data-header-styles="transparent"]
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-header-styles="transparent"]
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-logo {
    @apply block;
  }
}
[data-header-styles="transparent"].dark .app-header {
  --header-prime-color: 255 255 255 0.6;
  @apply bg-bodybg2 border-b border-b-white/10 !important;
}
[data-header-styles="transparent"].dark .app-header .sidebar-toggle {
  @apply hover:bg-black/20 text-white/60 hover:text-white focus:ring-white/10 focus:ring-offset-white/10 !important;
}
[data-header-styles="transparent"].dark .app-header .sidebar-toggle svg {
  @apply fill-white;
}
[data-header-styles="transparent"].dark .app-header .ti-dropdown-toggle {
  @apply hover:bg-black/20 border-white/10 text-white/60 hover:text-white focus:ring-offset-white/10 !important;
}
[data-header-styles="transparent"].dark .app-header .ti-dropdown-toggle svg {
  @apply fill-white;
}
[data-header-styles="transparent"].dark .app-header .dropdown-profile {
  @apply focus:ring-gray-400;
}
[data-header-styles="transparent"].dark .app-header .dropdown-profile img {
  @apply ring-white;
}
[data-header-styles="transparent"].dark .app-header .dropdown-profile p {
  @apply text-white/60 !important;
}
[data-header-styles="transparent"].dark .app-header .dropdown-profile span {
  @apply text-white/60 !important;
}
[data-header-styles="transparent"].dark .app-header #dropdown-flag {
  @apply focus:ring-white/10;
}
[data-header-styles="transparent"].dark .app-header .header-search button,
[data-header-styles="transparent"].dark .app-header .switcher-icon button {
  @apply hover:bg-black/20 text-white/60 hover:text-white focus:ring-white/10 focus:ring-offset-white/10 !important;
}
[data-header-styles="transparent"].dark .app-header .header-search button svg,
[data-header-styles="transparent"].dark .app-header .switcher-icon button svg {
  @apply fill-white;
}
[data-header-styles="transparent"].dark .app-header .header-theme-mode a,
[data-header-styles="transparent"].dark .app-header .header-fullscreen a {
  @apply hover:bg-black/20 text-white/60 hover:text-white focus:ring-white/10 focus:ring-offset-white/10 !important;
}
[data-header-styles="transparent"].dark .app-header .header-theme-mode a svg,
[data-header-styles="transparent"].dark .app-header .header-fullscreen a svg {
  @apply fill-white;
}
@media (min-width: 992px) {
  [data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply block;
  }
}
@media (min-width: 992px) {
  [data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-dark {
    @apply hidden;
  }
  [data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply block;
  }
}

/* End Header Styles */
@media (min-width: 992px) {
  [data-nav-layout="horizontal"] {
    /* horizontal arrows */
  }
  [data-nav-layout="horizontal"] .simplebar-track.simplebar-horizontal {
    @apply hidden;
  }
  [data-nav-layout="horizontal"] .app-sidebar {
    @apply h-[3rem];
  }
  [data-nav-layout="horizontal"] .sidemenu-layout-styles {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"] .main-menu {
    @apply flex !important;
    @apply transition-all ease-linear duration-[0.5s] flex-nowrap;
  }
  [data-nav-layout="horizontal"] .main-menu-container {
    @apply inline-flex;
  }
  [data-nav-layout="horizontal"] .side-menu__label .badge {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"] .mega-menu {
    @apply columns-3;
  }
  [data-nav-layout="horizontal"] .app-header {
    @apply ps-0 z-[50] shadow-none !important;
  }
  [data-nav-layout="horizontal"] .content {
    @apply mx-auto mt-[6.7rem] start-0 min-h-[calc(100vh-10.5rem)] w-full !important;
  }
  [data-nav-layout="horizontal"] .content > .container-fluid {
    @apply w-[94%] ms-auto me-auto;
  }
  [data-nav-layout="horizontal"] .content .main-content {
    @apply w-[94%] mx-auto;
  }
  [data-nav-layout="horizontal"] .app-sidebar .main-sidebar,
  [data-nav-layout="horizontal"] .app-sidebar .simplebar-mask {
    @apply overflow-visible;
  }
  [data-nav-layout="horizontal"] .app-sidebar .main-menu > .slide {
    @apply my-0 mx-[0.1875rem];
  }
  [data-nav-layout="horizontal"] .app-sidebar .main-sidebar {
    @apply shadow-none;
  }
  [data-nav-layout="horizontal"] .app-sidebar .simplebar-content-wrapper {
    @apply overflow-visible h-auto !important;
  }
  [data-nav-layout="horizontal"] .main-sidebar .simplebar-vertical {
    @apply invisible overflow-hidden !important;
  }
  [data-nav-layout="horizontal"] .main-sidebar,
  [data-nav-layout="horizontal"] .main-header-container {
    @apply w-[94%] my-0 mx-auto;
  }
  [data-nav-layout="horizontal"] .horizontal-logo {
    @apply py-[0.85rem] px-0 block;
  }
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo img {
    @apply h-8 leading-8;
  }
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo .desktop-logo {
    @apply block;
  }
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo .desktop-dark,
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo .toggle-logo,
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo .toggle-dark,
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo .desktop-white,
  [data-nav-layout="horizontal"] .horizontal-logo .header-logo .toggle-white {
    @apply hidden;
  }
  [data-nav-layout="horizontal"] [data-header-styles="dark"] .horizontal-logo {
    @apply py-[0.85rem] px-0 block;
  }
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    img {
    @apply h-8 leading-8;
  }
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-dark {
    @apply block !important;
  }
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-logo,
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-logo,
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-dark,
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .desktop-white,
  [data-nav-layout="horizontal"]
    [data-header-styles="dark"]
    .horizontal-logo
    .header-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"] .main-header-container .sidemenu-toggle {
    @apply hidden;
  }
  [data-nav-layout="horizontal"] .app-sidebar {
    @apply w-full h-12 top-[3.75rem] !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child1 li,
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child2 li,
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child3 li {
    @apply ps-2;
  }
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__item:before,
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__item:before,
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .side-menu__item:before {
    @apply top-[0.938rem] start-3 !important;
  }
  [data-nav-layout="horizontal"]
    .app-sidebar
    .app-sidebar
    .slide-menu.child1
    .side-menu__item,
  [data-nav-layout="horizontal"]
    .app-sidebar
    .app-sidebar
    .slide-menu.child2
    .side-menu__item {
    @apply py-[0.45rem] px-[1.6rem] !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .simplebar-content {
    @apply p-0 overflow-hidden !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .simplebar-content-wrapper {
    @apply overflow-visible h-auto !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .main-sidebar {
    @apply h-12 p-0 !important;
  }
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply start-6 p-0 rounded-b-sm shadow-[.063rem_.188rem_.5rem_rgba(0,0,0,0.1)]  dark:shadow-[.063rem_.188rem_.5rem_rgba(255,255,255,0.1)] !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply end-[100%] px-0 rounded-sm !important;
  }
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.active {
    @apply start-auto end-auto !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child1 {
    @apply absolute !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .side-menu__item {
    @apply w-full flex rounded-none p-[0.92rem];
  }
  [data-nav-layout="horizontal"] .app-sidebar .side-menu__angle {
    @apply end-[0.45rem] !important;
  }
  [data-nav-layout="horizontal"] .app-sidebar .side-menu__angle {
    @apply block;
  }
  [data-nav-layout="horizontal"] .app-sidebar .side-menu__icon {
    @apply me-1 mb-0;
  }
  [data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply bg-white dark:bg-bodybg top-[100%] min-w-[12rem];
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide {
    @apply p-0;
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child1 .slide.has-sub,
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child1 .slide {
    @apply w-full flex py-0 px-[0.1875rem];
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child2,
  [data-nav-layout="horizontal"] .app-sidebar .slide-menu.child3 {
    @apply rounded-sm;
  }
  [data-nav-layout="horizontal"] .app-sidebar .slide,
  [data-nav-layout="horizontal"] .app-sidebar .slide.has-sub {
    @apply static;
  }
  [data-nav-layout="horizontal"] .app-sidebar .main-menu {
    @apply mb-0;
  }
  [data-nav-layout="horizontal"] .main-sidebar-header {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"] .main-sidebar {
    @apply mt-0 pb-12;
  }
  [data-nav-layout="horizontal"] .slide__category {
    @apply hidden;
  }
  [data-nav-layout="horizontal"] .main-menu-container .slide-left {
    @apply start-[1.438rem];
  }
  [data-nav-layout="horizontal"] .main-menu-container .slide-left,
  [data-nav-layout="horizontal"] .main-menu-container .slide-right {
    @apply absolute top-[0.563rem] p-[0.375rem] flex items-center justify-center z-[1] cursor-pointer border border-solid border-defaultborder rounded-[3.125rem] text-white dark:text-bodybg;
  }
  [data-nav-layout="horizontal"] .main-menu-container .slide-left,
  [data-nav-layout="horizontal"] .main-menu-container .slide-right {
    @apply absolute top-[0.313rem] p-[0.375rem] flex items-center justify-center z-[1] cursor-pointer border border-solid border-defaultborder rounded-[3.125rem] text-white dark:text-bodybg bg-white dark:bg-bodybg;
  }
  [data-nav-layout="horizontal"] .main-menu-container .slide-left svg,
  [data-nav-layout="horizontal"] .main-menu-container .slide-right svg {
    @apply fill-black;
  }
  [data-nav-layout="horizontal"] .main-menu-container .slide-right {
    @apply end-[-3%];
  }
  [data-nav-layout="horizontal"] .main-menu-container .slide-left {
    @apply start-[-3%];
  }
  [data-nav-layout="horizontal"][page-style="classic"] .app-sidebar {
    @apply border-b border-b-defaultborder;
  }
  [data-nav-layout="horizontal"][dir="rtl"] .main-menu-container .slide-right,
  [data-nav-layout="horizontal"][dir="rtl"] .main-menu-container .slide-left {
    @apply rotate-180;
  }
  [data-nav-layout="horizontal"].dark .header-logo .desktop-dark {
    @apply block;
  }
  [data-nav-layout="horizontal"].dark .header-logo .toggle-logo,
  [data-nav-layout="horizontal"].dark .header-logo .desktop-logo,
  [data-nav-layout="horizontal"].dark .header-logo .toggle-logo,
  [data-nav-layout="horizontal"].dark .header-logo .desktop-white,
  [data-nav-layout="horizontal"].dark .header-logo .toggle-white {
    @apply hidden !important;
  }
  [data-nav-layout="horizontal"].dark .app-sidebar {
    @apply border-white/10 !important;
  }
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-bodybg border-white/10 !important;
  }
  [data-nav-layout="horizontal"].dark .app-sidebar .slide.side-menu__label1 {
    @apply border-white/10 text-white !important;
  }
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-black/20 text-white border-white/10;
  }
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-nav-layout="horizontal"].dark
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-white;
  }
  [data-nav-layout="horizontal"] .footer {
    @apply ps-0;
  }
}

@media (min-width: 992px) {
  .icon-click .content,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"] .content,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"] .content {
    @apply ms-[5rem];
  }
  .icon-click .app-sidebar,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"] .app-sidebar,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"] .app-sidebar {
    @apply w-20;
  }
  .icon-click .app-sidebar .main-sidebar,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar {
    @apply overflow-visible h-[90%];
  }
  .icon-click .app-sidebar .main-sidebar-header,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header {
    @apply w-20;
  }
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply block;
  }
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  .icon-click .app-sidebar .category-name,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .category-name,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .category-name,
  .icon-click .app-sidebar .side-menu__label,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__label,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .side-menu__label,
  .icon-click .app-sidebar .side-menu__angle,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__angle,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  .icon-click .app-sidebar .side-menu__icon,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__icon,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .side-menu__icon {
    @apply me-0;
  }
  .icon-click .app-sidebar .slide__category,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide__category,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide__category {
    @apply py-[1.2rem] px-[1.65rem] relative;
  }
  .icon-click .app-sidebar .slide__category:before,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide__category:before,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide__category:before {
    @apply content-[""] absolute start-9 end-0 top-5 bottom-0 w-[0.35rem] rounded-[3.125rem] h-[0.35rem] border border-[#536485] opacity-[1];
  }
  .icon-click .app-sidebar .simplebar-content-wrapper,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-content-wrapper,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .simplebar-content-wrapper {
    position: initial;
  }
  .icon-click .app-sidebar .simplebar-mask,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-mask,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .simplebar-mask {
    position: inherit;
  }
  .icon-click .app-sidebar .simplebar-placeholder,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-placeholder,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .simplebar-placeholder {
    @apply h-auto !important;
  }
  .icon-click .app-header,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"] .app-header,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"] .app-header {
    @apply ps-20;
  }
  .icon-click .content,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"] .content,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"] .content {
    @apply ms-20;
  }
  .icon-click .slide.side-menu__label1,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.side-menu__label1,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide.side-menu__label1 {
    @apply block py-2 px-4 border-b border-b-defaultborder !important;
  }
  .icon-click .slide.has-sub .slide-menu,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide.has-sub
    .slide-menu {
    @apply absolute start-20  rounded-e-md top-auto transition-none shadow-[0.125rem_0.063rem_0.5rem_rgba(0,0,0,0.1)] dark:shadow-[0.125rem_0.063rem_0.5rem_rgba(255,255,255,0.1)] !important;
  }
  .icon-click .slide.has-sub .slide-menu.child2,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide.has-sub
    .slide-menu.child2,
  .icon-click .slide.has-sub .slide-menu.child3,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide.has-sub
    .slide-menu.child3 {
    @apply start-48 !important;
  }
  .icon-click .slide-menu.child1,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child1,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child1,
  .icon-click .slide-menu.child2,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child2,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child2,
  .icon-click .slide-menu.child3,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child3,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child3 {
    @apply min-w-[12rem];
  }
  .icon-click .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child1
    .slide
    .side-menu__item,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child1
    .slide
    .side-menu__item,
  .icon-click .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child2
    .slide
    .side-menu__item,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child2
    .slide
    .side-menu__item,
  .icon-click .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child3
    .slide
    .side-menu__item,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child3
    .slide
    .side-menu__item {
    @apply text-start;
  }
  .icon-click .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  .icon-click .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  .icon-click .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child3
    .slide
    .side-menu__item:before,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child3
    .slide
    .side-menu__item:before {
    @apply top-[0.938rem] start-3;
  }
  .icon-click .slide-menu.child1 .side-menu__angle,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child1
    .side-menu__angle,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child1
    .side-menu__angle,
  .icon-click .slide-menu.child2 .side-menu__angle,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child2
    .side-menu__angle,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child2
    .side-menu__angle,
  .icon-click .slide-menu.child3 .side-menu__angle,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide-menu.child3
    .side-menu__angle,
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .slide-menu.child3
    .side-menu__angle {
    @apply block end-4 top-[0.65rem];
  }
}

@media (min-width: 992px) {
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu {
    @apply absolute start-20 transition-none shadow-[0.125rem_0.063rem_0.5rem_rgba(0,0,0,0.1)] dark:shadow-[0.125rem_0.063rem_0.5rem_rgba(255,255,255,0.1)] !important;
  }
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child1 {
    @apply top-full !important;
  }
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-style="icon-click"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child3 {
    @apply start-48 !important;
  }
  [data-nav-style="icon-click"][data-nav-layout="horizontal"] .mega-menu {
    @apply columns-1;
  }
}

@media (min-width: 992px) {
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .main-menu
    > .slide {
    @apply py-0 px-[1.2rem];
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide
    .slide-menu.child1,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide
    .slide-menu.child2,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide
    .slide-menu.child3 {
    @apply rounded-e-md rounded-s-none;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply block;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply hidden;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply block !important;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar {
    @apply absolute !important;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide-menu.child1
    li.slide,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide-menu.child2
    li.slide,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide-menu.child3
    li.slide {
    @apply ps-0;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide-menu.child1
    li.slide
    a,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide-menu.child2
    li.slide
    a,
  [data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .app-sidebar
    .slide-menu.child3
    li.slide
    a {
    @apply rounded-none;
  }
}

@media (min-width: 992px) {
  [data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .content {
    @apply min-h-[90rem];
  }
}
@media (min-width: 992px) {
  .icon-hover .content,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"] .content,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] .content {
    @apply ms-[5rem];
  }
  .icon-hover .app-sidebar,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"] .app-sidebar,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] .app-sidebar {
    @apply w-20;
  }
  .icon-hover .app-sidebar .main-sidebar,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar {
    @apply overflow-visible h-[90%];
  }
  .icon-hover .app-sidebar .main-sidebar-header,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header {
    @apply w-20;
  }
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo {
    @apply block;
  }
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  .icon-hover .app-sidebar .category-name,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .category-name,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .category-name,
  .icon-hover .app-sidebar .side-menu__label,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .side-menu__label,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .side-menu__label,
  .icon-hover .app-sidebar .side-menu__angle,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .side-menu__angle,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  .icon-hover .app-sidebar .side-menu__icon,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .side-menu__icon,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .side-menu__icon {
    @apply me-0;
  }
  .icon-hover .app-sidebar .slide__category,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide__category,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide__category {
    @apply py-[1.2rem] px-[1.65rem] relative;
  }
  .icon-hover .app-sidebar .slide__category:before,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide__category:before,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide__category:before {
    @apply content-[""] absolute start-[2.4rem] end-0 top-5 bottom-0 w-[0.35rem] rounded-[3.125rem] h-[0.35rem] border border-[#536485] opacity-[1];
  }
  .icon-hover .app-sidebar .simplebar-content-wrapper,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .simplebar-content-wrapper,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .simplebar-content-wrapper {
    position: initial;
  }
  .icon-hover .app-sidebar .simplebar-mask,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .simplebar-mask,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .simplebar-mask {
    position: inherit;
  }
  .icon-hover .app-sidebar .simplebar-placeholder,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .simplebar-placeholder,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .simplebar-placeholder {
    @apply h-auto !important;
  }
  .icon-hover .app-header,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"] .app-header,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] .app-header {
    @apply ps-20;
  }
  .icon-hover .content,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"] .content,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] .content {
    @apply ms-20;
  }
  .icon-hover .slide.side-menu__label1,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.side-menu__label1,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.side-menu__label1 {
    @apply block py-2 px-4 border-b border-b-defaultborder !important;
  }
  .icon-hover .slide.has-sub .slide-menu,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.has-sub
    .slide-menu,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub
    .slide-menu {
    @apply absolute start-20  rounded-e-md dark:bg-bodybg top-auto transition-none shadow-[0.125rem_0.063rem_0.5rem_rgba(0,0,0,0.1)] dark:shadow-[0.125rem_0.063rem_0.5rem_rgba(255,255,255,0.1)] !important;
  }
  .icon-hover .slide.has-sub .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub
    .slide-menu.child2,
  .icon-hover .slide.has-sub .slide-menu.child3,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub
    .slide-menu.child3 {
    @apply start-[11.8rem] !important;
  }
  .icon-hover .slide-menu.child1,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child1,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child1,
  .icon-hover .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child2,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child2,
  .icon-hover .slide-menu.child3,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child3,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child3 {
    @apply min-w-[12rem];
  }
  .icon-hover .slide-menu.child1 .slide .side-menu__item,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child1
    .slide
    .side-menu__item,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child1
    .slide
    .side-menu__item,
  .icon-hover .slide-menu.child2 .slide .side-menu__item,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child2
    .slide
    .side-menu__item,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child2
    .slide
    .side-menu__item,
  .icon-hover .slide-menu.child3 .slide .side-menu__item,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child3
    .slide
    .side-menu__item,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child3
    .slide
    .side-menu__item {
    @apply text-start pe-0 !important;
  }
  .icon-hover .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  .icon-hover .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  .icon-hover .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child3
    .slide
    .side-menu__item:before,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child3
    .slide
    .side-menu__item:before {
    @apply top-[0.938rem] start-3;
  }
  .icon-hover .slide-menu.child1 .side-menu__angle,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child1
    .side-menu__angle,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child1
    .side-menu__angle,
  .icon-hover .slide-menu.child2 .side-menu__angle,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child2
    .side-menu__angle,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child2
    .side-menu__angle,
  .icon-hover .slide-menu.child3 .side-menu__angle,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide-menu.child3
    .side-menu__angle,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide-menu.child3
    .side-menu__angle {
    @apply block end-4 top-[0.65rem];
  }
  .icon-hover .slide.has-sub:hover .slide-menu.child1,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.has-sub:hover
    .slide-menu.child1,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub:hover
    .slide-menu.child1 {
    @apply block !important;
  }
  .icon-hover
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2 {
    @apply block !important;
  }
  .icon-hover
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2
    .slide.has-sub:hover
    .slide-menu.child3,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2
    .slide.has-sub:hover
    .slide-menu.child3,
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2
    .slide.has-sub:hover
    .slide-menu.child3 {
    @apply block !important;
  }
}

@media (min-width: 992px) {
  [data-nav-style="icon-hover"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu {
    @apply absolute start-20 transition-none shadow-[0.125rem_0.063rem_0.5rem_rgba(0,0,0,0.1)] dark:shadow-[0.125rem_0.063rem_0.5rem_rgba(255,255,255,0.1)] !important;
  }
  [data-nav-style="icon-hover"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child1 {
    @apply top-full !important;
  }
  [data-nav-style="icon-hover"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-style="icon-hover"][data-nav-layout="horizontal"]
    .slide.has-sub
    .slide-menu.child3 {
    @apply start-48 !important;
  }
  [data-nav-style="icon-hover"][data-nav-layout="horizontal"] .mega-menu {
    @apply columns-1;
  }
}

@media (min-width: 992px) {
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .main-menu
    > .slide {
    @apply py-0 px-[1.2rem];
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide
    .slide-menu.child1,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide
    .slide-menu.child2,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide
    .slide-menu.child3 {
    @apply rounded-e-md rounded-s-none;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply block;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply hidden;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark[data-menu-styles="light"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"].dark[data-menu-styles="transparent"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply block !important;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar {
    @apply absolute !important;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child1,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child2,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child3 {
    @apply p-[0.1875rem];
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child1
    li.slide,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child2
    li.slide,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child3
    li.slide {
    @apply ps-0;
  }
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child1
    li.slide
    a,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child2
    li.slide
    a,
  [data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .app-sidebar
    .slide-menu.child3
    li.slide
    a {
    @apply rounded-none;
  }
}

@media (min-width: 992px) {
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .content {
    @apply min-h-[90rem];
  }
}
/* Start Icon-Overlay Styles */
@media screen and (min-width: 992px) {
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar {
    @apply w-[5rem];
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .main-sidebar-header {
    @apply w-[5rem] !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar {
    @apply w-[5rem];
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .main-sidebar-header {
    @apply w-[5rem] !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .app-header {
    @apply ps-[5rem] !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__item {
    @apply justify-center px-0;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__label,
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__icon {
    @apply me-0 !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .slide__category {
    @apply py-[1.2rem] px-[1.65rem] relative  before:absolute before:start-[2.25rem] before:end-0 before:top-[1.25rem]  before:w-[0.35rem] before:rounded-[3.125rem] before:h-[0.35rem] before:border before:border-solid before:opacity-[1] !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .slide__category
    .category-name {
    @apply hidden;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .side-menu__item {
    @apply p-3 !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .header-logo
    .desktop-logo,
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .header-logo
    .desktop-dark,
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .header-logo
    .toggle-logo {
    @apply block;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .header-logo
    .toggle-dark,
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    )
    .app-sidebar
    .slide-menu {
    @apply hidden !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-logo.toggle-logo,
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-logo
    .toggle-white {
    @apply hidden !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
      [data-icon-overlay="open"]
    ).dark
    .app-sidebar
    .main-logo.toggle-dark {
    @apply block !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"] .content {
    @apply ms-[5rem] !important;
  }
  [data-vertical-style="overlay"][data-toggled="icon-overlay-close"]
    .app-header {
    @apply ps-[5rem] !important;
  }
}

/* End Icon-Overlay Styles */
/* Start IconText-Menu Styles */
@media screen and (min-width: 992px) {
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar {
    @apply w-[9.5rem] !important;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .slide__category,
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .side-menu__item {
    @apply block text-center text-xs px-0 !important;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .side-menu__item
    .side-menu__icon {
    @apply mx-auto mb-2 w-full block;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply hidden px-0 !important;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .app-sidebar
    .slide {
    @apply px-[0.25rem] !important;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header {
    @apply w-[9.5rem] !important;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-logo,
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white,
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white {
    @apply hidden;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark {
    @apply block;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark {
    @apply hidden;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    )
    .app-sidebar
    .main-menu {
    @apply px-0;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    ).dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-logo,
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    ).dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-dark,
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    ).dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .toggle-white {
    @apply hidden;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
      [data-icon-text="open"]
    ).dark
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-dark {
    @apply block;
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"] .content {
    @apply ms-[9.5rem];
  }
  [data-vertical-style="icontext"][data-toggled="icon-text-close"] .app-header {
    @apply ps-[9.5rem];
  }
}

/* End IconText-Menu Styles */
/* Loader Styles */
[loader="disable"] #loader {
  @apply hidden;
}

/* Loader Styles */
/* Start:: Loader */
#loader {
  @apply fixed top-0 start-0 w-full h-full bg-white dark:bg-bodybg2 flex justify-center items-center z-[9999];
}

/* End:: Loader */
@media (min-width: 992px) {
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__item {
    @apply flex items-center px-[1.6rem] !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__icon {
    @apply mb-0 me-[0.35rem] !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__angle {
    @apply block !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply start-auto !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.active {
    @apply start-auto end-auto !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply top-[100%] !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="horizontal"] .app-header {
    @apply ps-[9.5rem];
  }
}

[data-nav-style="menu-click"][data-toggled="menu-click-closed"] .app-header {
  @apply ps-[9.5rem];
}

@media (min-width: 992px) {
  [data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
    .app-sidebar {
    @apply absolute !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
    .app-sidebar
    .side-menu__item {
    @apply rounded-md mt-1 mx-1 mb-0 !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
    .app-sidebar
    .side-menu__item:last-child {
    @apply m-1 !important;
  }
  [data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide
    .slide-menu.child1,
  [data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide
    .slide-menu.child2,
  [data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide
    .slide-menu.child3 {
    @apply rounded-e-md !important;
  }
}

@media (min-width: 992px) {
  .menu-click .content,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"] .content,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"] .content {
    @apply ms-[9.5rem];
  }
  .menu-click .app-sidebar,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"] .app-sidebar,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"] .app-sidebar {
    @apply w-[9.5rem];
  }
  .menu-click .app-sidebar .main-sidebar,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .main-sidebar {
    @apply overflow-visible h-[90%];
  }
  .menu-click .app-sidebar .main-sidebar-header,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .main-sidebar-header {
    @apply w-[9.5rem];
  }
  .menu-click .app-sidebar .side-menu__icon,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__icon,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .side-menu__icon {
    @apply me-0 mb-2;
  }
  .menu-click .app-sidebar .slide,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide {
    @apply p-0;
  }
  .menu-click .app-sidebar .slide-menu.child1,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child1,
  .menu-click .app-sidebar .slide-menu.child2,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child2,
  .menu-click .app-sidebar .slide-menu.child3,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child3 {
    @apply min-w-[12rem];
  }
  .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item,
  .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item,
  .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item {
    @apply text-start;
  }
  .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item:before,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item:before {
    @apply top-[0.938rem] start-3;
  }
  .menu-click .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__angle,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__angle,
  .menu-click .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__angle,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__angle,
  .menu-click .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .side-menu__angle,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child3
    .side-menu__angle {
    @apply block end-4 top-[0.65rem];
  }
  .menu-click .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .slide.side-menu__label1,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child1
    .slide.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .slide.side-menu__label1,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child2
    .slide.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .slide.side-menu__label1,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide-menu.child3
    .slide.side-menu__label1 {
    @apply hidden;
  }
  .menu-click .app-sidebar .slide__category,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide__category,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide__category,
  .menu-click .app-sidebar .side-menu__angle,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__angle,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  .menu-click .app-sidebar .side-menu__item,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__item,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .side-menu__item,
  .menu-click .app-sidebar .side-menu__label,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__label,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .side-menu__label {
    @apply block text-center;
  }
  .menu-click .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply absolute start-[9.5rem]  top-auto  transition-none shadow-[0.125rem_0.063rem_0.5rem_rgba(0,0,0,0.1)] dark:shadow-[0.125rem_0.063rem_0.5rem_rgba(255,255,255,0.1)] !important;
  }
  .menu-click .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  .menu-click .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply start-48 !important;
  }
  .menu-click .app-sidebar .simplebar-content-wrapper,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-content-wrapper,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .simplebar-content-wrapper {
    position: initial;
  }
  .menu-click .app-sidebar .simplebar-mask,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-mask,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .simplebar-mask {
    position: inherit;
  }
  .menu-click .app-sidebar .simplebar-placeholder,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-placeholder,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .app-sidebar
    .simplebar-placeholder {
    @apply h-auto !important;
  }
  .menu-click .content,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"] .content,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"] .content {
    @apply ms-[9.5rem];
  }
}
@media (max-width: 991.98px) {
  .menu-click .app-sidebar,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"] .app-sidebar,
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"] .app-sidebar {
    @apply w-60;
  }
}

@media (min-width: 992px) {
  [data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .content {
    @apply min-h-[100rem];
  }
}
@media (min-width: 992px) {
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__item {
    @apply flex items-center px-[1.6rem] !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__icon {
    @apply mb-0 me-[0.35rem] !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__angle {
    @apply block !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.active {
    @apply start-auto end-auto rounded-none !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply top-[100%] !important;
  }
}

@media (min-width: 992px) {
  [data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"]
    .app-sidebar {
    @apply absolute !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .side-menu__item {
    @apply rounded-md mt-1 mb-0 mx-1 !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .side-menu__item:last-child {
    @apply m-1 !important;
  }
  [data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide
    .slide-menu.child1,
  [data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide
    .slide-menu.child2,
  [data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide
    .slide-menu.child3 {
    @apply rounded-e-md rounded-s-none;
  }
}

@media (min-width: 992px) {
  .menu-hover .content,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"] .content,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] .content {
    @apply ms-[9.5rem];
  }
  .menu-hover .app-sidebar,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"] .app-sidebar,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] .app-sidebar {
    @apply w-[9.5rem];
  }
  .menu-hover .app-sidebar .main-sidebar,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .main-sidebar {
    @apply overflow-visible h-[90%];
  }
  .menu-hover .app-sidebar .main-sidebar-header,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-sidebar-header,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .main-sidebar-header {
    @apply w-[9.5rem];
  }
  .menu-hover .app-sidebar .side-menu__icon,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__icon,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .side-menu__icon {
    @apply me-0 mb-2;
  }
  .menu-hover .app-sidebar .slide,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide {
    @apply p-0;
  }
  .menu-hover .app-sidebar .slide-menu.child1,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child1,
  .menu-hover .app-sidebar .slide-menu.child2,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child2,
  .menu-hover .app-sidebar .slide-menu.child3,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child3 {
    @apply min-w-[12rem] hidden !important;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item,
  .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item,
  .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item {
    @apply text-start;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child1
    .slide
    .side-menu__item:before,
  .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child2
    .slide
    .side-menu__item:before,
  .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item:before,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child3
    .slide
    .side-menu__item:before {
    @apply top-[0.938rem] start-3;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .slide.side-menu__label1,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child1
    .slide.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .slide.side-menu__label1,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child2
    .slide.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .slide.side-menu__label1,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child3
    .slide.side-menu__label1 {
    @apply hidden;
  }
  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1 {
    @apply block !important;
  }
  .menu-hover
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2 {
    @apply block !important;
  }
  .menu-hover
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2
    .slide.has-sub:hover
    .slide-menu.child3,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2
    .slide.has-sub:hover
    .slide-menu.child3,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub:hover
    .slide-menu.child1
    .slide.has-sub:hover
    .slide-menu.child2
    .slide.has-sub:hover
    .slide-menu.child3 {
    @apply block !important;
  }
  .menu-hover .app-sidebar .slide__category,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide__category,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide__category,
  .menu-hover .app-sidebar .side-menu__angle,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__angle,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .side-menu__angle {
    @apply hidden;
  }
  .menu-hover .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__angle,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__angle,
  .menu-hover .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__angle,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__angle,
  .menu-hover .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child3
    .side-menu__angle,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide-menu.child3
    .side-menu__angle {
    @apply block end-4 top-[0.65rem];
  }
  .menu-hover .app-sidebar .side-menu__item,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__item,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .side-menu__item,
  .menu-hover .app-sidebar .side-menu__label,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .side-menu__label,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .side-menu__label {
    @apply block text-center;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply absolute start-[9.5rem]   top-auto transition-none rounded-e-md shadow-[0.125rem_0.063rem_0.5rem_rgba(0,0,0,0.1)] dark:shadow-[0.125rem_0.063rem_0.5rem_rgba(255,255,255,0.1)] !important;
  }
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply start-48 !important;
  }
  .menu-hover .app-sidebar .simplebar-content-wrapper,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-content-wrapper,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .simplebar-content-wrapper {
    position: initial;
  }
  .menu-hover .app-sidebar .simplebar-mask,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-mask,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .simplebar-mask {
    position: inherit;
  }
  .menu-hover .app-sidebar .simplebar-placeholder,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"]
    .app-sidebar
    .simplebar-placeholder,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .simplebar-placeholder {
    @apply h-auto !important;
  }
  .menu-hover .app-header,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"] .app-header,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] .app-header {
    @apply ps-[9.5rem];
  }
  .menu-hover .content,
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"] .content,
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] .content {
    @apply ms-[9.5rem];
  }
}

@media (min-width: 992px) {
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .content {
    @apply min-h-[100rem];
  }
}
/* Start Menu-Scrollable Styles */
[data-menu-position="scrollable"] .app-sidebar {
  @apply absolute;
}
[data-menu-position="scrollable"] .app-sidebar .main-sidebar-header {
  position: absolute;
}
[data-menu-position="scrollable"] .app-sidebar #sidebar-scroll {
  @apply pb-0;
}
[data-menu-position="scrollable"]:not([data-nav-layout="horizontal"]) .content {
  @apply min-h-[1300px];
}

/* End Menu-Scrollable Styles */
/* Start Menu Styles */
[data-menu-styles="dark"] .app-sidebar {
  @apply bg-[#111c43] border-white/10;
}
[data-menu-styles="dark"] .app-sidebar .main-sidebar-header {
  @apply backdrop-blur-3xl;
}
[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header
  .header-logo.desktop-logo,
[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-white {
  @apply hidden !important;
}
[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header
  .header-logo.desktop-dark {
  @apply block !important;
}
[data-menu-styles="dark"]
  .app-sidebar
  .main-sidebar-header
  .slide.has-sub
  .slide-menu {
  @apply bg-[#111c43] !important;
}
[data-menu-styles="dark"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo.toggle-logo {
  @apply lg:hidden;
}
[data-menu-styles="dark"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo.toggle-dark {
  @apply lg:block;
}
[data-menu-styles="dark"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo.toggle-logo {
  @apply lg:hidden !important;
}
[data-menu-styles="dark"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo.toggle-dark {
  @apply lg:block !important;
}
@media (min-width: 992px) {
  [data-menu-styles="dark"][class="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][class="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][class="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][class="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][class="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header {
    @apply bg-bodybg;
  }
  [data-menu-styles="dark"][class="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][class="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][class="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][class="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][class="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-bodybg !important;
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .main-sidebar-header {
    @apply bg-[#111c43];
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo {
    @apply lg:hidden !important;
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply lg:block !important;
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-[#111c43] border-white/10 !important;
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2 {
    @apply bg-[#111c43] border-white/10 !important;
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/10 text-white !important;
  }
  [data-menu-styles="dark"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]
    .dark
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .dark
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
    .dark
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .dark
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="dark"][data-vertical-style="doublemenu"]
    .dark
    .slide.has-sub
    .slide-menu {
    @apply bg-bodybg border-white/10 !important;
  }
  [data-menu-styles="dark"][data-nav-layout="horizontal"] .app-sidebar {
    @apply border-white/10 !important;
  }
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-[#111c43] border-white/10;
  }
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/10 text-white !important;
  }
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-black/20 text-white border-white/10;
  }
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="dark"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-white;
  }
}
[data-menu-styles="dark"].dark .app-sidebar {
  @apply bg-bodybg !important;
}
[data-menu-styles="dark"].dark .main-sidebar-header {
  @apply bg-bodybg !important;
}

[data-menu-styles="color"] .app-sidebar {
  @apply bg-primary border-white/20 !important;
}
[data-menu-styles="color"] .app-sidebar .side-menu__angle {
  @apply text-white/10 !important;
}
[data-menu-styles="color"] .app-sidebar .main-sidebar-header {
  @apply bg-primary backdrop-blur-3xl !important;
}
[data-menu-styles="color"] .app-sidebar .main-sidebar-header .header-logo img {
  @apply h-8 leading-8;
}
[data-menu-styles="color"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-white {
  @apply block;
}
[data-menu-styles="color"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-logo,
[data-menu-styles="color"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-logo,
[data-menu-styles="color"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-dark,
[data-menu-styles="color"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-dark {
  @apply hidden;
}
[data-menu-styles="color"] .app-sidebar .app-sidebar {
  @apply bg-primary !important;
}
[data-menu-styles="color"] .app-sidebar .side-menu__item {
  @apply text-white/60;
}
[data-menu-styles="color"] .app-sidebar .side-menu__item .side-menu__icon {
  @apply fill-white/60 text-white/60;
}
[data-menu-styles="color"] .app-sidebar .side-menu__item .side-menu__label,
[data-menu-styles="color"] .app-sidebar .side-menu__item .side-menu__angle {
  @apply text-white/60 !important;
}
[data-menu-styles="color"] .app-sidebar .side-menu__item.active {
  @apply text-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__icon {
  @apply fill-white text-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__label,
[data-menu-styles="color"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="color"] .app-sidebar .side-menu__item:hover {
  @apply text-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply fill-white text-white !important;
}
[data-menu-styles="color"] .app-sidebar .side-menu__item.active {
  @apply bg-white/10;
}
[data-menu-styles="color"] .app-sidebar .slide-menu.child1 .side-menu__item,
[data-menu-styles="color"] .app-sidebar .slide-menu.child2 .side-menu__item,
[data-menu-styles="color"] .app-sidebar .slide-menu.child3 .side-menu__item {
  @apply text-white/60 before:border-white/60;
}
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active {
  @apply text-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active::before,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active::before,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active::before {
  @apply border-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover {
  @apply text-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover::before,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover::before,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover::before {
  @apply border-white !important;
}
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="color"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="color"] .app-sidebar .slide__category {
  @apply text-white/50;
}
[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply block !important;
}
[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply hidden !important;
}
[data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply block !important;
}
[data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply hidden !important;
}
[data-menu-styles="color"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="color"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white {
  @apply block !important;
}
[data-menu-styles="color"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="color"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="color"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="color"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark
  .toggle-white {
  @apply hidden !important;
}
@media (min-width: 992px) {
  [data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="color"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="color"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply hidden !important;
  }
  [data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="color"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white {
    @apply block !important;
  }
  [data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="color"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-primary border-white/20 !important;
  }
  [data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="color"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category {
    @apply before:border-white/60 !important;
  }
  [data-menu-styles="color"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="color"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="color"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="color"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="color"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/20 text-white !important;
  }
  [data-menu-styles="color"][data-nav-layout="horizontal"] .app-sidebar {
    @apply border-white/20 !important;
  }
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-primary border-white/20 !important;
  }
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/20 text-white !important;
  }
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-black/20 text-white border-white/10;
  }
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="color"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-white;
  }
}

[data-menu-styles="gradient"] .app-sidebar {
  @apply bg-gradient-to-b from-primary to-secondary border-white/10 before:absolute before:h-full before:w-full before:inset-0 !important;
}
[data-menu-styles="gradient"] .app-sidebar .main-sidebar-header {
  @apply bg-gradient-to-t from-primary to-secondary border-white/10 backdrop-blur-3xl;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header
  .header-logo.desktop-logo,
[data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-dark {
  @apply hidden;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .main-sidebar-header
  .header-logo.desktop-white {
  @apply block !important;
}
[data-menu-styles="gradient"] .app-sidebar .side-menu__item {
  @apply text-white/70 before:text-white/70;
}
[data-menu-styles="gradient"] .app-sidebar .side-menu__item .side-menu__icon {
  @apply fill-white/70 text-white/70;
}
[data-menu-styles="gradient"] .app-sidebar .side-menu__item .side-menu__label {
  @apply text-white/60 !important;
}
[data-menu-styles="gradient"] .app-sidebar .side-menu__item.active {
  @apply text-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__icon {
  @apply fill-white text-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__label {
  @apply text-white !important;
}
[data-menu-styles="gradient"] .app-sidebar .side-menu__item:hover {
  @apply text-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply fill-white text-white !important;
}
[data-menu-styles="gradient"] .app-sidebar .side-menu__item.active {
  @apply bg-white/10;
}
[data-menu-styles="gradient"] .app-sidebar .slide-menu.child1 .side-menu__item,
[data-menu-styles="gradient"] .app-sidebar .slide-menu.child2 .side-menu__item,
[data-menu-styles="gradient"] .app-sidebar .slide-menu.child3 .side-menu__item {
  @apply text-white/70 before:border-white/70;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active {
  @apply text-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active::before,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active::before,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active::before {
  @apply border-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover {
  @apply text-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover::before,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover::before,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover::before {
  @apply border-white !important;
}
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="gradient"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="gradient"] .app-sidebar .slide__category {
  @apply text-white/50;
}
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply block !important;
}
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white,
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply hidden !important;
}
[data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply block !important;
}
[data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply hidden !important;
}
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white {
  @apply block !important;
}
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="gradient"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply hidden !important;
}
@media (min-width: 992px) {
  [data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply hidden !important;
  }
  [data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white {
    @apply block !important;
  }
  [data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category,
  [data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide__category {
    @apply before:border-white/60 !important;
  }
  [data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-gradient-to-b from-primary to-secondary -z-[1] before:-z-[1] before:absolute before:h-full before:w-full before:inset-0 !important;
  }
  [data-menu-styles="gradient"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="gradient"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="gradient"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="gradient"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/20 text-white !important;
  }
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply lg:hidden !important;
  }
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white {
    @apply lg:block !important;
  }
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-gradient-to-b from-primary to-secondary before:-z-[1] before:absolute before:h-full before:w-full before:inset-0 !important;
  }
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-transparent bg-none before:bg-none !important;
  }
  [data-menu-styles="gradient"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/20 text-white !important;
  }
  [data-menu-styles="gradient"][data-nav-layout="horizontal"] .app-sidebar {
    @apply border-white/20 !important;
    @apply bg-gradient-to-r from-primary to-secondary before:absolute before:h-full before:w-full before:inset-0;
  }
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-gradient-to-b from-primary to-secondary border-white/20 !important;
  }
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/20 text-white !important;
  }
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-black/20 text-white border-white/10;
  }
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="gradient"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-white;
  }
}
[data-menu-styles="gradient"] .dark .main-sidebar-header {
  @apply bg-primary !important;
}

[data-menu-styles="light"] .app-sidebar {
  --menu-prime-color: 128 135 147;
  @apply bg-white border-[#f3f3f3] !important;
}
[data-menu-styles="light"] .app-sidebar .slide__category::before {
  @apply border-black/50;
}
[data-menu-styles="light"] .app-sidebar .main-sidebar-header {
  @apply bg-white border-[#f3f3f3] backdrop-blur-3xl !important;
}
[data-menu-styles="light"] .app-sidebar .main-sidebar-header .header-logo img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-logo {
  @apply block;
}
[data-menu-styles="light"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-dark,
[data-menu-styles="light"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-logo,
[data-menu-styles="light"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-dark,
[data-menu-styles="light"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-white {
  @apply hidden;
}
[data-menu-styles="light"] .app-sidebar .app-sidebar {
  @apply bg-white border-[#f3f3f3] !important;
}
[data-menu-styles="light"] .app-sidebar .side-menu__label {
  @apply text-[#536485] !important;
}
[data-menu-styles="light"] .app-sidebar .side-menu__icon {
  @apply text-[#536485] !important;
}
[data-menu-styles="light"] .app-sidebar .side-menu__item {
  @apply text-[#536485];
}
[data-menu-styles="light"] .app-sidebar .side-menu__item:hover {
  @apply text-[#536485] bg-[#f3f6f8] !important;
}
[data-menu-styles="light"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__label,
[data-menu-styles="light"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-[#536485];
}
[data-menu-styles="light"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply text-[#536485] fill-[#536485] !important;
}
[data-menu-styles="light"] .app-sidebar .side-menu__item.active {
  @apply font-semibold bg-[#f3f6f8];
}
[data-menu-styles="light"] .app-sidebar .slide-menu.child1 .side-menu__item,
[data-menu-styles="light"] .app-sidebar .slide-menu.child2 .side-menu__item,
[data-menu-styles="light"] .app-sidebar .slide-menu.child3 .side-menu__item {
  @apply text-[#536485] before:border-[#536485]/80;
}
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:active,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.hover,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:active,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.hover,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:active,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.hover {
  @apply text-[#536485] before:border-[#536485] !important;
}
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:active
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.hover
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:active
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.hover
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:active
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="light"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.hover
  .side-menu__angle {
  @apply text-[#536485] !important;
}
[data-menu-styles="light"] .app-sidebar .slide__category {
  @apply text-[#536485];
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block;
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply hidden;
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block;
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply hidden;
}
@media (min-width: 992px) {
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo {
    @apply lg:block !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply lg:hidden !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-white border-gray-200 !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1 {
    @apply text-defaulttextcolor !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-gray-200 text-[#536485] !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar {
    @apply border-gray-200 !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-white border-gray-200;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-gray-200 text-[#536485] !important;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-white text-[#536485] border-gray-200;
  }
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-[#536485];
  }
}
[data-menu-styles="light"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo {
  @apply block !important;
}
[data-menu-styles="light"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white,
[data-menu-styles="light"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="light"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden !important;
}
@media (min-width: 992px) {
  [data-menu-styles="light"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-white border-[#f3f3f3] !important;
  }
  [data-menu-styles="light"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="light"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="light"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="light"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2,
  [data-menu-styles="light"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide-menu
    .child2 {
    @apply bg-white border-[#f3f3f3] !important;
  }
  [data-menu-styles="light"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-[#f3f3f3] text-[#222528] font-semibold !important;
  }
  [data-menu-styles="light"][data-nav-layout="horizontal"] .app-sidebar {
    @apply border-white/10 !important;
  }
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-white border-white/10;
  }
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-[#f3f3f3] text-[#222528] font-semibold !important;
  }
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-black/20 text-white border-white/10;
  }
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="light"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-white;
  }
  [data-menu-styles="light"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="light"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="light"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-white border-white/10 !important;
  }
}
[data-menu-styles="light"].dark .app-sidebar {
  --menu-prime-color: 128 135 147;
  @apply bg-white border-[#f3f3f3] !important;
}
[data-menu-styles="light"].dark .app-sidebar .main-sidebar-header {
  @apply border-[#f3f3f3] bg-white;
}
[data-menu-styles="light"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-logo {
  @apply block;
}
[data-menu-styles="light"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-dark,
[data-menu-styles="light"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-logo,
[data-menu-styles="light"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-dark,
[data-menu-styles="light"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-white {
  @apply hidden;
}
[data-menu-styles="light"].dark .app-sidebar .app-sidebar {
  @apply bg-white border-[#f3f3f3] !important;
}
[data-menu-styles="light"].dark .app-sidebar .side-menu__label {
  @apply text-[#536485] !important;
}
[data-menu-styles="light"].dark .app-sidebar .side-menu__icon {
  @apply text-[#536485] !important;
}
[data-menu-styles="light"].dark .app-sidebar .side-menu__item {
  @apply text-[#536485];
}
[data-menu-styles="light"].dark .app-sidebar .side-menu__item:hover {
  @apply text-[#536485] bg-[#f3f6f8] !important;
}
[data-menu-styles="light"].dark
  .app-sidebar
  .side-menu__item:hover
  .side-menu__label,
[data-menu-styles="light"].dark
  .app-sidebar
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-[#536485];
}
[data-menu-styles="light"].dark
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply text-[#536485] fill-[#536485] !important;
}
[data-menu-styles="light"].dark .app-sidebar .side-menu__item.active {
  @apply font-semibold bg-[#f3f6f8];
}
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item {
  @apply text-[#536485] before:border-[#536485]/80;
}
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:active,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.hover,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:active,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.hover,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:active,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.hover {
  @apply text-[#536485] before:border-[#536485] !important;
}
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:active
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.hover
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:active
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.hover
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:active
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="light"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.hover
  .side-menu__angle {
  @apply text-[#536485] !important;
}
[data-menu-styles="light"].dark .app-sidebar .slide__category {
  @apply text-[#536485];
}
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block;
}
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden;
}
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block;
}
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden;
}
@media (min-width: 992px) {
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo {
    @apply lg:block !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply lg:hidden !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-white border-gray-200 !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .side-menu__label1 {
    @apply text-defaulttextcolor !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-gray-200 text-[#536485] !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar {
    @apply border-gray-200 !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-white border-gray-200;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-gray-200 text-[#536485] !important;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-white text-[#536485] border-gray-200;
  }
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="light"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-[#536485];
  }
}
[data-menu-styles="light"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="light"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block !important;
}
[data-menu-styles="light"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="light"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="light"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="light"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="light"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden !important;
}

[data-menu-styles="transparent"] .app-sidebar {
  --menu-prime-color: 128 135 147;
  @apply bg-bodybg border-black/[0.07];
}
[data-menu-styles="transparent"] .app-sidebar .slide__category::before {
  @apply border-black/50;
}
[data-menu-styles="transparent"] .app-sidebar .main-sidebar-header {
  @apply border-black/[0.07] backdrop-blur-3xl !important;
}
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-logo {
  @apply block;
}
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-dark,
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-logo,
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-white,
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .toggle-dark,
[data-menu-styles="transparent"]
  .app-sidebar
  .main-sidebar-header
  .header-logo
  .desktop-white {
  @apply hidden;
}
[data-menu-styles="transparent"] .app-sidebar .side-menu__item {
  @apply text-[#536485];
}
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item
  .side-menu__icon {
  @apply fill-[#536485] text-[#536485];
}
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item
  .side-menu__label {
  @apply text-[#536485] !important;
}
[data-menu-styles="transparent"] .app-sidebar .side-menu__item.active,
[data-menu-styles="transparent"] .app-sidebar .side-menu__item:hover {
  @apply text-[#536485] before:text-[#536485] !important;
}
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__icon,
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply fill-[#536485] text-[#536485];
}
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__label,
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__label,
[data-menu-styles="transparent"]
  .app-sidebar
  .side-menu__item:hover
  .side-menu__angle {
  @apply fill-[#536485] text-[#536485];
}
[data-menu-styles="transparent"] .app-sidebar .side-menu__item.active {
  @apply bg-black/[0.05];
}
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item,
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item,
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item {
  @apply text-[#536485]/80 before:border-[#536485]/80;
}
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover {
  @apply text-[#536485] before:border-[#536485] bg-black/[0.05] !important;
}
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="transparent"]
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-[#536485];
}
[data-menu-styles="transparent"] .app-sidebar .slide__category {
  @apply text-[#536485];
}
[data-menu-styles="transparent"][data-vertical-style="doublemenu"]
  .app-sidebar
  .main-sidebar {
  @apply border-black/[0.07] !important;
}
[data-menu-styles="transparent"][data-vertical-style="doublemenu"]
  .app-sidebar
  .slide
  .side-menu__label1 {
  @apply text-defaulttextcolor font-normal !important;
}
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block;
}
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white {
  @apply hidden;
}
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo {
  @apply block !important;
}
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white,
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="transparent"][data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden !important;
}
@media (min-width: 992px) {
  [data-menu-styles="transparent"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-white,
  [data-menu-styles="transparent"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-white,
  [data-menu-styles="transparent"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-white,
  [data-menu-styles="transparent"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-white,
  [data-menu-styles="transparent"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-white {
    @apply lg:hidden !important;
  }
  [data-menu-styles="transparent"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="transparent"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="transparent"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="transparent"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark,
  [data-menu-styles="transparent"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-dark {
    @apply lg:block !important;
  }
  [data-menu-styles="transparent"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-bodybg border-black/[0.07] !important;
  }
  [data-menu-styles="transparent"][data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"][data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"][data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-black/[0.07]  !important;
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"] .app-sidebar {
    @apply border-b  !important;
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-bodybg border-black/[0.07]  !important;
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-black/[0.07]  text-primary !important;
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply bg-bodybg text-[#536485] border-black/[0.07];
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-[#536485];
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"].dark
    .app-sidebar {
    @apply border-white/10 !important;
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply bg-bodybg2 border-white/10 !important;
  }
  [data-menu-styles="transparent"][data-nav-layout="horizontal"].dark
    .main-menu-container
    .slide-left,
  [data-menu-styles="transparent"][data-nav-layout="horizontal"].dark
    .main-menu-container
    .slide-right {
    @apply border-white/10;
  }
}
[data-menu-styles="transparent"].dark .app-sidebar {
  @apply bg-bodybg2 border-white/10 !important;
}
[data-menu-styles="transparent"].dark .app-sidebar .main-sidebar-header {
  @apply backdrop-blur-3xl bg-bodybg2 border-white/10 !important;
}
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item {
  @apply text-white/60 !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item
  .side-menu__icon {
  @apply fill-white/60 text-white/60 !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item
  .side-menu__label {
  @apply text-white/60 !important;
}
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item.active,
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item:hover {
  @apply text-white/60 before:text-white/60 !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item.active
  .side-menu__icon,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply fill-white/60 text-white/60 !important;
}
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item.active {
  @apply bg-white/[0.05];
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item {
  @apply text-white/60 before:border-white/60;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover {
  @apply text-white/60 before:border-white/60 before:text-white/60 bg-white/[0.05] !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-white/60 !important;
}
[data-menu-styles="transparent"].dark .app-sidebar .slide__category {
  @apply text-white/60 before:border-white/70;
}
[data-menu-styles="transparent"].dark .app-sidebar .header-logo img {
  @apply h-8 leading-8;
}
[data-menu-styles="transparent"].dark .app-sidebar .header-logo .desktop-white {
  @apply block;
}
[data-menu-styles="transparent"].dark .app-sidebar .header-logo .desktop-logo,
[data-menu-styles="transparent"].dark .app-sidebar .header-logo .toggle-logo,
[data-menu-styles="transparent"].dark .app-sidebar .header-logo .toggle-dark,
[data-menu-styles="transparent"].dark .app-sidebar .header-logo .toggle-white,
[data-menu-styles="transparent"].dark .app-sidebar .header-logo .desktop-dark {
  @apply hidden;
}
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply block !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .desktop-white,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-click"][data-toggled="icon-click-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .desktop-white,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="transparent"].dark
  .app-sidebar[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden !important;
}
[data-menu-styles="transparent"].dark .app-sidebar {
  @apply border-white/10;
}
[data-menu-styles="transparent"].dark .app-sidebar .main-sidebar-header {
  @apply border-white/10;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo.desktop-logo {
  @apply hidden;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .main-sidebar-header
  .header-logo.desktop-dark {
  @apply block;
}
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item {
  @apply text-white/70;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item
  .side-menu__icon {
  @apply fill-white/70 text-white/70;
}
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item.active,
[data-menu-styles="transparent"].dark .app-sidebar .side-menu__item:hover {
  @apply text-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item.active
  .side-menu__icon,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .side-menu__item:hover
  .side-menu__icon {
  @apply fill-white text-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item {
  @apply text-white/70 before:border-white/80;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active {
  @apply text-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active::before,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active::before,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active::before {
  @apply border-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item.active
  .side-menu__angle,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item.active
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover {
  @apply text-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover::before,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover::before,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover::before {
  @apply border-white !important;
}
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child1
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child2
  .side-menu__item:hover
  .side-menu__angle,
[data-menu-styles="transparent"].dark
  .app-sidebar
  .slide-menu.child3
  .side-menu__item:hover
  .side-menu__angle {
  @apply text-white !important;
}
[data-menu-styles="transparent"].dark .app-sidebar .slide__category {
  @apply text-white/50;
}
[data-menu-styles="transparent"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo.toggle-logo,
[data-menu-styles="transparent"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply lg:hidden;
}
[data-menu-styles="transparent"].dark[data-vertical-style="overlay"][data-toggled="icon-overlay-close"]:not(
    [icon-overlay="open"]
  )
  .app-sidebar
  .header-logo.toggle-white {
  @apply lg:block;
}
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  img {
  @apply h-8 leading-8;
}
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-white {
  @apply block !important;
}
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-logo,
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .desktop-dark,
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-white,
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-logo,
[data-menu-styles="transparent"].dark[data-vertical-style="icontext"][data-toggled="icon-text-close"]:not(
    [icon-text="open"]
  )
  .app-sidebar
  .header-logo
  .toggle-dark {
  @apply hidden !important;
}
@media (min-width: 992px) {
  [data-menu-styles="transparent"].dark[data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"].dark[data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-dark,
  [data-menu-styles="transparent"].dark[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"].dark[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-dark,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-dark,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-dark,
  [data-menu-styles="transparent"].dark[data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-logo,
  [data-menu-styles="transparent"].dark[data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo
    .toggle-dark {
    @apply lg:hidden !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="transparent"].dark[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white,
  [data-menu-styles="transparent"].dark[data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .header-logo.toggle-white {
    @apply lg:block !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"].dark[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu,
  [data-menu-styles="transparent"].dark[data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.has-sub
    .slide-menu {
    @apply bg-bodybg2 border-white/10 !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-style="menu-click"][data-toggled="menu-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"].dark[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-click"][data-toggled="icon-click-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"].dark[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1,
  [data-menu-styles="transparent"].dark[data-vertical-style="doublemenu"]:not(
      [data-nav-layout="horizontal"]
    )
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/10 !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"]
    .app-sidebar {
    @apply border-white/10 !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"]
    .app-sidebar
    .slide.side-menu__label1 {
    @apply border-white/10 !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left,
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right {
    @apply text-white border-white/10;
  }
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-left
    svg,
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"]
    .app-sidebar
    .main-menu-container
    .slide-right
    svg {
    @apply fill-white;
  }
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"].dark
    .app-sidebar {
    @apply border-white/10 !important;
  }
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"].dark
    .main-menu-container
    .slide-left,
  [data-menu-styles="transparent"].dark[data-nav-layout="horizontal"].dark
    .main-menu-container
    .slide-right {
    @apply border-white/10;
  }
}

/* End Menu Styles */
.main-sidebar {
  @apply mt-[3.72rem] pt-2 pb-20 h-screen relative overflow-auto;
}

.app-sidebar .side-menu__angle {
  @apply rtl:rotate-180;
}

.sidemenu-toggle .header-link .header-link-icon {
  @apply rtl:rotate-180;
}

.app-sidebar {
  @apply w-[15rem] h-full bg-[#111c43] dark:bg-bodybg border-e border-e-white/10 fixed top-0 start-0 font-Montserrat z-[49] transition-all ease-linear duration-[0.05s];
}
.app-sidebar .main-sidebar-header {
  @apply h-[3.75rem] w-[15rem] fixed flex  dark:bg-bodybg z-[9] items-center justify-center py-[0.813rem] px-5 border-b border-b-white/10 border-e border-e-white/10 transition-all ease-linear duration-[0.05s];
}
.app-sidebar .main-sidebar-header .header-logo img {
  @apply h-8 leading-8;
}
.app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  @apply block;
}
.app-sidebar .main-sidebar-header .header-logo .desktop-logo,
.app-sidebar .main-sidebar-header .header-logo .toggle-logo,
.app-sidebar .main-sidebar-header .header-logo .toggle-dark,
.app-sidebar .main-sidebar-header .header-logo .toggle-white {
  @apply hidden;
}
.app-sidebar .slide__category {
  @apply text-[0.6rem] font-semibold tracking-[0.065rem] uppercase py-3 px-[1.65rem] whitespace-nowrap opacity-50 relative text-[#a3aed1] dark:text-[#8f9bb3];
}
.app-sidebar .slide {
  @apply mx-0 my-0.5 py-[0rem] px-3;
}
.app-sidebar .side-menu__item {
  @apply p-3 relative flex items-center no-underline text-[0.78rem] text-[#a3aed1] dark:text-[#8f9bb3] font-medium rounded-md;
}
.app-sidebar .side-menu__item.active,
.app-sidebar .side-menu__item:hover {
  @apply text-white bg-white/[0.05] before:border-white;
}
.app-sidebar .side-menu__item.active .side-menu__label,
.app-sidebar .side-menu__item.active .side-menu__angle,
.app-sidebar .side-menu__item:hover .side-menu__label,
.app-sidebar .side-menu__item:hover .side-menu__angle {
  @apply text-white;
}
.app-sidebar .side-menu__item.active .side-menu__icon,
.app-sidebar .side-menu__item:hover .side-menu__icon {
  @apply fill-white text-white;
}
.app-sidebar .side-menu__item.active {
  @apply font-semibold;
}
.app-sidebar .slide-menu.child1 .side-menu__item.active,
.app-sidebar .slide-menu.child2 .side-menu__item.active,
.app-sidebar .slide-menu.child3 .side-menu__item.active {
  @apply bg-transparent;
}
.app-sidebar .slide-menu {
  @apply p-[0rem];
}
.app-sidebar .slide-menu.child1 .side-menu__item,
.app-sidebar .slide-menu.child2 .side-menu__item {
  @apply py-[0.45rem] px-[1.6rem];
}
.app-sidebar .slide-menu.child1 .side-menu__item:before,
.app-sidebar .slide-menu.child2 .side-menu__item:before,
.app-sidebar .slide-menu.child3 .side-menu__item:before {
  @apply absolute content-[""] w-[0.3rem] h-[0.3rem] border border-[#536485] dark:border-[#8f9bb3] rounded-full bg-transparent start-2 opacity-50;
}
.app-sidebar .slide-menu.child1 .side-menu__item:hover,
.app-sidebar .slide-menu.child2 .side-menu__item:hover,
.app-sidebar .slide-menu.child3 .side-menu__item:hover {
  @apply text-white;
}
.app-sidebar .slide-menu.child1 .side-menu__item:hover:before,
.app-sidebar .slide-menu.child2 .side-menu__item:hover:before,
.app-sidebar .slide-menu.child3 .side-menu__item:hover:before {
  @apply content-[""] border border-white rounded-full bg-transparent opacity-100 !important;
}
.app-sidebar .slide-menu.child1 .side-menu__item.active:before,
.app-sidebar .slide-menu.child2 .side-menu__item.active:before,
.app-sidebar .slide-menu.child3 .side-menu__item.active:before {
  @apply absolute content-[""] w-[0.3rem] h-[0.3rem] border border-white rounded-full bg-transparent opacity-100;
}
.app-sidebar .slide-menu.child1 li,
.app-sidebar .slide-menu.child2 li,
.app-sidebar .slide-menu.child3 li {
  @apply p-0 ps-6 relative;
}
.app-sidebar .side-menu__label {
  @apply whitespace-nowrap text-[#a3aed1] dark:text-[#8f9bb3] relative text-[0.85rem] leading-none align-middle;
}
.app-sidebar .side-menu__label .badge {
  @apply p-2 rounded-sm;
}
.app-sidebar .side-menu__icon {
  @apply me-2.5 w-[1.15rem] h-[1.15rem] text-[1.15rem] text-center text-[#a3aed1] fill-[#a3aed1] dark:text-[#8f9bb3] rounded-md;
}
.app-sidebar .side-menu__angle {
  @apply origin-center absolute end-3 text-[0.85rem] text-[#a3aed1] dark:text-[#8f9bb3] transition-all ease-linear duration-[0.05s] !important;
}
.app-sidebar .slide.side-menu__label1 {
  @apply hidden;
}

.horizontal-logo {
  @apply py-[0.85rem] px-[0rem];
}

.slide.has-sub .slide-menu {
  @apply visible translate-x-0 translate-y-0 !important;
}

.nav ul li {
  @apply list-none;
}

.nav > ul {
  @apply ps-0;
}

.slide-menu {
  @apply hidden;
}

.slide.has-sub {
  @apply grid;
}
.slide.has-sub.open > .side-menu__item .side-menu__angle {
  @apply rotate-90;
}

/* Start Responsive Styles */
[data-toggled="open"] .app-sidebar {
  @apply translate-x-0 transition-all duration-300;
  @apply lg:translate-x-0 !important;
}
[data-toggled="open"] .app-sidebar .main-sidebar-header {
  @apply hidden;
}
[data-toggled="open"] .app-sidebar #sidebar-scroll {
  @apply mt-0;
}

[data-toggled="close"] .app-sidebar {
  @apply transition-all duration-300 -translate-x-full;
  @apply lg:translate-x-0 !important;
}
[data-toggled="close"] .app-sidebar .main-sidebar-header {
  @apply hidden;
}
[data-toggled="close"] .app-sidebar #sidebar-scroll {
  @apply mt-0;
}
[data-toggled="close"][dir="ltr"] .app-sidebar {
  @apply -translate-x-full;
}
[data-toggled="close"][dir="rtl"] .app-sidebar {
  @apply translate-x-full;
}

@media screen and (max-width: 991px) {
  .main-sidebar-header {
    @apply hidden;
  }
  #sidebar-scroll {
    @apply mt-0 !important;
  }
}
/* End Responsive Styles */
/* Responsive Styles Start */
@media (max-width: 991.98px) {
  .horizontal-logo .header-logo .desktop-logo,
  .horizontal-logo .header-logo .desktop-dark,
  .horizontal-logo .header-logo .toggle-dark,
  .horizontal-logo .header-logo .desktop-white,
  .horizontal-logo .header-logo .toggle-white {
    @apply hidden;
  }
  .horizontal-logo .header-logo .toggle-logo {
    @apply block;
  }
  .main-sidebar-header {
    @apply hidden !important;
  }
  .main-sidebar {
    @apply mt-0;
  }
  .app-sidebar {
    @apply top-0;
  }
}
.slide-left,
.slide-right {
  @apply hidden;
}

[data-nav-layout="vertical"] .slide.has-sub.active > .side-menu__item {
  @apply mb-1;
}

@media (min-width: 992px) {
  .horizontal-logo {
    @apply hidden;
  }
}
/* Responsive Styles End */
/* Responsive Overlay Start */
#responsive-overlay {
  @apply invisible fixed inset-0 z-[101] transition-all ease-in-out duration-100 bg-[#878b94];
}
#responsive-overlay.active {
  @apply visible;
}

/* Responsive Overlay End */
@media (max-width: 991.98px) {
  .main-menu {
    @apply m-0 !important;
  }
  .app-sidebar {
    @apply z-[50] !important;
  }
}
/* SWITCHER STYLES */
/* TAILWIND STYLES */
.accordion-button {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor py-3 px-4 text-[0.85rem] font-medium;
}
.accordion-button:not(.collapsed) {
  @apply text-defaulttextcolor  shadow-none;
}
.accordion-button:focus {
  @apply bg-defaultbackground dark:bg-light text-defaulttextcolor shadow-none;
}

.accordion-body {
  @apply py-3 px-4 text-[0.8125rem] text-textmuted;
}

.accordion-item {
  @apply text-defaulttextcolor bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-defaultborder/10 dark:text-defaulttextcolor/70;
}

.accordions-items-seperate .accordion-item:not(:first-of-type) {
  @apply mt-2;
}
.accordions-items-seperate .accordion-item {
  @apply border-t border-solid border-defaultborder dark:border-defaultborder/10 rounded-md overflow-hidden;
}

.accordion-item:last-of-type {
  @apply rounded-b-[0.35rem];
}

.accordion-item:first-of-type {
  @apply rounded-t-[0.35rem];
}

.accordion.accordion-flush .accordion-button {
  @apply pe-[2.5rem];
}
.accordion.accordion-flush .accordion-button:after {
  @apply start-[98.5rem] !important;
}

/* Start:: light colored accordions */
.accordion.accordion-primary .accordion-button {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor py-3 px-4 text-[0.85rem] font-medium;
}
.accordion.accordion-primary .accordion-button:focus {
  @apply bg-primary/10 text-primary;
}
.accordion.accordion-primary .accordion-button:after,
.accordion.accordion-primary .accordion-button:not(.collapsed)::after {
  @apply bg-none;
}
.accordion.accordion-primary .accordion-button:after {
  @apply bg-primary text-white;
}
.accordion.accordion-primary .accordion-button.collapsed {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor dark:text-defaulttextcolor/70;
}
.accordion.accordion-primary .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor dark:text-defaulttextcolor/70;
}
.accordion.accordion-secondary .accordion-button {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor py-3 px-4 text-[0.85rem] font-medium;
}
.accordion.accordion-secondary .accordion-button:focus {
  @apply bg-secondary/10 text-secondary;
}
.accordion.accordion-secondary .accordion-button:after,
.accordion.accordion-secondary .accordion-button:not(.collapsed)::after {
  @apply bg-none;
}
.accordion.accordion-secondary .accordion-button:after {
  @apply bg-secondary text-white;
}
.accordion.accordion-secondary .accordion-button.collapsed {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor;
}
.accordion.accordion-secondary .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-success .accordion-button {
  @apply bg-success/10 text-success;
}
.accordion.accordion-success .accordion-button:after {
  @apply bg-success text-white;
}
.accordion.accordion-success .accordion-button.collapsed {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor;
}
.accordion.accordion-success .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-danger .accordion-button {
  @apply bg-danger/10 text-danger;
}
.accordion.accordion-danger .accordion-button:after {
  @apply bg-danger text-white;
}
.accordion.accordion-danger .accordion-button.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-danger .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-warning .accordion-button {
  @apply bg-warning/10 text-warning;
}
.accordion.accordion-warning .accordion-button:after {
  @apply bg-warning text-white;
}
.accordion.accordion-warning .accordion-button.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-warning .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-info .accordion-button {
  @apply bg-info/10 text-info;
}
.accordion.accordion-info .accordion-button:after {
  @apply bg-info text-white;
}
.accordion.accordion-info .accordion-button.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-info .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}

/* End:: light colored accordions */
/* Start:: solid colored accordions */
.accordion.accordion-solid-primary .accordion-button {
  @apply bg-white text-defaulttextcolor py-3 px-4 text-[0.85rem] font-medium;
}
.accordion.accordion-solid-primary .accordion-button:focus {
  @apply bg-primary text-white;
}
.accordion.accordion-solid-primary .accordion-button:after {
  @apply bg-white text-primary;
}
.accordion.accordion-solid-primary .accordion-button.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-solid-primary .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-solid-secondary .accordion-button {
  @apply bg-white text-defaulttextcolor py-3 px-4 text-[0.85rem] font-medium;
}
.accordion.accordion-solid-secondary .accordion-button:focus {
  @apply bg-secondary text-white;
}
.accordion.accordion-solid-secondary .accordion-button:after {
  @apply bg-white text-secondary;
}
.accordion.accordion-solid-secondary .accordion-button.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-solid-secondary .accordion-button.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-solid-success {
  @apply bg-success text-white;
}
.accordion.accordion-solid-success:after {
  @apply bg-white text-success;
}
.accordion.accordion-solid-success.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-solid-success.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-solid-danger {
  @apply bg-danger text-white;
}
.accordion.accordion-solid-danger:after {
  @apply bg-white text-danger;
}
.accordion.accordion-solid-danger.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-solid-danger.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-solid-warning {
  @apply bg-danger text-white;
}
.accordion.accordion-solid-warning:after {
  @apply bg-white text-danger;
}
.accordion.accordion-solid-warning.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-solid-warning.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}
.accordion.accordion-solid-info {
  @apply bg-info text-white;
}
.accordion.accordion-solid-info:after {
  @apply bg-white text-info;
}
.accordion.accordion-solid-info.collapsed {
  @apply bg-white text-defaulttextcolor;
}
.accordion.accordion-solid-info.collapsed:after {
  @apply bg-defaultbackground text-defaulttextcolor;
}

/* End:: solid colored accordions */
/* Start:: border colors */
.accordion.accordion-border-primary .accordion-item {
  @apply border border-solid border-primary;
}
.accordion.accordion-border-primary .accordion-button {
  @apply bg-white dark:bg-bodybg text-primary border-b-0;
}
.accordion.accordion-border-primary .accordion-button:after {
  @apply bg-white text-primary border border-solid border-primary;
}
.accordion.accordion-border-primary .accordion-button:not(.collapsed) {
  @apply border-solid border-primary;
}
.accordion.accordion-border-success .accordion-item {
  @apply border border-solid border-success;
}
.accordion.accordion-border-success .accordion-button {
  @apply bg-white dark:bg-bodybg text-success border-b-0;
}
.accordion.accordion-border-success .accordion-button:after {
  @apply bg-white dark:bg-bodybg text-success border border-solid border-success;
}
.accordion.accordion-border-success .accordion-button:not(.collapsed) {
  @apply border-solid border-success;
}

/* End:: border colors */
/* Start:: no icon */
.accordion.accordionicon-none .accordion-button:after {
  @apply hidden;
}

/* Start:: no icon */
/* Start:: left aligned icon */
.accordion.accordionicon-left .accordion-button:after {
  @apply absolute start-[1.935rem];
}

/* End:: left aligned icon */
/* Start:: custom icons */
.accordion.accordion-customicon1 .accordion-button:after {
  @apply content-[""] !important;
}
.accordion.accordion-customicon1 .accordion-button:not(.collapsed)::after {
  @apply content-[""] !important;
}

/* End:: custom icons */
/* Start:: customized accordion */
.customized-accordion
  .accordion-item.custom-accordion-primary
  .accordion-button {
  @apply bg-primary/10 border-s-[0.25rem] border-solid border-primary/60;
}
.customized-accordion
  .accordion-item.custom-accordion-primary
  .accordion-button:after {
  @apply bg-primary/60 text-white;
}
.customized-accordion .accordion-item.custom-accordion-primary .accordion-body {
  @apply bg-primary/10 pt-0 border-s-[0.25rem] border-solid border-primary/60;
}
.customized-accordion
  .accordion-item.custom-accordion-secondary
  .accordion-button {
  @apply bg-secondary/10 border-s-[0.25rem] border-solid border-secondary/60;
}
.customized-accordion
  .accordion-item.custom-accordion-secondary
  .accordion-button:after {
  @apply bg-secondary/60 text-white;
}
.customized-accordion
  .accordion-item.custom-accordion-secondary
  .accordion-body {
  @apply bg-secondary/10 pt-0 border-s-[0.25rem] border-solid border-secondary/60;
}
.customized-accordion
  .accordion-item.custom-accordion-danger
  .accordion-button {
  @apply bg-danger/10 border-s-[0.25rem] border-solid border-danger/60;
}
.customized-accordion
  .accordion-item.custom-accordion-danger
  .accordion-button:after {
  @apply bg-danger/60 text-white;
}
.customized-accordion .accordion-item.custom-accordion-danger .accordion-body {
  @apply bg-danger/10 pt-0 border-s-[0.25rem] border-solid border-danger/60;
}

/* End:: customized accordion */
.alert {
  @apply py-[0.625rem] px-[0.85rem]  border rounded-md text-[0.8125rem] items-center;
}
.alert.alert-dismissible {
  @apply py-[0.625rem] px-[0.85rem];
}
.alert .btn-close {
  @apply bg-none p-[0.95rem] flex items-center justify-center;
}
.alert .btn-close i {
  @apply text-[1.5rem] leading-[1.5];
}
.alert:last-child {
  @apply mb-0;
}
.alert .alert-link {
  @apply font-semibold;
}

/* Basic Alerts */
.alert-warning {
  @apply bg-warning/10 text-warning border-warning/10;
}
.alert-warning .alert-link {
  @apply text-warning;
}
.alert-warning .btn-close {
  @apply text-warning;
}
.alert-warning .btn-close.custom-close {
  @apply text-warning opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-primary {
  @apply bg-primary/10 text-primary border-primary/10;
}
.alert-primary .alert-link {
  @apply text-primary;
}
.alert-primary .btn-close {
  @apply text-primary;
}
.alert-primary .btn-close.custom-close {
  @apply text-primary opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-secondary {
  @apply bg-secondary/10 text-secondary border-secondary/10;
}
.alert-secondary .alert-link {
  @apply text-secondary;
}
.alert-secondary .btn-close {
  @apply text-secondary;
}
.alert-secondary .btn-close.custom-close {
  @apply text-secondary opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-success {
  @apply bg-success/10 text-success border-success/10;
}
.alert-success .alert-link {
  @apply text-success;
}
.alert-success .btn-close {
  @apply text-success;
}
.alert-success .btn-close.custom-close {
  @apply text-success opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-info {
  @apply bg-info/10 text-info border-info/10;
}
.alert-info .alert-link {
  @apply text-info;
}
.alert-info .btn-close {
  @apply text-info;
}
.alert-info .btn-close.custom-close {
  @apply text-info opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-danger {
  @apply bg-danger/10 text-danger border-danger/10;
}
.alert-danger .alert-link {
  @apply text-danger;
}
.alert-danger .btn-close {
  @apply text-danger;
}
.alert-danger .btn-close.custom-close {
  @apply text-danger opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-light {
  @apply bg-light text-defaultsize border-light;
}
.alert-light .alert-link {
  @apply text-defaultsize;
}
.alert-light .btn-close {
  @apply text-defaultsize;
}
.alert-light .btn-close.custom-close {
  @apply text-defaulttextcolor opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

.alert-dark {
  @apply bg-black/10 text-black dark:text-defaulttextcolor/70 border-black/10;
}
.alert-dark .alert-link {
  @apply text-black dark:text-defaulttextcolor/70;
}
.alert-dark .btn-close {
  @apply text-black dark:text-defaulttextcolor/70;
}
.alert-dark .btn-close.custom-close {
  @apply text-black dark:text-defaulttextcolor/70 opacity-[1] rounded-[50px] shadow p-[0.85rem];
}

/* Basic Alerts */
/* Solid Colored Alerts */
.alert-solid-primary {
  @apply bg-primary text-white border-primary;
}
.alert-solid-primary .btn-close {
  @apply text-white;
}

.alert-solid-secondary {
  @apply bg-secondary text-white border-secondary;
}
.alert-solid-secondary .btn-close {
  @apply text-white;
}

.alert-solid-warning {
  @apply bg-warning text-white border-warning;
}
.alert-solid-warning .btn-close {
  @apply text-white;
}

.alert-solid-info {
  @apply bg-info text-white border-info;
}
.alert-solid-info .btn-close {
  @apply text-white;
}

.alert-solid-success {
  @apply bg-success text-white border-success;
}
.alert-solid-success .btn-close {
  @apply text-white;
}

.alert-solid-danger {
  @apply bg-danger text-white border-danger;
}
.alert-solid-danger .btn-close {
  @apply text-white;
}

.alert-solid-light {
  @apply bg-light text-defaulttextcolor border-light;
}
.alert-solid-light .btn-close {
  @apply text-defaulttextcolor;
}

.alert-solid-dark {
  @apply bg-black text-white border-black;
}
.alert-solid-dark .btn-close {
  @apply text-white;
}

/* Solid Colored Alerts */
/* Outline Alerts */
.alert-outline-primary {
  @apply text-primary border-primary;
}
.alert-outline-primary .btn-close {
  @apply text-primary;
}

.alert-outline-secondary {
  @apply text-secondary border-secondary;
}
.alert-outline-secondary .btn-close {
  @apply text-secondary;
}

.alert-outline-info {
  @apply text-info border-info;
}
.alert-outline-info .btn-close {
  @apply text-info;
}

.alert-outline-warning {
  @apply text-warning border-warning;
}
.alert-outline-warning .btn-close {
  @apply text-warning;
}

.alert-outline-success {
  @apply text-success border-success;
}
.alert-outline-success .btn-close {
  @apply text-success;
}

.alert-outline-danger {
  @apply text-danger border-danger;
}
.alert-outline-danger .btn-close {
  @apply text-danger;
}

.alert-outline-light {
  @apply text-defaulttextcolor border-light;
}
.alert-outline-light .btn-close {
  @apply text-light;
}

.alert-outline-dark {
  @apply text-black dark:text-defaulttextcolor/70 border-black;
}
.alert-outline-dark .btn-close {
  @apply text-light;
}

/* Outline Alerts */
/* Customized Alerts */
.alert-primary.custom-alert-icon {
  @apply border-s-[0.313rem]   border-solid text-[#8c9097] bg-white dark:bg-bodybg border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
  @apply border-s-primary !important;
}
.alert-primary.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-secondary.custom-alert-icon {
  @apply border-s-[0.313rem] border-solid text-[#8c9097] bg-white  dark:bg-bodybg dark:border-defaultborder/10 border border-defaultborder text-[0.813rem];
  @apply border-s-secondary !important;
}
.alert-secondary.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-warning.custom-alert-icon {
  @apply border-s-[0.313rem] border-solid bg-white dark:bg-bodybg text-[#8c9097] border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
  @apply border-s-warning !important;
}
.alert-warning.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-danger.custom-alert-icon {
  @apply border-s-[0.313rem] border-s-danger border-solid bg-white dark:bg-bodybg text-[#8c9097] border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
}
.alert-danger.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-success.custom-alert-icon {
  @apply border-s-[0.313rem]  border-solid bg-white dark:bg-bodybg text-[#8c9097] border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
  @apply border-s-success !important;
}
.alert-success.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-info.custom-alert-icon {
  @apply border-s-[0.313rem] border-solid bg-white dark:bg-bodybg text-[#8c9097] border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
  @apply border-s-info !important;
}
.alert-info.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-light.custom-alert-icon {
  @apply border-s-[0.313rem]  border-solid text-[#8c9097] border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
  @apply border-s-light !important;
}
.alert-light.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

.alert-dark.custom-alert-icon {
  @apply border-s-[0.313rem] border-solid text-[#8c9097] border dark:border-defaultborder/10 border-defaultborder text-[0.813rem];
  @apply border-s-black !important;
}
.alert-dark.custom-alert-icon .btn-close {
  @apply text-[#8c9097];
}

/* Customized Alerts */
/* Customized Alerts1 */
.custom-alert1 {
  @apply me-0 bg-white dark:bg-bodybg border-0 p-[1.25rem] text-defaulttextcolor;
}
.custom-alert1 p {
  @apply mb-[2.5rem] text-[#8c9097] text-[0.8rem];
}
.custom-alert1 .custom-alert-icon {
  @apply w-[3.125rem] h-[3.125rem] mb-[0.85rem];
}
.custom-alert1 .btn-close {
  @apply p-0 mb-4;
}
.custom-alert1.alert-primary {
  @apply border-t-[0.313rem] border-solid border-primary;
}
.custom-alert1.alert-secondary {
  @apply border-t-[0.313rem] border-solid border-secondary;
}
.custom-alert1.alert-warning {
  @apply border-t-[0.313rem] border-solid border-warning;
}
.custom-alert1.alert-danger {
  @apply border-t-[0.313rem] border-solid border-danger;
}

/* Customized Alerts1 */
/* Image alerts */
.alert-img {
  @apply flex items-center;
}
.alert-img .avatar {
  @apply border border-solid border-black/10;
}

/* Image alerts */
/* Start Avatar Styles */
.avatar {
  @apply relative h-[2.625rem] w-[2.625rem] inline-flex items-center justify-center rounded-md font-medium mb-1;
}
.avatar a.badge:hover {
  @apply text-white;
}
.avatar img {
  @apply w-full h-full rounded-md;
}
.avatar .avatar-rounded {
  @apply rounded-full !important;
}
.avatar .avatar-rounded img {
  @apply rounded-full !important;
}
.avatar.avatar-radius-0 {
  @apply rounded-none;
}
.avatar.avatar-radius-0 img {
  @apply rounded-none;
}
.avatar .avatar-badge {
  @apply absolute -top-[4%] -end-[0.375rem] w-[1.4rem] h-[1.4rem] text-[0.625rem] border-2 border-solid border-white rounded-full flex items-center justify-center !important;
}
.avatar.online,
.avatar.offline {
  @apply relative before:absolute before:w-[0.75rem] before:h-[0.75rem] before:rounded-full before:end-0 before:bottom-0 before:border-[2px] before:border-solid before:border-white before:dark:border-black;
}
.avatar.online:before {
  @apply bg-success;
}
.avatar.offline:before {
  @apply bg-gray-500;
}
.avatar.avatar-xs {
  @apply w-[1.25rem] h-[1.25rem] leading-[1.25rem] text-[0.65rem];
}
.avatar.avatar-xs .avatar-badge {
  @apply p-[0.25rem] w-[1rem] h-[1rem] leading-[1rem] text-[0.5rem] -top-[25%] -end-[0.5rem] !important;
}
.avatar.avatar-xs.online,
.avatar.avatar-xs.offline {
  @apply before:w-[0.5rem] before:h-[0.5rem];
}
.avatar.avatar-sm {
  @apply w-[1.75rem] h-[1.75rem] leading-[1.75rem] text-[0.65rem];
}
.avatar.avatar-sm .avatar-badge {
  @apply p-[0.3rem] w-[1.1rem] h-[1.1rem] leading-[1.1rem] text-[0.5rem] -top-[38%] -end-[0.5rem] !important;
}
.avatar.avatar-sm.online,
.avatar.avatar-sm.offline {
  @apply before:w-[0.5rem] before:h-[0.5rem];
}
.avatar.avatar-md {
  @apply w-[2.5rem] h-[2.5rem] leading-[2.5rem] text-[0.8rem];
}
.avatar.avatar-md .avatar-badge {
  @apply p-[0.4rem] w-[1.2rem] h-[1.2rem] leading-[1.2rem] text-[0.65rem] -top-[6%] -end-[13%] !important;
}
.avatar.avatar-md.online,
.avatar.avatar-md.offline {
  @apply before:w-[0.75rem] before:h-[0.75rem];
}
.avatar.avatar-md svg {
  @apply w-[1.5rem] h-[1.5rem];
}
.avatar.avatar-lg {
  @apply w-[3rem] h-[3rem] leading-[3rem] text-[1rem];
}
.avatar.avatar-lg .avatar-badge {
  @apply -top-[15%] -end-[0.25%];
}
.avatar.avatar-lg.online,
.avatar.avatar-lg.offline {
  @apply before:w-[0.8rem] before:h-[0.8rem];
}
.avatar.avatar-lg svg {
  @apply w-[1.8rem] h-[1.8rem];
}
.avatar.avatar-xl {
  @apply w-[4rem] h-[4rem] leading-[4rem] text-[1.25rem];
}
.avatar.avatar-xl .avatar-badge {
  @apply -top-[8%] -end-[0.2%];
}
.avatar.avatar-xl.online,
.avatar.avatar-xl.offline {
  @apply before:w-[0.95rem] before:h-[0.95rem];
}
.avatar.avatar-xxl {
  @apply w-[5rem] h-[5rem] leading-[5rem] text-[1.5rem];
}
.avatar.avatar-xxl .avatar-badge {
  @apply -top-[4%] -end-[0%];
}
.avatar.avatar-xxl.online,
.avatar.avatar-xxl.offline {
  @apply before:w-[1.05rem] before:h-[1.05rem] bottom-1;
}

.avatar-rounded {
  @apply rounded-full !important;
}
.avatar-rounded img {
  @apply rounded-full !important;
}

/* Start:: Breadcrumb Styles */
.breadcrumb {
  @apply mb-2;
}
.breadcrumb .breadcrumb-item a {
  @apply text-primary !important;
}
.breadcrumb .breadcrumb-item.active {
  @apply text-defaulttextcolor font-semibold;
}

.breadcrumb-item + .breadcrumb-item {
  @apply ps-2;
}

.breadcrumb-item + .breadcrumb-item {
  @apply before:content-["/"] before:text-textmuted before:me-2;
}

.breadcrumb-example1 .breadcrumb-item + .breadcrumb-item {
  @apply before:content-[""] before:text-textmuted before:me-0 !important;
}

.breadcrumb-example2 .breadcrumb-item + .breadcrumb-item {
  @apply before:content-["~"] before:text-textmuted;
}

.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before {
  @apply text-textmuted content-["->"];
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  @apply text-textmuted content-[""];
}

.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  @apply text-textmuted content-[""];
}

.embedded-breadcrumb:before {
  @apply opacity-[0.7] before:content-[""];
}

.dark .embedded-breadcrumb:before {
  @apply invert-[1];
}

/* End:: Breadcrumb Styles */
/* Start Buttons Styles */
.ti-btn {
  @apply py-2 px-3 inline-flex justify-center items-center gap-2 mb-1 rounded-sm border border-transparent font-medium focus:ring-0 focus:outline-none focus:ring-offset-0 transition-all text-sm  ms-0;
}
.ti-btn.ti-btn-outline {
  @apply border;
}
.ti-btn.ti-btn-disabled {
  @apply cursor-not-allowed opacity-[0.65];
}
.ti-btn.ti-btn-sm {
  @apply w-[1.75rem] h-[1.75rem] text-[0.8rem];
}
.ti-btn.ti-btn-lg {
  @apply py-[0.65rem] px-4 text-[0.95rem] rounded-md;
}

.ti-btn.ti-btn-w-xs {
  @apply min-w-[5.625rem];
}
.ti-btn.ti-btn-w-sm {
  @apply min-w-[6.975rem];
}
.ti-btn.ti-btn-w-md {
  @apply min-w-[8.125rem];
}
.ti-btn.ti-btn-w-lg {
  @apply min-w-[9.375rem];
}

.ti-btn-group {
  @apply inline-flex justify-center items-center gap-2 -ms-px first:rounded-s-sm first:ms-0 last:rounded-e-sm font-medium align-middle focus:z-10  transition-all text-sm;
}

.ti-btn-primary-full {
  @apply bg-primary text-white;
}

.ti-btn-secondary-full {
  @apply bg-secondary text-white;
}

.ti-btn-success-full {
  @apply bg-success text-white;
}

.ti-btn-danger-full {
  @apply bg-danger text-white;
}

.ti-btn-warning-full {
  @apply bg-warning text-white;
}

.ti-btn-info-full {
  @apply bg-info text-white;
}

.ti-btn-purple-full {
  @apply bg-[#1F7A7] text-white;
}

.ti-btn-orange-full {
  @apply bg-orangemain text-white;
}

.ti-btn-teal-full {
  @apply bg-[#12c2c2] text-white;
}

.ti-btn-link {
  @apply text-[#0d6efd] underline !important;
}

.ti-btn-primary {
  @apply bg-primary/10 text-primary hover:bg-primary focus:bg-primary hover:text-white focus:text-white;
}
.ti-btn-primary.active {
  @apply text-white;
}

.ti-btn-group:first-child {
  @apply ms-0 rounded-s-sm;
}

.ti-btn-group:last-child {
  @apply rounded-e-sm;
}

.ti-btn-secondary {
  @apply bg-secondary/10 text-secondary hover:bg-secondary hover:text-white;
}

.ti-btn-warning {
  @apply bg-warning/10 text-warning hover:bg-warning hover:text-white;
}

.ti-btn-success {
  @apply bg-success/10 text-success hover:bg-success hover:text-white;
}

.ti-btn-light {
  @apply bg-light text-defaulttextcolor dark:text-defaulttextcolor/80 hover:bg-light hover:text-defaulttextcolor focus:bg-light/80;
}

.ti-btn-dark {
  @apply bg-gray-900 text-gray-100 dark:text-black hover:bg-gray-900 hover:text-white dark:bg-white !important;
}

.ti-btn-info {
  @apply bg-info/10 text-info hover:text-white hover:bg-info;
}

.ti-btn-danger {
  @apply bg-danger/10 text-danger hover:text-white hover:bg-danger focus:ring-danger dark:focus:ring-offset-white/10;
}

.ti-btn-purple {
  @apply bg-purplemain/10 text-purplemain hover:text-white hover:bg-purplemain focus:ring-purplemain dark:focus:ring-offset-white/10;
}

.ti-btn-teal {
  @apply bg-[#12c2c2]/10 text-[#12c2c2] hover:text-white hover:bg-[#12c2c2] focus:ring-[#12c2c2] dark:focus:ring-offset-white/10;
}

.ti-btn-orange {
  @apply bg-orangemain/10 text-orangemain hover:text-white hover:bg-orangemain focus:ring-orangemain dark:focus:ring-offset-white/10;
}

.ti-btn-outline-primary {
  @apply border-primary text-primary hover:text-white hover:bg-primary hover:border-primary focus:ring-primary dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-secondary {
  @apply border-secondary text-secondary hover:text-white hover:bg-secondary hover:border-secondary focus:ring-secondary dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-danger {
  @apply border-danger text-danger hover:text-white hover:bg-danger hover:border-danger focus:ring-danger dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-warning {
  @apply border-warning text-warning hover:text-white hover:bg-warning hover:border-warning focus:ring-warning dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-info {
  @apply border-info text-info hover:text-white hover:bg-info hover:border-info focus:ring-info dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-success {
  @apply border-success text-success hover:text-white hover:bg-success hover:border-success focus:ring-success dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-light {
  @apply border-light text-black dark:text-defaulttextcolor/70 dark:hover:bg-bodybg2 hover:text-defaulttextcolor hover:bg-gray-200 hover:border-defaultborder dark:hover:border-transparent focus:ring-gray-100 dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-dark {
  @apply border-gray-900 text-gray-900 hover:text-white hover:bg-gray-900 hover:border-gray-900 dark:border-white/10 dark:text-white/70 focus:ring-white/10 dark:focus:ring-offset-white/10 !important;
}

.ti-btn-outline-teal {
  @apply border-[#12c2c2] text-[#12c2c2] hover:text-white hover:bg-[#12c2c2] hover:border-[#12c2c2] dark:border-white/10 dark:text-white/70 focus:ring-white/10 dark:focus:ring-offset-white/10 !important;
}

.ti-btn-ghost-primary {
  @apply text-primary hover:bg-primary/10;
}

.ti-btn-ghost-secondary {
  @apply text-secondary hover:bg-secondary/10;
}

.ti-btn-ghost-warning {
  @apply text-warning hover:bg-warning/10;
}

.ti-btn-ghost-info {
  @apply text-info hover:bg-info/10;
}

.ti-btn-ghost-danger {
  @apply text-danger hover:bg-danger/10;
}

.ti-btn-ghost-success {
  @apply text-success hover:bg-success/10;
}

.ti-btn-ghost-light {
  @apply text-defaulttextcolor hover:bg-light/10;
}

.ti-btn-ghost-dark {
  @apply text-black hover:bg-black/10;
}

.ti-btn-ghost-orange {
  @apply text-orangemain hover:bg-orangemain/10;
}

.ti-btn-ghost-purple {
  @apply text-purplemain hover:bg-purplemain/10;
}

.ti-btn-ghost-teal {
  @apply text-[#12c2c2]  hover:bg-[#12c2c2]/10;
}

.btn-loader {
  @apply pointer-events-none;
}

.ti-btn-primary-gradient {
  @apply bg-gradient-to-r from-primary to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-secondary-gradient {
  @apply bg-gradient-to-r from-secondary to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-success-gradient {
  @apply bg-gradient-to-r from-success to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-danger-gradient {
  @apply bg-gradient-to-r from-danger to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-warning-gradient {
  @apply bg-gradient-to-r from-warning to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-info-gradient {
  @apply bg-gradient-to-r from-info to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-orange-gradient {
  @apply bg-gradient-to-r from-orangemain to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-purple-gradient {
  @apply bg-gradient-to-r from-purplemain to-[#0086ed] text-white border-0 hover:border-0;
}

.ti-btn-teal-gradient {
  @apply bg-gradient-to-r from-[#12c2c2] to-[#0086ed] text-white border-0 hover:border-0;
}

.btn-border-down.btn-primary-light {
  @apply border-b-[0.1875rem] border-solid border-primary;
}

/* End Buttons Styles */
/* Start:: Social Buttons */
.ti-btn-facebook {
  @apply bg-facebook text-white border border-solid border-facebook;
}
.ti-btn-facebook:hover,
.ti-btn-facebook:focus,
.ti-btn-facebook:active {
  @apply bg-facebook text-white border border-solid border-facebook !important;
}

.ti-btn-google {
  @apply bg-google text-white border border-solid border-google;
}
.ti-btn-google:hover,
.ti-btn-google:focus,
.ti-btn-google:active {
  @apply bg-google text-white border border-solid border-google !important;
}

.ti-btn-twitter {
  @apply bg-twitter text-white border border-solid border-twitter;
}
.ti-btn-twitter:hover,
.ti-btn-twitter:focus,
.ti-btn-twitter:active {
  @apply bg-twitter text-white border border-solid border-twitter !important;
}

.ti-btn-github {
  @apply bg-github text-white border border-solid border-github;
}
.ti-btn-github:hover,
.ti-btn-github:focus,
.ti-btn-github:active {
  @apply bg-github text-white border border-solid border-github !important;
}

.ti-btn-youtube {
  @apply bg-youtube text-white border border-solid border-youtube;
}
.ti-btn-youtube:hover,
.ti-btn-youtube:focus,
.ti-btn-youtube:active {
  @apply bg-youtube text-white border border-solid border-youtube !important;
}

.ti-btn-instagram {
  @apply bg-[#f09433] text-white border border-solid border-transparent;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f09433", endColorstr="#bc1888",GradientType=1 );
}
.ti-btn-instagram:hover,
.ti-btn-instagram:focus,
.ti-btn-instagram:active {
  @apply text-white border border-solid border-transparent;
}

/* End:: Social Buttons */
.ti-btn-group > .ti-btn-group:not(:first-child) > .ti-btn,
.ti-btn-group > .ti-btn:nth-child(n + 3),
.ti-btn-group > :not(.ti-btn-check) + .ti-btn {
  @apply rounded-s-none;
}

.ti-btn-group-vertical {
  @apply flex-col items-start justify-center relative inline-block align-middle;
}

.ti-btn-group-vertical > .ti-btn-group:not(:last-child) > .ti-btn,
.ti-btn-group-vertical > .ti-btn:not(:last-child):not(.ti-dropdown-toggle) {
  @apply rounded-b-none border-b-0;
}

.ti-btn-group-vertical > .ti-btn-group:not(:first-child) > .ti-btn,
.ti-btn-group-vertical > .ti-btn ~ .ti-btn {
  @apply rounded-t-none;
}

.ti-btn-group-vertical .ti-btn-info-full {
  @apply hover:bg-info/90;
}
.ti-btn-group-vertical .ti-btn-primary-full {
  @apply hover:bg-primary/90;
}

.ti-btn-check {
  @apply absolute pointer-events-none sr-only;
}

.ti-btn-group .ti-btn {
  @apply py-[0.45rem] px-3;
}

.ti-btn-group-lg .ti-btn {
  @apply py-[0.65rem] px-4;
}

.ti-btn-group-sm .ti-btn {
  @apply py-1 px-2;
}

.ti-btn-group-vertical {
  @apply flex-col items-start justify-center relative inline-flex align-middle;
}

/* Start:: Label Buttons */
.label-ti-btn {
  @apply relative ps-[2.6rem];
}

.label-ti-btn-icon {
  @apply absolute w-[2.25rem] text-[1rem] flex items-center justify-center -start-0 -top-0 -bottom-0 bg-white/20;
}

.label-ti-btn.label-end {
  @apply ps-4 pe-[2.6rem];
}
.label-ti-btn.label-end .label-ti-btn-icon {
  @apply -end-0 start-auto;
}

.custom-button {
  @apply relative ps-[2.75rem];
}
.custom-button .custom-ti-btn-icons {
  @apply shadow-[0_0_1px_0.25rem_rgba(0,0,0,0.1)] absolute -start-[0.125rem] top-0 bg-white flex items-center justify-center overflow-hidden p-[0.375rem] rounded-[3.125rem]
        text-[1rem] w-[2.25rem] h-[2.25rem];
}
.custom-button .custom-ti-btn-icons i {
  @apply absolute;
}

.ti-btn-border-down.ti-btn-teal {
  @apply border-b-[0.1875rem] border-t-0 border-x-0 border-solid border-[#12c2c2] !important;
}

.ti-btn-border-start.ti-btn-secondary {
  @apply border-s-[0.1875rem] border-t-0 border-b-0 border-e-0 border-solid border-secondary !important;
}

.ti-btn-border-end.ti-btn-purple {
  @apply border-e-[0.1875rem] border-t-0 border-s-0 border-b-0 border-solid border-purplemain !important;
}

.ti-btn-border-top.ti-btn-warning {
  @apply border-t-[0.1875rem] border-b-0 border-x-0 border-solid border-warning !important;
}

.ti-btn-hover {
  @apply relative;
}
.ti-btn-hover.ti-btn-hover-animate {
  @apply transition-all duration-[0.2s] ease-linear delay-[0s] before:content-[""] before:text-[0.8125rem] before:absolute 
        before:flex before:items-center before:justify-center before:end-0 before:top-0 before:opacity-0 before:h-full before:w-[2rem] before:transition-all
        before:duration-[0.2s] before:ease-linear before:delay-[0s] before:font-bootstrap;
}
.ti-btn-hover.ti-btn-hover-animate:hover {
  @apply pe-8 before:opacity-[1] before:indent-0;
}

.ti-btn-darken-hover {
  @apply relative;
}
.ti-btn-darken-hover:hover {
  @apply before:absolute before:w-full before:h-full before:bg-black/[0.25] before:top-0 before:start-0;
}

.ti-btn-loader i {
  @apply animate-spin !important;
}

.ti-btn-group-vertical > .ti-btn,
.ti-btn-group > .ti-btn {
  @apply relative flex-grow;
}

.btn-check {
  @apply absolute pointer-events-none sr-only;
}

.ti-btn-list a {
  @apply ml-0 mr-1.5 mt-0 mb-1.5 !important;
}

/* End:: Label Buttons */
.box.box-bg-primary {
  @apply bg-primary text-white !important;
}

.box.box-bg-success {
  @apply bg-secondary text-white !important;
}

.mySwiper1 .swiper-button-next:after,
.mySwiper1 .swiper-button-prev:after {
  @apply text-[1.5rem] font-[300] text-white !important;
}
.mySwiper1 .swiper-pagination-bullet {
  @apply bg-white text-white w-[30px] h-[3px] rounded-none;
}

/* Start Box Styles */
body {
  @apply dark:bg-bodybg2 !important;
}

.box {
  @apply flex flex-col shadow-sm rounded-md text-defaulttextcolor dark:text-defaulttextcolor/70 bg-white  text-[0.813rem]  mb-6 relative dark:bg-bodybg;
}

.box-header {
  @apply font-medium border-b border-b-defaultborder md:flex items-center gap-[0.25rem] rounded-t-sm px-[1.25rem] py-4 dark:border-white/10 flex-wrap;
}

.box-title {
  @apply relative font-[700] text-defaulttextcolor text-[.9375rem] me-auto dark:text-defaulttextcolor/70 before:absolute;
}

.box .box-header .box-title {
  @apply before:absolute before:h-4 before:w-[0.2rem] before:top-[0.15rem] before:-start-[0.65rem] before:bg-gradient-to-b before:from-primary/50 before:to-secondary/50 before:rounded-md;
}

.box-body {
  @apply p-5 text-defaulttextcolor dark:text-defaulttextcolor/70 flex-auto;
}

.box-footer {
  @apply border-t rounded-b-sm py-4 px-[1.25rem] text-defaultsize text-defaulttextcolor md:py-4 md:px-5 dark:border-white/10;
}

.display-1 {
  @apply text-[5rem];
}

.display-2 {
  @apply text-[4.5rem];
}

.display-3 {
  @apply text-[4rem];
}

.display-4 {
  @apply text-[3.5rem];
}

.display-5 {
  @apply text-[3rem];
}

.display-6 {
  @apply text-[2.5rem];
}

.overlay-box {
  @apply relative overflow-hidden text-white/90 before:absolute before:bg-black/20 before:inset-0;
}
.overlay-box .box-header {
  @apply border-white/10;
}
.overlay-box .box-footer {
  @apply border-white/10;
}

.over-content-bottom {
  @apply top-auto;
}

.box-anchor {
  @apply content-[] absolute inset-0 z-[1] pointer-events-none;
}

.box.box-fullscreen {
  @apply fixed inset-0 z-[9999] m-0 rounded-none;
}

.box-img-overlay {
  @apply absolute inset-0 rounded-sm overflow-auto;
}

.card-img-top {
  @apply rounded-t-md !important;
}

/* End Box Styles */
.lead {
  @apply text-[1.25rem] font-[300];
}

/* Start Alert Styles */
.alert {
  @apply text-sm rounded-sm px-4 py-3 mb-5 last:mb-0;
}

.text-badge .badge {
  @apply absolute -end-4 -top-4;
}

/* End Alert Styles */
.review-quote {
  @apply absolute end-[0.8rem] text-[1.875rem] leading-[0] text-primary/70 bg-transparent p-[0.625rem];
}

/* Start Badge Styles */
.badge {
  @apply inline-flex items-center text-[0.75em] py-1 px-[0.45rem] font-semibold;
}
.badge:last-child {
  @apply mb-0;
}

/* End Badge Styles */
/* Start List-Group Styles */
.ti-list-group {
  @apply items-center font-normal text-[0.8125rem] border border-solid border-defaultborder rounded-md  text-start dark:border-defaultborder/10;
}
.ti-list-group .ti-list-group-item {
  @apply py-3 px-[1.25rem] border-b border-solid border-defaultborder dark:border-defaultborder/10 last:border-b-0;
}
.ti-list-group .ti-list-group-item.active {
  @apply text-white bg-primary border-primary rounded-t-md !important;
}
.ti-list-group .ti-list-group-item.disabled,
.ti-list-group .ti-list-group-item:disabled {
  @apply text-textmuted bg-light opacity-[0.5] !important;
}
.ti-list-group .ti-list-group-item.ti-list-group-item-action {
  @apply w-full;
}
.ti-list-group .ti-list-group-item.ti-list-group-item-action:hover,
.ti-list-group .ti-list-group-item.ti-list-group-item-action:focus {
  @apply bg-light;
}
.ti-list-group .ti-list-group-item.ti-icon-link {
  @apply focus:z-10 focus:outline-none py-[0.375rem] px-3 focus:ring-0;
}

.ti-list-group-flush {
  @apply border-0;
}

/* End List-Group Styles */
/* Start Progress Styles */
.ti-main-progress {
  @apply flex w-full h-1.5 rounded-full overflow-hidden;
}
.ti-main-progress .ti-main-progress-bar {
  @apply flex flex-col justify-center overflow-hidden;
}

/* End Progress Styles */
/* Start Spinner Styles */
.ti-spinner {
  @apply animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent rounded-full;
}

/* End Spinner Styles */
/* Start Toast Styles */
.ti-toast {
  @apply max-w-xs border rounded-sm shadow-lg dark:border-white/10;
}

/* End Toast Styles */
.ti-border {
  @apply border-[#e5e7eb];
}

/* Start:: Nav-tabs */
.nav-link:focus,
.nav-link:hover {
  @apply text-primary;
}

.nav-tabs {
  @apply border-defaultborder;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  @apply text-defaulttextcolor bg-light border-defaultborder;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  @apply border-defaultborder;
}

.nav-tabs-header {
  @apply border-0;
}
.nav-tabs-header .nav-item {
  @apply me-2;
}
.nav-tabs-header .nav-item:last-child {
  @apply me-0;
}
.nav-tabs-header .nav-item .nav-link {
  @apply border border-solid rounded-md border-transparent text-[0.8rem] p-2 font-medium;
}
.nav-tabs-header .nav-item .nav-link.active {
  @apply bg-primary/10 text-primary border border-solid border-transparent;
}
.nav-tabs-header .nav-item .nav-link:hover,
.nav-tabs-header .nav-item .nav-link:focus {
  @apply border border-solid border-transparent text-primary;
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  @apply basis-0 flex-grow text-center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  @apply w-full;
}

.nav.nav-style-1 {
  @apply border-0;
}

.nav.nav-style-3 .nav-link {
  @apply rounded-none !important;
}
.nav.nav-style-3 .nav-link {
  @apply border-b-[3px] border-solid border-transparent;
}
.nav.nav-style-3 .nav-link.active {
  @apply bg-transparent border-b-[3px] border-solid border-primary text-primary rounded-none !important;
}

/* End:: Nav-tabs */
/* Start:: Tab-style-6 */
.tab-style-6 {
  @apply border-0 bg-primary/10 rounded-md text-primary p-2;
}
.tab-style-6 .nav-item {
  @apply border-0 me-2;
}
.tab-style-6 .nav-item:last-child {
  @apply me-0;
}
.tab-style-6 .nav-item .nav-link {
  @apply text-primary py-2 px-4 text-defaultsize border-0 font-medium;
}
.tab-style-6 .nav-item .nav-link.active {
  @apply border-0 bg-primary text-white shadow-sm;
}
.tab-style-6 .nav-item .nav-link:hover,
.tab-style-6 .nav-item .nav-link:focus {
  @apply border-0;
}

.navbar {
  @apply rounded-md;
}
.navbar .navbar-nav .nav-link {
  @apply leading-none py-[0.4rem] px-4 font-medium;
}
.navbar .navbar-toggler {
  @apply p-[0.4rem] text-[1rem] leading-none text-black border border-solid border-defaultborder rounded-md;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  @apply w-[1rem] h-[1rem] relative bg-none before:content-[""] before:absolute before:text-[1rem] before:text-defaulttextcolor before:start-0;
}
.navbar .navbar-toggler:focus {
  @apply shadow-none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  @apply text-white bg-primary;
}

.navitem {
  @apply me-4;
}

/* End:: Tab-style-6 */
/* start::background */
.bg-primary-gradient {
  @apply bg-gradient-to-r from-primary to-[#0086ed] text-white !important;
}

.bg-secondary-gradient {
  @apply bg-gradient-to-r from-secondary to-[#0086ed] text-white !important;
}

.bg-warning-gradient {
  @apply bg-gradient-to-r from-warning to-[#0086ed] text-white !important;
}

.bg-info-gradient {
  @apply bg-gradient-to-r from-info to-[#0086ed] text-white !important;
}

.bg-success-gradient {
  @apply bg-gradient-to-r from-success to-[#0086ed] text-white !important;
}

.bg-danger-gradient {
  @apply bg-gradient-to-r from-danger to-[#0086ed] text-white !important;
}

.bg-orange-gradient {
  @apply bg-gradient-to-r from-orangemain to-[#0086ed] text-white !important;
}

.bg-purple-gradient {
  @apply bg-gradient-to-r from-purplemain to-[#0086ed] text-white !important;
}

.bg-teal-gradient {
  @apply bg-gradient-to-r from-tealmain to-[#0086ed] text-white !important;
}

.bg-light-gradient {
  @apply bg-gradient-to-r from-light to-[#0086ed] text-white !important;
}

.bg-dark-gradient {
  @apply bg-gradient-to-r from-black to-[#0086ed] text-white !important;
}

/* End:: backgrounds */
code {
  @apply text-[0.9rem] text-redmain break-words !important;
}

.color-container {
  @apply w-[3rem] h-[3rem] rounded-full flex items-center justify-center leading-[3rem];
}

.bd-example-row [class^="col"],
.bd-example-cssgrid .grid > * {
  @apply pt-3 pb-3 bg-light/75 border border-solid border-defaultborder;
}

.bd-example-cssgrid .grid > * {
  @apply rounded-sm;
}

.bd-example-row-flex-cols .grid {
  @apply min-h-[10rem] bg-light/50;
}
.bd-example-row-flex-cols .grid .col {
  @apply p-3;
}

.flex-container div {
  @apply bg-transparent border-0;
}
.flex-container div > div {
  @apply bg-light border border-solid border-white dark:border-bodybg;
}

.ratio {
  @apply relative w-full before:block;
}

.bd-example-ratios .ratio {
  @apply inline-block w-[10rem] text-textmuted bg-primary/10 border-defaultborder rounded-md;
}

.bd-example-position-utils .position-absolute {
  @apply w-8 h-8 bg-primary/10 rounded-[0.375rem] absolute;
}

.bd-example-position-utils .position-relative {
  @apply h-[12.5rem] bg-light relative;
}

.border-container {
  @apply inline-block w-[5rem] h-[5rem] m-1 bg-light/30;
}

.upcoming-events-list li {
  @apply mb-[1.5rem] ps-[1.5rem] relative before:absolute before:h-full before:w-[0.25rem] before:start-0 before:rounded-md;
}

.upcoming-events-list li:nth-child(1) {
  @apply before:bg-primary/30;
}

.upcoming-events-list li:nth-child(2) {
  @apply before:bg-secondary/30;
}

.upcoming-events-list li:nth-child(3) {
  @apply before:bg-success/30;
}

.upcoming-events-list li:nth-child(4) {
  @apply before:bg-danger/30;
}

.upcoming-events-list li:nth-child(5) {
  @apply before:bg-info/30;
}

.upcoming-events-list li:nth-child(5) {
  @apply before:bg-warning/30;
}

.upcoming-events-list li:last-child {
  @apply mb-0;
}

#external-events .fc-event {
  @apply cursor-move mb-[0.4rem] py-[0.375rem] px-3 text-[0.75rem] rounded-[0.35rem];
}

.glightbox {
  @apply overflow-hidden;
}

/* start:: project-list */
.project-list-title {
  @apply max-w-[13.375rem];
}

/* Start Custom Styles */
.page-header {
  @apply py-5;
}

.main-content {
  @apply sm:px-6 px-6;
}

.content {
  @apply lg:ms-60 mt-[60px] transition-all ms-0;
}

pre {
  @apply border-gray-200 border dark:border-white/10 bg-slate-200 text-[0.75rem] p-5;
}

html {
  @apply font-inter scroll-smooth text-start;
}

html[dir="rtl"] {
  @apply dir-rtl !important;
}

body {
  @apply scroll-smooth bg-bodybg h-full text-defaulttextcolor dark:text-white m-0 font-inter font-normal text-[0.813rem] relative text-start rtl:dir-rtl;
}

.page {
  @apply flex flex-col h-full min-h-screen;
}

::-webkit-scrollbar {
  @apply w-[0px] h-[5px] bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply w-[0px] h-[2px] bg-gray-300;
}

.dark ::-webkit-scrollbar {
  @apply bg-black/20;
}
.dark ::-webkit-scrollbar-thumb {
  @apply bg-black/20;
}

code {
  @apply text-danger text-sm;
}

.icons-list {
  @apply list-none mt-0 -me-[1px] -mb-[1px] ms-0 p-0 flex flex-wrap;
}

.icons-list .icons-list-item {
  @apply flex flex-col text-center p-2 no-underline relative items-center justify-center border border-gray-200 dark:border-white/10 rounded-md;
}
.icons-list .icons-list-item:hover i {
  @apply scale-150;
}

.icons-list .icons-list-item i {
  @apply text-2xl text-primary;
}

.icon-label {
  @apply text-gray-500 dark:text-white/70 text-xs mt-4;
}

.simplebar-scrollbar:before {
  @apply bg-gray-200 !important;
}

.app-sidebar .simplebar-track.simplebar-horizontal {
  @apply hidden !important;
}

.simplebar-track.simplebar-vertical {
  @apply w-[10px] !important;
}

.simplebar-track,
.simplebar-scrollbar {
  @apply -right-px rtl:-left-px !important;
}

[data-toggled="icon-overlay-close"] .content {
  @apply lg:ms-24;
}

/* Start:: Back to Top */
.scrollToTop {
  @apply fixed bottom-5 end-5 hidden bg-primary text-white  items-center justify-center text-center z-10 h-10 w-10 bg-no-repeat bg-center transition duration-100 rounded-md shadow-lg;
}

/* Start:: Back to Top */
select option:active,
select option:hover,
select option:focus {
  background-color: green !important;
}

/* End Custom Styles */
select {
  @apply pe-9 ps-2;
}

#drag-right .box,
#drag-center .box,
#drag-left .box {
  @apply touch-none;
}

.ti-img-thumbnail {
  @apply p-1 rounded-md border border-defaultborder dark:border-defaultborder/10 max-w-full h-auto dark:bg-bodybg2 mx-auto;
}

.ti-img-thumbnail-rounded {
  @apply p-1 rounded-full border border-defaultborder dark:border-defaultborder/10 max-w-full h-auto dark:bg-bodybg2 mx-auto;
}

#responsive-overlay {
  @apply transition-all duration-100 fixed inset-0 z-[49] bg-gray-900 invisible bg-opacity-0;
}
#responsive-overlay.active {
  @apply bg-opacity-50 dark:bg-opacity-80 visible;
}

.container,
.container-fluid {
  @apply w-full px-[calc(1.5rem*0.5)] mx-auto;
}

.container {
  @apply sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] xxl:max-w-[1320px];
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    @apply max-w-[540px] mx-auto;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    @apply max-w-[720px] mx-auto;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    @apply max-w-[960px] mx-auto;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    @apply max-w-[1140px] mx-auto;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    @apply max-w-[1320px] mx-auto;
  }
}
[class^="ri-"],
[class*=" ri-"] {
  @apply inline-flex;
}

@layer utilities {
  .aspect-w-16 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 16;
  }
  .aspect-w-16 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .aspect-h-9 {
    --tw-aspect-h: 9;
  }
  .aspect-w-1 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
  }
  .aspect-w-1 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .aspect-h-1 {
    --tw-aspect-h: 1;
  }
}
.bg-teal {
  @apply bg-[#12c2c2] !important;
}

#pie-basic .apexcharts-pie text,
#donut-simple .apexcharts-pie text,
#pie-monochrome .apexcharts-pie text,
#pie-image .apexcharts-pie text,
#donut-pattern .apexcharts-pie text {
  @apply fill-defaulttextcolor dark:fill-white !important;
}

.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
  @apply hidden !important;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable,
  .choices__list[aria-expanded] .choices__item--selectable {
    @apply pe-0 !important;
  }
}
.gridjs-footer {
  @apply px-0 !important;
}

a {
  @apply cursor-pointer;
}

#hs-select-temporary {
  @apply w-full;
}

code[class*="language-"] {
  @apply p-4 !important;
}

.apexcharts-title-text {
  @apply dark:fill-defaulttextcolor/70;
}

#nft-collections-slide {
  @apply rtl:dir-ltr;
}

#buy-crypto2 .choices,
#sell-crypto2 .choices {
  @apply overflow-visible;
}

.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.ti-striped-table tbody tr:nth-child(even) {
  @apply bg-light !important;
}

#switcher-body [type="radio"] {
  @apply border-[#e2e8f0] dark:border-white/30 !important;
}

@media (max-width: 576px) {
  .header-element.header-country .ti-dropdown-menu {
    @apply w-full start-0 end-auto !important;
  }
}
#navbar-collapse-basic1,
#navbar-collapse-basic2 {
  @apply h-full !important;
}

@media (min-width: 992px) {
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left {
    @apply end-0 !important;
  }
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left {
    @apply -start-full !important;
  }
}

.page-item:not(:first-child) .page-link {
  @apply ms-[calc(var(--bs-width)*-1)];
}

.ql-snow .ql-tooltip input[type="text"] {
  @apply dark:bg-bodybg2;
}

#radialbar-multiple .apexcharts-text.apexcharts-datalabel-label {
  @apply dark:fill-white;
}

.fe-arrow-left {
  @apply rtl:before:content-[""];
}

.fe-arrow-right {
  @apply rtl:before:content-[""];
}

.input-group .form-control {
  @apply border-s-0;
}

.underlined-floatiing-label textarea {
  @apply dark:focus:border-x-0 dark:focus:border-t-0 !important;
}
.underlined-floatiing-label input {
  @apply dark:focus:border-x-0 dark:focus:border-t-0 !important;
}

.echart-charts canvas {
  @apply w-full !important;
}
.echart-charts div:first-child {
  @apply w-full !important;
}

@media (max-width: 575.98px) {
  #crm-revenue-analytics .apexcharts-canvas .apexcharts-title-text {
    @apply text-[0.71rem];
  }
}
.dropzone .dz-preview .dz-details {
  @apply z-[-9] !important;
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  @apply z-[48] !important;
}

.main-sidebar::-webkit-scrollbar {
  height: 0px;
}

[dir="rtl"] #contact-phone {
  @apply dir-rtl;
}

@media (min-width: 576px) {
  #folders-close-btn {
    display: none;
  }
}
.btn-check + .ti-btn.ti-btn-outline-primary:hover,
.btn-check:active + .ti-btn-outline-primary,
.btn-check:checked + .ti-btn-outline-primary,
.ti-btn-outline-primary.active,
.ti-btn-outline-primary.dropdown-toggle.show,
.ti-btn-outline-primary:active {
  @apply text-white bg-primary border-primary !important;
}

/* Start Dropdown Styles */
.ti-dropdown {
  @apply relative inline-flex;
}

.ti-dropdown-toggle {
  @apply py-3 px-4 inline-flex justify-center items-center gap-2 rounded-sm border dark:border-defaultborder/10 font-medium  shadow-sm align-middle;
}

.ti-dropdown-caret {
  @apply w-2.5 h-2.5 text-gray-600 dark:text-white/70;
}

.ti-dropdown-menu {
  @apply transition-[opacity,margin] hs-dropdown-open:opacity-100  opacity-0 z-[13] mt-2 min-w-[10rem] bg-white shadow-md border border-gray-200 rounded-sm dark:bg-bodybg dark:border-white/10 dark:divide-white/10;
}

.ti-dropdown-item {
  @apply py-2 px-[0.9375rem] text-[0.8125rem] font-medium block w-full  text-gray-800 hover:bg-primary/[0.05] hover:text-primary focus:ring-0 focus:ring-primary dark:text-white dark:hover:bg-primary/5 dark:hover:text-primary;
}
.ti-dropdown-item.active {
  @apply bg-primary text-white !important;
}
.ti-dropdown-item.disabled {
  @apply text-textmuted bg-transparent pointer-events-none;
}

.ti-dropdown-menu-hover {
  @apply after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full;
}

.ti-dropdown-divider {
  @apply py-2 first:pt-0 last:pb-0 dark:border-defaultborder/10;
}

hr {
  @apply dark:border-defaultborder/10 !important;
}

.ti-dropdown-title {
  @apply block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-white/70;
}

.ti-dropdown-header {
  @apply py-3 px-5 -m-2 bg-gray-100 rounded-t-sm dark:bg-black/20;
}
.ti-dropdown-header .ti-dropdown-header-title {
  @apply text-sm text-gray-500 dark:text-white;
}
.ti-dropdown-header .ti-dropdown-header-content {
  @apply text-sm font-medium text-gray-800 dark:text-white;
}

.ti-dropdowm-profileimg {
  @apply w-8 h-auto rounded-full;
}

.ti-dropdowm-profileimg-content {
  @apply text-gray-600 font-medium truncate max-w-[7.5rem] dark:text-white/70;
}

.ti-dropdown-icon {
  @apply w-4 h-4 text-gray-600 dark:text-white/70;
}

.ti-dropdown-toggle {
  @apply relative;
}
.ti-dropdown-toggle .ti-dropdown-toggle {
  @apply after:inline-block after:ms-1 after:align-[0] after:content-[""] after:border-0 after:text-[0.6rem] after:font-semibold;
}
.ti-dropdown-toggle .ti-dropup .ti-dropdown-toggle {
  @apply after:inline-block after:ms-1 after:align-[0] after:content-[""] after:border-0 after:text-[0.6rem] after:font-semibold;
}
.ti-dropdown-toggle .dropend .ti-dropdown-toggle {
  @apply after:inline-block after:ms-1 after:align-[0] after:content-[""] after:border-0 after:text-[0.6rem] after:font-semibold;
}
.ti-dropdown-toggle .dropstart .ti-dropdown-toggle {
  @apply before:inline-block before:ms-1 before:align-[0] before:content-[""] before:border-0 before:text-[0.6rem] before:font-semibold;
}
.ti-dropdown-toggle .dropdown-toggle-split {
  @apply opacity-[0.85];
}

.dropmenu-item-warning .ti-dropdown-item:hover,
.dropmenu-item-warning .ti-dropdown-item:focus,
.dropmenu-item-warning .ti-dropdown-item:active,
.dropmenu-item-warning .ti-dropdown-item.active {
  @apply bg-warning/10 text-warning !important;
}

.dropmenu-item-info .ti-dropdown-item:hover,
.dropmenu-item-info .ti-dropdown-item:focus,
.dropmenu-item-info .ti-dropdown-item:active,
.dropmenu-item-info .ti-dropdown-item.active {
  @apply bg-info/10 text-info !important;
}

.dropmenu-light-success {
  @apply bg-success/10 backdrop-blur-3xl;
}
.dropmenu-light-success .ti-dropdown-item:hover,
.dropmenu-light-success .ti-dropdown-item:focus,
.dropmenu-light-success .ti-dropdown-item:active,
.dropmenu-light-success .ti-dropdown-item.active {
  @apply text-white bg-success !important;
}

.dropmenu-light-danger {
  @apply bg-danger/10 backdrop-blur-3xl;
}
.dropmenu-light-danger .ti-dropdown-item:hover,
.dropmenu-light-danger .ti-dropdown-item:focus,
.dropmenu-light-danger .ti-dropdown-item:active,
.dropmenu-light-danger .ti-dropdown-item.active {
  @apply text-white bg-danger !important;
}

.ti-btn-list a {
  @apply me-0;
}

.bd-example > .dropdown-menu {
  @apply static block;
}

.dropdown-header {
  @apply block py-2 px-4 mb-0 text-[0.875rem] !important;
}

/* End Dropdown Styles */
/* Start Form Styles */
.ti-form-input {
  @apply border border-inputborder block w-full text-sm focus:border-gray-200  focus:shadow-sm dark:shadow-white/10 dark:bg-bodybg dark:border-white/10 dark:focus:border-white/10 dark:text-white/70;
}

select {
  @apply border dark:border-white/10 dark:bg-bodybg !important;
}

.ti-switch {
  @apply relative w-[3.25rem] h-7 bg-gray-200 checked:bg-none checked:bg-primary border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ring-0 ring-transparent focus:border-primary focus:shadow-none focus:ring-transparent focus:ring-offset-0 ring-offset-white focus:outline-0 appearance-none dark:bg-black/20 dark:checked:bg-primary dark:focus:ring-offset-white/10 before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-white before:translate-x-0 ltr:checked:before:translate-x-full rtl:checked:before:-translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-black/20 dark:checked:before:bg-black/20;
}

.ti-form-select {
  @apply py-3 px-4 pe-9 block w-full border-gray-200 rounded-sm text-sm focus:border-primary focus:ring-primary dark:dark:bg-bodybg dark:border-white/10 dark:text-white/70;
}

.ti-form-select-label {
  @apply block text-sm font-medium mb-2 dark:text-white;
}

.ti-form-label {
  @apply block text-sm font-medium mb-2 dark:text-white;
}

.ti-form-control {
  @apply border-inputborder text-defaulttextcolor bg-white dark:bg-bodybg2 dark:border-white/10 text-[0.875rem] font-normal leading-[1.6] rounded-[0.35rem] py-2 px-[0.85rem] placeholder:text-defaulttextcolor dark:placeholder:text-defaulttextcolor/70;
}
.ti-form-control:focus {
  @apply shadow-none border-inputborder bg-white dark:bg-bodybg text-defaulttextcolor dark:text-defaulttextcolor/70;
}

.form-select {
  @apply block w-full py-[0.375rem] pe-[2.25rem] ps-[0.75rem] text-[1rem] font-normal leading-[1.5];
}

.form-select {
  @apply bg-white dark:bg-bodybg border border-solid border-defaultborder dark:border-white/10 text-defaulttextcolor text-defaultsize rounded-md focus:border focus:border-primary;
}
.form-select option {
  @apply bg-white dark:bg-bodybg py-[0.35rem] px-3 rounded-sm border border-primary/10;
}
.form-select option:checked {
  @apply bg-primary/20 text-primary;
}

.form-check-input {
  @apply h-[0.9rem] w-[0.9rem] bg-white dark:bg-bodybg dark:border-white/10 border border-solid border-inputborder rounded-sm;
}
.form-check-input:focus {
  @apply border border-defaultborder outline-none !important;
}
.form-check-input:checked {
  @apply bg-primary border-primary;
}
.form-check-input.form-checked-outline:checked {
  @apply bg-transparent border-primary;
}
.form-check-input.form-checked-secondary:checked {
  @apply bg-secondary border-secondary hover:bg-secondary;
}
.form-check-input.form-checked-warning:checked {
  @apply bg-warning border-warning hover:bg-warning;
}
.form-check-input.form-checked-info:checked {
  @apply bg-info border-info hover:bg-info;
}
.form-check-input.form-checked-success:checked {
  @apply bg-success border-success focus:bg-success focus:border-success focus:shadow-success focus:ring-0;
}
.form-check-input.form-checked-danger:checked {
  @apply bg-danger border-danger hover:bg-danger;
}
.form-check-input.form-checked-light:checked {
  @apply bg-light border-light hover:bg-light;
}
.form-check-input.form-checked-dark:checked {
  @apply bg-[#232323] border-[#232323] hover:bg-black;
}

[type="radio"] {
  @apply dark:bg-bodybg dark:border-defaultborder/10 ring-primary;
}

.form-check-input {
  @apply align-top appearance-none;
}

.form-check-input[type="checkbox"] {
  @apply rounded-[0.25rem];
}

.form-check-input[type="radio"] {
  @apply rounded-[50%] ring-primary;
}

.form-check-input:active {
  @apply brightness-[90%];
}

.form-check-input:focus {
  @apply border-[#86b7fe] outline-none !important;
}

.form-check-input:checked {
  @apply bg-primary border-primary ring-primary;
}

.input-group-text {
  @apply border-inputborder text-[0.875rem] rounded-[0.3125rem] bg-light text-defaulttextcolor;
}
.input-group-text .form-control {
  @apply border-0 rounded-s-none !important;
}

.input-group-text {
  @apply flex items-center py-[0.375rem] px-3 text-[0.875rem] font-normal leading-[1.5] text-center whitespace-nowrap border border-solid border-defaultborder dark:border-defaultborder/10;
}

.input-group {
  @apply relative flex flex-wrap items-stretch w-full;
}

.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  @apply relative w-[1%] min-w-0 flex-grow flex-shrink basis-auto;
}

.form-control {
  @apply border-inputborder text-defaulttextcolor bg-white dark:border-white/10 dark:bg-bodybg text-[0.875rem] font-normal leading-[1] rounded-none  py-2 px-[0.85rem] placeholder:text-defaulttextcolor dark:placeholder:text-defaulttextcolor/70;
  @apply placeholder:opacity-40 placeholder:font-medium placeholder:text-[0.8rem];
}
.form-control:focus {
  @apply shadow-none border-inputborder bg-white dark:bg-bodybg text-defaulttextcolor dark:text-defaulttextcolor/70;
}

.form-checked-outline:checked[type="checkbox"] {
  @apply bg-none relative bg-transparent hover:bg-transparent before:absolute  before:text-primary before:w-[0.625rem] before:h-[0.625rem] before:-top-4 before:start-0 before:text-[0.688rem];
}
.form-checked-outline:checked[type="checkbox"].form-checked-success {
  @apply before:text-success;
}

.form-control-lg {
  @apply text-[.75rem] py-2 px-4;
}

.form-check-md .form-check-input {
  @apply w-[1.15rem] h-[1.15rem];
}

.form-check-lg .form-check-input {
  @apply w-[1.35rem] h-[1.35rem];
}

.form-check-reverse {
  @apply pe-[1.5rem] ps-0 text-end;
}

.form-check-reverse .form-check-input {
  @apply ltr:float-right rtl:float-left -me-[1.5em] ms-0;
}

[type="text"]:focus,
input:where(:not([type])):focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply border-defaultborder dark:border-defaultborder/10 ring-0 !important;
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  @apply bg-primary ring-primary;
}

/* Start:: toggle switches-1 */
.toggle {
  @apply w-[3.75rem] h-[1.563rem] bg-light ms-[0.625rem] mb-[0.313rem] p-[0.125rem] rounded-[0.188rem] relative overflow-hidden transition-all duration-[0.2s] ease-linear;
}
.toggle span {
  @apply absolute top-[0.188rem] bottom-1 start-[0.188rem] block w-[1.25rem] rounded-[0.125rem] bg-white shadow-sm cursor-pointer transition-all duration-[0.2s] ease-linear
    before:-start-[1.563rem] before:content-["on"] after:content-["off"] after:-end-[1.813rem] after:text-textmuted;
}
.toggle span::before,
.toggle span::after {
  @apply absolute text-[0.625rem] font-medium space-x-2 rtl:space-x-reverse uppercase top-[0.188rem] leading-[1.38] transition-all duration-[0.2s] ease-linear;
}
.toggle.on {
  @apply bg-primary/30;
}
.toggle.on span {
  @apply bg-primary before:text-primary start-[2.313rem];
}
.toggle.on.toggle-secondary {
  @apply bg-secondary/40;
}
.toggle.on.toggle-secondary span {
  @apply bg-secondary before:text-secondary;
}
.toggle.on.toggle-warning {
  @apply bg-warning/40;
}
.toggle.on.toggle-warning span {
  @apply bg-warning before:text-warning;
}
.toggle.on.toggle-info {
  @apply bg-info/40;
}
.toggle.on.toggle-info span {
  @apply bg-info before:text-info;
}
.toggle.on.toggle-success {
  @apply bg-success/40;
}
.toggle.on.toggle-success span {
  @apply bg-success before:text-success;
}
.toggle.on.toggle-danger {
  @apply bg-danger/40;
}
.toggle.on.toggle-danger span {
  @apply bg-danger before:text-danger;
}
.toggle.on.toggle-light {
  @apply bg-light/40;
}
.toggle.on.toggle-light span {
  @apply bg-light before:text-textmuted;
}
.toggle.on.toggle-dark {
  @apply bg-black/40;
}
.toggle.on.toggle-dark span {
  @apply bg-black before:text-white;
}
.toggle.on span {
  @apply start-[2.313rem];
}
.toggle.on.toggle-sm span {
  @apply start-[2.313rem] before:-top-[1px] before:-start-[1.563rem];
}
.toggle.on.toggle-lg span {
  @apply start-[2.563rem] before:top-2 before:-start-[1.75rem];
}
.toggle.toggle-sm {
  @apply h-[1.063rem] w-[3.125rem];
}
.toggle.toggle-sm span {
  @apply w-[0.625rem] h-[0.625rem] after:-end-[1.875rem] after:-top-[1px];
}
.toggle.toggle-lg {
  @apply h-[2.125rem] w-[4.5rem];
}
.toggle.toggle-lg span {
  @apply w-[1.75rem] after:top-2 after:-end-[1.938rem];
}

/* Start:: toggle switches-2 */
.custom-toggle-switch > input[type="checkbox"] {
  @apply hidden;
}

.custom-toggle-switch > label {
  @apply cursor-pointer h-0 relative w-[2.5rem];
}

.label-primary {
  @apply bg-primary text-white;
}

.label-secondary {
  @apply bg-secondary text-white;
}

.label-warning {
  @apply bg-warning text-white;
}

.label-info {
  @apply bg-info text-white;
}

.label-success {
  @apply bg-success text-white;
}

.label-danger {
  @apply bg-danger text-white;
}

.label-light {
  @apply bg-light text-white;
}

.label-dark {
  @apply bg-black text-white;
}

.custom-toggle-switch > input[type="checkbox"]:checked + label::before {
  @apply bg-inherit opacity-[0.5];
}

.custom-toggle-switch > label {
  @apply before:bg-textmuted before:rounded-md before:h-4 before:-mt-2 before:absolute before:opacity-[0.3] before:transition-all before:duration-[0.4s] before:ease-in-out before:w-[2.5rem]
  after:bg-white after:rounded-[1rem] after:h-[1.5rem]  after:shadow-[0_0_0.313rem_rgba(228,229,237,0.8)] after:-start-1 after:-mt-2 after:absolute after:-top-1 after:transition-all after:duration-[0.3s] after:ease-in-out after:w-[1.5rem];
}

.custom-toggle-switch > input[type="checkbox"]:checked + label::after {
  @apply bg-inherit start-[50%];
}

.custom-toggle-switch.toggle-sm > label::before {
  @apply h-[10px] w-[27px] rounded-[10px];
}

.custom-toggle-switch.toggle-sm input[type="checkbox"]:checked + label::after {
  @apply start-[13px];
}

.custom-toggle-switch.toggle-sm > label::after {
  @apply h-[17px] w-[17px] rounded-full;
}

.custom-toggle-switch.toggle-lg > label::before {
  @apply h-[27px] w-[55px] rounded-[20px];
}

.custom-toggle-switch.toggle-lg input[type="checkbox"]:checked + label::after {
  @apply start-[77%];
}

.custom-toggle-switch.toggle-lg > label::after {
  @apply h-[35px] w-[35px] -mt-[8px] rounded-full;
}

/* End:: toggle switches-2 */
/* End:: toggle switches-1 */
/* End Form Styles */
.form-control-sm {
  @apply text-[0.8rem] py-1 px-[0.8rem] border-inputborder  dark:border-white/10 !important;
}

.form-control {
  @apply text-defaulttextcolor bg-white text-[0.875rem] font-normal leading-[1.6] rounded-[0.35rem] py-2 px-[0.85rem] w-full;
}
.form-control:focus {
  @apply shadow-none border-inputborder bg-white dark:bg-bodybg text-defaulttextcolor;
}

.form-control:disabled,
.form-select:disabled {
  @apply bg-light text-defaulttextcolor;
}

.form-control-plaintext {
  @apply block w-full p-[0.375rem] mb-0 leading-6 bg-transparent border-transparent border-0;
}

.form-input-color {
  @apply h-[2.25rem] w-[2.25rem] rounded-md overflow-hidden p-0;
}

.form-text {
  @apply mt-1 text-[0.875em] !important;
}

.form-check {
  @apply block ps-0 mb-[0.125rem];
}
.form-check .form-check-label {
  @apply ps-2;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  @apply cursor-default opacity-50;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  @apply rounded-s-none !important;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  @apply rounded-e-none !important;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  @apply py-2 px-4 text-[1.25rem] rounded-md !important;
}

.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  @apply py-1 px-2 text-[0.875rem] !important;
}

.form-range {
  @apply w-full h-[0.5rem] p-0 bg-light rounded-md appearance-none;
}

.form-range:focus {
  @apply outline-none;
}

.form-range:disabled {
  @apply pointer-events-none;
}

.form-control-color {
  @apply w-[1.75rem] h-[1.75rem] overflow-hidden p-0 rounded-md !important;
}

input[type="week"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:invert !important;
}

input[type="week"],
input[type="month"],
input[type="date"],
input[type="datetime-local"],
input[type="time"] {
  @apply rtl:text-end rtl:dir-rtl !important;
}
input[type="week"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply rtl:text-end rtl:dir-rtl !important;
}

.form-select {
  @apply rtl:bg-left rtl:bg-[0.5rem] !important;
}

/* Start Modal Styles */
.ti-modal {
  @apply size-full fixed top-0 start-0 z-[80] hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none;
}
.ti-modal .ti-modal-header {
  @apply flex justify-between items-center py-3 px-4 border-b dark:border-white/10;
}
.ti-modal .ti-modal-box {
  @apply sm:max-w-lg sm:w-full m-3 sm:mx-auto;
}
.ti-modal .ti-modal-content {
  @apply flex flex-col pointer-events-auto bg-white border shadow-sm rounded-sm dark:dark:bg-bodybg dark:border-white/10 dark:shadow-black/[0.05];
}
.ti-modal .ti-modal-title {
  @apply font-bold text-gray-800 dark:text-white;
}
.ti-modal .ti-modal-close-btn {
  @apply inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 dark:text-white dark:hover:text-white/70 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white transition-all text-sm dark:focus:ring-white/10 dark:focus:ring-offset-white/10;
}
.ti-modal .ti-modal-body {
  @apply p-4;
}
.ti-modal .ti-modal-footer {
  @apply flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-white/10;
}

/* End Modal Styles */
/* Start Offcanvas Styles */
.ti-offcanvas {
  @apply fixed transition-all duration-300 transform h-full bg-white dark:dark:bg-bodybg dark:border-white/10 z-[150] shadow-md;
}
.ti-offcanvas.ti-offcanvas-left {
  @apply ltr:-translate-x-full rtl:translate-x-full top-0 start-0 max-w-[27.5rem] w-full border-e;
}
.ti-offcanvas.ti-offcanvas-top {
  @apply -translate-y-full top-0 inset-x-0 max-h-40 w-full border-b;
}
.ti-offcanvas.ti-offcanvas-right {
  @apply ltr:translate-x-full rtl:-translate-x-full top-0 end-0 max-w-[27.5rem] w-full border-s;
}
.ti-offcanvas.ti-offcanvas-bottom {
  @apply translate-y-full bottom-0 inset-x-0 max-h-40 w-full border-b;
}
.ti-offcanvas.open {
  @apply translate-x-0  translate-y-0 !important;
}
.ti-offcanvas .ti-offcanvas-header {
  @apply flex justify-between items-center py-3 px-4 border-b dark:border-white/10;
}
.ti-offcanvas .ti-offcanvas-title {
  @apply font-medium text-gray-800 dark:text-white;
}
.ti-offcanvas .ti-offcanvas-body {
  @apply overflow-y-auto h-screen px-5 pb-80;
}
.ti-offcanvas .ti-offcanvas-footer {
  @apply py-3 px-[1.563rem] absolute bottom-0  w-full bg-white dark:bg-bodybg  border-t border-dashed border-defaultborder dark:border-defaultborder/10 shadow-md !important;
}

/* End Offcanvas Styles */
.switcher-style-head {
  @apply text-[0.8rem] font-semibold mb-0 bg-light py-[0.313rem] px-[0.625rem] text-defaulttextcolor;
}

.switcher-style {
  @apply py-[0.875rem] px-[1.563rem];
}

[type="radio"] {
  @apply text-primary !important;
}

.theme-colors.switcher-style {
  @apply py-[0.938rem] px-[1.563rem];
}
.theme-colors [type="radio"] {
  @apply bg-none checked:border-[3px] checked:border-gray-400 dark:checked:border-gray-300 checked:ring-primary focus:border-primary ring-primary focus:ring-primary  !important;
}
.theme-colors .switch-select .color-input {
  @apply w-8 h-8 rounded-full;
}
.theme-colors .switch-select .color-input.form-check-input:checked {
  @apply border border-defaultborder border-solid relative before:absolute before:content-[""] before:text-success before:w-full
                before:h-full before:flex before:items-center before:justify-center before:text-[1.35rem] before:font-semibold;
}
.theme-colors .switch-select .color-input.color-white {
  @apply bg-white;
}
.theme-colors .switch-select .color-input.color-dark {
  @apply bg-black;
}
.theme-colors .switch-select .color-input.color-primary {
  @apply bg-primary;
}
.theme-colors .switch-select .color-input.color-primary-1 {
  @apply bg-[#3a5892];
}
.theme-colors .switch-select .color-input.color-primary-2 {
  @apply bg-[#5c90a3];
}
.theme-colors .switch-select .color-input.color-primary-3 {
  @apply bg-[#a15adf];
}
.theme-colors .switch-select .color-input.color-primary-4 {
  @apply bg-[#4eac4c];
}
.theme-colors .switch-select .color-input.color-primary-5 {
  @apply bg-[#df5a5a];
}
.theme-colors .switch-select .color-input.color-gradient {
  @apply bg-gradient-to-tr from-primary to-[#6e72a8] !important;
}
.theme-colors .switch-select .color-input.color-transparent {
  @apply bg-[url("../public/assets/images/menu-bg-images/transparent.png")] bg-cover bg-center !important;
}
.theme-colors .switch-select .color-input.color-bg-1 {
  @apply bg-[#141e60];
}
.theme-colors .switch-select .color-input.color-bg-2 {
  @apply bg-[#084e73];
}
.theme-colors .switch-select .color-input.color-bg-3 {
  @apply bg-[#5a2587];
}
.theme-colors .switch-select .color-input.color-bg-4 {
  @apply bg-[#186533];
}
.theme-colors .switch-select .color-input.color-bg-5 {
  @apply bg-[#784214];
}

.menu-image .bgimage-input {
  @apply w-[3.5rem] h-[5.625rem] rounded-md border-0;
}
.menu-image .bgimage-input.form-check-input:focus {
  @apply border-transparent shadow-sm;
}
.menu-image .bgimage-input.bg-img1 {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img1.jpg")] bg-center bg-cover bg-no-repeat !important;
}
.menu-image .bgimage-input.bg-img1.form-check-input:checked[type="radio"] {
  @apply bg-none !important;
}
.menu-image .bgimage-input.bg-img2 {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img2.jpg")] bg-center bg-cover bg-no-repeat !important;
}
.menu-image .bgimage-input.bg-img2.form-check-input:checked[type="radio"] {
  @apply bg-none !important;
}
.menu-image .bgimage-input.bg-img3 {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img3.jpg")] bg-center bg-cover bg-no-repeat !important;
}
.menu-image .bgimage-input.bg-img3.form-check-input:checked[type="radio"] {
  @apply bg-none !important;
}
.menu-image .bgimage-input.bg-img4 {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img4.jpg")] bg-center bg-cover bg-no-repeat !important;
}
.menu-image .bgimage-input.bg-img4.form-check-input:checked[type="radio"] {
  @apply bg-none !important;
}
.menu-image .bgimage-input.bg-img5 {
  @apply bg-[url("../public/assets/images/menu-bg-images/bg-img5.jpg")] bg-center bg-cover bg-no-repeat !important;
}
.menu-image .bgimage-input.bg-img5.form-check-input:checked[type="radio"] {
  @apply bg-none !important;
}

.pickr-container-primary .pickr .pcr-button,
.pickr-container-background .pickr .pcr-button {
  @apply h-8 w-8 overflow-hidden rounded-full border border-solid bg-primary border-inputborder -top-[4px]  after:text-white/70 after:text-[1.25rem] !important;
}
.pickr-container-primary .pickr .pcr-button:focus,
.pickr-container-background .pickr .pcr-button:focus {
  @apply shadow-none;
}

#hs-overlay-switcher .theme-colors .theme-container-primary button,
#hs-overlay-switcher .theme-colors .theme-container-background button {
  @apply hidden !important;
}

.pickr-container-primary .pickr .pcr-button,
.pickr-container-background .pickr .pcr-button {
  @apply -top-[4px] h-8 w-8 overflow-hidden rounded-full border border-solid bg-primary !important;
}

.pickr-container-primary .pickr .pcr-button::after,
.pickr-container-background .pickr .pcr-button::after {
  @apply content-[""] font-["remixicon"] text-white/70  !important;
}

/* Start Pagination Styles */
.ti-pagination {
  @apply flex items-center px-3 py-[0.375rem];
}
.ti-pagination li .page-link {
  @apply border text-defaulttextcolor text-[1rem] border-defaultborder dark:bg-transparent dark:border-defaultborder/10  dark:text-defaulttextcolor/70 dark:hover:border-defaultborder/10;
}
.ti-pagination li .page-link.active {
  @apply bg-primary text-white dark:text-defaulttextcolor/70 dark:bg-primary !important;
}
.ti-pagination li .page-link.disabled {
  @apply pointer-events-none text-gray-500 dark:bg-bodybg;
}
.ti-pagination li .page-link:focus {
  @apply shadow-none bg-light;
}
.ti-pagination li .page-link:hover {
  @apply text-primary bg-light border-defaultborder;
}
.ti-pagination li:first-child .page-link {
  @apply rounded-s-md;
}
.ti-pagination li:last-child .page-link {
  @apply rounded-e-md;
}
.ti-pagination.pagination-sm li .page-link {
  @apply py-2 px-2 text-xs;
}
.ti-pagination.pagination-sm li:first-child .page-link {
  @apply rounded-s-md;
}
.ti-pagination.pagination-sm li:last-child .page-link {
  @apply rounded-e-md;
}
.ti-pagination.pagination-lg li .page-link {
  @apply py-3 sm:px-6 px-3 text-lg;
}
.ti-pagination.pagination-lg li:first-child .page-link {
  @apply rounded-s-md;
}
.ti-pagination.pagination-lg li:last-child .page-link {
  @apply rounded-e-md;
}

.pagination-style-1 .ti-pagination li {
  @apply space-x-2 rtl:space-x-reverse;
}
.pagination-style-1 .ti-pagination li .page-link {
  @apply border-0 rounded-md leading-none sm:px-3 px-3 py-[0.375rem] dark:bg-bodybg;
}

.pagination-style-2 .ti-pagination li {
  @apply space-x-2 rtl:space-x-reverse border-0;
}
.pagination-style-2 .ti-pagination li.active .page-link {
  @apply border-0 bg-white  rounded-md leading-none sm:px-3 py-[0.375rem] dark:bg-bodybg
                         text-primary relative font-bold  before:absolute before:h-[0.1rem] before:w-full before:inset-x-0 before:bottom-[-7px] before:bg-primary !important;
}

.pagination-style-3 .ti-pagination li {
  @apply space-x-2 rtl:space-x-reverse;
}
.pagination-style-3 .ti-pagination li .page-link {
  @apply border-0 rounded-full leading-none px-3 py-[0.375rem]  !important;
}

.pagination-style-4 .ti-pagination li {
  @apply space-x-2 rtl:space-x-reverse text-[.8125rem] !important;
}
.pagination-style-4 .ti-pagination li .page-link {
  @apply border-0 rounded-md leading-none px-3 py-[0.375rem];
}
.pagination-style-4 .ti-pagination li .page-link.active {
  @apply bg-primary text-white !important;
}
.pagination-style-4 .ti-pagination li .page-link.active .page-link {
  @apply hover:bg-primary hover:text-white !important;
}

/* End Pagination Styles */
.progress {
  @apply bg-light h-[0.75rem] rounded-sm flex w-full;
}

/* Start:: progressbar-height */
.progress.progress-xs,
.progress-stacked.progress-xs {
  @apply h-[0.3125rem];
}
.progress.progress-sm,
.progress-stacked.progress-sm {
  @apply h-[0.5rem];
}
.progress.progress-lg,
.progress-stacked.progress-lg {
  @apply h-[1rem];
}
.progress.progress-xl,
.progress-stacked.progress-xl {
  @apply h-[1.25rem];
}

/* End:: progressbar-height */
.progress-stacked {
  @apply bg-light;
}

.progress-bar {
  @apply text-white bg-primary flex justify-center items-center rounded-s-full;
}

.progress-animate {
  @apply relative rounded-[0.625rem];
}
.progress-animate .progress-bar {
  @apply relative rounded-[0.625rem];
}

/* Start:: custom-progress */
.progress-custom {
  @apply overflow-visible relative;
}
.progress-custom .progress-bar {
  @apply relative overflow-visible after:w-[1.15rem] after:h-[1.15rem] after:rounded-[50%] after:bg-white after:absolute after:-end-[0.375rem]
        after:-top-[0.375rem] after:border-[0.25rem] after:border-solid after:border-primary;
}
.progress-custom .progress-bar.progress-secondary {
  @apply bg-secondary after:border-secondary !important;
}
.progress-custom .progress-bar.progress-secondary .progress-bar-value {
  @apply after:border-t-secondary;
}
.progress-custom .progress-bar.progress-warning {
  @apply bg-warning after:border-warning !important;
}
.progress-custom .progress-bar.progress-warning .progress-bar-value {
  @apply after:border-t-warning;
}
.progress-custom .progress-bar.progress-info {
  @apply bg-info after:border-info !important;
}
.progress-custom .progress-bar.progress-info .progress-bar-value {
  @apply after:border-t-info;
}
.progress-custom .progress-bar.progress-success {
  @apply bg-success after:border-success !important;
}
.progress-custom .progress-bar.progress-success .progress-bar-value {
  @apply after:border-t-success;
}
.progress-custom .progress-bar.progress-danger {
  @apply bg-danger after:border-danger !important;
}
.progress-custom .progress-bar.progress-danger .progress-bar-value {
  @apply after:border-t-danger;
}
.progress-custom .progress-bar-value {
  @apply w-[1.875rem] h-[1.25rem] leading-[1.25rem] rounded-[.188rem] bg-primary shadow-[0_0.313rem_0.313rem_rgba(0,0,0,.4)] dark:shadow-[rgba(255,255,255,.4)] text-[.625rem] font-semibold text-white text-center absolute bottom-6 end-[-0.688rem]
    after:absolute after:border-x-[0.438rem] after:border-x-transparent after:border-t-[0.438rem] after:border-primary after:bottom-[-0.375rem] after:start-[28%];
}

.progress-bar-title {
  @apply py-[0.313rem] px-[0.625rem] m-0 bg-primary rounded-s-sm text-[0.625rem] font-semibold text-white uppercase absolute -top-[0.438rem] start-0 z-[1]
    after:absolute  after:top-0 after:-end-[1.063rem] after:border-t-[.8rem] after:border-b-[.85rem] after:border-transparent after:border-s-[1.063rem] after:border-solid after:border-s-primary;
}

/* Start:: custom-progress-2 */
.progress-item-1,
.progress-item-2,
.progress-item-3 {
  @apply absolute -mt-1 z-[1] h-[0.938rem] w-[0.98rem] rounded-[1.563rem] bg-light;
}

.progress-item-1 {
  @apply start-[25%];
}

.progress-item-2 {
  @apply start-[50%];
}

.progress-item-3 {
  @apply start-[75%];
}

/* End:: custom-progress-2 */
/* Start:: custom-progress-3 */
.custom-progress-3 {
  @apply visible rounded-[0.625rem];
}
.custom-progress-3 .progress-bar {
  @apply relative visible m-[0.313rem] rounded-sm
        before:absolute before:w-1 before:h-1 before:start-[0.125rem] before:bg-white before:rounded-md;
}
.custom-progress-3 .progress-bar .progress-bar-value {
  @apply border-[0.125rem] border-solid border-primary w-[2.5rem] h-[1.563rem] leading-[1.563rem] rounded-[3.125rem] bg-white dark:bg-bodybg text-[0.625rem] font-semibold
            text-primary text-center absolute -end-[0.625rem];
}
.custom-progress-3 .progress-bar .progress-bar-value.primary {
  @apply border-[0.125rem] border-solid border-primary text-primary;
}
.custom-progress-3 .progress-bar .progress-bar-value.secondary {
  @apply border-[0.125rem] border-solid border-secondary text-secondary;
}
.custom-progress-3 .progress-bar .progress-bar-value.warning {
  @apply border-[0.125rem] border-solid border-warning text-warning;
}
.custom-progress-3 .progress-bar .progress-bar-value.info {
  @apply border-[0.125rem] border-solid border-info text-info;
}
.custom-progress-3 .progress-bar .progress-bar-value.success {
  @apply border-[0.125rem] border-solid border-success text-success;
}
.custom-progress-3 .progress-bar .progress-bar-value.danger {
  @apply border-[0.125rem] border-solid border-danger text-danger;
}

/* End:: custom-progress-3 */
/* Start:: custom-progress-4 */
.custom-progress-4.progress {
  @apply bg-primary/10 rounded-sm;
}
.custom-progress-4.progress.secondary {
  @apply bg-secondary/10;
}
.custom-progress-4.progress.secondary .progress-bar-label {
  @apply text-secondary;
}
.custom-progress-4.progress.warning {
  @apply bg-warning/10;
}
.custom-progress-4.progress.warning .progress-bar-label {
  @apply text-warning;
}
.custom-progress-4.progress.info {
  @apply bg-info/10;
}
.custom-progress-4.progress.info .progress-bar-label {
  @apply text-info;
}
.custom-progress-4.progress.success {
  @apply bg-success/10;
}
.custom-progress-4.progress.success .progress-bar-label {
  @apply text-success;
}
.custom-progress-4.progress.danger {
  @apply bg-danger/10;
}
.custom-progress-4.progress.danger .progress-bar-label {
  @apply text-danger;
}
.custom-progress-4.progress .progress-bar {
  @apply rounded-sm;
}
.custom-progress-4.progress .progress-bar-label {
  @apply absolute end-[0.313rem] font-semibold text-[0.813rem] text-primary;
}

/* End:: custom-progress-4 */
/* Start Table Styles */
.table {
  @apply text-defaulttextcolor rounded-md mb-0 text-start;
}
.table tr {
  @apply dark:border-defaultborder/10;
}
.table tbody tr th {
  @apply font-medium;
}
.table th,
.table td {
  @apply p-[0.75rem] align-middle leading-[1.462] text-[0.813rem] font-medium text-start;
}
.table thead tr th {
  @apply font-semibold text-[0.85rem];
}
.table.table-sm > :not(caption) > * > * {
  @apply p-[0.3rem];
}
.table.table-dark {
  @apply text-white/70 border-white/10 dark:border-black/[0.025];
}
.table.table-primary {
  @apply text-primary/70 border-primary/10;
}
.table.table-bordered.table-bordered-primary tbody,
.table.table-bordered.table-bordered-primary td,
.table.table-bordered.table-bordered-primary tfoot,
.table.table-bordered.table-bordered-primary th,
.table.table-bordered.table-bordered-primary thead,
.table.table-bordered.table-bordered-primary tr {
  @apply border-primary/30 !important;
}
.table.table-bordered.table-bordered-info tbody,
.table.table-bordered.table-bordered-info td,
.table.table-bordered.table-bordered-info tfoot,
.table.table-bordered.table-bordered-info th,
.table.table-bordered.table-bordered-info thead,
.table.table-bordered.table-bordered-info tr {
  @apply border-info/30;
}
.table.table-bordered.table-bordered-secondary tbody,
.table.table-bordered.table-bordered-secondary td,
.table.table-bordered.table-bordered-secondary tfoot,
.table.table-bordered.table-bordered-secondary th,
.table.table-bordered.table-bordered-secondary thead,
.table.table-bordered.table-bordered-secondary tr {
  @apply border-secondary/30;
}
.table.table-bordered.table-bordered-warning tbody,
.table.table-bordered.table-bordered-warning td,
.table.table-bordered.table-bordered-warning tfoot,
.table.table-bordered.table-bordered-warning th,
.table.table-bordered.table-bordered-warning thead,
.table.table-bordered.table-bordered-warning tr {
  @apply border-warning/30;
}
.table.table-bordered.table-bordered-success tbody,
.table.table-bordered.table-bordered-success td,
.table.table-bordered.table-bordered-success tfoot,
.table.table-bordered.table-bordered-success th,
.table.table-bordered.table-bordered-success thead,
.table.table-bordered.table-bordered-success tr {
  @apply border-success/30;
}
.table.table-bordered.table-bordered-danger tbody,
.table.table-bordered.table-bordered-danger td,
.table.table-bordered.table-bordered-danger tfoot,
.table.table-bordered.table-bordered-danger th,
.table.table-bordered.table-bordered-danger thead,
.table.table-bordered.table-bordered-danger tr {
  @apply border-danger/30;
}
.table.table-striped > tbody > tr:nth-of-type(odd) > * {
  @apply text-defaulttextcolor;
}
.table.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  @apply text-defaulttextcolor;
}
.table tbody.table-group-divider {
  @apply border-t border-solid rounded-md;
}
.table.table-hover > tbody > tr:hover > * {
  @apply text-defaulttextcolor;
}
.table .table-active {
  @apply text-defaulttextcolor;
}

.ti-custom-table-head {
  @apply divide-y divide-gray-200 dark:divide-white/10;
}

.table-responsive,
.table {
  @apply overflow-y-visible !important;
}

.table-responsive {
  @apply block w-full overflow-x-auto;
}

.table-responsive {
  @apply overflow-x-auto;
}

.ti-custom-table {
  @apply min-w-full;
}
.ti-custom-table th {
  @apply px-6 py-2 text-start leading-[1.462] font-semibold text-xs uppercase bg-gray-50 dark:bg-black/20 dark:text-white;
}
.ti-custom-table tbody {
  @apply divide-y divide-gray-200 dark:divide-white/10;
}
.ti-custom-table td {
  @apply px-6 py-4 whitespace-nowrap text-sm dark:text-gray-200 font-medium;
}

.ti-striped-table tbody tr {
  @apply odd:bg-white even:bg-gray-100 dark:odd:dark:bg-bodybg dark:even:bg-black/20;
}

.ti-custom-table-hover tbody tr {
  @apply hover:bg-gray-100 dark:hover:bg-light;
}

.table-bordered {
  @apply border dark:border-defaultborder/10;
}
.table-bordered tr {
  @apply divide-x rtl:divide-x-reverse divide-gray-200 dark:divide-white/10;
}

.table-bordered-primary {
  @apply border border-primary/30 dark:border-primary/30;
}
.table-bordered-primary .ti-custom-table-head {
  @apply divide-y divide-primary/30 dark:divide-primary/30;
}
.table-bordered-primary tbody {
  @apply divide-y divide-primary/30 dark:divide-primary/30;
}
.table-bordered-primary tr {
  @apply divide-x rtl:divide-x-reverse divide-primary/30 dark:divide-primary/30;
}

.table-bordered-secondary {
  @apply border border-secondary/30 dark:border-secondary/30;
}
.table-bordered-secondary .ti-custom-table-head {
  @apply divide-y divide-secondary/30 dark:divide-secondary/30;
}
.table-bordered-secondary tbody {
  @apply divide-y divide-secondary/30 dark:divide-secondary/30;
}
.table-bordered-secondary tr {
  @apply divide-x rtl:divide-x-reverse divide-secondary/30 dark:divide-secondary/30;
}

.table-bordered-warning {
  @apply border border-warning/30 dark:border-warning/30;
}
.table-bordered-warning .ti-custom-table-head {
  @apply divide-y divide-warning/30 dark:divide-warning/30;
}
.table-bordered-warning tbody {
  @apply divide-y divide-warning/30 dark:divide-warning/30;
}
.table-bordered-warning tr {
  @apply divide-x rtl:divide-x-reverse divide-warning/30 dark:divide-warning/30;
}

.table-bordered-danger {
  @apply border border-danger/30 dark:border-danger/30;
}
.table-bordered-danger .ti-custom-table-head {
  @apply divide-y divide-danger/30 dark:divide-danger/30;
}
.table-bordered-danger tbody {
  @apply divide-y divide-danger/30 dark:divide-danger/30;
}
.table-bordered-danger tr {
  @apply divide-x rtl:divide-x-reverse divide-danger/30 dark:divide-danger/30;
}

.table-bordered-info {
  @apply border border-info/30 dark:border-info/30;
}
.table-bordered-info .ti-custom-table-head {
  @apply divide-y divide-info/30 dark:divide-info/30;
}
.table-bordered-info tbody {
  @apply divide-y divide-info/30 dark:divide-info/30;
}
.table-bordered-info tr {
  @apply divide-x rtl:divide-x-reverse divide-info/30 dark:divide-info/30;
}

.table-bordered-success {
  @apply border border-success/30 dark:border-success/30;
}
.table-bordered-success .ti-custom-table-head {
  @apply divide-y divide-success/30 dark:divide-success/30;
}
.table-bordered-success tbody {
  @apply divide-y divide-success/30 dark:divide-success/30;
}
.table-bordered-success tr {
  @apply divide-x rtl:divide-x-reverse divide-success/30 dark:divide-success/30;
}

.table-primary {
  @apply bg-primary/10;
}
.table-primary th {
  @apply text-black dark:text-white;
}
.table-primary tbody {
  @apply divide-y divide-primary/10 dark:divide-primary/10;
}
.table-primary td {
  @apply text-gray-800 dark:text-gray-200;
}
.table-primary tr {
  @apply divide-x rtl:divide-x-reverse divide-primary/10 dark:divide-primary/10;
}
.table-primary .ti-custom-table-head {
  @apply divide-y divide-primary/10 dark:divide-primary/10;
}

.table-dark {
  @apply bg-black/10 dark:bg-bodybg2;
}
.table-dark th {
  @apply text-black dark:text-white;
}
.table-dark tbody {
  @apply divide-y divide-black/10 dark:divide-black/[0.025];
}
.table-dark td {
  @apply text-black dark:text-white;
}
.table-dark tr {
  @apply divide-x rtl:divide-x-reverse divide-black/10 dark:divide-black/[0.025];
}
.table-dark .ti-custom-table-head {
  @apply divide-y divide-black/10 dark:divide-black/[0.025];
}

.table-secondary {
  @apply bg-secondary/10;
}
.table-secondary th {
  @apply text-black dark:text-white;
}
.table-secondary tbody {
  @apply divide-y divide-secondary/10 dark:divide-secondary/10;
}
.table-secondary td {
  @apply text-gray-800 dark:text-gray-200;
}
.table-secondary tr {
  @apply divide-x rtl:divide-x-reverse divide-secondary/10 dark:divide-secondary/10;
}
.table-secondary .ti-custom-table-head {
  @apply divide-y divide-secondary/10 dark:divide-secondary/10;
}

.table-warning {
  @apply bg-warning/10;
}
.table-warning th {
  @apply text-black dark:text-white;
}
.table-warning tbody {
  @apply divide-y divide-warning/10 dark:divide-warning/10;
}
.table-warning td {
  @apply text-gray-800 dark:text-gray-200;
}
.table-warning tr {
  @apply divide-x rtl:divide-x-reverse divide-warning/10 dark:divide-warning/10;
}
.table-warning .ti-custom-table-head {
  @apply divide-y divide-warning/10 dark:divide-warning/10;
}

.table-info {
  @apply bg-info/10;
}
.table-info th {
  @apply text-black dark:text-white;
}
.table-info tbody {
  @apply divide-y divide-info/10 dark:divide-info/10;
}
.table-info td {
  @apply text-gray-800 dark:text-gray-200;
}
.table-info tr {
  @apply divide-x rtl:divide-x-reverse divide-info/10 dark:divide-info/10;
}
.table-info .ti-custom-table-head {
  @apply divide-y divide-info/10 dark:divide-info/10;
}

.table-danger {
  @apply bg-danger/10;
}
.table-danger th {
  @apply text-black dark:text-white;
}
.table-danger tbody {
  @apply divide-y divide-danger/10 dark:divide-danger/10;
}
.table-danger td {
  @apply text-gray-800 dark:text-gray-200;
}
.table-danger tr {
  @apply divide-x rtl:divide-x-reverse divide-danger/10 dark:divide-danger/10;
}
.table-danger .ti-custom-table-head {
  @apply divide-y divide-danger/10 dark:divide-danger/10;
}

.table-success {
  @apply bg-success/10;
}
.table-success th {
  @apply text-black dark:text-white;
}
.table-success tbody {
  @apply divide-y divide-success/10 dark:divide-success/10;
}
.table-success td {
  @apply text-gray-800 dark:text-gray-200;
}
.table-success tr {
  @apply divide-x rtl:divide-x-reverse divide-success/10 dark:divide-success/10;
}
.table-success .ti-custom-table-head {
  @apply divide-y divide-success/10 dark:divide-success/10;
}

.ti-head-primary th {
  @apply bg-primary/20 dark:bg-primary/20;
}

.ti-head-secondary th {
  @apply bg-secondary/20 dark:bg-secondary/20;
}

.ti-head-warning th {
  @apply bg-warning/20 dark:bg-warning/20;
}

.ti-head-success th {
  @apply bg-success/20 dark:bg-success/20;
}

.ti-head-info th {
  @apply bg-info/20 dark:bg-info/20;
}

.ti-head-danger th {
  @apply bg-danger/20 dark:bg-danger/20;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  @apply bg-black/[0.0125] text-defaulttextcolor;
}

.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  @apply bg-black/[0.0125] text-defaulttextcolor dark:bg-black/20;
}

.table.table-success.table-striped > tbody > tr:nth-of-type(odd) > * {
  @apply bg-success/20;
}

caption {
  @apply text-textmuted pt-2 pb-2 text-start;
}

.table-active {
  @apply bg-light;
}

/* End Table Styles */
/* Start Tooltip Styles */
.ti-main-tooltip {
  @apply inline-block relative;
}
.ti-main-tooltip .ti-main-tooltip-toggle {
  @apply block text-center;
}

.ti-main-tooltip-content {
  @apply leading-4 hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-50 py-2 px-4 bg-white text-sm text-gray-600 rounded-md shadow-md dark:bg-bodybg dark:text-white/70;
}

/* End Tooltip Styles */
.toast-container {
  @apply z-[1090]  max-w-full pointer-events-none;
}

.toast {
  @apply bg-white border border-solid border-defaultborder shadow-sm rounded-md;
}
.toast .toast-header {
  @apply bg-light py-[0.375rem] px-3 flex items-center;
}
.toast .toast-header img {
  @apply w-[1.25rem] h-[1.25rem];
}
.toast .toast-header {
  @apply border-b border-solid border-defaultborder;
}
.toast .toast-header .btn-close {
  @apply me-[0.125rem] p-3;
}
.toast .toast-body {
  @apply text-[0.8rem] p-3;
}
.toast .btn-close {
  @apply text-[0.563rem];
}
.toast.colored-toast {
  @apply border-0 backdrop-blur-[20px] !important;
}
.toast.colored-toast .btn-close {
  @apply invert-[1];
}
.toast.colored-toast .toast-header {
  @apply border-b border-solid border-black/10;
}

.bd-example-toasts {
  @apply min-h-[15rem];
}

/* TAILWIND STYLES */
.ps__thumb-y {
  @apply bg-[#f3f6f8];
}

.ps__rail-y:hover > .ps__thumb-y {
  @apply bg-[#f3f6f8];
}

.range-slider__thumb {
  @apply left-[calc(50%+0px)] !important;
}

.range-slider__range {
  @apply left-0 w-[50%] !important;
}

.MuiSlider-root.MuiSlider-colorPrimary {
  @apply text-[#845adf];
}

.range-slider .range-slider__thumb {
  @apply bg-[#845adf];
}

.range-slider .range-slider__range {
  @apply bg-[#845adf];
}

.square-thumb.range-slider .range-slider__thumb {
  @apply rounded-[5px];
}

.single-thumb .range-slider__range {
  @apply rounded-[6px];
}

.dzu-inputLabel {
  @apply text-[#536485] bg-[#f6f6f6];
}

.table-bordered {
  @apply w-full !important;
}

#search-modal .hidden {
  display: none;
}

#search-modal .ti-modal-body .box-body .ti-list-group {
  @apply p-3 rounded-none border-b-0;
}
#search-modal .ti-modal-body .box-body .ti-list-group:first-child {
  @apply p-3 rounded-tl-sm rounded-tr-sm border-b-0 !important;
}
#search-modal .ti-modal-body .box-body .ti-list-group:last-child {
  @apply border-b !important;
}

[data-width="boxed"] .content {
  @apply dark:bg-bodybg2 !important;
}

[data-width="boxed"] body {
  @apply dark:bg-bodybg/60 !important;
}

.box-fullscreen .terms-heading-cover {
  @apply rounded-none !important;
}

.rmsc .dropdown-container {
  @apply flex items-center border border-defaultborder dark:border-defaultborder/10 h-[38px] !important;
}

[dir="rtl"] .rmsc .item-renderer input {
  @apply me-2 mt-0 mb-0 ms-0 !important;
}

@media (max-width: 575px) {
  .overlay-card .card-text {
    @apply text-[0.613rem] !important;
  }
}
.react-select__menu {
  @apply bg-white dark:bg-bodybg border border-defaultborder dark:border-defaultborder/10 !important;
}
.react-select__menu ul,
.react-select__menu div {
  @apply bg-white dark:bg-bodybg text-defaulttextcolor  dark:text-defaulttextcolor/80 !important;
}
.react-select__menu ul .react-select__option--is-focused,
.react-select__menu div .react-select__option--is-focused {
  @apply bg-primary text-white !important;
}

.react-select__option--is-focused {
  @apply bg-primary text-white !important;
}

#secondary-colored-slider .css-1diafny-MuiSlider-root {
  @apply text-secondary !important;
}

#warning-colored-slider .MuiSlider-root {
  @apply text-warning !important;
}

#info-colored-slider .MuiSlider-root {
  @apply text-info !important;
}

#success-colored-slider .MuiSlider-root {
  @apply text-success !important;
}

#danger-colored-slider .MuiSlider-root {
  @apply text-danger !important;
}

.dzu-dropzone {
  @apply border-0 !important;
}

@media (min-width: 621px) {
  .companies-search-input .rmsc .dropdown-container {
    @apply rounded-none !important;
  }
}
@media (max-width: 622px) {
  .companies-search-input .Select2__control {
    @apply mb-3;
  }
  .create-nft-item
    .filepond--wrapper
    .circular-filepond.filepond--root[data-style-panel-layout~="circle"] {
    @apply h-[13.75rem] w-[13.75rem] !important;
  }
}
#btc-chart .apexcharts-canvas,
#btc-chart .apexcharts-svg,
#eth-chart .apexcharts-canvas,
#eth-chart .apexcharts-svg,
#glm-chart .apexcharts-canvas,
#glm-chart .apexcharts-svg,
#dash-chart .apexcharts-canvas,
#dash-chart .apexcharts-svg,
#lite-chart .apexcharts-canvas,
#lite-chart .apexcharts-svg,
#ripple-chart .apexcharts-canvas,
#ripple-chart .apexcharts-svg,
#eos-chart .apexcharts-canvas,
#eos-chart .apexcharts-svg,
#bytecoin-chart .apexcharts-canvas,
#bytecoin-chart .apexcharts-svg,
#iota-chart .apexcharts-canvas,
#iota-chart .apexcharts-svg,
#monero-chart .apexcharts-canvas,
#monero-chart .apexcharts-svg {
  @apply w-[120px] !important;
}

[data-nav-layout="horizontal"][data-menu-styles="light"]
  .main-menu-container
  .slide-right,
[data-nav-layout="horizontal"][data-menu-styles="light"] .slide-left {
  @apply bg-[#fff] border-defaultborder !important;
}
[data-nav-layout="horizontal"][data-menu-styles="light"]
  .main-menu-container
  .slide-right
  svg,
[data-nav-layout="horizontal"][data-menu-styles="light"] .slide-left svg {
  @apply fill-[#536485] !important;
}

@media (min-width: 992px) {
  [data-nav-layout="horizontal"] .footer {
    @apply ps-0 !important;
  }
}
#projectAnalysis .apexcharts-tooltip-title,
#btc-chart .apexcharts-tooltip-title,
#eth-chart .apexcharts-tooltip-title,
#dash-chart .apexcharts-tooltip-title {
  @apply border-b-[$default-border] border-0 border-b border-solid !important;
}

@media (min-width: 992px) {
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply start-auto !important;
  }
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70;
}

.product-checkout nav .btn.active {
  @apply text-primary !important;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-sorter {
  @apply rtl:left-1 rtl:right-auto !important;
}

#visitors-countries svg {
  @apply w-full h-[23.4rem] !important;
}

.react-select__indicators span {
  @apply w-0 !important;
}

.ps__thumb-y {
  @apply bg-gray-200 !important;
}

code[class*="language-"] {
  @apply max-h-[inherit] h-[inherit] block overflow-auto px-[1em] py-0;
}

pre[class*="language-"] > code {
  @apply relative z-[1] shadow-[-1px_0_0_0_#358ccb,0_0_0_1px_#dfdfdf] bg-[#fdfdfd] bg-[linear-gradient(transparent_50%,rgba(69,142,209,0.04)_50%)] bg-[3em_3em] bg-origin-content bg-local border-l-[10px] border-l-[#358ccb] border-solid;
}

code[class*="language-"],
pre[class*="language-"] {
  @apply text-black text-[1em] text-left whitespace-pre break-normal leading-normal bg-transparent  hyphens-none;
}

#SvgjsLine4971 {
  @apply hidden;
}

.Select2__placeholder {
  @apply opacity-40;
}

.swiper-view .swiper-slide {
  @apply border border-[rgb(var(--default-border))] dark:border-defaultborder/10 bg-[rgb(255_255_255_/_var(--tw-bg-opacity))] rounded-lg border-solid mt-[15px] !important;
}

.swiper-view .swiper-slide {
  @apply dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.h5 {
  @apply text-xl;
}

.h6 {
  @apply text-base;
}

#profile-posts-scroll,
#full-calendar-activity {
  @apply overflow-y-auto;
}

#profile-posts-scroll {
  @apply max-h-[35rem];
}

.h3 {
  @apply text-[1.75rem];
}

.ti-btn-group .ti-dropdown {
  @apply me-2;
}

.total-mail-recepients {
  @apply flex-col;
}

.color-picker-input input {
  @apply w-8 h-8 rounded-[0.5rem] cursor-pointer relative;
}
.color-picker-input input[type="color" i]::-webkit-color-swatch {
  @apply border border-primary bg-primary rounded-[50%] !important;
}
.color-picker-input input[type="color" i]::-webkit-color-swatch-wrapper {
  @apply p-0 absolute top-0;
}

.scrollbar-container {
  @apply relative h-full;
}

.max-h-\[28\.375rem\] {
  @apply max-h-[29.375rem] !important;
}

.table-responsive,
.table {
  @apply overflow-y-visible !important;
}

.rmsc .dropdown-container {
  @apply border-defaultborder dark:border-defaultborder/10 !important;
}

.apexcharts-svg {
  @apply w-full !important;
}

.color-picker-input input {
  @apply rounded-full !important;
}

.ti-main-tooltip-content {
  @apply border !important;
}

.css-13cymwt-control {
  @apply shadow-none !important;
}

.css-t3ipsp-control {
  @apply shadow-none !important;
}

.danger-input .css-13cymwt-control {
  @apply border-danger !important;
}

.success-input .css-13cymwt-control {
  @apply border-success !important;
}

@media (max-width: 575px) {
  .box-img-overlay .box-text {
    @apply text-[0.613rem] !important;
  }
}
.ti-offcanvas-title {
  @apply text-[1rem] font-medium !important;
}

#stars-unlimited {
  @apply overflow-hidden !important;
}

.selectpage {
  @apply rounded-sm bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.btn-outline-light {
  @apply px-3 py-2 border rounded-sm border-defaultborder dark:border-defaultborder/10 !important;
}

@media (max-width: 575px) {
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-right {
    @apply top-[21px] !important;
  }
}
#chart-year .flat-select {
  @apply hidden;
}

.under-maintenance-time {
  @apply border-2 border-dashed border-black/10 dark:border-white/10 rounded-md !important;
}

.aunthentication-cover-content .swiper-pagination-bullet {
  @apply dark:bg-white/30 !important;
}
.aunthentication-cover-content .swiper-pagination-bullet-active {
  @apply dark:bg-white !important;
}

.btn-check + .btn.btn-outline-primary:hover,
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  @apply text-white bg-primary border-primary !important;
}

#reactivity-table td {
  @apply text-start !important;
}

.validation-statedata .react-select__indicator {
  @apply p-0.5;
}

[dir="rtl"] .swiper {
  direction: ltr !important;
}
[dir="rtl"] .swiper-backface-hidden .swiper-slide {
  direction: rtl !important;
}

#crm-main .apexcharts-canvas,
#crm-main .apexcharts-svg {
  @apply w-auto !important;
}

[data-hs-tab].active.hs-tab-active\:bg-success\/20 {
  @apply bg-success/20 !important;
}

.range-slider .range-slider__thumb,
.range-slider .range-slider__range {
  @apply bg-[#7950d1] !important;
}

#top-collector ::-webkit-scrollbar {
  @apply w-0 !important;
}

.box.custom-box .box-header .box-title .subtitle {
  @apply normal-case mb-0;
}

.fs-12 {
  @apply text-xs;
}

@media (min-width: 1400px) {
  [data-width="boxed"] .xxxl\:col-span-3,
  [data-width="boxed"] .xxxl\:col-span-4,
  [data-width="boxed"] .xxxl\:col-span-6,
  [data-width="boxed"] .xxxl\:col-span-8 {
    @apply col-span-12 !important;
  }
}
@media (max-width: 460px) {
  .main-header-container .header-content-right .ti-dropdown-menu {
    @apply w-full !important;
  }
}
h3 {
  font-size: 1.75rem;
}

.react-calendar {
  @apply dark:bg-bodybg  border-defaultborder dark:border-defaultborder/70 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  @apply bg-bodybg dark:bg-bodybg2 rounded-md !important;
}

.react-calendar__tile--active {
  @apply rounded-md bg-primary !important;
}

.dzu-inputLabel {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70  !important;
}

.end-2 {
  @apply right-2.5 rtl:left-2.5;
}

[type="checkbox"].ti-switch:focus {
  @apply dark:border-defaultborder/20 !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps .ps__rail-y:hover,
.ps__thumb-y {
  @apply dark:bg-bodybg2 !important;
}

.dzu-inputLabel {
  @apply dark:bg-bodybg2 !important;
}

.dzu-dropzone {
  @apply dark:border-defaultborder/20;
}

.main-chat-area .scrollbar-container {
  height: calc(100vh - 21rem) !important;
}

select {
  @apply dark:bg-bodybg2;
}

.ti-main-tooltip .ti-main-tooltip-toggle.hs-tooltip-toggle {
  @apply flex !important;
}

input.gridjs-input {
  @apply dark:border-defaultborder/20 !important;
}

.app-sidebar .simplebar-wrapper {
  @apply h-full;
}

.app-sidebar .simplebar-placeholder {
  @apply h-[100px] !important;
}

.ti-btn-outline-light:hover {
  @apply dark:border-defaultborder/20 !important;
}

.ti-pagination li .page-link:hover {
  @apply dark:border-defaultborder/20 !important;
}

.react-select__input-container {
  @apply dark:text-defaulttextcolor/70  !important;
}

[dir="rtl"] .form-select {
  @apply bg-[left_0.5rem_center];
}

.hs-dropdown.ti-dropdown:focus-visible {
  @apply outline-none  !important;
}

.tabulator .tabulator-header .tabulator-header-contents {
  @apply relative overflow-hidden;
}

th.gridjs-th {
  @apply w-[200px];
}

.react-select__single-value {
  @apply dark:text-defaulttextcolor/70 !important;
}

.rmsc .dropdown-container {
  @apply dark:bg-bodybg !important;
}

.search-company .Select2__control {
  @apply min-h-[40px] border-e-0;
}

@media (max-width: 991.98px) {
  .mail-navigation {
    @apply hidden;
  }
}
.avatar-img {
  @apply rounded-none;
}

h6 {
  @apply text-base !important;
}

.flex-none {
  @apply flex-[none];
}

.react-calendar.ti-form-input {
  @apply dark:border-defaultborder/10 !important;
}

#add-task .react-datepicker {
  @apply w-[21rem];
}

.ti-chevron-right:before {
  @apply rtl:content-[""];
}

#todo-compose .Select2__menu,
#add-task .Select2__menu {
  @apply bottom-auto !important;
}

@media (min-width: 992px) {
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .main-menu
    > .slide.has-sub
    > .side-menu__item {
    @apply ps-[0.75rem] pe-[1.75rem] !important;
  }
}
@media screen and (min-width: 992px) and (hover: none) and (pointer: coarse) {
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .slide
    .side-menu__item {
    @apply z-0;
  }
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .slide.has-sub
    .side-menu__item {
    @apply z-[-1];
  }
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .slide
    .slide-menu
    .slide
    .side-menu__item {
    @apply z-0;
  }
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .slide
    .slide-menu
    .slide.has-sub
    .side-menu__item {
    @apply z-[-1];
  }
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .slide
    .slide-menu
    .slide-menu
    .slide
    .side-menu__item {
    @apply z-0;
  }
  [data-nav-style="menu-hover"][data-toggled="menu-hover-closed"]
    .slide
    .slide-menu
    .slide-menu
    .slide.has-sub
    .side-menu__item {
    @apply z-[-1];
  }
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide
    .side-menu__item {
    @apply z-0;
  }
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide.has-sub
    .side-menu__item {
    @apply z-[-1];
  }
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide
    .slide-menu
    .slide
    .side-menu__item {
    @apply z-0;
  }
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide
    .slide-menu
    .slide.has-sub
    .side-menu__item {
    @apply z-[-1];
  }
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide
    .slide-menu
    .slide-menu
    .slide
    .side-menu__item {
    @apply z-0;
  }
  [data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
    .slide
    .slide-menu
    .slide-menu
    .slide.has-sub
    .side-menu__item {
    @apply z-[-1];
  }
}
.avatar.avatar-lg i.custom-lg-icon {
  @apply text-[1.25rem] w-auto h-auto !important;
}

@media (min-width: 991.98px) and (max-width: 992px) {
  [data-nav-layout="horizontal"]
    .landing-body
    .landing-banner
    .landing-banner-heading {
    @apply text-[2.5rem] !important;
  }
}
.file-manager-navigation .ps__rail-x,
.file-manager-navigation .ps__thumb-x {
  @apply hidden !important;
}

@media (min-width: 992px) {
  [data-nav-layout="vertical"][data-menu-styles="gradient"][class="dark"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][icon-overlay="open"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white {
    @apply block !important;
  }
}
@media (min-width: 992px) {
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.active {
    @apply left-auto right-auto !important;
  }
}
@media (min-width: 992px) {
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child1
    .side-menu__item,
  [data-nav-style="menu-click"][data-nav-layout="horizontal"]
    .app-sidebar
    .slide-menu.child2
    .side-menu__item {
    @apply py-[0.45rem] !important;
  }
  [data-nav-layout="horizontal"][data-header-position="scrollable"]
    .app-header {
    @apply z-[49] !important;
  }
  .dark[data-vertical-style="doublemenu"][data-toggled="double-menu-close"][data-menu-styles="dark"]
    .app-sidebar
    .main-sidebar-header {
    @apply border-e-[1px];
  }
  [data-vertical-style="detached"][data-header-styles="transparent"].dark
    .app-header
    .horizontal-logo
    .header-logo
    .desktop-white {
    @apply hidden;
  }
}
#hs-overlay-switcher .ti-offcanvas-title {
  @apply text-[1.25rem] !important;
}

.apexcharts-tooltip {
  @apply transition-none !important;
}

.collections-data .hs-dropdown-menu.ti-dropdown-menu {
  inset: auto 20px 75px auto !important;
  transform: none !important;
}

#slider-square .MuiSlider-thumbSizeMedium {
  @apply rounded-sm;
}

#users-map span {
  @apply hidden;
}

@media (min-width: 992px) {
  [data-nav-layout="vertical"][data-menu-styles="gradient"][class="dark"]
    .app-sidebar
    .main-sidebar-header
    .header-logo
    .desktop-white {
    @apply block;
  }
}
#vector-map svg {
  @apply h-[337px] w-full;
}

#marker-map svg {
  @apply h-[360px] w-full !important;
}

#russia-map svg,
#us-map svg,
#marker-image-map svg,
#lines-map svg,
#marker-map svg {
  @apply h-[310px] w-full !important;
}

.dropzone-file-upload .file_input {
  @apply h-40 w-full border-[rgb(var(--default-border))] rounded-lg;
}

@media (min-width: 768px) {
  .basicsteps {
    @apply flex !important;
  }
}
.basicsteps {
  @apply block justify-center border-[rgb(var(--default-border))] border-b border-dashed;
}

.basicsteps .btn.active {
  @apply text-defaulttextcolor;
}

.basicsteps .btn {
  @apply relative inline-flex w-full grow cursor-pointer items-center text-center text-sm font-medium text-[rgb(var(--default-text-color))] px-8 py-4;
}

.deals-content {
  @apply touch-none;
}

[dir="rtl"] .ql-tooltip.ql-editing {
  @apply left-auto right-[200px] top-0 !important;
}

.main-chat-area .ps--active-x {
  @apply overflow-auto;
}

[data-nav-layout="horizontal"][data-nav-style="menu-click"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child1.force-left,
[data-nav-layout="horizontal"][data-nav-style="menu-hover"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child1.force-left,
[data-nav-layout="horizontal"][data-nav-style="icon-click"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child1.force-left,
[data-nav-layout="horizontal"][data-nav-style="icon-hover"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child1.force-left {
  @apply end-0 !important;
}

[data-nav-layout="horizontal"][data-nav-style="menu-click"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child2.force-left,
[data-nav-layout="horizontal"][data-nav-style="menu-click"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child3.force-left,
[data-nav-layout="horizontal"][data-nav-style="menu-hover"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child2.force-left,
[data-nav-layout="horizontal"][data-nav-style="menu-hover"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child3.force-left,
[data-nav-layout="horizontal"][data-nav-style="icon-click"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child2.force-left,
[data-nav-layout="horizontal"][data-nav-style="icon-click"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child3.force-left,
[data-nav-layout="horizontal"][data-nav-style="icon-hover"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child2.force-left,
[data-nav-layout="horizontal"][data-nav-style="icon-hover"]
  .app-sidebar
  .slide.has-sub
  .slide-menu.child3.force-left {
  @apply start-[-100%] !important;
}

.pickr-container-primary .pickr .pcr-button {
  @apply after:bg-primary !important;
}

.pickr-container-background .pickr .pcr-button {
  @apply after:bg-primary !important;
}

[data-header-position="scrollable"][data-menu-position="scrollable"][data-nav-style="menu-click"][data-nav-layout="vertical"][data-toggled="menu-click-closed"]
  .app-sidebar {
  @apply absolute !important;
}

@media (min-width: 992px) {
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    @apply rounded-se-none rounded-ss-none !important;
  }
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3 {
    @apply rounded-ss-none rounded-es-none !important;
  }
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1.force-left {
    @apply rounded-se-none rounded-ss-none !important;
  }
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="menu-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-hover"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child2.force-left,
  [data-nav-layout="horizontal"][data-nav-style="icon-click"]
    .app-sidebar
    .slide.has-sub
    .slide-menu.child3.force-left {
    @apply rounded-se-none rounded-ee-none !important;
  }
}
.slide.has-sub.open > .side-menu__item .side-menu__angle {
  @apply rotate-90;
}

#search-modal .search-tags {
  @apply me-2;
}

.search-result .box-header {
  overflow: scroll;
}

.ecommerce-sale-image img {
  @apply h-[16.7rem] !important;
}

#buy-crypto1 .Select2__menu,
#buy-crypto2 .Select2__menu {
  @apply mt-3 !important;
}

.react-datepicker-popper .react-datepicker__triangle {
  @apply fill-white text-white dark:fill-bodybg dark:text-bodybg !important;
}

.react-datepicker-popper .react-datepicker__triangle {
  @apply stroke-defaultborder dark:stroke-defaultborder/10 !important;
}

.ti-main-tooltip-content {
  @apply border-0 !important;
}

.product-checkout .basicsteps .btn.active {
  @apply border-b-[.175rem] border-primary !important;
}
.product-checkout .basicsteps .btn.active .basicstep-icon {
  @apply bg-primary/10 text-primary w-[1.875rem] h-[1.875rem] flex items-center justify-center rounded-full me-2 !important;
}
.product-checkout .basicsteps .btn .basicstep-icon {
  @apply bg-light w-[1.875rem] h-[1.875rem] flex items-center justify-center rounded-full me-2 !important;
}

.basicsteps {
  @apply border-defaultborder dark:border-defaultborder/10;
}

.hs-accordion code {
  @apply ms-1 !important;
}

.light-select .Select2__control {
  @apply bg-light !important;
}

.icon-style .avatar-lg i {
  @apply text-[22px] !important;
}

.custom-form-group .custom-form-btn {
  @apply rtl:right-auto;
}

.react-datepicker-time__input {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.object-fit-container {
  @apply flex justify-center items-center !important;
}

.ti-dropdown-item {
  @apply flex !important;
}

.main-header-container .header-content-right .ti-dropdown-item {
  @apply block !important;
}

.MuiSlider-root {
  @apply w-full !important;
}

.dropzone-file-upload .file_input {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.input-group {
  @apply flex-nowrap !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-primary/10 text-primary !important;
}

.chrome-picker,
.sketch-picker {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 border !important;
}
.chrome-picker input,
.sketch-picker input {
  @apply bg-light shadow-none text-defaulttextcolor dark:text-defaulttextcolor/70 border-defaultborder dark:border-defaultborder/10 !important;
}
.chrome-picker .flexbox-fix,
.sketch-picker .flexbox-fix {
  @apply border-defaultborder dark:border-defaultborder/10 !important;
}

#hs-wrapper-select-for-copy select {
  @apply w-full border-defaultborder dark:border-defaultborder/10 rounded-sm bg-none !important;
}

.MuiRating-iconEmpty {
  @apply dark:text-defaultbackground/20 !important;
}

@media (max-width: 400px) {
  .MuiRating-icon {
    @apply text-[1.5rem] !important;
  }
}
@media (max-width: 520px) {
  #rangearea-basic .apexcharts-toolbar,
  #rangearea-combo .apexcharts-toolbar {
    @apply hidden !important;
  }
}
.Select2__menu {
  @apply mt-3 !important;
}

.companies-search-input .Select2__input-container {
  @apply h-[34px] !important;
}

.create-nft-item
  .circular-filepond.filepond--root[data-style-panel-layout~="circle"] {
  @apply h-[15.75rem] w-[15.75rem] m-0 rounded-sm !important;
}
.create-nft-item
  .filepond--root[data-style-panel-layout~="integrated"]
  .filepond--panel-root {
  @apply rounded-sm !important;
}
.create-nft-item .filepond.circular-filepond .filepond--panel-root {
  @apply rounded-sm !important;
}

#wrapper svg {
  @apply bg-white dark:bg-bodybg !important;
}

[data-menu-styles="color"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply hidden !important;
}

[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .toggle-white {
  @apply hidden !important;
}
[data-menu-styles="gradient"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .desktop-white {
  @apply block !important;
}

[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply hidden !important;
}
[data-menu-styles="light"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .desktop-logo {
  @apply block !important;
}

[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .toggle-logo {
  @apply hidden !important;
}
[data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .desktop-logo {
  @apply block !important;
}

[data-width="boxed"] body {
  @apply dark:bg-bodybg !important;
}

[data-nav-layout="horizontal"] .app-sidebar .slide.has-sub .slide-menu.child1 {
  @apply start-auto !important;
}

[data-page-style="modern"] .switcher-style {
  @apply bg-[#f3f6f8] dark:bg-bodybg;
}

#crm-revenue-analytics .apexcharts-toolbar {
  display: none;
}

.ql-container .ql-editor .ql-video body {
  @apply text-defaulttextcolor dark:text-defaulttextcolor/70 !important;
}

.js-example-templating .Select2__single-value img {
  display: none;
}

[class="dark"] #hs-toggle-password-with-checkbox {
  @apply border rounded-[3px] border-solid border-[#ffffff1f];
  background: rgb(var(--dark-bg));
}
[class="dark"]
  [data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .desktop-logo {
  @apply hidden !important;
}
[class="dark"]
  [data-menu-styles="transparent"][data-vertical-style="overlay"][data-toggled="icon-overlay-close"][data-icon-overlay="open"]
  .app-sidebar
  .header-logo
  .desktop-dark {
  @apply block !important;
}

.yarl__slide_image {
  border-radius: 5px;
}

@media (min-width: 992px) {
  [data-menu-styles="light"][data-nav-layout="horizontal"].dark
    .app-sidebar
    .slide.has-sub
    .slide-menu.child1 {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2) !important;
  }
}
.Select2__indicator.Select2__dropdown-indicator {
  @apply ms-2;
}

.rcp {
  @apply mt-[10px];
}

.text-pink {
  @apply text-[pink];
}

[data-nav-layout="vertical"] .app-sidebar .main-menu {
  @apply me-0 ms-0 !important;
}

.ti-btn-list .ti-dropdown a {
  @apply me-0 ms-0 !important;
}

#bubble-3d .apexcharts-toolbar,
#bubble-simple .apexcharts-toolbar {
  @apply hidden !important;
}

.Select2__control.Select2__control--is-disabled {
  @apply dark:border-defaultborder/10 !important;
}

#marker-image-map {
  @apply dirrtl !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-primary text-white;
}

#addtask .react-datepicker__input-container {
  @apply w-[120px] !important;
}

.react-datepicker__input-container input {
  @apply py-[0.375rem] px-3 relative flex-1 w-full min-w-0 h-[36px] rounded-tr-[0.35rem] rounded-br-[0.35rem]  border border-defaultborder dark:border-defaultborder/10 text-defaulttextcolor !important;
}
.react-datepicker__input-container input:focus-within {
  @apply border-0 outline-0 !important;
}

[dir="rtl"] .react-datepicker__input-container input {
  @apply rounded-tl-[0.35rem] rounded-bl-[0.35rem]  rounded-tr-[0] rounded-br-[0] !important;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  @apply -left-[29px] !important;
}

.react-datepicker-wrapper {
  @apply block  !important;
}

.react-datepicker__header {
  @apply border-defaultborder dark:border-defaultborder/10 !important;
}
.react-datepicker__header .react-datepicker__current-month,
.react-datepicker__header .react-datepicker-time__header,
.react-datepicker__header .react-datepicker-year-header {
  @apply text-defaulttextcolor !important;
}

.react-datepicker {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 text-defaulttextcolor !important;
}

.react-datepicker__time-container .react-datepicker__time {
  @apply bg-white dark:bg-bodybg !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  @apply border-b-defaultborder dark:border-t-defaultborder/10 !important;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle:after,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle:before {
  @apply border-t-defaultborder dark:border-t-defaultborder/10 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  @apply bg-primary !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  @apply bg-primary !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply text-defaulttextcolor !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply bg-primary text-white !important;
}

.sun-editor-editable {
  @apply bg-white dark:bg-bodybg;
}

.react-datepicker__day--selected {
  @apply bg-primary text-white !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  @apply text-white !important;
}

.react-datepicker__header {
  @apply bg-white dark:bg-bodybg !important;
}

.react-datepicker__input-container svg {
  @apply hidden;
}

.rmsc .dropdown-heading {
  padding-block: 10px;
}

.rmsc .dropdown-container:focus-within {
  @apply shadow-none;
}

.react-datepicker__input-container input:focus-within {
  @apply border !important;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  @apply dark:bg-bodybg2 !important;
}

.react-datepicker__input-container input {
  @apply dark:bg-bodybg2;
}

.react-datepicker__time-container {
  @apply ltr:border-s rtl:border-s dark:border-defaultborder/10;
}

.react-datepicker__input-container input {
  @apply text-[13px];
}

.react-datepicker__close-icon {
  @apply rtl:left-4 rtl:right-auto !important;
}

@media (max-width: 370px) {
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    @apply right-[92px] !important;
  }
}
/* ###### 3.13 globalReactselect ###### */
.Select2__indicator-separator {
  @apply hidden;
}

.Select2__option:hover {
  @apply text-[#fff];
}

.Select2__menu {
  @apply m-0 !important;
}

.Select2__control {
  @apply shadow-none !important;
}

.background__indicator-separator {
  @apply hidden;
}

.default .Select2__multi-value__label {
  @apply py-[0.025rem] px-[0.625rem] bg-primary text-white !important;
}
.default .Select2__multi-value__remove {
  @apply hidden;
}

.passing-options .react-select__multi-value__remove {
  @apply hidden;
}

.Select2__menu div.active,
.Select2__menu div .Select2__option--is-selected,
.Select2__menu li.active,
.Select2__menu li .Select2__option--is-selected,
.Select2__menu .Select2__single-value.active,
.Select2__menu .Select2__single-value .Select2__option--is-selected {
  @apply bg-primary !important;
}

.rmsc.multi-select .dropdown-container,
.rdl-actions .rdl-move,
.Select2__control {
  @apply border-defaultborder bg-white;
}

.multi-select .dropdown-content {
  @apply bg-white border border-defaultborder;
}
.multi-select .dropdown-content div {
  @apply bg-white;
}
.multi-select .dropdown-content div label:hover,
.multi-select .dropdown-content div label.selected {
  @apply bg-white;
}

.Select2__menu {
  @apply bg-white border border-defaultborder;
}

.Select2__option--is-selected,
.Select2__option--is-focused {
  @apply bg-white text-defaulttextcolor !important;
}

.Select2__option:hover {
  @apply bg-primary !important;
}

.Select2__control {
  @apply bg-white border-defaultborder dark:bg-bodybg;
}

.Select2__option img,
.Select2__single-value img {
  @apply w-[30px] h-[30px]  rounded-full  !important;
}

.input-group .buysell .Select2__input-container {
  @apply text-defaulttextcolor;
}
.input-group .buysell .Select2__control {
  @apply rounded-ss-none rounded-es-none min-h-[39px] border-defaultborder !important;
}

.Select2__control,
.Select2__menu {
  @apply border-defaultborder !important;
}

.Select2__option:hover {
  @apply text-[#fff] bg-primary !important;
}

.Select2__input-container,
.Select2__single-value {
  @apply text-defaulttextcolor !important;
}

.input-group .Select2__control {
  @apply rounded-ss-none rounded-es-none !important;
}

.Select2__menu div.active,
.Select2__menu div .Select2__option--is-selected,
.Select2__menu li.active,
.Select2__menu li .Select2__option--is-selected,
.Select2__menu .Select2__single-value.active,
.Select2__menu .Select2__single-value .Select2__option--is-selected {
  @apply text-[#fff] bg-primary !important;
}

.Select2__menu {
  @apply z-[3] !important;
}

.Select2__multi-value {
  @apply bg-primary text-[#fff] !important;
}

.Select2__multi-value__label {
  @apply text-[#fff] !important;
}

.react-select__control {
  @apply border-defaultborder !important;
}

.react-select__multi-value {
  @apply bg-primary !important;
}

.react-select__multi-value__label {
  @apply text-[#fff] !important;
}

.react-select__multi-value__remove svg {
  @apply fill-white !important;
}

.Select2__placeholder {
  @apply text-defaulttextcolor !important;
}

.rmsc {
  --rmsc-main: transparent;
  --rmsc-border: #e9edf6 !important;
  --rmsc-bg: #fff !important;
  --rmsc-main: #000;
  --rmsc-selected: #845adf1a !important;
  --rmsc-hover: #845adf1a !important;
  --rmsc-h: 42px !important;
}

.rmsc .dropdown-container {
  @apply bg-white border border-defaultborder !important;
}

.rmsc .dropdown-content {
  @apply border-defaultborder !important;
}

.Select2__dropdown-indicator {
  @apply after:absolute after:h-0 after:w-0 after:border-solid after:border-b-transparent 
  after:border-l-transparent after:border-t-textmuted after:border-r-transparent after:border-[5px] after:right-[11.5px] after:top-[50%] 
  after:-mt-[2.5px] after:pointer-events-none !important;
}
.Select2__dropdown-indicator svg {
  @apply hidden;
}

.Select2__indicator svg {
  @apply hidden !important;
}

.Select2__single-value div {
  @apply flex items-center !important;
}

.Select2__menu div div {
  @apply flex items-center !important;
}

/* ###### 3.13 globalReactselect ###### */
[dir="rtl"] .Select2__dropdown-indicator {
  @apply after:left-[11.5px] after:right-auto !important;
}

.css-13cymwt-control {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}
.css-13cymwt-control:focus {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.Select2__menu {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.css-t3ipsp-control {
  @apply bg-white dark:bg-bodybg border-defaultborder dark:border-defaultborder/10 !important;
}

.Select2__control {
  @apply rounded-sm !important;
}

.companies-search-input .Select2__control {
  @apply sm:rounded-none min-h-[40px] !important;
}

.crypto-buy-sell .Select2__control {
  @apply min-h-[40px] text-ellipsis overflow-hidden whitespace-nowrap w-[100px] !important;
}

.Select2__option--is-focused {
  @apply dark:bg-bodybg !important;
}

.crypto-data .Select2__control {
  @apply w-20 min-h-[44px] !important;
}

/* Default desktop styles */
.export-dropdown-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .export-dropdown-container {
    justify-content: center;
    margin-right: 0;
  }
}

/*# sourceMappingURL=style.css.map */
