@import "../public/assets/css/style.css";

//Packages css
@import "@simonwep/pickr/dist/themes/classic.min.css";
@import "@simonwep/pickr/dist/themes/monolith.min.css";
@import "@simonwep/pickr/dist/themes/nano.min.css";

@import "simplebar-react/dist/simplebar.min.css";

@media (max-width: 991.98px) {
  [data-toggled="open"] {
    #responsive-overlay {
      @apply visible #{!important};
      --tw-bg-opacity: 0.5;
    }
  }
}

@media screen and (min-width: 992px) {
  [data-vertical-style="doublemenu"]
    .app-sidebar
    .slide.has-sub.active
    > .slide-menu {
    display: block !important;
  }
}
